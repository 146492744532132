var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_html = pug_html + "\u003Cdiv id=\"Triangles\"\u003E";
pug_mixins["triangle"] = pug_interp = function(num, color){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cpolygon" + (" points=\"\" fill=\"none\""+pug.attr("stroke", color, true, true)+" stroke-width=\"5\"") + "\u003E\u003Canimate" + (" attributeName=\"points\" repeatCount=\"indefinite\" dur=\"4s\""+pug.attr("begin", num+"s", true, true)+" from=\"50 57.5, 50 57.5, 50 57.5\" to=\"50 -75, 175 126, -75 126\"") + "\u003E\u003C\u002Fanimate\u003E\u003C\u002Fpolygon\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"overlay\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"container\"\u003E";
for (var x = 1; x < 60; x++)
{
pug_html = pug_html + "\u003Csvg class=\"shape\" viewBox=\"0 0 100 115\" preserveAspectRatio=\"xMidYMin slice\"\u003E";
pug_mixins["triangle"](0, "hsl(0,0%,96%)");
pug_mixins["triangle"](1, "hsl(0,0%,97%)");
pug_mixins["triangle"](2, "hsl(0,0%,98%)");
pug_mixins["triangle"](3, "hsl(0,0%,99%)");
pug_html = pug_html + "\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;