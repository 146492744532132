import { Component, OnInit } from '@angular/core';
import { Dispute } from '../../../models/dispute';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-disputes',
  templateUrl: './admin-disputes.component.pug',
  styleUrls: ['./admin-disputes.component.styl']
})
export class AdminDisputesComponent implements OnInit {
	
	isAdmin = false;
	disputes: Dispute;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listDisputes().subscribe((dis) => {
				this.disputes = dis;
			});
		}
	}
}