import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'items',
  templateUrl: './items.component.pug',
  styleUrls: ['./items.component.styl']
})
export class ItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}