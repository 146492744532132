import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Point } from '../../../models/point';

@Component({
  selector: 'admin-points',
  templateUrl: './admin-points.component.pug',
  styleUrls: ['./admin-points.component.styl']
})
export class AdminPointsComponent implements OnInit {
	isAdmin = false;
	
	points: Point;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getusers() {
		this.userservice.allPoints().subscribe((pts) => {
			this.points = pts;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getusers();
			}
		});
	}
}
