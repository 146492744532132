import { environment } from '../../../environments/environment';
import { Component, HostListener, NgZone, OnInit, ViewChild, ElementRef, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { productMenu, ovL, properties, show, popin, showCart, flash, nextImageA, lastImageA, image, preloading, picture, titlespot } from './single.animations';
import { red } from '../products.animations';
import { fadeInFull } from '../../app.animations';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Product } from '../../models/product';
import { Info } from '../../models/info';
import { PaymentMethod } from '../../models/paymentmethod';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'product',
  templateUrl: './single.component.pug',
  styleUrls: ['./single.component.styl'],
  animations: [ red, image, preloading, picture, titlespot, fadeInFull, productMenu, properties, show, showCart, flash, ovL, nextImageA, lastImageA ]
})
export class ProductComponent implements OnInit {
	constructor(
  		private router: Router,
  		private location: Location,
  		private route: ActivatedRoute,
  		private marketplace: MarketplaceService,
  		private user: UserService,
  		private ngZone: NgZone
  	) { 
		router.events.subscribe( (event: Event) => {
            if (event instanceof NavigationEnd)
     			this.changeProduct();
        });
  	}
	@Input('prodctID') inputID = 0;
	@ViewChild('container') container: ElementRef;
	@ViewChild('Image') image: ElementRef;
	@ViewChild('PreloaderImage') preloaderimage: ElementRef;
	@ViewChild('MainImage') mainimage: ElementRef;
  	ce(mi?) {/*nw*/}
  	_dn() {return false;}
	_up() {return false;}
  	id: any;
  	cart: string;
	a = 0;
	si = 0; // selectedimage: number=0;
	ap = 0; // account's products: number=0;
	fw = 0;
	apc = 0;
  	TAX_PER_GRAM = 0;
  	FEE_PER_GRAM = 0;
	inititalQuantity = 0;
	oldScroll = 0;
	scrollY = 0;
	cart_count = 0;
	totalprice = 0;
	totalweight = 0;
  	imagePage = 0;
  	showOnPage = 5;
  	totalImages = 1;
  	SHIPPING = 10;
  	products: Product;
  	cartInfo: Info;
	otherProducts: Product;
	authorsProducts: Product;
	newReview = new Review();
	newQuestion = new Question();
	image_loaded = false;
	last_image_loaded = false;
	next_image_loaded = false;
	showConcentration = false;
	showMoreFromUser = false;
	showproperties = false;
	productMenu = false;
	itemsInCart = false;
	isFavorite = false;
	fadeIn = false;
	fadeInT = false;
  	ee = false;
	loaded = false;
	htmlView = true;
	flashItem = null;
	currencyDelimiter = '$';
	totalWegihtInCart: number;
	nextProductTitle: string;
	taxtotal: number;
	authorID: number;
	productID: number;
	cartProducts: any;
	authorName: any;
	autoCart: any;
	selected: any;
	pq = [0];
	view = [300, 300];
	selectedunit = [];
	showQuantity:any = [];
	//productDelimiter = 'x';
	shOv = 'false';
	maximgheight = 'auto';
	productDelimiter = '•';
	BlurPreload = false;
	sL = false;
	sM = true;
	iM = 'false';
	cw = '';
	ch = '';
	
	t = new Array();
	dots = new Array();
	hover = new Array();
	cartContents: Product;
	paymentoptions: PaymentMethod;
	single = new SingleProduct(1,'g');

	DotMatrics(a) {for(var l=1;l<=a;l++){this.dots.push(l);}}//<3

	back() {
		this.router.navigate(['/products'])
	}

	next() {
		this.router.navigate(['/edit'])
	}

	viewCart() {
		this.router.navigate(['/cart'])
	}

	nextImage() {
		this.imagePage=(this.imagePage>0)?this.imagePage-1:this.imagePage;
	}
	
	prevImage() {
		this.imagePage=(this.imagePage < this.totalImages-1)?this.imagePage+1:this.imagePage;
	}

	_addPrix(item,single) {
		return (single.quantity>0?single.quantity:1)*item.price;
	}

	_addTD(item,single) {
		var a = (item.taxes*(single.quantity>0?single.quantity:1))+item.delivery;
		return a;
	}

	_addTS(item,single) {
		var a = item.taxes*(single.quantity>0?single.quantity:1)+item.shipping;
		return a;
	}

	selectUnit(o,si?) {
		let i = this.selectedunit.indexOf(o.name);
		if (i > -1) {
			this.selectedunit = [];
			this.totalprice = Number(0);
			this.totalweight = Number(0);
		} else {
			this.selectedunit = [];
			if(si) this.si = (si <= this.totalImages-1) ? si : 0;
			this.totalprice = Number(Number(o.price));
			this.totalweight = Number(Number(o.weight));
			this.selectedunit.push(o.name);
		}
	}

	getPaymentOptions() {
		this.marketplace.paymentOptions().subscribe((opt) => {
			this.paymentoptions = opt;
		});
	}

	edit($event, a) {;
		this.router.navigate(['/edit',a.id])
	}


	publish($event, a) {
		this.marketplace.publish( a ).subscribe( ( e ) => {
			if (e) this.getMarketProduct();
		})
	}

	unpublish($event, a) {
		this.marketplace.unpublish( a ).subscribe( ( e ) => {
			if (e) this.getMarketProduct();
		})
	}

	getCart() {
		this.cartProducts = this.marketplace.parseCart();
		if( this.cartProducts ) {
			this.marketplace.products((this.cartProducts)?this.cartProducts:'-1').subscribe( (c) => {
				this.cartContents = c;
				this.itemsInCart = (c[0]) ? true : false;
				this.marketplace.cartInfo().subscribe( (c) => {
					this.cartInfo = c;
					this.totalWegihtInCart = c[0].weight;
					this.cart_count = c[0].length;
					if( this.cart_count > 0 ) {
						this.showOnPage = 3;
					} else {
						this.showOnPage = 5;
					}
				});
			});
		}
	}

	addToCart(product, quantity = this.single.quantity, i=0) {
		if (!product) return;
		if ( this.inCart( product,quantity,i ) ) {
			this.checkout();
			return;
		}
		if ( product.scaled == 'fixed' ) {
			for (var i = 0; i < Object(this.selectedunit).length; i++) {
				if ( isNaN(this.selectedunit[i]) ) {
					this.marketplace.addToCart( product.id, quantity, '', false, false, this.selectedunit[i]  );
				} else {
					for (var j = 0; j <= product.quantitiesCount; j++) {
						if ( product.quantities[j].in_stock == true ) {
							this.marketplace.addToCart( product.id, quantity, product.unit, product.quantities[j].weight, false, product.quantities[j].name );
							return;
						}
					}
				}
			}
		} else {
			this.marketplace.addToCart(product.id, quantity);
		}
		this.getCart();
	}

	addToCartAuto(p) {
		if (!p) return;
		if ( this.inCart( p.quantity, i ) ) {
			this.checkout();
			return;
		}
		if ( p.scaled == 'scaled' ) {
			this.marketplace.addToCart( p.id, p.order );
		} else if ( p.scaled == 'fixed' ) {
			for (var i = 0; i <= p.quantitiesCount; i++) {
				if ( p.quantities[i].in_stock == true ) {
					this.marketplace.addToCart( p.id, 1, 'g', p.quantities[i].weight, false, p.quantities[i].name );
					return;
				}
			}
		}
		this.getCart();
	}

	flash( id ) {
		if ( !this.flashItem ) {
			this.flashItem = id;
			setTimeout( () => {
				this.flashItem = null;
			}, 700 );
		}
	}

	getAuthorsProducts(id) {
		this.marketplace.randomproducts(null,id,null,null,null,'12').subscribe( (ap) => {
			this.authorsProducts = ap;
			this.apc = ap[0].count;
			if ( this.apc > 0 ) this.showMoreFromUser = true;
		});
	}

	taxrig(item?) {
		if ( item ) this.single.quantity = (this.single.quantity>item.quantity) ? item.quantity : this.single.quantity;
		this.taxtotal = (this.single.quantity * this.TAX_PER_GRAM);
	}

	favorite() {
		this.isFavorite = (this.isFavorite) ? false : true;
		this.marketplace.favorite( String(this.productID) ).subscribe( (bool) => {
			this.isFavorite = bool; this.shOv = 'fav';
			setTimeout( () => { this.shOv = 'false'; }, 5000);
		});
	}

	getMarketProduct() {
		this.loaded = false; this.fadeIn = false;
		this.marketplace.product(this.id).subscribe((r) => {
			if (r[0]) { 
				this.products = r;
				// this.marketplace.pageTitle(r[0].name+' | Tree St.');
				this.productID = r[0].id;
				this.authorID = r[0].accountid;
				this.authorName = r[0].account[0].username;
				this.totalImages = r[0].imagecount;
				if(this.totalImages>1) this.si = this.totalImages-1; //thumbnail fix
				this.isFavorite = r[0].favorite;
				this.loaded = true;
				this.getAuthorsProducts(r[0].accountid);
				this.DotMatrics(r[0].imagecount);
				// this.ugn(r[0].id);
				if( r[0].thc && r[0].total_thc && r[0].total_cbd && r[0].cbd ) {
					if ( r[0].thc != '0.000000' || r[0].total_thc != '0.000000' || r[0].total_cbd != '0.000000' || r[0].cbd != '0.000000' ) {
						this.showConcentration = true;
					}
				}
				if (!this.fadeInT) {
					this.fadeInT=true;
					setTimeout( () => { this.fadeIn = true; }, 210);
				} 
				setTimeout( () => {this.rsz();}, 200);
			}
		});
	}

	inCart( product, variety?, i? ) {
		if ( variety ) {
			if ( product && product.quantities.length > 0 && product.quantities[i] ){
				let n = this.marketplace.getUnitQuantities(product.quantities[i]);
				return (n > 0) ? true : false;
			}
		} else {
			if ( product && product.quantities.length > 0 && product.quantities[0] ){
				let n = this.marketplace.getUnitQuantities(product.quantities[0]);
				return (n > 0) ? true : false;
			}
		}
		return false;
	}

	ugn(id) {
		let nc = this.marketplace.numberInCart(id);
		this.single.quantity = nc;
		this.inititalQuantity = nc; 
	}

	ucb() { return ((this.single.quantity != this.inititalQuantity) || (this.single.quantity==0)); }

	arrowup(prod) {
		this.single.quantity = (this.products[0].quantity > this.single.quantity) ? this.single.quantity + 1 : this.single.quantity;
	}

	arrowdown(prod) {
		this.single.quantity = (this.single.quantity > 0) ? this.single.quantity - 1 : this.single.quantity;
	}

	prevProduct() {
		this.marketplace.getPrevProduct(this.id).subscribe( (itemnumber) => {
			this.changeProduct(itemnumber);
		});
	}
	nextProduct() {
		this.marketplace.getNextProduct(this.id).subscribe( (itemnumber) => {
			this.changeProduct(itemnumber);
		});
	}

	changeProduct(id=0, scroll=true) {
		this.id = (id>0) ? id : this.route.snapshot.paramMap.get("id");
		this.getMarketProduct();
		// if ( scroll ) location.hash = "#ts"
		this.image_loaded=false;
		this.last_image_loaded=false;
		this.next_image_loaded=false;
		if ( scroll ) this.toTop();
	}

	favoriteAuthor(p) {
		this.user.favoriteauthor(p.id).subscribe( (bool) => {
			if ( bool ) p.favorite = (p.favorite) ? false : true;
		});
	}

	isAuthorFavorite( p ) { return this.user.isauthorfavorite(p.id); }

	checkout() { this.router.navigate(['cart']);}

	toTop() {
		$("html, body").animate({ scrollTop: 0 }, "fast");
	}

	ngOnInit() { this.getMarketProduct();
		this.location.subscribe((x) => { 
			this.changeProduct(this.inputID,true); 
		});
		this.getPaymentOptions();
		this.getCart();
		this.taxrig();
		this.selectedunit = []
		this.nextProductTitle = 'true';
		setTimeout( () => {this.rsz();}, 300);
		setTimeout( () => {this.rsz();}, 700);
		setTimeout( () => {this.rsz();}, 1000);
		this.toTop();
	}

	ngOnDestroy() { 
		location.hash = "";
		this.image_loaded=false;
		this.last_image_loaded = false;
		this.next_image_loaded = false;
	}

	_sp_sh(a='') {
		if( a=='ups' || a=='fedex' || a=='dhl' ) return true;
		return false;
	}

	unitQuantities(o) {
		return this.marketplace.getUnitQuantities(o);
	}

	increaseunitquantity(p, o) {
		this.marketplace.addUnit(p, o);
		this.getCart();
	}

	reduceunitquantity(p, o) {
		this.marketplace.removeUnit(p, o);
		this.getCart();
	}

	removeUnit(o, p) {
		this.marketplace.removeUnitFromCart(o, p);
		this.getCart();
	}

	launch(e?){
		this.fw = this.fw + 1;
		if ( this.fw < 77 ) return;
		switch (e) {
			case "firework":
				this.ee=true;
			break;
		}
	}
	
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.rsz();
	}

	@HostListener('document:keyup', ['$event'])
	onKeyupHandler(event) {
		if ( event.code == 'ControlRight' || event.code == 'ShiftRight' ) this.shOv = 'false';
	} 

	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event) {
		if(!event.ctrlKey||!event.shiftKey) return;
		
		if ( event.code == 'ArrowUp' || event.code == 'KeyX' ) {
			this.ap=(this.ap>0)?this.ap-1:0;
		}
		if ( event.code == 'ArrowDown' || event.code == 'Period' ) {
			this.ap=(this.ap<this.apc-3)?this.ap+1:this.ap;
		}
		if ( event.code == 'KeyK' ) {
			this.router.navigate(['/cart']);
		}
		if ( event.code == 'KeyS' ) {
			let t = $(window).scrollTop() + 120;
			$('html,body').animate({ scrollTop: t }, 'fast');
		}
		if ( event.code == 'KeyW' || event.code == 'KeyL' ) {
			this.chM();
			this.toTop();
			this.shOv = 'img';
		}
		if ( event.code == 'KeyA' ) {
			this.prevProduct();
			this.shOv = 'prev';
		}
		if ( event.code == 'KeyL' ) {
			this.nextProduct();
			this.shOv = 'next';
		}
		if ( event.code == 'KeyG' ) {
			this.y();
		}
		if ( event.code == 'KeyF' ) {
			this.favorite();
		}
		if ( event.code == 'ControlRight' || event.code == 'ShiftRight' || event.code == 'ControlLeft' || event.code == 'ShiftLeft' ) {
			this.shOv='ctl';
		}
	}

	chM() {
		// if ( !this.iM || this.iM=='false' ) { this.iM = 'large'; }
		// else if ( this.iM=='large' ) { this.iM = 'true'; }
		// else if ( this.iM=='true' ) { this.iM = 'round'; }
		// else if ( this.iM=='round' ) { this.iM = 'false'; }
		if ( !this.iM || this.iM=='false' ) { this.iM = 'round'; }
		else if ( this.iM=='round' ) { this.iM = 'false'; }
	}

	y() { this.ee=(this.ee)?false:true; }

	rsz() {
		if( !this.container ) return;
		let a = this.container.nativeElement.offsetWidth;
		let b = (a > 70) ? a-35 : 100;
		
		if ( a < 768 ) {
			this.sM = false;
		} else {
			this.sM = true;
		}

		if ( a < 768 ) {
			this.cw = b+'px';
			this.ch = b+'px';
		} else { 
			this.cw = '';
			this.ch = '';
		}
	}

	_disp_sw( o, a = false, i? ) {
		let bb = false;var c=0;
		for (var t = o.supported_currencies.length - 1; t >= 0; t--) {
			if(o.supported_currencies[t]==i.currency)c++;
		}
		if (o.name!='Account Funds') bb=true;
		if (c==0) bb = false;
		return bb;
	}

}
export class Question {
	constructor(
		public body?: string
	) {}
}
export class Review {
	constructor(
		public body?: string,
		public rating?: any
	) {}
}
export class SingleProduct {
	constructor(
		public quantity?: number,
		public unit?: string,
		public offer?: number
	) {}
}