import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricing'
})
export class PricingPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if ( args ) {
		let values = [];
		for (var i = value.length - 1; i >= 0; i--) {
			if ( !args ) values.push(value[i]);
			if ( value[i].scaled == args) values.push( value[i] );
		}
		return values;
	} else {
		return value;
	}
  }

}
