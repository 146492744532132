import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iframe-ex',
  templateUrl: './example.iframe.component.pug',
  styleUrls: ['./example.iframe.component.styl']
})
export class IFrameExampleComponent implements OnInit {

	constructor( 
		private activated: ActivatedRoute,
		private sanitizer: DomSanitizer
	) {	
		this.storeID = this.activated.snapshot.paramMap.get("id");
		if (!this.storeID) this.storeID  = '8';
		this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.URL + this.storeID);
	}

	storeID: string;
	iframeURL: any;
	URL = 'http://localhost:4200/iframe/';

	ngOnInit() {
	}

}