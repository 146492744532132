
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/**
* Admin Module
**/

import { AdminComponent } from './admin.component';
import { AdminDeliveryComponent } from './admin-delivery/admin-delivery.component';
import { AdminIssuesComponent } from './admin-delivery/admin-issues/admin-issues.component';
import { AdminNotificationsComponent } from './admin-delivery/admin-notifications/admin-notifications.component';
import { AdminParcelsComponent } from './admin-delivery/admin-parcels/admin-parcels.component';
import { AdminReturnsComponent } from './admin-delivery/admin-returns/admin-returns.component';
import { AdminPaymentsComponent } from './admin-payments/admin-payments.component';
import { AdminWithdrawlsComponent } from './admin-payments/admin-withdrawls/admin-withdrawls.component';
import { AdminTransactionsComponent } from './admin-payments/admin-transactions/admin-transactions.component';
import { AdminDepositsComponent } from './admin-payments/admin-deposits/admin-deposits.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminCategoriesComponent } from './admin-products/admin-categories/admin-categories.component';
import { AdminPromotionsComponent } from './admin-products/admin-promotions/admin-promotions.component';
import { AdminReviewsComponent } from './admin-products/admin-reviews/admin-reviews.component';
import { AdminStatisticsComponent } from './admin-products/admin-statistics/admin-statistics.component';
import { AdminSalesComponent } from './admin-sales/admin-sales.component';
import { AdminCouponsComponent } from './admin-sales/admin-coupons/admin-coupons.component';
import { AdminDisputesComponent } from './admin-sales/admin-disputes/admin-disputes.component';
import { AdminSubscriptionsComponent } from './admin-sales/admin-subscriptions/admin-subscriptions.component';
import { AdminTaxesComponent } from './admin-sales/admin-taxes/admin-taxes.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminBalancesComponent } from './admin-users/admin-balances/admin-balances.component';
import { AdminCardsComponent } from './admin-payments/admin-cards/admin-cards.component';
import { AdminCoinsComponent } from './admin-coin/admin.coin.component';
import { AdminFriendsComponent } from './admin-users/admin-friends/admin-friends.component';
import { AdminGiftsComponent } from './admin-users/admin-gifts/admin-gifts.component';
import { AdminMessagesComponent } from './admin-users/admin-messages/admin-messages.component';
import { AdminPointsComponent } from './admin-users/admin-points/admin-points.component';
import { OrdersComponent } from '../account/orders/orders.component';
import { UsersComponent } from '../account/users/users.component';

import { MarketplaceService } from '../services/marketplace.service';
import { DriverService } from '../services/driver.service';
import { UserService } from '../services/user.service';
import { Auth } from '../models/auth';
import { adminRoutes } from './admin.routes';

@NgModule({
	declarations: [
		AdminComponent,
		AdminDeliveryComponent,
		AdminIssuesComponent,
		AdminNotificationsComponent,
		AdminParcelsComponent,
		AdminCoinsComponent,
		AdminReturnsComponent,
		AdminPaymentsComponent,
		AdminWithdrawlsComponent,
		AdminTransactionsComponent,
		AdminDepositsComponent,
		AdminProductsComponent,
		AdminCategoriesComponent,
		AdminPromotionsComponent,
		AdminReviewsComponent,
		AdminStatisticsComponent,
		AdminSalesComponent,
		AdminCouponsComponent,
		AdminDisputesComponent,
		AdminSubscriptionsComponent,
		AdminTaxesComponent,
		AdminUsersComponent,
		AdminBalancesComponent,
		AdminFriendsComponent,
		AdminGiftsComponent,
		AdminMessagesComponent,
		AdminPointsComponent,
		AdminCardsComponent,
	 	// OrdersComponent,
		// UsersComponent
    ],
	imports: [
	    FormsModule,
	    ReactiveFormsModule,
	    HttpModule,
	    BrowserModule,
	    HttpClientModule,
	    RouterModule.forRoot(adminRoutes)
	],
	providers: [
		Auth,
		UserService,
		CookieService,
		DriverService,
		HttpClientModule,
		MarketplaceService
	],
	bootstrap: [
		AdminComponent
	]
})
export class AdminModule { }
