import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { welcomebox, inputError, sideDown } from '../../app.animations';

import { UserService } from '../../services/user.service';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'vendor-application',
  templateUrl: './vendor-application-component.pug',
  styleUrls: ['./vendor-application-component.styl'],
  animations: [ inputError, welcomebox, sideDown ]
})

export class VendorApplicationComponent implements OnInit {
	constructor(
 	  private router: Router,
    private route: ActivatedRoute,
    private user: UserService) {
  }
  newVendor = new Application('','','',0,0,false,false);
  SMS_Cooldown = 35; // seconds
  username = '';
	errMsg = '';
  maxpage = 5;
  page = 0;
  lastSMS = 0;
  SMSvalid = false;
  sentSMS = false;
  lastInt: any;

  back() {
    this.page = (this.page>0) ? this.page - 1 : this.page;
    $("html, body").animate({ scrollTop: 0 }, 200);
  }

  send_sms() {
    if (this.lastSMS > 0) return;
    this.lastSMS = this.SMS_Cooldown;
    this.user.send_sms(this.newVendor.phone).subscribe( (s) => {
      console.log(s);
      if (s && s[0].valid==true) {
        this.sentSMS = true;
      } else if (s && s[0].valid==false) {
        this.sentSMS = false;
        this.errMsg = s[0].message;
      }
    });
    if (this.lastSMS > 0) {
      this.lastInt = setInterval(()=>{
        this.lastSMS = this.lastSMS-1;
        if(this.lastSMS==0) 
          clearInterval(this.lastInt);
      },1000);
    }
  }

  check_code( k ) {
    this.user.check_auth_key(k).subscribe( (s) => {
      console.log(s);
      if(s && s[0].valid==true) {
        this.SMSvalid = true;
      } else if (s && s[0].valid==false) {
        this.SMSvalid = false;
        this.errMsg = s[0].message;
      }
    });
  }

  next() {
    // if ( this.page==0 && !this.newVendor.agree_policy ) return false;
    // if ( this.page==1 && this.newVendor.identity==0 ) return false;
    // if ( this.page==2 && !this.newVendor.sin  && this.newVendor.sin!=' ' ) return false;
    // if ( this.page==3 && this.newVendor.address==0 ) return false;
    this.page = (this.page<this.maxpage) ? this.page + 1 : this.page;
    $("html, body").animate({ scrollTop: 0 }, 200);
  }

  sin_key(k,e?) {
    if ( e.key=="Escape" ) {
      this.newVendor.sin = null;
      return;
    } else {
      this.newVendor.sin = this.sin(k); 
    }
  }
  
  sin(tel) {
    var num = tel.toString().replace(/\D/g,'');
    if (num.length>0) return num.substring(0,3)+' '+num.substring(3,6)+' '+num.substring(6);
    if (num.length==0) return num;
  }

  cellphone(k,e?) {
    if ( e.key=="Escape" ) {
      this.newVendor.phone = null;
      return;
    } else {
      this.newVendor.phone = this.phone(k); 
    }
  }
  
  phone(tel) {
    var num = tel.replace(/\D/g,''); 
    if (num.length>0) return '(' + num.substring(0,3) + ') ' + num.substring(3,6) + '-' + num.substring(6,10); 
    if (num.length==0) return num;
  }
  
  authcode(k,e?) {
    if ( e.key=="Escape" ) {
      this.newVendor.authcode = null;
      return;
    } else {
      this.newVendor.authcode = this.authcheck(k); 
    }
  }
  
  authcheck(tel) {
    var num = tel.toString().replace(/\D/g,'');
    if (num.length>0) return num.substring(0,3)+' '+num.substring(3,6);
    if (num.length==0) return num;
  }

  ngOnInit() {
    this.user.authorize().subscribe( (a) => {
      this.username = a[0].username;
    });
  }

}

export class Application {
  constructor(
    public sin: string,
    public phone: string,
    public authcode: string,
    public address: number,
    public identity: number,
    public agree_policy: boolean,
    public recaptcha: boolean
  ) {  }
}