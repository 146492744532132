import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static',
  templateUrl: './static.component.pug',
  styleUrls: ['./static.component.styl']
})
export class StaticComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
