import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { Order } from '../../models/order';
import { environment } from '../../../environments/environment';
import { DayMode, NiteMode } from '../../map.styles';
import { mapOpened } from './purchases.animations';

@Component({
  selector: 'user-purchases',
  animations: [ mapOpened ],
  templateUrl: './users-purchases.component.pug',
  styleUrls: ['./users-purchases.component.styl']
})
export class UsersPurchasesComponent implements OnInit {
  OrderUP() {for(var l=1;l<=this.fakeloade;l++){this.load.push(l);}}//<3
  public mapStyle = DayMode;
  @ViewChild('AgmMap') map: ElementRef;  
  @Input('singleOrder') so:boolean = false;

  constructor(
    private router: Router,
    private user: UserService,
    private marketplace: MarketplaceService
  ) { }


  loading = true;
  load = [];
  cardSelected = [];
  lat: number;
  lng: number;
  orders: Order;
  username:string;
  locationImage:string;
  fakeloade = 0;
  dkM = false;
  Pi = 0;
  ex = 0;
  oc = -1;
  ox = 0;

  mapClick(i?, e?) {

  }
  mapType(i?, e?) {

  }
  mapZoom(i?, e?) {

  }

  goTo(t) { this.router.navigate([t]); }

  getLocationImage() {
    //var k = 'AIzaSyDNl9Nf3I7nj0oPGqIqt1RFeMzB1rauyfs';
    this.user.locate( (l) => {
      var k = environment.gmapsAPI;
      var lat = l.lat+','+l.lng;
        this.lat = l.lat;
        this.lng = l.lng;
    });
  }

  get_purchases() {
    this.marketplace.getOrders().subscribe( (o) => {

      this.orders = o;
      this.loading = false;

      this.marketplace.orderCount().subscribe( (c) => {
          this.oc = c;
      });

    });
  }


  ngOnInit() {
    this.dkM = this.marketplace.getDark();
    this.getLocationImage();
  	this.get_purchases();
  }

}