
import { NgModule } from '@angular/core';

import { HttpModule } from '@angular/http';
import { DragulaModule } from 'ng2-dragula';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from "ngx-img-cropper";
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageUploader } from './imageuploader/imageuploader.component';
import { AvatarUploader } from './avatar-uploader/avatar.uploader.component';
import { BackgroundUploader } from './bguploader/bguploader.component';
import { LogoUploader } from './logouploader/logouploader.component';
import { AddressImage } from './address-image/address.image.component';
import { AccountImageValidation } from './account-validation/images.account.validation.component';

import { MarketplaceService } from '../services/marketplace.service';
import { DriverService } from '../services/driver.service';
import { UserService } from '../services/user.service';

@NgModule({
	declarations: [
		ImageUploader,
		AvatarUploader,
		BackgroundUploader,
		LogoUploader,
		AddressImage,
		AccountImageValidation,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ImageCropperModule,
		HttpModule,
		BrowserModule,
		DragulaModule.forRoot(),
		HttpClientModule
	],
	exports: [
		ImageUploader,
		AvatarUploader,
		BackgroundUploader,
		LogoUploader,
		AddressImage,
		AccountImageValidation
	],
	providers: [
		MarketplaceService,
		UserService,
		DriverService,
		CookieService,
		HttpClientModule
	]
})
export class ImagesModule { }