import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, HostListener, NgZone, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { inputError } from '../../app.animations';
import { open, openup, active, stocks, draftsWindow, DropOpen, animateAlert, units, unit, monies, pillbutton, activemode, activetitle, strainselected, childCategory, flyIn } from './add.animations';
import { Item } from '../../models/item';
import { Currency } from '../../models/currency';
import { Address, Country, Locale } from '../../models/address';
import { environment } from '../../../environments/environment';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Product } from '../../models/product';
import { Strain } from '../../models/strain';
import { Category } from '../../models/category';
import { Provider } from '../../models/provider';
import { ShippingOption } from '../../models/shipping_option';
import { ShopInfo } from '../../models/shopinfo';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs'; 
import { Registration } from '../../models/registration';
@Component({
  selector: 'add-product',
  templateUrl: './add.component.pug',
  styleUrls: ['./add.component.styl'],
  animations: [ open, openup, active, stocks, DropOpen, draftsWindow, inputError, animateAlert, pillbutton, units, unit, monies, activemode, activetitle, strainselected, childCategory, flyIn ]
})
export class AddProductComponent implements OnInit {
 	constructor(
		private marketplace: MarketplaceService,
		private user: UserService,
		private maps: MapsAPILoader,
		private router: Router,
  		private route: ActivatedRoute,
		private dragulaService: DragulaService,
		private ngZone: NgZone) {
 		this.drag.add(this.dragulaService.drop("ORDERS")
		.subscribe(({ name, el, target, source, sibling }) => {
			console.log( el );
		}));
	}
	@Input('allpages') allPages = true;
	addCategoryAllowed = true;
	showAlikeProducts = true;
	vendingNotAllowed = null;
	allowShipToAll = false;
	allowFixedRate = true;
	enableFlatRateShipping = false;
	drag = new Subscription();
	showDrafts: boolean;
	addSubCat: boolean;
	offline: boolean;
 	dragActive: any;
	savingPub: boolean;
	addCat: boolean;
	// saving: boolean;
	openSignIn = false;
	openSignUp = false;
	saving = false;
	saved: boolean;
	shp: string;
	alike: any;
	autosaver: any;
	draft: number;
	drafts: Item;
	listing: Item;
	strains: Strain;
	provider: Provider;
	alike_products: Product;
	registration: Registration;
	anyDrafts = false;
	loggedIn = false;
	locationFilled = false;
	loading = false;
	accountErr = false;
	vendingAllowed = '';
	addingCategory = false;
	catSelected = false;
	currencies: Currency;
	currency: Currency;
	leftUnits = 0;
	imageLoader = 0;
	currencyCount = 0;
	basePrice = 0;
	accountId = 0;
	sT = 0;
	numberOfStrains = 0;
	darkMarket = environment.darkmarket;
	stockControl = false;
	isVendor = false;
	invalid = false;
	valid = false;
	__h = false;
	shopQEdit = [];
	fee = '-';
	currencySymbol='$';
	editorConfig = {
		placeholder: "Enter a  description for your product.",
		spellcheck: "true",
		translate: "yes",
		imageEndPoint: environment.base+'upload/image/?authorization='+this.user.wib(),
		font: 'Arial',
		toolbar: [
			["bold", "italic", "underline", "strikeThrough"],
			["fontName", "fontSize", "color", "orderedList", "unorderedList"],
			["justifyLeft", "justifyCenter", "justifyRight", "indent", "outdent"],
			["orderedList", "unorderedList", "paragraph", "blockquote", "removeBlockquote", "horizontalLine" ],
			["link", "unlink", "image", "video"]
    	]
	};
	cats = [];
	locales = [];
	providers = [];
	mailproviders = [];
	provider_country = [];
	shopInfo: ShopInfo;
	countries: Country;
	products: Product;
	categories: Category;
	shippingOptions: ShippingOption;
	product = new NewProduct('g','CAD', 1, 0, 0, 0, 0, "%", false);
	newcat = new NewCategory();
	strain = new SearchStrain();
	option = new Options('g','CAD');
	fische = new File();
	login = new LoginField();
	register = new LoginField();
	weight = new Weight('g',0,1,0);
	showModel = false;
	askPublish = false;
	AddingOption = false;
	addingFixedOption = false;
	productId: string;
	showPubList: boolean;
	filterStrain = null;
	selectedStrain = null;
	loadingStrains = false;
	alike_products_count = 0;
	offsetStrains = 0;
	maxStrains = 51;
	changed = false;
	boxy = 10;
	page = 1;
	w = 10;
	s_f = 0;
	@ViewChild('searchElement') public searchElement: ElementRef;
	@ViewChild('parentCategory') public parentCategory: ElementRef;
	@ViewChild('photoInput') public photoInput: ElementRef;
	@ViewChild('strainWindow') public strainWindow: ElementRef;
	s_o() {
		this.s_f = 0;
	}
	ct( type ) {
		if ( this.filterStrain == type ) {
			this.filterStrain = null;
		} else {
			this.filterStrain = type;
		}
		this.get_strains();
	}
	ffs(i) {
		let filter = true;
		if (this.filterStrain) {
			if (this.filterStrain==i) {
				filter = true;
			} else { 
				filter = false;
			}
		}
		return filter;
	}
	ht(a) {
		this.product.strain = ( this.product.strain == a.name ) ?  null : a.name;
		this.product.name = ( this.product.name ) ?  this.product.name : a.name;
	}
	sf() {
		this.s_f = this.s_f + 1;
	}
	ft(b, c = false) {
		let t = '';

		if (this.product.strain) {
			if (this.product.strain==b.value) {
				t = b.type; 
			}
		} else {
			t = b.type; 
		}

		return t;
	}
	_disp_sw( s, a = false ) {
		let g=null;
		switch (s.symbol) {
			case "BTC":
			case "POT":
			case "ETH":
			case "DOPE":
			case "CANN":
				if ( this.darkMarket && environment.production ) {
					g= (!a)?'block':true;
				} else { 
					g= (!a)?'none':false;
				}
			break;
			default:
				if ( this.darkMarket && environment.production ) {
					g= (!a)?'none':false;
				} else { 
					g= (!a)?'block':true;
				}
			break;
		}
		return g;
	}
	fts( s ) {
		this.get_strains();
	}
	cs ( x ) {
		// if ( !this.product.category )  return x.name;
		if ( this.product.category == x.id ) return x.name+' Green';
		if ( this.product.category ) return '';
	}
	ty( x ) { 
		this.product.type = x;
	}
	_sYsel(symbol) {
		let a = false;
		if( this.product.currency == symbol ) {
			a = true;
		}
		return a;
	}
	_sYselB(symbol) {
		if ( !this.product.enabled_currencies ) return false;
		let C = this.product.enabled_currencies;
		let A = false;
		let B = C.split(',');
		if ( B == [] ) return false;
		for (var i = B.length - 1; i >= 0; i--) {
			if (B[i] == symbol) A = true;
		}
		return A;
	}
	_opnOpt( ) {
		let AAA = this.provider_country.length;
		return (AAA>0) ? true : false;
	}
	select_alike(product) {
		this.showAlikeProducts=false;
		this.alike = product;
		this.product = product;
	}
	SelectAllCountries(did?) {}
	SelectAllProviders(did?) {}
	SetFixedRate(did?) {
		if ( did ) {
			return this.enableFlatRateShipping;
		}  else {
			this.enableFlatRateShipping=(this.enableFlatRateShipping)?false:true;
		}
	}
	isCountrySelected(C) {
		let A = false; 
		var AA = this.countries[0].count; 
		let AAA = this.provider_country.length;
		// console.log(this.provider_country);
		// for (var i =  0; i <= AA; i++) {
			for (var d =  0; d <= AAA; d++) {
				// let B = (this.countries[i])?this.countries[i].code:null;
				let BB = (this.provider_country[d])?this.provider_country[d]:'';
				if (BB == C) A = (!A) ? true : A;
			}
		// }
		return A;
	}
	ToggleShipping(l, e?) { console.log(l);
		if ( l.options ) {
			for (var i = l.options.length - 1; i >= 0; i--) {
				let a = String(l.options[i].id);
				let b = String(l.provider);
				let c = String(l.code);
				let o = this.providers.indexOf(a);
				if (o > -1) {
					this.providers.splice(o, 1);	
					this.mailproviders.splice(o, 1);	
				} else {
					this.providers.push(a);
					this.mailproviders.push(b);
				}
			}
		} else {
			let a = String(l.id);
			let b = String(l.provider);
			let c = String(l.code);
			let o = this.providers.indexOf(a);
			if (o > -1) {
				this.providers.splice(o, 1);
				this.mailproviders.splice(o, 1);
			} else { 
				this.providers.push(a);
				this.mailproviders.push(b);
			}
		}
		this.updateProviders();
	}
	updateProviders() {
		this.product.shipping_providers = this.providers.join(',');
	}
	ShippingProviderSelected(i) {
		i = String(i.id);
		let k = (this.product.shipping_providers) ? this.product.shipping_providers.indexOf(i) : -1;
		return (k > -1) ? true : false;
	}
	ProviderSelected(h) {
		h = String(h.provider);
		let s = this.mailproviders.join(',');
		let m = (this.mailproviders) ? s.indexOf(h) : -1;
		return (m > -1) ? true : false;
	}
	checkTitle(a = false) {
		if ( a==true ) {
			return (this.product.name && this.product.name != 'New Draft');
		} else { 
			return (!this.product.name || this.product.name == 'New Draft');
		}
	}
	selectAddress(event) {
		this.product.origin = event.id;
		console.log(this.product.origin);
	}
	edit(i) {
		this.shopQEdit[i] = this.shopQEdit[i] ? false : true;
	}
	remove(i) {
		this.weight.order = this.weight.order - 1;
		//this.product.quantities.pop(i);
	}
	addOption(n?) {
		if ( n ) if ( !this.weight.name ) return;
		if ( !this.weight.price || this.weight.price == 0 ) return;
			  this.weight.order = this.product.quantitiesCount + 1;
		if (!this.product.quantities) this.product.quantities = [];
		if ( n ) this.weight.weight = this.product.quantities.length+1;
		if (this.weight.quantity > 0) this.weight.in_stock = true;
		let sku = this.marketplace.sharpen_name(this.weight.name);
		let w = new Weight(this.weight.unit, this.weight.weight, this.weight.quantity, this.weight.price, this.weight.order, this.weight.name, sku, this.weight.in_stock);
		this.product.quantities.push(w);
		this.resetWeight();
		this.AddingOption=false;
		if ( n ) this.addingFixedOption = false;
		this.resetProductPrice();
	}
	resetWeight() {
		this.weight.price = 0;
		this.weight.unit = 'g';
		this.weight.name = '';
		this.weight.weight = 0;
		this.weight.quantity = 1;
		this.weight.order = this.weight.order+1;
	}
	resetProductPrice() {
		this.product.price = 0;
		this.product.quantity = 0;
	}
	onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragActive = true;
	}
	onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragActive = false;
	}
	onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		let files = evt.dataTransfer.files;
		if(files.length > 0){
			this.dragActive = false;
		}
	}
	onFileChange(element) {
		let files = element.target.files;
		console.log(files[0].name);
	}
	pricingKey(e) {
		if (!e) return;
		if (e.keycode && e.keycode == 13) e.preventDefault();
		if (e.keycode && e.keycode == 38) { this.product.price = this.product.price + 10; } // Up 
		if (e.keycode && e.keycode == 40) { this.product.price = ((this.product.price-10)>=0)?this.product.price-10:0; } // Down
	}
	toggleCategory(c,p) {
		this.product.category = (this.product.category==c.id) ? null : c.id;
		this.newcat.streetcatname = '';
		this.addSubCat = false;
		this.addCat = false;
	}
	subCategorySelected(a) {
		let results = false;
		for (var i = a.children.length - 1; i >= 0; i--) {
			if ( a.children[i].id == this.product.category ) results = true;
		}
		return results;
	}
	categorySelected(i) {
		let results = false;
		if ( i.children ) {
			if ( this.product.category == i.id ) results = true;
			if ( i.parent == this.product.category ) results = true;
		}
		return results;
	}
	addCategory() {
		this.marketplace.addCategory( this.newcat.streetcatname, this.newcat.parent ).subscribe( (a) => {
			this.get_categories();
			this.newcat.streetcatname = '';
		});
	}
	toggleCountry(l) {  this.toggleLocale(l); }
	toggleLocale(l, e?) {
		if ( l.subdivisions ) {
			for (var i = l.subdivisions.length - 1; i >= 0; i--) {
				let a = String(l.subdivisions[i].id);
				let C = String(l.code);
				let o = this.locales.indexOf(a);
				if (o > -1) {
					this.provider_country.splice(o, 1);
					this.locales.splice(o, 1);	
				} else {
					this.provider_country.push(C);
					this.locales.push(a);
				}
			}
		} else {
			let a = String(l.id);
			let C = String(l.country);
			let o = this.locales.indexOf(a);
			if (o > -1) {
				this.provider_country.splice(o, 1);
				this.locales.splice(o, 1);
			} else {
				this.provider_country.push(C);
				this.locales.push(a);
			}
		}
		this.updateLocales();
	}
	localeSelected(i) {
		i = String(i);
		// console.log(this.locales);
		let k = (this.product.ship_to) ? this.product.ship_to.indexOf(i) : -1; 
		let results = (k > -1) ? true : false;
		return results;
	}
	shipEverywhere() {
		for (var i = 0; i >= 0; i + 1) {
			this.locales.push(this.countries[i].id);
		}
		this.updateLocales();
	}
	updateLocales() {
		this.product.ship_to = this.locales.join(',');
	}
	onScroll(e) {
		if ( e.target.scrollTop > e.srcElement.clientHeight ) {
			this.offsetStrains = this.offsetStrains+this.maxStrains;
			this.add_strains();
		}
	}
	switch_currency_symbol(c) {
		switch(c) {
			case 'EUR':
				this.currencySymbol = '€';
				break;
			case 'CANN':
				this.currencySymbol = 'CANN ';
				break;
			case 'BTC':
				this.currencySymbol = 'BTC ';
				break;
			case 'ETH':
				this.currencySymbol = 'ETH ';
				break;
			case 'POT':
				this.currencySymbol = 'POT ';
				break;
			case 'DOPE':
				this.currencySymbol = 'DOPE ';
				break;
			default:
				this.currencySymbol = '$';
				break;
		}
	}
	get_currencies() {
		this.marketplace.getCurrencies().subscribe( (c) => {
			this.currencies = c;
			this.currency = c[0];
			this.marketplace.countCurrencies().subscribe( (a) => {
				this.currencyCount = a; let n = 0;
				for (var i = a-1; i>=0; i--) { 
					if( this._disp_sw(c[i], true) ) {
						n = n + 1;	
					}
				}
				this.w = 100/n;
			});
		});
	}
	get_categories() {
		this.marketplace.categories().subscribe( (c) => {
			this.categories = c;
		});
	}
	get_countries() {
		this.marketplace.countries().subscribe( (c) => {
			this.countries = c;
		});
	}
	find_alike_products() {
		this.marketplace.search(this.product.name).subscribe( (p) => {
			this.alike_products = p;
			this.showAlikeProducts=true;
			this.alike_products_count = p[0].count;
		});	
	}
	get_strains() {
		this.marketplace.strains(this.filterStrain, this.maxStrains, this.offsetStrains, this.strain.name).subscribe( (s) => {
			this.strains = s;
			this.marketplace.countStrains().subscribe( (n) => {
				this.numberOfStrains = n;
			});
		});
	}
	add_strains() {
		if ( this.loadingStrains ) return false;
		this.loadingStrains = true;
		this.marketplace.strains(this.filterStrain, this.maxStrains, this.offsetStrains).subscribe( (s) => {
			this.strains = this.strains;
			this.loadingStrains = false;
		});
	}
	switchOptionCurrency( x ) {
		if(!this.option.price) {
			this.option.currency = x.symbol;
			return false;
		}
		var a,b,c = this.option.price;
		for (var i = this.currencyCount - 1; i >= 0; i--) {
			if ( this.currencies[i].symbol == this.option.currency ) a= this.currencies[i].value; 
			if ( this.currencies[i].symbol == x.symbol ) b= x.value;
		}
		let v = (c/a)*b;
		this.option.currency = x.symbol;
		switch (x.symbol) {
			case "USD":
			case "CAD":
			case "EUR":
				this.option.price = Number(v.toFixed(2));
			break;
			case "CANN":
			case "DOPE":
			case "POT":
				this.option.price = Number(v.toFixed(3));
			break;
			default:
				this.option.price = Number(v.toFixed(6));
			break;
		}
	}
	switchCurrency( x ) {
		this.product.enabled_currencies = null;
		this.toggleCurrency( x );
		if(!this.product.price) {
			this.product.currency = x.symbol;
			return false;
		}
		var a,b,c = this.product.price;
		for (var i = this.currencyCount - 1; i >= 0; i--) {
			if ( this.currencies[i].symbol == this.product.currency ) a= this.currencies[i].value; 
			if ( this.currencies[i].symbol == x.symbol ) b= x.value;
		}

		let v = (c/a)*b;
		this.product.currency = x.symbol;
		this.user.set_currency(x.symbol);
		this.switch_currency_symbol(x.symbol);
		switch (x.symbol) {
			case "BTC":
			case "ETH":
				this.product.price = Number(v.toFixed(6));
			break;
			case "CANN":
			case "DOPE":
			case "POT":
				this.product.price = Number(v.toFixed(3));
			break;
			default:
				this.product.price = Number(v.toFixed(2));
			break;
		}
	}
	toggleCurrency( x ) {
		let ar = [], b =[];
		if ( this.product.enabled_currencies == this.product.currency ) return;
		if ( this.product.enabled_currencies && this.product.enabled_currencies != '' ) {
			if ( this.product.enabled_currencies.indexOf(',') > -1) {
				ar = this.product.enabled_currencies.split(',');
			} else {
				ar = [this.product.enabled_currencies];
			}
		} b=ar; 
		let s = ar.indexOf(x.symbol);
		if (s<0) b.push(x.symbol);
		if (s>-1) b.splice(s,1);
		this.product.enabled_currencies = b.join();
	}
	units(g=0) {
		if ( g==1 ) {
			if ( this.weight.unit ) this.leftUnits = this.weight.unit.length;
		} else {
			if ( this.product.unit ) this.leftUnits = this.product.unit.length;
		}
		if ( this.leftUnits == 0 ) this.leftUnits = 15;
		return this.leftUnits+'px';
	}
	changeUnit( u, g=0 ) {

		if ( g==1 ) {
			this.weight.unit = u;
			this.weight.price =  Number( this.convert( u, this.weight.price ) );
		} else { 
			this.product.unit = u;
			this.product.price = Number( this.convert( u, this.product.price ) );
		}
 
	}
	convert( u, m ) { return (u=='oz') ? this.toOunze(m) : (u=='g') ? this.toGram(m) : null; }
	toGram( oz ) { return Math.round(oz/28.3495).toFixed(2); }
	toOunze( g ) { return Math.round(g*28.3495).toFixed(2); }
	toggleType(s) { this.product.type = s.name; }
	quantity_price() {
		var p = 0;
		if ( this.product.price && this.product.quantity ) {
			p = this.product.price * this.product.quantity;
		}
		return p;
	}
	full_amount() {
		this.product.unit = String(this.quantity_price());
		var price = this.product.price * this.product.quantity;
		return price;
	}
	loadMap() { 
	    this.maps.load().then(() => {
		// let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
		// 		types: ["address"],
		// 		componentRestrictions: { country: ['CA'] }
		// });
	 	// autocomplete.addListener("place_changed", () => {
		// 	this.ngZone.run(() => {
		// 		let place: google.maps.places.PlaceResult = autocomplete.getPlace();
		// 		if (place.geometry === undefined || place.geometry === null) return;
		// 		console.log(place)
		// 	});
		// });
		});
	}
	checkPwd( p? ) {}
	checkUser( p? ) {}
	gologin() {
	    this.invalid = false; 
		this.user.login( this.login.username, this.login.password ).subscribe( (acc) => {
	      this.registration = acc;
	      console.log(this.registration);
	      if ( acc.code == 400 ) {
	        this.invalid = true;
	        setTimeout( () => { this.invalid = false; }, 3100);
	      } else if ( acc.code == 200 ) {
	        this.valid = true;
	      	this.vendingAllowed = 'true';
	      	// location.reload();
	      }
		});
	}
	preview() {
		this.saving = true;
		this.product.preview = true;
		this.marketplace.save(this.product).subscribe( (post) => {
			this.toggle_saved();
			this.saving = false;
			this.router.navigate(['product/', post[0].id]);
		});
	}
	save(r=0) {
		this.saving = true; 
		this.marketplace.save(this.product).subscribe( (post) => {
			this.toggle_saved();
			if (r) this.router.navigate(['product/', post[0].id]);
		});
	}
	publish(r=1) { 
		this.savingPub = true;
		this.marketplace.publish(this.product).subscribe( (post) => {
			if (r) this.router.navigate(['product/', post[0].id]);
			this.savingPub = false;
			this.product.published = false;
			this.loadProduct();
		});
	}
	unpublish(r=1) { 
		this.savingPub = true;
		this.marketplace.unpublish(this.product).subscribe( (post) => {
			this.savingPub = false;
			this.product.published = false;
			this.loadProduct();
		});
	}
  	imageID(e?) {console.log(e);}
  	validAddress(a) {return (a)?true:false;}
  	toggle_saved() {this.saved = true;}
	toggle_unsaved() {
		this.changed = true;
		this.saved = false;
	}
	toggle_offline() {
		this.offline = true;
		setTimeout( () => {
			this.offline = false;
		}, 1700);
	}
	triggerAutosave() { 
		this.autosaver = setInterval( () => { 
			if ( !this.product.published && this.product.name == "New Draft" ) { 
				this.saving = true;
				this.save();
			}
		}, 30000);
	}
	get_info() {
		this.marketplace.getShopInfo().subscribe( (i) => {
			this.shopInfo = i;
			this.fee = this.shopInfo[0].BASE_FEE;
		})
	}
	ngOnChange() {
		this.toggle_unsaved();
	}
	ngOnDestroy() {
		this.drag.unsubscribe();
		clearInterval(this.autosaver);
	}
	loadProduct() {
		this.marketplace.product( this.productId ).subscribe( (p) => {
			if( p[0].accountid != this.accountId ) {
				console.log(this.accountId, p[0].accountid);
				this.accountErr = true;
				this.loading = false;
			} else {
				this.product = p[0];
				this.switch_currency_symbol(p[0].currency);
				this.loading = false;
				if ( this.route.snapshot.paramMap.get("id") ) this.allPages = true;
			}
		});
	}
	getShippingOptions() {
		this.marketplace.shippingOptions( this.product.origin ).subscribe( (s) => { 
			this.shippingOptions = s;
		});
	}
	applyToVend() { this.router.navigate(['my-account','vendor-application']); }
	ngOnInit() {
		this.user.vendorStatus().subscribe((is) => {
			this.isVendor = is.json().vendor;
			if( this.isVendor ) this.vendingAllowed = 'true';
			if( !this.isVendor ) this.vendingAllowed= 'not_vendor';
		});
		this.user.authorize().subscribe( (a) => {
			if(a && a[0]) {
				this.loggedIn = true;
				this.accountId = a[0].accountid;
				this.productId = this.route.snapshot.paramMap.get("id");
				if ( !this.productId ) {
					this.marketplace.newProduct().subscribe( (c) => {
						this.product = c;
						this.product.unit='g';
						this.product.thc_unit='%';
						this.product.currency=a[0].currency;
						this.product.enabled_currencies=a[0].currency;
						this.product.id = c[0].id;
						this.productId = c[0].id;
					});
				} else {
					this.loading = true;
					this.loadProduct();
				}
			} else { 
				this.vendingAllowed = 'false';
				this.vendingNotAllowed = false;
			}
		});
		this.get_countries();
		this.get_currencies();
		this.get_categories();
		this.get_strains();
		this.get_info();
		this.loadMap();
		this.triggerAutosave();
		this.getShippingOptions();
		this.units();
	}
}
export class SearchStrain {
   constructor(
	public name?: any
   ) {}
}
export class NewCategory {
	constructor(
	public streetcatname?: string,
	public parent?: string
	) {}
}
export class Options {
	constructor(
public unit?: string,
public currency?: string,
public price?: number,
public name?: string,
public weight?: number,
public stock?: number,
	) {}
}
export class NewProduct {
   	constructor(
	public unit: string,
	public currency: string,
	public quantity: number,
	public cbd: number,
	public thc: number,
	public total_cbd: number,
	public total_thc: number,
	public thc_unit: string,
	public pickup?: boolean,
	public condition?: boolean,
	public account?: any,
	public address?: string,
	public accountid?: number,
	public cart_url?: string,
	public category?: number,
	public created?: any,
	public description?: string,
	public discount?: any,
	public quantities?: any,
	public quantitiesCount?: number,
	public editable?: boolean,
	public html?: any,
	public icon?: string,
	public id?: number,
	public image?: string,
	public scaled?: string,
	public imagecount?: number,
	public images?: any,
	public imagesizes?: any,
	public includes?: any,
	public instock?: boolean,
	public items?: any,
	public modified?: any,
	public name?: string,
	public offers?: any,
	public origin?: string,
	public originNote?: string,
	public originalprice?: any,
	public points?: any,
	public price?: number,
	public published?: boolean,
	public shipping_providers?: any,
	public ship_locally?: string,
	public ship_to?: any,
	public preview?: boolean,
	public shippable?: boolean,
	public slug?: string,
	public sold?: number,
	public stock?: number,
	public strain?: string,
	public flatrate?: number,
	public subscribable?: boolean,
	public enabled_currencies?: string,
	public type?: string,
	public url?: string
	) {}
}
export class Weight  {
	   constructor(
	public unit: string,
	public weight: number,
	public quantity: number,
	public price?: number,
	public order?: number,
	public name?: string,
	public sku?: string,
	public in_stock?: boolean
   ) {}
}
export class File {
   constructor(
	public id?: any,
	public files?: any
   ) {}
}
export class LoginField {
   constructor(
	public username?: string,
	public password?: string
   ) {}
}
