import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';

export const addressPallet = trigger('addressPallet', [
    state('true', style({
        opacity: 1 ,
        'max-height': '2000px',
        'margin-top': '25px',
        'margin-left': '-1px',
        'padding': '30px 20px 20px 20px'
    })),
    state('false', style({
        opacity: 0,
        'max-height': '0px',
        'margin-top': '0px',
        'margin-left': '-1px',
        'padding': '0px 20px 0 20px'
    })),
    transition('*=>*', animate('320ms ease-out'))
])
export const active = trigger('active', [
    state('true', style({
        'border-color': '#9dd44d',
        'box-shadow': '0 0 10px #b7ff4e'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('200ms'))
])
export const activebox = trigger('activebox', [
    state('true', style({
        'color': 'rgb(255, 255, 255)',
        'background': 'linear-gradient( to bottom, rgb(122, 193, 42), #66a922)',
        'border': '3px solid rgb(91, 150, 1)',
        'text-shadow': 'rgb(79, 144, 0) 1px 2px 0px'
    })),
    state('false', style({
        'text-shadow': '0 0 0 transparent'
    })),
    transition('*=>*', animate('100ms ease-out'))
]);
export const green = trigger('green', [
    state('true', style({
        'color': 'rgb(0, 172, 75)'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('200ms'))
])
export const activebg = trigger('activebg', [
    state('true', style({
        'color': 'rgb(255, 255, 255)',
        'background': 'linear-gradient(rgb(122, 193, 42), rgb(102, 169, 34))',
        'border': '3px solid rgb(91, 150, 1)',
        'text-shadow': 'rgb(79, 144, 0) 0px 2px 0px',
    })),
    state('false', style({
    })),
    transition('*=>*', animate('100ms'))
])
export const errorstate = trigger('errorstate', [
    state('true', style({
        'border': '1px solid #d61515',
        'box-shadow': 'rgba(214, 21, 21, 1) 0px 0px 7px',
        'border-bottom': '2px solid rgb(214, 21, 21)',
        'background': '#ff5d000d'
    })),
    state('false', style({
        'box-shadow': 'rgba(214, 21, 21, 0) 0px 0px 0px',
        'border': '1px solid #dadada',
        'border-bottom': '2px solid #dadada'
    })),
    transition('*=>*', animate('100ms'))
])

export const errorstates = trigger('errorstates', [
    state('true', style({
        'border': '3px solid rgb(197, 45, 45)',
        'background': '#ffe0e0',
        'box-shadow': '0px 0px 8px 0px #ff2323',
    })),
    state('false', style({
    })),
    transition('*=>*', animate('100ms'))
])
export const truestates = trigger('truestates', [
    state('true', style({
        'border': '3px solid rgb(197, 45, 45)',
        'background': '#ffe0e0',
        'box-shadow': '0px 0px 8px 0px #ff2323',
    })),
    state('false', style({
    })),
    transition('*=>*', animate('100ms'))
])
export const errorstate_thick = trigger('errorstate_thick', [
    state('true', style({
        'border': '4px solid #d61515',
        'background': '#ff5d000d'
    })),
    //state('false', style({
        //'border': '4px solid #dadada'
    //})),
    transition('*=>*', animate('100ms'))
])
export const full = trigger('full', [
    state('true', style({
        'width': '100% !important',
    })),
    state('false', style({
        
    })),
    transition('*=>*', animate('100ms'))
])
export const fade = trigger('fade', [
    state('true', style({
        'opacity': '1',
        'transform': 'scaleY(1)'
    })),
    state('false', style({
        'opacity': '0',
        'transform': 'scaleY(0)'
    })),
    transition('*=>*', animate('200ms'))
])
export const rotation = trigger('rotation', [
    state('true', style({
        'transform': 'rotate(180deg)'
    })),
    state('truetrue', style({
        'transform': 'rotate(360deg)'
    })),
    state('false', style({
        'transform': 'rotate(0deg)'
    })),
    transition('false=>true', animate('730ms cubic-bezier(0.5, -0.68, 0.5, 1.5)')),
    transition('true=>truetrue', animate('730ms cubic-bezier(0.5, -0.68, 0.5, 1.5)')),
    transition('truetrue=>false', animate('0ms'))
]);

