import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'productsiframer',
  templateUrl: './iframe.product.component.pug',
  styleUrls: ['./iframe.product.component.styl']
})
export class IFrameProductComponent implements OnInit {

	constructor( 
		private activated: ActivatedRoute
	) {	
		this.productID = this.activated.snapshot.paramMap.get("id");
	}
	productID: string;
	ngOnInit() {
	}

}