import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'splash',
  templateUrl: './splash.component.pug',
  styleUrls: ['./splash.component.styl']
})
export class SplashComponent implements OnInit {

  constructor() { }
  user = new User();
  minAge: number;

  changeAge( age ) {
  	this.user.age = age;
  	this.user.ofage = ( age >= this.minAge ) ? true : false;
  }

  ngOnInit() {
  }

}

export class User {
   constructor(
		public type?: string,
		public email?: string,
		public age?: number,
		public ofage?: boolean,
		public enjoys?: boolean,
		public password?: string
   ) {}
}
