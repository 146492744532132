import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'strains',
  templateUrl: './top.strains.component.pug',
  styleUrls: ['./top.strains.component.styl']
})
export class StrainsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
