import { cart, cartBubble, cartLoading, selectedunits, cartUnits, fade, sideDown, countryList, notifButton, active, overlay, slieDownDelivery } from '../app.animations';
import { red,rp } from '../products/products.animations';
import { Locale, Address, Country, UserAddress } from '../models/address';
import { MarketplaceService } from '../services/marketplace.service';
import { environment } from '../../environments/environment';
import { errorstate } from '../checkout/checkout.animations';
import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentMethod } from '../models/paymentmethod';
import { UserService } from '../services/user.service';
import { Registration } from '../models/registration';
import { Currency } from '../models/currency';
import { ShopInfo } from '../models/shopinfo';
import { Product } from '../models/product';
import { Account } from '../models/account';
import { Interac } from '../models/interac';
import { PayPal } from '../models/paypal';
import { Balances } from '../models/balances';
import { Coupon } from '../models/coupon';
import { Coin } from '../models/coin';
import { Info } from '../models/info';
import { Card } from '../models/card';

declare var jquery:any;
declare var $:any;

@Component({
  selector: 'cart',
  templateUrl: './app.cart.sidebar.pug',
  styleUrls: ['app.cart.sidebar.styl'],
  animations: [ cart, red, rp, fade, sideDown, cartBubble, cartLoading, errorstate, cartUnits, countryList, slieDownDelivery, selectedunits, notifButton, overlay, active ]
})
export class CartComponent implements OnInit {
	payPalConfig: PayPalConfig;
  	TAX_PER_GRAM = 0;
  	FEE_PER_GRAM = 0;
  	SHIPPING = 2;
	total = 0;
	favs = 0;
	currencyDelimiter = '$';
	account: Account;
	country: Country;
	coins: Coin;
	countries: Country;
	currencies: Currency;
	products: Product;
	cartProducts: Product;
	featProducts: Product;
	payments: PaymentMethod;
	locations: Address;
	locationKey: string;
	shop: ShopInfo;
	cartInfo: Info;
	coupon: Coupon;
	balances: Balances;
	couponCode = new NewCoupon();
	processing = 'Processing Payment...';
	productsList = '';
	accountOwing = 0;
	creditCardsCount = 0;
	accountPoints = 0;
	bonusPoints = 0;
	totalGrams = 0;
	path = '';
	guest = null;
	isGuest = false;
	dnd = true;
	dna = true;
	nodelv = 'No Delivery Available';
	authtimer: any;
	cartPayment: any;
	locationImage: any;
	shippingMethod: any;
	savedAddresses: any;
	paymentMethod: any;
	selectedWeight = [];
	totalprice = [];
	totalweight = [];
	loaded = false;
	registed = false;
	requireAccount = false;
	showpasswordchange = false;
	shippingError = false;
	paymentError = false;
	identityError = false;
	identitySuccess = false;
	requireIdentity = false;
	emailError = false;
	newPaymentPage = false;
	newAddressPage = false;
	deliveryAva = false;
	deliveryAvailable = false;
	shippingAvailable = true;
	purchaseRunning = false;
	selectPayment = false;
	showPurchase = false;
	showPayPalButton = false;
	removedItems = false;
	identityVerified = 0;
	canncoinenabled = false;
	selectedAddress = 0;
	selectedAddressFull: string;
	showTip: string;
	cartDiscount = 0;
	selectedCard: any;
	editingUnit = 0;
	randomPanel = 0;
	cartCount = 0;
	cartLocale = false;
	loggedIn = false;
	loggingIn = false;
	loginError = false;
	selectAddress = false;
	authorizing = false;
	showLogin = false;
	showCoupons = false;
	couponError = false;
	emailSuccess = false;
	couponSuccess = false;
	couponSuccessA = false;
	selectDelivery = false;
	selectShipping = true;
	selectPickup = false;
	verifyIdentity = false;
	infoloaded = false;
	welcomeguest = true;
	showFeatured = false;
	noProductWarning = false;
	noAddressWarning = false;
	checkoutLoading = false;
	checkoutSuccess=false;
	noCardWarning = false;
	paypalAddress: string;
	appliedCoupon: string;
	paymentType: string;
	approved: string;
	useCredits = false;
	_vid = false;
	_sb = true;
	loginNotifOn = true;
	paymentNotifOn = false;
	showExtraProducts = true;
	Step0 = false;
	Step1 = false;
	Step2 = false;
	Step3 = false;
	halfStep = true;
	paymentCode: number;
	acpwn: number;
	aupwn: number;
	cartDelivery = 0;
	cartShipping = 0;
	accountID = 0;
	accountBalance = 0;
	accountCurrency = 'CAD';
	cartCurrency = 'CAD';
	cartTaxes = 0;
	cartSubtotal = 0;
	cartTotal = 0;
	loginErr = '';
	option = '';
	// productDelimiter = '•';
	productDelimiter = 'x';
	_chk = false;
	cartUnit = 'g';
	mainimageheight = 0;
	mainImageWidth = 0;
	errTimer = null;
	checkoutWord = 'Are you sure you want to purchase:';
	shareURL = 'https://treest.ca/';
	_g = false;
	sD = 0;
	sL = 0;
	sP = 0;
	pq = [0];
	_name = ['Tony','Pitor','Lindsay','Milan','Shankar'];
	_memory: any;
	_game: any;
	cartWeight = 0;
	rezChecker: any;
	accSymbol: any;
	rewardAmount = 5;
	username: string;
	pur: boolean;
	fet: boolean;
	clos: boolean;
	checkoutErr: boolean;
	selectedcurrency: string;
	darkMarket = environment.darkmarket;
	login = new Login();
	cartShippingAmount = 0;
	creditCards: Card;
	addresses: UserAddress;
	registration: Registration;
	interacts: Interac;
	paypals: PayPal;
	ownerInfo = {
		owner: {
			name: '',
			address: {
				line1: '',
				city: '',
				postal_code: '',
				country: '',
			},
			email: ''
		},
	};

	@Input('mode') sidebarMode = true;
	@Input('open') showCart = false;
	
	@ViewChild('mainImage') mainImage: ElementRef;

	constructor( 
		private user: UserService,
		private marketplace: MarketplaceService,
		private route: ActivatedRoute,
		public router: Router  ) {
			const currentPageUrl = router.url;
			this.path = router.url;
	}

	// RESIZE 
	@HostListener('window:resize', ['$event'])
	onWindowResize(event) {
		this.rez();
	}

	rez() {
		if ( this.mainImage && this.mainImage.nativeElement ) {
			this.mainimageheight = this.mainImage.nativeElement.clientWidth;
		}
	}

	_claz() {
		var q = (this.loaded?'l ':'');
		var w = (this.selectedAddressFull?'tall ':'');
		var e = (this.clos)?'close':'o';
		var a = (this.fet)?'alt hidden':'alt hidden '+e;
		var r = (this.pur?'alt':a);
		var t = (false?'':'');
		var y = q+w+r+t;
		return y;
	}

	_cbtn_classes() {
		var a = '';
		a = a + (this.loaded?'l ':'');
		a = a + (this.selectedAddressFull?'tall ':'');
		a = a + (this.pur?'alt':'alt hidden');
		if (!this.fet) a = a + (this.clos)?' close':' o';
		return a;
	}

	_new_card(e) {
		// console.log(e);
		// this.getPaymentMethods();
		this.getPaymentCards();
		//location.reload();
	}

	_new_paypal(e) {
		this.getPayPals();
	}

	_new_interacs(e) {
		this.getInteracs();
	}

	_new_method(e) {
		// console.log(e);
		this.getPaymentMethods();
		// this.getPaymentCards();
	}
	
	// Set payment method
	_settype(t,a) {
		console.log(a);
		this.selectAddress=false;
		this.selectedCard=a;
		this.selectPayment=(this.selectPayment)?false:true;
	}

	setbal(b) {
		this.paymentType = 'acc';
		this.accSymbol = b.symbol;
		this.selectedCard = b.symbol;
		this.selectAddress = false;
		this.selectPayment = (this.selectPayment) ? false : true;
		this.saveType();
	}

	_ppaypal(a) {
		console.log(a);
		this.selectedCard=a;
		this.paymentType='pp';
		this.selectAddress=false;
		this.selectPayment=(this.selectPayment)?false:true;
		this.RunPayPalConfig();
		this.saveType();
	}

	// --- SHOW **/
	tryCheckout(c=false) {
		if ( this.checkoutLoading ) return false;
		/*if ( !this.loggedIn ) {
			this.showLogin = true;
			this.le();
			return false;	
		} else */
		if ( this.cartTotal == 0 ) {
			this.noProductWarning = true;
			setTimeout( () => { this.noProductWarning = false; }, 3400);
			return false;
		} else if ( this.selectedAddress == 0 ) {
			this.shippingError = true;
			setTimeout( () => { this.shippingError = false; }, 3400);
			return false;
		} else if ( this.selectedCard == 0 ) {
			if( this.savedAddresses == 0 ) this.Step2=true;
			this.paymentError = true;
			setTimeout( () => { this.paymentError = false; }, 3400);
			return false;
		} else if ( this.requireIdentity && this.identityVerified==0 )  {
			this.identityError = true;
			setTimeout( () => { this.identityError = false; }, 3400);
			return false;
		}

		this.pur = (this.pur) ? false : true;

		// setTimeout( () => { 
		// 	if (c) {
		// 		this.clos = true;
		// 	} else {
		// 	}
		// }, 240);

		this.fet = true;

		setTimeout( () => { 
			this.clos = false;
			this.fet = false;
		}, 600);

		setTimeout( () => { 
			this.checkoutErr = false;
			this.checkoutWord = 'Are you sure you want to purchase:';
		}, 700);

	}

	favorite(p) {
		this.marketplace.favorite(p.id).subscribe( (bool) => {
			if ( bool ) p.favorite = (p.favorite) ? false : true;
			this.getCart(true);
		});
	}

	changeSelectedAddress( a ) {
		this.selectedAddress = a;
		if(this.selectedAddress) this.Step0 = false;
	}
	
	changeSelectedPayment( a ) {
		this.selectedCard = a;
		if(this.selectedCard) this.clearSteps();
	}

	getCountries() {
		// Get countries
		this.marketplace.countries().subscribe( (c) => {
			this.countries = c;
		});
	}

	setVerification( v ) {
		if (v && this.selectedCard) {
			this.marketplace.verification(v, this.selectedCard).subscribe((vc) => {
				if (vc[0].code == 200) {
					this.identityVerified = 1;
				} else if (vc[0].code == 300) {
					this.identityVerified = 2;
					this._vid = true;
				}
			});
		}
	}

	getAccount() {
		this.user.authorize().subscribe( (a) => {
			if( a && a[0] ) {
				this.account = a; 
				// console.log(a);
				this.processAccount(a[0]);
				this._donelogin(true, a[0].guest);
				clearInterval(this.authtimer); 
				this.marketplace.countFavorite().subscribe( (f) => {
					this.favs = f;
				});
			} else {
				this.Step0 = true;
				this.loginNotifOn = true;
				this.paymentNotifOn = true;
				this.generateGuestAccount();
			}
		});
	}

	generateGuestAccount() {
		this.user.guest().subscribe( (a) => {
			// console.log(a);
			if (a.account.username) this.guest = a.account.username;
			if (a.account.accountid) this.Step0 = false;
			if (a.account.accountid) this.showLogin = false;
			this.loggedIn = true;
			this.processAccount(a.account);
			this._donelogin(true, true);
			clearInterval(this.authtimer); 
		});
	}

	processAccount(a) {
		// this.account = a;
		this._g = a.gay;
		this.accountID = a.id;
		this.username = a.username;
		this.accountBalance = a.balance;
		this.accountCurrency = a.currency;
		this.paymentType = (a.payment_type)?a.payment_type:'c';
		this.identityVerified = (a.identity_verified)?1:0;
		this.shareURL = this.shareURL + 'r/' + a.username;
		if (a.id) this.Step0 = false;
		if (a.id) this.showLogin = false;
		this.welcomeguest = false;
		this.isGuest = a.guest;
		this.getAccountInfo();
		this.countPaymentMethods();
		this.verify_identity();
	}

	cart(s) {
		this.showCart = s;
	}

	selectUnit(o, pid) {
		this.selectedWeight[pid.id] = (this.selectedWeight[pid.id]==o) ? false : o;
	}

	unitselected(o,pid) {
		if ( !this.selectedWeight[pid] ) return false
		var i = this.selectedWeight[pid].indexOf(o);
		return (i>-1)?true:false;
	}

	increasequantity(p) { 
		var u = this.unitQuantities(p);
		if ( p.quantity > u ) {
			u = u+1;
			this.marketplace.addToCart(p.id, u);
			this.getCart(true);
		}
	}

	reducequantity(p) {
		var u = this.unitQuantities(p);
		if (u > 0 ) {
			u = u-1;
			this.marketplace.addToCart(p.id, u);
			this.getCart(true);
		}
	}

	getInteracs() {
		this.user.getInterac().subscribe( (int) => {
			this.interacts = int;
			if(!this.selectedCard && int[0]) {
				this.selectedCard=int[0].id;
			}
			if(this.selectedCard) this.clearSteps();
		});
	}

	getPayPals() {
		this.user.getPayPal().subscribe( (pp) => {
			this.paypals = pp;
			if(pp[0]) {
				this.paypalAddress = pp[0].email;
			}
			if(!this.selectedCard && pp[0]) {
				this.selectedCard = pp[0].id;
			}
			if(this.selectedCard) this.clearSteps();
		});
	}

	getBalances() {
		this.user.getBalances().subscribe( (bal) => {
			this.balances = bal;
		});
	}

	addToCart(product, quantity=1) {
		if ( this.products[0].scaled == 'fixed' ) {
			for (var i = 0; i < Object(this.selectedWeight).length; i++) {
				if ( isNaN(this.selectedWeight[product.id]) ) {
					this.marketplace.addToCart( product, quantity, '', false, false, this.selectedWeight[product.id]  );
				} else {
					this.marketplace.addToCart( product, quantity, product.unit, this.selectedWeight[product.id]  );
				}
			}
		} else { 
			this.marketplace.addToCart(product, quantity);
		}
		this.getCart();
	}


	increaseunitquantity(p, o) {
		this.marketplace.addUnit(p, o);
		this.getCart(true);
	}

	reduceunitquantity(p, o) {
		this.marketplace.removeUnit(p, o);
		this.getCart(true);
	}

	removeUnit(o, p) {
		this.marketplace.removeUnitFromCart(o, p);
		this.getCart(true);
	}

	editUnit( u ) {
		//this.editingUnit;
	}

	Remove(p) {
		this.marketplace.removeFromCart(p);
		this.getCart(true);
	}

	unitincart(o,pid) {
		return this.marketplace.unitInCart(o, pid);
	}

	undoRemove(p) {
		if ( p.scaled == 'scaled' ) {
			this.marketplace.addToCart(p.id, 1,'g',1);
		} else if ( p.scaled == 'fixed' ) { 
			this.marketplace.addToCart(p.id, 1,'g',1);
		}
		this.getCart(true);
	}

	resetCoupon() {
		this.appliedCoupon = '';
		this.couponCode.code = '';
		this.cartDiscount = 0;
		this.getCart(true);
		this.marketplace.clearCoupon();
	}

	checkCoupon() {
		this.marketplace.getCoupon(this.couponCode.code).subscribe( (l) => {

			if (l[0].code == 400) this.couponError = true;

			if (l[0].code != 400) {
				if ( l[0].active == "1" ) {

					this.appliedCoupon = this.couponCode.code;
					this.couponSuccessA = true;
					this.couponSuccess = true;
					this.couponError = false;

					this.marketplace.addCoupon(this.appliedCoupon);

					if ( l[0].bind_to_account ){
						this.user.set_applied_coupon(this.appliedCoupon);
					}

					if ( l[0].value ) {
						this.cartDiscount = l[0].value;
					} else if ( l[0].percentage ) {
						this.cartDiscount = l[0].percentage;
					}

					// this.cartTotal = this.cartTotal - this.cartDiscount;
					this.getCart(true);

					setTimeout( () => {
						this.showCoupons = false;
						this.couponSuccessA = false;
						this.couponCode.code = '';
					}, 5000);

					setTimeout( () => {
						this.couponSuccess = false;
					}, 7000);

				}
			}

			setTimeout( () => {
				this.couponError = false;
			}, 5000);

		});
	}

	getPaymentMethods() {
		this.marketplace.paymentMethods().subscribe( (p) => {
			this.payments = p;
			if (p[0]&&p[0].count>0) this._sb = false;
		});
	}

	countPaymentMethods() {
		var a = false;
		this.user.lengthAddress().subscribe( (nuts) => {
			this.savedAddresses  = nuts;
		});
		/// If needed, check if you have any payment methods.
		// this.user.countPayPal().subscribe( (c) => {
		// 	this.user.countInterac().subscribe( (cb) => {
		// 		this.user.getCoinAddressessCount().subscribe( (cba) => {
		// 			this.user.bankAccountsCount().subscribe( (cbaa) => {
		// 				a = (c==0 && cb==0 && cba==0 && cbaa==0) ? true : a;
		// 				if ( !this.Step1 ) this.Step2 = a;
		// 			});
		// 		});
		// 	});
		// });
	}

	_closestepzero() {
		this.Step0=false;
		if(this.halfStep) this.Step1=true;
	}

	_closestepone() {
		this.Step1=false;
		if(this.halfStep) this.Step2=true;
	}

	_closesteptwo() {
		this.Step2=false;
		if(this.halfStep) this.Step3=true;
	}
	clearSteps() {
		this.Step0 = false;
		this.Step1 = false;
		this.Step2 = false;
		this.Step3 = false;
	}
	_new_addr() {
		this.top();
		this.selectAddress = false;
		this.newAddressPage = true;
	}

	_new_payment() {
		this.top();
		this.selectPayment = false;
		this.newPaymentPage = true;
	}

	getLocations() {
		this.marketplace.getLocations().subscribe( (l) => {
			if ( l[0] && l[0].id ) {
				this.locations = l;
				if ( !this.selectedAddress ) this.selectedAddress = l[0].id;
			}
		});
	}
	
	getPaymentCards() {
		this.user.getPaymentCards().subscribe( (c) => {
			if ( c[0] && c[0].id ) {
				this.creditCards = c;
				if (c[0].count>0) this._sb = false;
				if(!this.selectedCard && c[0]) {
					this.selectedCard = c[0].id;
					this.requireIdentity=true;
				}
				if(this.selectedCard) this.clearSteps();
			}
		})
		this.user.paymentProductCount().subscribe( (lighting) => {
			this.creditCardsCount = lighting;
		});
	}


	getCoins() {
		this.marketplace.getCoins().subscribe( (c) => {
			if ( c[0] && c[0].id ) {
				this.coins = c;
				if(!this.selectedCard && c[0]) {
					this.selectedCard = 'coin'+c[0].id;
				}
				if(this.selectedCard) this.clearSteps();
			}
		});
	}

	getAddresses() {
		this.user.getAddresses().subscribe( (ikbar) => {
			if ( ikbar[0] && ikbar[0].id ) {
				this.addresses  = ikbar;
				if (ikbar[0].count>0) this._sb = false;
				if ( !this.selectedAddress ) {
					this.selectedAddress = ikbar[0].id;
					this.selectedAddressFull = ikbar[0].address;
					this.deliveryAva = ikbar[0].delivery_available;
				} else {
					this.deliveryAva = ikbar[0].delivery_available;
				}

				this.selectDelivery = (ikbar[0].delivery_available) ? true : false;
				this.selectPickup = (ikbar[0].delivery_available) ? true : false;
				this.getCart(true);

			}
		});
	}

	getShopInfo() {
		this.marketplace.getShopInfo().subscribe( (i) => {
			this.shop = i;
		});
	}


	getLocationImage() {
		//var k = 'AIzaSyDNl9Nf3I7nj0oPGqIqt1RFeMzB1rauyfs';
		this.user.locate( (l) => {
			var k = environment.gmapsAPI;
			var lat = l.lat+','+l.lng;
			var z = 13;
			this.locationImage = "https://maps.googleapis.com/maps/api/staticmap?center="+lat+"&zoom="+z+"&size=260x160&maptype=roadmap&key="+k;
		});
	}

	changeShipping( id ) {
		setTimeout( () => {
			this.selectAddress = true;
			setTimeout( () => {
				this.selectAddress = false;
			}, 0);
			this.newAddressPage = false;
			this.selectedAddress = id.id;
			this.deliveryAva = id.delivery_available;
			if ( this.deliveryAva ) this.selectDelivery = true;
		}, 800);
	}

	selectShippingAddress( sh ) {
		this.selectAddress = false;
		this.selectedAddress = sh.id;
		this.selectedAddressFull = sh.address;
		this.selectShipping = (sh.delivery_available)?false:true;
		this.selectDelivery = (sh.delivery_available)?true:false;
		this.selectPickup = false;
	}

	changePayment( ev ) {
		console.log( ev );
		setTimeout( () => {
			this.selectPayment = true;
			setTimeout( () => {
				this.selectPayment = false;
			}, 0);
			this.selectAddress = false;
			this.newPaymentPage = false;
			this.selectedCard = ev.id;
			if(this.selectedCard) this.clearSteps();
		}, 300);
	}
	
	selectCard( caid, t, acc=false ) {
		this.paymentType = t;
		this.selectPayment = false;
		if (acc) caid = acc+caid;
		// caid = t+caid;
		this.selectedCard = caid;
		if(this.selectedCard) this.clearSteps();
		this.requireIdentity = (t=='c') ? true : false;
		this.RunPayPalConfig();
		this.saveType();
	}
	
	top(t='fast') {
		$("html, body").animate({ scrollTop: 0 }, t);
		$(".application").animate({ scrollTop: 0 }, t);
	}

	// addToCart(id, order) {
	// 	this.marketplace.addToCart(id, order);
	// 	this.getCart();
	// }

	stripTags(o) { return o.replace(/(<([^>]+)>)/gi, ""); }

	unitQuantities(o) {
		return this.marketplace.getUnitQuantities(o);
	}

	register() { 
		
		if ( !this.login.email || !this.login.password || !this.login.username ) { 
			this.le('Please note that all fields are required.'); 
			return; 
		}

		this.user.register(this.login.email, this.login.password, this.login.username).subscribe( (r) => {
			r = r[0];
			if ( r.code == 200 ) {
				this.registration = r;
				// this.account = a;
				// this.loggedIn = true;
				// this.accountID = a[0].id;
				// this.username = a[0].username;
				// this.accountBalance = a[0].balance;
				// this.accountCurrency = a[0].currency;
				// this.shareURL = this.shareURL + 'r/' + a[0].username;
				// this.countPaymentMethods();
				this._donelogin(); //false
			} else {
				this.le(r.error);
			}
		});

	}

	loginNow() {
		this.loggingIn = true;
		if ( !this.login.password && (!this.login.email || !this.login.username) ) { this.le('Please enter a username or email and your password.'); return; } 
		this.user.login(this.login.username, this.login.password).subscribe( (l) => {
			if ( l.code == 200 ) {
				this.Step0 = false;
				this.loggedIn = true;
				// console.log(l);
				this.processAccount( l[0].account );
				this._donelogin(true, l[0].account[0].guest);
			} else {
				this.showpasswordchange = true;
				this.loggedIn = false;
				this.le(l.error);
			}
			this.loggingIn = false;
		});
	}

	getCart(skip=false) {

		this.infoloaded = false;

		// var p = this.marketplace.parseCart();
		// this.marketplace.products(p).subscribe( (c) => {
		// 	this.cartProducts = c;
		// });

		this.marketplace.cartContents().subscribe( (c) => {
			if ( !skip ) this.cartProducts = c;
		});
		
		this.productsList = this.marketplace.parseCart();

		//** 
		// * All the Cart sidebar Info is generated by
		// * the backend. products/?products=
		//**
		this.locationKey = this.user.locKey();
		this.marketplace.cartInfo( this.appliedCoupon, this.selectDelivery, this.selectPickup, this.selectShipping, this.locationKey, this.selectedAddress, 1 ).subscribe( (a) => {
			this.cartInfo = a;
			this.cartWeight = a[0].weight;
			this.bonusPoints = a[0].bonus;
			this.totalGrams = a[0].weight;
			this.cartTaxes = a[0].taxes;
			this.accountOwing = a[0].owing;
			this.cartShipping = a[0].shipping;
			this.cartShippingAmount = a[0].shipping;
			this.cartCurrency = a[0].currency;
			this.cartSubtotal = a[0].subtotal;
			this.cartDelivery = a[0].delivery;
			this.cartTotal = a[0].total;
			this.cartCount = a[0].length;
			this.deliveryAvailable = a[0].delivery_available;
			this.cartDiscount = a[0].discount;
			this.loaded = true; 
			if ( a[0] ) { this.infoloaded = true; }
		});

		// if ( skip ) setTimeout( () => { this.getCart(); }, 750);
		this.rezChecker = setInterval( () => {
			this.rez();
		}, 320);

	}



	// // // // // //
	//  Purchase  //
	// // // /// //
	//
	//
	//
	purchase() {

		if ( this.purchaseRunning ) return false;
		if ( this.checkoutLoading ) return false;
		
		if ( this.cartTotal == 0 ) {
			this.checkoutLoading = false;
			this.purchaseRunning = false;
			this.checkoutErr = true;
			this.checkoutWord = 'Your cart is empty.<br />Please fill it.';
			setTimeout( () => {
				this.tryCheckout(true);
			}, 3600);
			console.log('Error 1');
			return false;
		}

		if ( !this.loggedIn ) {
			this.le();
			this.showLogin = true;
			console.log('Error 2');
			return false;
		}

		if ( this.requireIdentity && this.identityVerified==0 ) { 
			this.identityError = true;
			console.log('Error 3');
			return false;
		}

		if ( !this.selectedAddress ) {
			this.Step1 = true;
			this.shippingError = true;
			console.log('Error 4');
			return false;
		}

		if ( !this.selectedCard ) {
			this.Step2 = true;
			this.paymentError = true;
			console.log('Error 5');
			return false;
		}

		if ( this.guest && !this.login.email ) {
			this.emailError = true;
			console.log('Error 6');
			return false;
		}

		this.tgl_processing();
		this.checkoutLoading = true;

		if ( this.selectedCard[0] == 'i') this.paymentType='int';
		if ( this.selectedCard[0] == 'p') this.paymentType='paypal';
		if ( this.selectedCard[0] == 'c') this.paymentType='card';
		if ( this.selectedCard[0] == 'coin') this.paymentType='coin';
		if ( this.selectedCard[0] == 'a') this.paymentType='account';

		this.purchaseRunning = true;
		this.marketplace.purchase( 
			this.selectedAddress, 
			this.productsList,
			this.cartTotal,
			this.appliedCoupon,
			this.selectedCard,
			this.selectDelivery,
			this.selectPickup,
			this.selectShipping,
			this.useCredits,
			this.cartCurrency,
			this.login.email
		).subscribe( ( data ) => { this.post_purchase(data); });
	}


	// // // // // // //  //
	//  PayPal Purchase  //
	// // // // / // // //
	//
	//
	//
	RunPayPalConfig() {
		
		var PayPalClient = {sandbox: environment.paypalclientID, production: environment.paypalclientID};
		var PaypalEnv = PayPalEnvironment.Sandbox;
		if (environment.production) PaypalEnv = PayPalEnvironment.Production;

		this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PaypalEnv, {
			commit: true,
			transactions: [{
				amount: {
					currency: this.cartCurrency,
					total: this.cartTotal
				}
			}],
			client: PayPalClient,
			button: {
				label: 'paypal',
			},
			onPaymentComplete: (data, actions) => {
				this.purchaseRunning = true;
				this.marketplace.PayPalPurchase(
					this.selectedAddress, 
					this.productsList,
					this.cartTotal,
					this.couponCode.code,
					this.selectedCard,
					this.selectDelivery,
					this.selectPickup,
					this.selectShipping,
					this.useCredits,
					this.cartCurrency,
					data.paymentToken,
					data.orderID,
					data.payerID,
					data.paymentID
				).subscribe( ( data ) => { 
					this.post_purchase(data); 
				});
			},
			onCancel: (data, actions) => {
				this.tryCheckout();
				this.checkoutLoading = false;
				// console.log('OnCancel', data);
			},
			onError: (err) => {
				this.checkoutLoading = false;
				// console.log('OnError', err);
			},
			onClick: (e) => {
				if ( this.selectedCard[0] == 'p') this.paymentType='paypal';
				this.checkoutLoading = true;
				this.sL=100;
			}
		});
	}


	// // // // // // // // // // // //// // // // // // //  //
	//  					Post_Purchase  					//
	//  Callback that happens after a successful purchase  //
	// // // /// //	// // // /// //	// // // /// //	// // //
	//
	//
	post_purchase(data) {

		// Interac / Additional Payment Required
		if ( data[0].code == 100 ) {
			setTimeout( () => {
				var orderID = data[0].id;
				this.checkoutErr = false;
				this.checkoutSuccess = true;
				this.checkoutWord = 'Order submitted.<br />Proceeding to payment...';
				//if ( this.paymentType == 'int'||this.paymentType == 'paypal' ) {
					setTimeout( () => {
						this.checkoutLoading = false;
						this.marketplace.clearCart();
						this.router.navigate(['checkout-complete', orderID]);
					}, 4270);
				//}
			}, 3457);
		}

		this.paymentCode = data[0].code;

		// Payment Complete
		if ( data[0].code == 200 ) {
			this.checkoutLoading = false;
			var orderID = data[0].id;
			this.checkoutErr = false;
			this.checkoutSuccess = true;
			this.checkoutWord = 'Purchase complete.<br />Thank you!';
			setTimeout( () => {
				this.marketplace.clearCart();
				this.router.navigate(['checkout-complete', orderID]);
			}, 4270);
		}

		// Payment Error
		if ( data[0].code == 400 ) {
			this.checkoutLoading = false;
			this.purchaseRunning = false;
			this.checkoutErr = true;
			this.checkoutWord = 'Sorry, not Approved.<br />Please try again.';
			setTimeout( () => {
				this.tryCheckout();
			}, 3600);
		}

	}


	generateShareURL() {
		this.shareURL = this.shareURL+'invite/'+this.username;
	}

	getAccountInfo() {
		this.getAddresses();
		this.getPaymentMethods();
		this.getPaymentCards();
		this.getInteracs();
		this.getPayPals();
		this.getLocations();
		this.getBalances();
	}

	le(err?) {
		if(err) this.loginErr = err;
		this.loginError = true;
		clearInterval(this.errTimer);
		this.errTimer = setTimeout( () => { 
			this.loginErr = '';
			this.loginError = false; 
		}, 5000);
	}

	pc(a) {
		this.acpwn = a;
		setTimeout( () => {
			this.acpwn = 0;
		}, 100);
	}

	pu(o) {
		this.aupwn = o;
		setTimeout( () => {
			this.aupwn = 0;
		}, 100);
	}

	_donelogin(a=true, guest = false) {
		this.loggedIn = true;
		this.option = 'doneloggingin';
		setTimeout( () => { this._chk = true; }, 100);
		if ( guest ) {
			this.isGuest = true;
			if(!this.selectedAddress && !this.selectedCard) {
				this.Step0 = true;
			}
		}
		console.log('_donelogin', this.isGuest);
		// if (a) this.getAccount();
		//setTimeout(()=>{ 
			// this.option = 'finloggingin'; 
			//location.reload(); 
		//}, 1500);
	}

	isCoin(c?) {
		if ( c=='CANN' ) {
			return true;
		} else if ( c=='POT' ) {
			return true;
		} else if ( c=='DOPE' ) {
			return true;
		} else if ( c=='ETH' ) {
			return true;
		} else if ( c=='ETC' ) {
			return true;
		} else if ( c=='BTC' ) {
			return true;
		} else if ( c=='DASH' ) {
			return true;
		} else { return false; }
	}

	default_weight(p,o,i){
		if (!this.selectedWeight[p.id] && i==0) {
			return true;
		}
	}

	tgl(a?) {
		this.dna = false;
		// --- SHOW **/
		var C = 0;
		var D = setInterval( () => {
			if ( this.nodelv.length+3 == this.sD ) {
				C = C + 1;
				if ( C > 50 ) this.sD = 0;
			} else if ( this.sD < this.nodelv.length - 9 && C==0 ) {
				this.sD = this.sD + 1;
			} else if ( this.sD > this.nodelv.length - 9 && C==0 ) {
				this.sD = this.sD - 1;
			}
		}, 150);
		setTimeout( ()=>{clearInterval(D);this.sD=-0;}, 4000);
		setTimeout( ()=>{this.dna=true;},4001);
	}

	//  P-R-O-C-E-S-S-I-N-G  P-A-Y-M-E-N-T Animation
	tgl_processing(a?) {
		this.dnd = false; var C = 0;
		var D = setInterval( () => {
			if ( this.processing.length+3 == this.sL ) {
				C = C + 1;
				if ( C > 50 ) this.sL = 0;
			} else if ( this.sL < this.processing.length && C==0 ) {
				this.sL = this.sL + 1;
			} else if ( this.sL > this.processing.length && C==0 ) {
				this.sL = this.sL - 1;
			}
		}, 66);
		setTimeout( ()=>{clearInterval(D);}, 4000);
		setTimeout( ()=>{this.dnd=true;},4001);
	}

	fullPrice(pid?) {
		return 1;
	}

	curry() {
		this.user.get_currency().subscribe( (c) => {
			this.selectedcurrency = c;
		});
	}

	get_currencies() {
		this.marketplace.getCurrencies().subscribe( (c) => {
			if(c) {
				var a = c[0].count;
				this.currencies = c;
				for (var i = a - 1; i >= 0; i--) {
					if( c[i].symbol == 'CANN' && c[i].enabled) {
						this.canncoinenabled = true;
					}
				}
			}
		});
	}

	verify_identity() {
		this.user.getIdentityImages(this.accountID, 'cc').subscribe((v) => {
			if( v[0] ) this.setVerification(v[0].id);
		});
	}

	fullWeight(p) {
		// for (var i = p.quantities.length - 1; i >= 0; i--) {
			// p.quantities[i]
		// }
		var a = 1;
		return (a==1)?'':a;
	}

	empty_cart() {
		this.cartCount = 0;
		this.marketplace.clearCart();
		this.getCart();
	}

	autoAuthorize() {
		if ( localStorage.getItem('Wib') ) {
			this.getAccount();
		} else {
			this.authtimer = setInterval( () => {
				this.authorizing = true;
				if ( !this.authorizing && localStorage.getItem('Wib') ) {
					this.getAccount();
				}
			}, 2777);
			this.generateGuestAccount();
		}
	}

	saveType() {
		this.user.savePaymentType( this.paymentType ).subscribe( (p) => { });
	}

	ngOnDestroy() {
		clearInterval(this.rezChecker); 
		clearInterval(this.authtimer); 
		clearInterval(this._memory); 
	}

	ngOnInit() {
		this.top();
		this.rez();
		this.curry();
		this.getCountries();
		this.autoAuthorize();
		this.RunPayPalConfig();
		this.getCart();
		this.get_currencies();
		this.appliedCoupon = this.marketplace.returnCoupon();
		if ( this.path == '/cart') this.sidebarMode = false;
		var n = 0; var ab = [];
		this._memory = setInterval( () => { 
			if (n<4) n++;
			ab[0]=this._name[-1+n];
			ab[1]=this._name[n];
			ab[2]=this._name[-2+n];
			ab[3]=this._name[-3+n];
			ab[4]=this._name[-4+n];
			if (n==4) n=0;
			for (var i =0; i<=42; i++) { if (n==4&&i==42) n=0; }
			// console.log(ab);
		},420); 
		//this._name = ab;
	}
}

export class NewCoupon {
   constructor(
	public code?: any
   ) {}
}

export class Login {
   constructor(
	public username?: string,
	public email?: string,
	public password?: string
   ) {}
}