  /**
  * A BIG Tree St. WELCOME ! 
  * @cannaboosle aka @bentbot 
  * 2019,2020
  **/
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ngCopy } from 'angular-6-clipboard';
import { UserService } from '../../services/user.service'
import { MarketplaceService } from '../../services/marketplace.service'
import { Account } from '../../models/account';
import { Locale, Address } from '../../models/address';
import { Country } from '../../models/address';
import { Registration } from '../../models/registration';
import { welcomebox, inputError, overlay, hidewelcome } from '../../app.animations';
import { Location } from '@angular/common';
import { PaymentMethod } from '../../models/paymentmethod';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.pug',
  styleUrls: ['./welcome.component.styl'],
  animations: [ inputError, welcomebox, overlay, hidewelcome ]
})
export class WelcomeComponent implements OnInit {
  constructor( 
      private router: Router,
      private user: UserService,
      private loc: Location,
      private route: ActivatedRoute,
      private marketplace: MarketplaceService ) {
      this.router.events.subscribe((val) => {
        if ( this.loc.path() == '/signup' || (this.loc.path().indexOf('/signup') > -1) ) {
          this.showRegister = true;
        } else if ( this.loc.path() == '/register' || (this.loc.path().indexOf('/register') > -1) ) {
          this.showRegister = true;
        } else {
          this.showRegister = false;
        }
      });
      this.recaptchaKey = environment.recaptcha;
  }
  IFShowWelcome = false;
  @Input('showLogin') showLogin:boolean;
  @Input('showWelcome') showWelcome = false;
  @Input('showRegister') showRegister = false;
  @Input('showProducts') showProducts = true;
  @Input('reloadAfterLogin') reloadAfterLogin = true;
  @Output('loggingIn') loggingIn = new EventEmitter();
  account: Account;
  location: Locale;
  countries: Country;
  paymentoptions: PaymentMethod;
  registration: Registration;
  email = new Email();
  newUser = new Register();
  showtitle = '';
  GeoLock = false;
  welcomeflash = false;
  formComplete = false;
  legalerror = false;
  termserror = false;
  userLoginError = false;
  passwordLoginError = false;
  ShowPopupOnlyOnFirstWelcome = true;
  ref: string;
  light: string;
  new: boolean;
  panel: string;
  shareURL: string;
  loggedIn: boolean;
  birthday: any;
  recaptchaKey: string;
  minimumAge = 18;
  birthdayAge: number;
  firstVisit:boolean;
  userError:boolean;
  emailError:boolean;
  loginForm:boolean;
  passwordError:boolean;
  requireBirthday:boolean;
  country_count = 0;
  fl: boolean;
  fh = 0;
  forgottenpassword() {
    this.router.navigate(['/store']);
  }
  welcome() {
    this.removeError();
    this.termserror = false; this.legalerror = false;
    if ( this.newUser.email && this.newUser.name && this.newUser.password ){
      if ( this.newUser.legal && this.birthdayAge > 18 ) {
        if ( this.newUser.terms ) {
          this.user.welcome(this.newUser.email,
                          this.newUser.name,
                          this.newUser.password,
                          this.newUser.recaptcha).subscribe( (a) => {
                            this.registration=a;
                            if (a.code == 200) this.router.navigate(['/store']);
          });
        } else { this.termserror = true; }
      } else { this.legalerror = true; }
    } else { 
      if ( !this.newUser.email )    this.emailError = true;
      if ( !this.newUser.name )     this.passwordError = true;
      if ( !this.newUser.password ) this.userError = true; 
      if ( !this.newUser.legal || this.birthdayAge < 18 ) this.legalerror = true;
      if ( !this.newUser.terms ) this.termserror = true; 
    }
  }
  removeError() {
      this.emailError = false;
      this.passwordError = false;
      this.userError = false; 
      this.legalerror = false;
      this.termserror = false;
  }
  register() {
    this.user.welcome(this.newUser.email, 
                      this.newUser.name, 
                      this.newUser.password,
                      this.newUser.recaptcha).subscribe( (a) => {
                  this.registration=a;
                  this.login();
                  if ( a[0].code = 200 ) {
                    window.location.pathname='/';
                  }
    });
  }
  login() { 
    this.user.login( this.newUser.name, this.newUser.password ).subscribe( (acc) => {
      if( acc ) {
        this.registration = acc;
        if (acc.code == 200) {
          this.auth();
        } else {
          this.userLoginError=true;
          this.passwordLoginError=true;
        }
      }
    });
  }
  copyURL(el?) { ngCopy(this.shareURL); }
  togglePanel(l) { this.panel=(this.panel==l)?'false':l; }
  toggleAge() { this.newUser.legal = this.newUser.legal ? false : true; }
  flashWelcome(e?) {
    if ( this.showWelcome && !this.loggedIn ) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      this.panel='false';
      e.preventDefault();
      this.welcomeflash = true;
      setTimeout( () => {
        this.welcomeflash = false;
      }, 80);
      setTimeout( () => {
        this.welcomeflash = true;
      }, 280);
      setTimeout( () => {
        this.welcomeflash = false;
      }, 350);
    }
  }
  sendEmail() {
    var result = this.user.share_email(this.email);
    if ( result ) console.log(result);
  }
  f() {
    this.fh = this.fh+1;
    if (this.fh>3) this.loggedIn = true;
    if (this.fl) return;
    this.fl = true;
    setTimeout( () => {
      this.fl = false;
      this.fh = 0;
    }, 12500);
  }
  _disp_sw( s, a = false ) {
    var k = null;
    switch (s.name) {
      case "Account Funds":
        k= (!a)?'none':false;
      break;
      // case "Dash":
      // case "BitCoin":
      // case "PotCoin":
      // case "LiteCoin":
      // case "Ethererium":
      // case "DopeCoin":
      // case "CannabisCoin":
      //   if ( environment.darkmarket || !environment.production ) {
      //     return (!a)?'block-inline':true;
      //   } else { 
      //     return (!a)?'none':false;
      //   }
      // break;
      default:
        if ( environment.darkmarket && environment.production ) {
          k= (!a)?'none':false;
        } else { 
          k= (!a)?'block-inline':true;
        }
      break;
    }
    return k;
  }
  share(url) {
    this.panel = url;
    if ( url == 'facebook' ) {
    } else if ( url == 'twitter' ) {
    } else if ( url == 'email' ) {
    } else {
    }
  }
  step_showtitle(letter?) {
    if ( letter ) this.showtitle = this.showtitle + letter;
  }
  _dO(o) {
    var a = false;
    if ( o.name != 'Account Funds' ) {
      if ( environment.darkmarket )  {
        if (o.name != 'Credit Card' && o.name != 'Interac Transfer' && o.name != 'PayPal') {  
          a = true;
        }
      } else {
        a = true;
      }
    }
    return a;
  }
  mb(e) {
    console.log( e );
    var top = e.layerY;
    var left = e.layerX;
  }
  auth() { 
    this.user.authorize().subscribe( (a) => {
      this.account = a;
      if ( this.account[0] ) {
        this.loggingIn.emit(this.account[0]);
        if (this.reloadAfterLogin) location.reload();
      }
    });
  }
  checkUser() {
    if ( this.newUser.name ) {
      this.user.checkUser(this.newUser.name).subscribe( (deg) => {
        this.userError = (deg==true)?false:true;
      });
    }
  }
  checkEmail() {
    if ( this.newUser.email ) {
      this.user.checkEmail(this.newUser.email).subscribe( (deg) => {
        this.emailError = (deg==true)?false:true;
      });
    }
  }
  showTerms() {
    this.user.cacheRegistration(this.newUser);
    this.router.navigate(['/terms']);
  }
  checkPwd() {
    if ( this.newUser.password ) this.passwordError = ( this.newUser.password.length < 6 ) ? true : false;
    if ( this.newUser.password && this.newUser.name && this.newUser.email ) {
      this.userError = false;
      this.emailError = false;
    }
  }
  GeoRestrict() {
    this.user.locale().subscribe( (l) => {
      this.marketplace.locationAllowed(l[0].country).subscribe( (u) => {
        if ( !u ) this.router.navigate(['/unavailable']);
      });
      this.location = l;
    }); 
  }
  ageChange(e) {
    this.birthdayAge = e;
  }
  dateChange(e) {
    this.birthday = e;
    console.log(this.birthday);
  }
  _welcome() {
    if( this.requireBirthday ) {
      if ( this.birthdayAge >= this.minimumAge ) {
        this.showLogin = false;
        this.showRegister = true;
      }
    } else { 
      this.showLogin = false;
      this.showRegister = true;
    }
  }
  _already_a_member() {
    this.showRegister = false;
    this.showLogin = true;
  }
  updatedCart(e) {}
  userpastWelcome(a) {}
  ngOnInit() {
    if (this.router.url == '/login') { this.IFShowWelcome = true; }
    if (this.GeoLock) this.GeoRestrict();
    if (this.ShowPopupOnlyOnFirstWelcome && this.IFShowWelcome) {
      this.firstVisit = this.user.firstVisit();
       if (this.firstVisit) this.showWelcome = this.IFShowWelcome;
    } else { this.showWelcome = false; }
    this.ref = this.route.snapshot.paramMap.get("id");
    var acc = sessionStorage.getItem('acc');
    if(!acc) {
      this.user.authorize().subscribe( (a) => {
        if (a && a[0]) {
          // sessionStorage.setItem('acc', JSON.stringify(a));
          this.loggedIn = true;
          this.showWelcome = false;
          this.account = a;
          this.shareURL = 'https://'+((environment.production)?'treest.ca':'localhost:4200')+'/signup/'+a[0].accountid;
          this.email.subject = 'Invite you to join Tree St.';
          this.email.message = 'Hey, I am inviting you to visit TreeSt.ca. It is the new online market for cannabis retail. You can join Tree St. free using this link: '+this.shareURL; 
        } else if (this.firstVisit) {
          this.loginForm = true;
          this.showWelcome = this.IFShowWelcome;
        }
      });
    } else {
      this.loggedIn = true;
      this.showWelcome = false;
      this.account = JSON.parse(acc);
    }
    this.marketplace.countries().subscribe( (c) => {
      this.countries = c;
      this.country_count = c[0].count;
    });
    this.marketplace.paymentOptions().subscribe((opt) => {
      this.paymentoptions = opt;
    });

  }
}
export class Register {
  constructor(
    public email?: string,
    public name?: string,
    public password?: string, 
    public confirmation_password?: string, 
    public legal?: boolean,
    public terms?: boolean,
    public recaptcha?: string
  ) {}
}
export class Email {
  constructor(
    public to?: string,
    public subject?: string,
    public message?: string
  ) {}
}