import { ViewChild, Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef  } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MarketplaceService } from '../../services/marketplace.service';
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { Image } from '../../models/image';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'logo-uploader',
  templateUrl: './logouploader.component.pug',
  styleUrls: ['./logouploader.component.styl']
})
export class LogoUploader {
	
	constructor(
		public dome: DomSanitizer,
		public elementRef: ElementRef,
		public dragulaService: DragulaService,
		public marketplace: MarketplaceService) {
	}

	@Output('id') id: string;
	@Output('newID') newID = new EventEmitter();
	@Output('url') url = new EventEmitter();
	@Input('id') identifier = '0';
	@Input('icon') icon = 1;
	@Input('classes') classes = '';
	@Input('button') button = 'Add Logo Image';

	selectedFiles: FileList;
	currentFileUpload: File;
	filesUploaded: boolean;
	fileFinished: boolean;
	cropperSettings: any;
	showViewer: boolean;
	sonicHeight: any;
	waiting: boolean;
	viewing = false;
	selected: any;
	images: Image;
	image: Image;
	loading: any;
	value = 100;
	order: any;
	error: any;
	clo: any;

	selectFile(event) {
		this.selectedFiles = event.target.files;
		this.upload();
	}

	upload() {
		if ( this.selectedFiles ) {
			for (var i = this.selectedFiles.length - 1; i >= 0; i--) {
		    	this.currentFileUpload = this.selectedFiles.item(i);
		    	if ( this.currentFileUpload.type == 'image/jpeg' || this.currentFileUpload.type == 'image/png' ) {
			    	this.marketplace.uploadLogo(this.currentFileUpload, this.identifier)
			    		.subscribe((e) => {
							if ( e[0].error ) { 
				    			this.error = e[0].error;
				    		} else {
				    			this.image = e;
				    			this.url.emit(e[0].url);
				    			this.error = false;
				    			this.filesUploaded = true;
				    		}
			    	});
		    	}
		    }
		    // selectedFiles: FileList;
	    }
	}

	reordered() {
			this.marketplace.uploadImage(this.currentFileUpload, this.identifier).subscribe((e) => {
				if ( e[0].error ) { 
					this.error = e[0].error;
				} else {
					this.image = e;
					this.error = false;
					this.filesUploaded = true;
				}
	    	});
	}

	ngOnDestroy() {

	}

	ngOnInit() {

	}

}
