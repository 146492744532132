import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router'; 
import { Address, Provence, Country } from '../../models/address';
import { Category } from '../../models/category';
import { Product } from '../../models/product';
import { Account } from '../../models/account';
import { Review } from '../../models/review';
import { Order } from '../../models/order';
import { Location } from '@angular/common';
import { entryAnimation, underline, up, large, icon, map, showFade, starburst, showstatus, overlay } from './order.animations';
import { DayMode, NiteMode } from '../../map.styles';
import { Socket } from 'ng-socket-io';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'user-orders',
  templateUrl: './orders.component.pug',
  animations: [ entryAnimation, showFade, underline, up, large, icon, map, starburst, showstatus ],
  styleUrls: ['./orders.component.styl']
})
export class OrdersComponent implements OnInit {

	@ViewChild('ButtonSpecial') ButtonSpecial: ElementRef;
	@ViewChild('circle') circle: ElementRef;
	@Input('style') style = 'dark';
	@Input('singleOrder') singleOrder = false;
	@Input('modularOrders') mo = false;
	@Input('orders') ppg = 1;
	@Input('title') title:string = 'Active Orders';
	@Input('store') store:number = 0;
	@Input('checkout_page') checkout_page:boolean = false;
	@Input('owner') owner:boolean = false;
	@Input('id') id:number = 0;
	@Input('refresh') public set refresh(val: boolean) {
		this.getOrders();
	}
	@Input('mapStyle') mapStyle = 'light';
	@Input('position') position = [{ lat: 43.6532, lng: -79.3832 }];
	public mapStylesheet: any;
	constructor(
		private router: Router,
		private locator: Location,
		private socket: Socket,
		private marketplace: MarketplaceService,
		private user: UserService) {
		this.setOnComplete();
		for (var i = 0; i <= 11; i++) { this.hours.push(((i==0)?12:i)+' AM'); }
		for (var i = 0; i <= 11; i++) { this.hours.push(((i==0)?12:i)+' PM'); }
		for (var i = 0; i <= 59; i++) { this.minutes.push(i); }
		var d = new Date();
		this.sm = d.getMinutes();
		this.sh = d.getHours();
		this.stars = [1,2,3,4,5];
	}
	n:any;
	so: any;
	_ot: any;
	_occ: any;
	watc: any;
	setOrder: any;
	supertimer: any;
	timeInterv: any;
	holdingOnTo: any;
	screenWidth: number;
	screenHeight: number;
	orderID: number;
	filterWindow: any;
	filterCategory: any;
	r = [];
	sC = [];
	opp = [];
	star = [];
	load = [];
	stars = [];
	hours = [];
	minutes = [];
	showMap = [];
	payment = [];
	question = [];
	subquestion = [];
	loading = true;
	isAdmin = false;
	reviewReport = false;
	pickupActive = false;
	filterSearch = false;
	showItems = false;
	showDetails = false;
	OnCompletePage = false;
	addressChanged = false;
	searchingForDriver = false;
	showTipBar = true;
	paymentCancelled = false;
	hasDriver = false;
	ownership = [];
	isMobile = false;
	isMobile720 = false;
	showTip = false;
	showReport = false;
	newOrder = false;
	filters = false;
	orderCard = false;
	format = true;
	oIn = false;
	show = false;
	dkM = false;
	_oc = true;
	_h = false;
	sa = false;
	of = 0;
	ex = 0;
	oc = -1;
	ox = 0;
	pI = 0;
	sh = 0;
	sm = 0;
	hour = 0;
	minute = 0;
	fakeloade = 0;
	reviewsCount= 0;
	circumference= 0;
	showAddressMap = -1;
	progressHeight = 125;
	tipErr = false;
	on = '';
	OrderCanceled = 'Order Canceled';
	OnCompleteString = 'Complete Payment';
	publish_button = 'Publish Review';
	filtering = 'Showing 100 products'
	currencyDelimiter = "$";
	ordercurrency: "CA";
	order = 'popular';
	time: 'latest'; 
	location: 'local';
	orders: Order;
	locale: Address;
	reviews: Review;
	countries: Country;
	categories: Category;
	subdivisions: Provence;
	single = [new SingleProduct(0, 'g')];
	tip = [new Tip(0)];
	tipI = 0;
	tipId = 0;
	filter = new Filter('popular','latest','local');
	newtime = new ChangeTime();
	bkreview = new NewReview();
	report = new Report();
	review = new NewReview();
	newAddress = new ChangeAddress();
	navIcon: any;
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.rez(event);
	} rez(e?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		if ( this.screenWidth<=992) {
			this.isMobile = true;
			this.isMobile720 = false;
			this.progressHeight = 115;
		} else {
			this.isMobile = false; 
			this.isMobile720 = false;
			this.progressHeight = 170;
		}
		if ( this.screenWidth<768) {
			this.isMobile720 = true;
		}
	}

	getOrders() {
		this.marketplace.getOrders().subscribe( (YourTreeStreetOrder) => {
			if ( YourTreeStreetOrder[0].count > 0 ) {
				var ytd = YourTreeStreetOrder[0];
				this.orders = YourTreeStreetOrder;
				this.hasDriver = ( ytd.driver[0] ) ? true : false;
				this.oc = ytd.count;
				this.on = ytd.count;
				this.loading = false;
				for (var i = 0; i <= YourTreeStreetOrder[0].count; i++) {
					this.opp[i]=0;
					this.tip[i]=new Tip(0);
					if ( YourTreeStreetOrder[i] && YourTreeStreetOrder[i].accountid == String(this.id) ) {
						this.ownership[i] = true;
					} else { this.ownership[i] = false; }
				}
			} else {
				this.loading = false;
			}
		});
	}

	changeTip(t,i) {
		this.tipErr = false;
		this.tip[i].amount = t.toFixed(2);
	}

	addTip(a,i) {
		this.tipErr = false;
		if (this.tip[i].amount) {
			this.tip[i].amount = Number(this.tip[i].amount);
			this.tip[i].amount = this.tip[i].amount+a;
		} else {
			this.tip[i].amount = 1;
		}
		this.tip[i].amount = this.tip[i].amount.toFixed(2);
	}

	orderShipped(o) {
		return (o.paid==true && !o.delivery && o.shipped && !o.delivered);
	}

	orderRecieved(o) {
		return o.received;
	}

	unitQuantities(o) {
		return o.quantity;
	}

	changeAddress(id, newAddress) {
		this.user.changeOrderAddress(id, newAddress.address).subscribe((a) => {
			this.addressChanged = a[0];
		});
	}

	activateDriverCard(e,b=0) {
		console.log(e);
		this.pickupActive = true;
		setTimeout( () => {
			this.pickupActive = false;
		}, 300);
	}

	mark_as_shipped( order ) {
		order.shipped = true;
		this.marketplace.mark_order_as_shipped(order).subscribe((a) => {
			order = a[0];
			order.shipped = a[0].shipped;
		});
	}

	mark_as_received( order ) {
		order.delivered = true;
		this.marketplace.mark_order_as_received(order).subscribe((a) => {
			order = a[0];
			order.delivered = a[0].delivered;
		});
	}

	get_reviews(order=0) {
		this.user.getOrderReviews(order).subscribe( (a) => {
			this.reviews = a;
			this.reviewsCount = a[0].count;
		});
	}

	post_review(p,r,o) {
		r.vendor = o.accountid;
		r.product = p.id;
		r.order = o.id;
		if ( r.rating ) {
			this.user.post_review(r,this.n).subscribe( (a) => {
				this.reviews = a;
				this.reviewsCount = a[0].count;
				console.log(a); //this.review.code = a.code
			});
		} else {
			this.publish_button = 'add a star rating';
		}
	}

	post_report(p,r,o) {
		r.vendor = o.accountid;
		r.product = p.id;
		this.user.post_report(r,this.n).subscribe( (a) => {
			this.reviews = a;
			this.reviewsCount = a[0].count;
			console.log(a); //this.review.code = a.code
		});
	}

	oxy() {
		var x= (this.so)?(this.ox+this.ppg):this.oc;
		return x;
	}
	bry() {
		var x = (this.so)?(this.oc-this.ox+this.ppg)>0?this.oc-this.ox+this.ppg:this.oc:this.oc;
		return x;
	}

	recieveOrder() {
		this.newOrder = true;
		this.getOrders();
	}

	toggleCoder(xxx?,i?,y?) {
		if ( this.oIn[y] ) {
			this.showMap[i],this.oIn[y] = false;
		} else if ( this.oIn[y] == false ) {
			this.showMap[i],this.oIn[y] = true;	
		}
	}

	setOnComplete() {
		var p = this.locator.path();
		if ( p.includes('checkout-complete') ) {
			this.OnCompleteString = 'Complete Below';
			this.OnCompletePage = true;
		} else {
			this.OnCompleteString = 'Complete Payment';
			this.OnCompletePage = false;
		}
	}

	getNonce() {
		this.user.getNonce().subscribe((nonce)=>{
			this.n=nonce[0].code;
		})
	}

	goTo(id) {
		this.router.navigate(['checkout-complete',id]);
	}

	sendTip(id, i) {
		this.tipErr = false;
		if ( !this.tip[i].amount || this.tip[i].amount == 0 || this.tip[i].amount == '0' ) {
			this.tipErr = true;
			this.tip[i].id = id;
			this.marketplace.tip(this.tip[i]).subscribe( (s) => {
				if(!s) { this.tipErr=true; }
			});
			setTimeout(()=>{ this.tipErr=false; }, 3500);
		}
	}

	//** Showtime variables **/

	setProgress(percent) {
		var radius = 170;
		this.circumference = radius * 2 * Math.PI;
		this.of = this.circumference - percent / 100 * this.circumference;
	}

	OrderUP(a?) {
		this.fakeloade = (this.so==true)?1:2;
		if(a) this.fakeloade = a;
		for(var l=1; l<=this.fakeloade; l++) {
			this.load.push(l);
		}
	}

	seen(id) {
		this.marketplace.seen_order(id).subscribe( (s) => {

		});
	}

	oos_cancel(o) {
		this.marketplace.cancel_order( o, this.n ).subscribe( (o) => {
			this.getOrders();
		});
	}

	oos_continute(o) {
		this.marketplace.remove_out_of_stock_items_from_order( o, this.n ).subscribe( (o) => {
			this.getOrders();
		});
	}

	hide_order(id?) {
		this.OrderCanceled = 'OK';
	}

	archive(id?) {
		
	}

	sma(a,h=0) {
		if(h) {
			if (a) {
				this.sm=(this.sm>0)?this.sm-1:59
			} else { 
				this.sm=(this.sm<59)?this.sm+1:0
			}
			if (this.holdingOnTo=='sma') {
				this.timeInterv = setInterval( () => {
					this.sma(a,h);
				}, 200);
			} else {
				this.sma(a,0);
			}
			this.holdingOnTo='sma';
		} else { this.holdingOnTo=false; clearInterval(this.timeInterv) }
	}

	sha(a,h=0){
		if(h) {
			if ( a ) {
				this.sh=(this.sh<23)?this.sh+1:0
			} else {
				this.sh=(this.sh>0)?this.sh-1:23
			}
			if (this.holdingOnTo=='sha') {
				this.timeInterv = setInterval( () => {
					this.sha(a,1);
				}, 200);
			} else {
				this.sha(a,0);
			}
			this.holdingOnTo='sha';
		} else { this.holdingOnTo=false; clearInterval(this.timeInterv) }
	}

	aoc(e,b=-1,o) {
		if ( b==1 )  this.sa = true;
		if ( this.sa ) this.ex = e.offsetX;
		if ( b==2 )  {
			this.sa = false;
			this.ex = e.srcElement.clientWidth;
			if ( o ) {
				setTimeout( () => {
					this.sa = false;
					this[o]=true;
					this.ex = 0.0;
				}, 350) // 
			}
		}
		if ( b ==3 ) {
			this.sa = false;
			this.ex = 0.0;
		}
	}

	ss(ii, j, i, set) {
		if ( set ) this.review.rating = j + ( (i=='h') ? 0.5 : (i=='f') ? 1 : 0 );
		for ( var h = 4; h >= 0; h--) {
			if ( i ) {
				this.star[h] = (j+1>h) ? (i=='h' && j+1 == h+1 ? 'h' : 'f') : 0;
			} else {
				this.star[h] = (this.review.rating>h)?(this.review.rating==h+.5?'h':'f'):0;
			}
		}
		// Trust me this draws a star.
	}

	cR(i) {
		for (var a = 0; a <= this.oc; a++) {
			this.sC[a] = '';
		}

		this.bkreview[this.review.i] = this.review;
		this.sC[i] = 'review';

		if ( this.bkreview[i] ) {
			this.review = this.bkreview[i];
		} else { 
			this.review = new NewReview();
			this.review.i = i;
		}

		for ( var h = 4; h >= 0; h--) {
			this.star[h] = (this.review.rating>h)?(this.review.rating==h+.5?'h':'f'):0;
		}
	}

	_sp(i) {
		this._sir(i);		
		this.sC[i] = 'products';
	}

	_sd(i,o) { 
		this._sir(i);
		if (o.delivery) {
			this.sC[i] = 'delivery';
		} else { 
			this.sC[i] = 'shipping';
		}
	}

	_sir(i) {
		this.question[i]=0;
		for (var l=18;l<= 28;l++) {       }
	}

	_alw_rwv(o) { // Allow new reviews or not 
		if ( this.owner ) {
			if ( o.review_count == 0 ) {
				return true;
			} else {
				return false;
			}
		} else {
			if ( o.review_count < o.products_count ) {
				return true;
			} else {
				return false;
			}
		}
	}

	switch_style() {
		this.mapStyle = 'light';
		this.dkM = this.marketplace.getDark();
		if (this.dkM) this.mapStyle = 'dark';//
		if ( this.mapStyle == 'dark' ) {
			this.mapStylesheet = NiteMode;
		} 
  		if ( this.mapStyle == 'light' ) {
  			this.mapStylesheet = DayMode;
  		} 
  		this.navIcon = {
			url:(this.mapStyle=='dark')?'/assets/img/greenicon.png':'/assets/img/greenicon.png'
		}
	}

	cancel_payment() {
		this.marketplace.cancel_order_payment(this.orderID).subscribe( (o) => {
			console.log(o);
		});	
	}
	cancel_order() {
		var order = {id:this.orderID};
		this.marketplace.cancel_order(order, this.n).subscribe( (o) => {
			this.getOrders();
			console.log(o);
		});
	}
	delivery_issue() {
		this.marketplace.delivery_issue(this.orderID).subscribe( (o) => {
			console.log(o);
		});	
	}
	vendor_order() {
		this.marketplace.vendor_order(this.orderID).subscribe( (o) => {
			console.log(o);
		});
	}
	report_issue() {
		this.marketplace.report_issue(this.orderID).subscribe( (o) => {
			console.log(o);
		});
	}

	ngOnInit() {
		this.so = this.singleOrder;
		this.rez();
		this.OrderUP();
		this.setOnComplete();
		this.user.authorize().subscribe( (a) => {
			this.socket.emit('open_connection', {id: a[0].accountid});
			this.id=a[0].accountid;
			this.getOrders();
		});
		this.user.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		this._ot = setInterval( () => { this._oc=this._oc?false:true; }, 1000);
		this.getNonce();
		this.socket.fromEvent<any>("new_order").subscribe((r) => {
			this.recieveOrder();
		});
		this.socket.fromEvent<any>("pong").subscribe((r) => {
			// console.log(r);
		});
		setInterval( () => {
			this.switch_style();
		}, 400);
		this.switch_style();
	  	// 	this._occ = setInterval( () => {
				// this.OrderCanceled=(this.OrderCanceled=='Order Canceled')?this.OrderCanceled='Acknowledge':'Order Canceled';
	  	// 	}, 2700);
	}

	ngOnDestroy() {
		clearInterval(this._occ);
		clearInterval(this._ot);
	}
}



export class SingleProduct {
	constructor(
		public quantity?: number,
		public unit?: string,
		public offer?: number
	) {}
}

export class Filter {
   constructor(
   	public order: string,
   	public time: string,
   	public location: string,
   	public term?: string,
   	public user?: string,
   	public type?: string,
   ) {}
}

export class ChangeAddress {
   constructor(
   	public address?: string
   ) {}
}

export class ChangeTime {
   constructor(
   	public hour?: number,
   	public minute?: number
   ) {}
}

export class Tip {
   constructor(
   	public amount?: any,
   	public id?: number
   ) {}
}

export class NewReview {
   constructor(
   	public i?: number,
   	public body?: string,
   	public product?: number,
   	public item?: number,
   	public vendor?: number,
   	public driver?: number,
   	public rating?: number
   ) {}
}
export class Report {
   constructor(
   	public i?: number,
   	public body?: string,
   	public product?: number,
   	public item?: number,
   	public vendor?: number,
   	public driver?: number,
   	public report?: string,
   	public type?: string
   ) {}
}