import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Favicons } from '../../app.favicons';
@Component({
  selector: 'logout',
  templateUrl: './logout.component.pug',
  styleUrls: ['./logout.component.styl']
})
export class LogoutComponent implements OnInit {
  constructor(
    public icons: Favicons,
  	public user: UserService,
    public router: Router,
    private route: ActivatedRoute
  ) { this.favicons = icons; }
  favicons: Favicons;
  fwdpath: any;
  path = '';
  loggedOut() {}
  ngOnInit() {
  	this.user.logout().subscribe( (loggedOut) => {
      this.favicons.reset();
  	  if (loggedOut) {
        this.loggedOut();
        this.fwdpath = this.route.snapshot.params.fwdpath;
        if(this.fwdpath) { 
          this.router.navigate(['/',this.fwdpath]);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }
}