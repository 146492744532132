import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vendor-app',
  templateUrl: './vendor.app.component.pug',
  styleUrls: ['./vendor.app.component.styl']
})
export class VendorAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}