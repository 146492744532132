import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Account } from '../../models/account';
import { Friend } from '../../models/friend';
import { Currency } from '../../models/currency';
import { UserService } from '../../services/user.service'
import { MarketplaceService } from '../../services/marketplace.service'
@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.pug',
  styleUrls: ['./user-menu.component.styl']
})
export class UserMenuComponent implements OnInit {

  constructor(
    private user: UserService,
    private router: Router,
    private marketplace: MarketplaceService,
  ) { }
  @Output('linkHit') linkHit = new EventEmitter();
  pendingFriends: Friend;
  currencies: Currency;
  accounts: Account;
  countcurrencies = 0;
  selectedcurrency: any;
  hasBalance: boolean;
  retail: string;
  greet: string;
  orders = 0;
  saying = '';
  vending: boolean;
  loading_delivery: boolean;
  isGuest: boolean;
  isVendor: boolean;
  clickLink(l?) {
    this.linkHit.emit(true);
    if (l) this.router.navigate(l);
  }
  changeDelivery() {
    this.loading_delivery =  true;
    if ( this.vending == true ) {
      this.user.diableDelivery().subscribe((r) => {
        this.accounts = r;
        this.vending = false;
        this.loading_delivery = false;
      });
    } else {
      this.user.enableDelivery().subscribe((r) => {
        this.accounts = r;
        this.vending = true;
        this.loading_delivery = false;
      });
    }
  }
  get_currencies() {
    this.marketplace.getCurrencies().subscribe( (a) => {
      this.currencies = a;
    });
    this.marketplace.countCurrencies().subscribe( (c) => {
      this.countcurrencies = c;
    });
  }
  set_currency( c ) {
    this.user.setCurrency(c).subscribe( (c) => {

    }); 
  }
  random_saying() {
    this.user.getSaying().subscribe( (c) => {
      this.saying = c;
    }); 
  }
  toggleVendor() {
    this.vending = (this.vending) ? false : true;

  }
  accept_request(f) {
    this.user.acceptFriend(f.accountid).subscribe( (c) => {
      this.get_friend_requests();
    });
  }
  reject_request(f) {
    this.user.rejectFriend(f.accountid).subscribe( (c) => {
      this.get_friend_requests();
    });
  }
  get_friend_requests() {
    this.user.friendRequests().subscribe( (c) => {
      this.pendingFriends = c;
    });
  }
  ngOnInit() {
    this.user.get_currency().subscribe( (c) => {
      this.selectedcurrency = c;
    });
    this.random_saying();
    this.user.authorize().subscribe( (a) => {
      this.accounts = a;
      this.hasBalance = a[0].hasBalance;
      this.get_friend_requests();
      let hello = 'Hello';
      this.vending = (a[0].enable_delivery=="1"||a[0].enable_delivery==1)?true:false;
      this.isVendor = (a[0].delivery_available && a[0].vendor) ? true : false;
      this.isGuest = (a[0].guest) ? true : false;
      this.greet  = (a[0].role=='admin')?"Greetings, ":hello+", ";
      this.retail = (a[0].role=='admin')?"Admin":(a[0].vendor)?"Vendor":(a[0].retailer)?"Retailer":"";
    });
  }
}