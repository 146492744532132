	/**
	* 	Name: 			Tree Street
	* 	Description: 	Cannabis Marketplace
	*	Author: 		Liam Hogan / aka: @bentbot
	*					liam@hogan.re http://hogan.re
	*	Date: 			October 24, 2019
	**/
import { environment } from '../environments/environment';
import { Component, ElementRef, Input, Output, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LoadingBarModule, LoadingBarService } from "id-loading-bar";
import { MarketplaceService } from './services/marketplace.service';
import { UserService } from './services/user.service';
import { Auth } from './models/auth';
import { Info } from './models/info';
import { Message } from './models/message';
import { Account } from './models/account';
import { Product } from './models/product';
import { Favicons } from "./app.favicons";
import { Notification } from './models/notification';
import { Address, Country, Provence, Locale } from './models/address';
import { show, ex, bubble, height, finderInput, fadeSlow, finderLocale, welcomebox, finderWidth, loginBox, localeLabel, notifBox, gramReveal, countryList, flashcart, notifButton } from './app.animations';
declare var jquery:any; declare var $ :any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug', 
  animations: [ show, ex, bubble, height, finderInput, fadeSlow, finderLocale, welcomebox, finderWidth, loginBox, gramReveal, localeLabel, notifBox, countryList, flashcart, notifButton ],
  providers: [ MarketplaceService, UserService ],
  styleUrls: ['./app.component.styl']
})
export class AppComponent {
	prod = environment.production;
	_AAA: 				boolean;
	fh: 				boolean;
	fv: 				boolean;
	ug: 				boolean;
	mw: 				boolean;
	showDark: 			boolean;
	itemsInCart: 		boolean;
	showCartPopover: 	boolean;
	coun= 				'CA';
	prov: 				any;
	cit: 				any;
	mvp: 				any;
	mn: 				any;
	mt: 				any;
	nn: 				any;
	authtimer: 			any;
	darktimer: 			any;
	locationtimer: 		any;
	cartProducts: 		any;
	lastCartProducts: 	any;
	parsedCart: 		any;
	notifTimer: 		any;
	autoUpdater: 		any;
	cartLoop: 			any;
	screenHeight:		number;
	screenWidth:		number;
	loaderRunInterval =	300;
	usersMessages =		2;
	loaderHeight =		2;
	cartGrams= 			0;
	cartItems= 			0;
	a 		=			0;
	c 		=			1;
	h 		=			3;
	n =					0;
	t =					0;
	locationTag =		'Locating...';
	login_option =		'form';
	cartUnits =			'g';
	loaderColor =		"#84d500";
	_siteClasses = 	'';
	_siteContainerClasses ='';
	headerMessages =	true;
	showHeader =		true;
	showFooterLinks =	true;
	enableMessage =		true;
	enableAdd =			true;
	trylocate =			true;
	loading =			true;
	AutoDarken =		false;
	il 		=			false;
	lili 	=			false;
	nil 	=			false;
	nf 		=			false;
	lk=					false;
	isMobile=			false;
	ch =				false;
	m_w =				false;
	_actv 	=			false;
	registering= 		false;
	registered= 		false;
	headerNotifs =		false;
	headerAlerts =		true;
	fullApplication =	false;
	showCart =			false;
	enableCart =		false;
	firstVisit =		false;
	showSpinner =		false;
	enableChat =		false;
	userFound =			false;
	showChat =			false;
	headerLocale =		false;
	headerLogin =		false;
	flashingCart =		false;
	haveAlerts =		true;
	haveMessages =		false;
	haveNotifications =	false;
	showSearchBar =		false;
	headerSearch =		false;
	extraLarge =		false;
	searchTerm =		false;
	showlocale =		false;
	locating =			false;
	hovering =			false;
	located =			false;
	inIframe =			false;
	isAdmin =			false;
	isVendor =			false;
	authorizing =		false;
	_overSrolling = 	true;
	sub =				[false];
	@Input('master') masterName: string;
	@Input('userMenu') userMenu: boolean;
	@ViewChild('searchBox') searchBox: ElementRef;
	@ViewChild('application') application: ElementRef;
	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event) {
		this.t = window.scrollY;
		if(this.t > 0) {
			this._overSrolling=true;
		} else {
			this._overSrolling=false;
		}
	}
	appScrolled(event) {
		localStorage.setItem('top',event.srcElement.scrollTop);
		localStorage.setItem('height',event.srcElement.scrollTop);
		localStorage.setItem('scrollHeight',event.srcElement.scrollTop);
	}
	@HostListener('touchstart', ['$event'])
	onTouchStart(event) {
		// if( document.body.scrollTop === 0 ) {
		// 	document.body.scrollTop += 1;
		// } else if( document.body.scrollTop + document.body.offsetHeight >= document.body.scrollHeight ) {
		// 	document.body.scrollTop -= 1;
		// }
	}
	@HostListener('window:click', ['$event'])
	onClick(event) {
		this.showCart = false;
	}
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event) {
		if ( event.code == 'KeyD' && event.altKey ) this.toggleDark();
		if ( event.code == 'KeyA' && event.altKey ) {
			this._AAA = true; 
			this.siteClasses();
		}
	}
	@HostListener('document:keyup', ['$event'])
	onKeyupHandler(event) {
		if ( event.code == 'KeyA' && event.altKey ) {
			this._AAA = false; 
			this.siteClasses();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.resizeScreen(event);
	} resizeScreen(e?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		if ( this.screenWidth<=425) {
			this.isMobile = true;
		} else {
			this.isMobile = false; 
		}
	}
	auth: Auth;
	locale: Locale;
	cartInfo: Info;
	messages: Message;
	accounts: Account;
	countries: Country;
	favicons: Favicons;
	alerts: Notification;
	cartContents: Product;
	subdivisions: Provence;
	notifications: Notification;
	search = new Search('');
	constructor( 
		private router: Router,
		private icons: Favicons,
		private location: Location,
		private loader: LoadingBarService,
		private activatedRoute: ActivatedRoute,
		private marketplace: MarketplaceService,
		private user: UserService ) {
		window.addEventListener('gesturestart', e => e.preventDefault());
		window.addEventListener('gesturechange', e => e.preventDefault());
		window.addEventListener('gestureend', e => e.preventDefault());
		this.favicons = icons;
		this.router.events.subscribe((val) => {
			var p = this.location.path();
			if ( p == '/m' || p == '/create' || p == '/newdriver' || p == '/my-account' || p == '/checkout' ||  p == '/recover' ||  p == '/drive' || p == '/cart' || p == '/run'|| p == '/drive' || (p.indexOf('/edit') > -1) || (p.indexOf('/my-account') > -1) ) {
				this.enableCart = false;
				this.extraLarge = true;
			} else {
		      	this.enableCart = true;
				this.extraLarge = false;
		    }
			if ( p == '/my-account/messages') {
				this.enableMessage = false;
			} else {
				this.enableMessage = true;
			}
		    if ( !p.includes("/product") ) this.marketplace.pageTitle();
		    if ( p == '/run' || p == '/drive' ) {
		    	this.showHeader = false;
		    	this.fullApplication = true;
		    	this.siteClasses();
		    }
			if ( p == '/cart' ) this.enableCart = false;
			if ( p.includes('/checkout-complete') ) this.enableCart = false;
			if ( p.includes('/receipt') ) {
				this.showHeader = false;
				this.fullApplication = true;
			}
			if ( p.includes('/iframe') ) {
				this.showHeader = false;
				this.inIframe = true;
			}
	    });
	}
	force_locate() {
		this.forceful_find();
		this.headerLocale = false;
		this.hovering=true;
		this.locating= true;
	}
	phone_locate() {
		this.locating = true; this.lk = true;
		var key = this.user.locKey();
		if ( key ) {
			this.get_locale();
		} else { 
			setTimeout( () => {
				this.web_locate();
			}, 4200);
		}
	}
	get_locale() {
		this.user.locale().subscribe( (a) => {
			if ( a[0] ) {
				this.locale = a;
				this.locationTag = a[0].msg;
				this.user.setLocKey( a[0].key );
				this.user.changeLocale( a[0].iso );
				this.located = true;
				if (!a[0].msg||!a[0].key||!a[0].iso) {
					this.trylocate = false;
					this.web_locate();
				}
			} else {

			}
		});
		setTimeout( () => {
			this.locating = false;
		}, 7420);
	}
	bail(s=0) {
		setTimeout( () => {
			this.trylocate = false;
			this.web_locate();
		}, s);
	}
	forceful_find() {
		this.user.forceLocate( (pos) => {
			if ( pos != false ) {
				this.user.pingLatLng(pos).subscribe( (l) => {
					if ( l[0] ) {
						this.locale = l;
						this.locationTag = l[0].msg;
						this.user.setLocMsg( l[0].msg );
						this.user.setLocKey( l[0].key ); 
						this.user.changeLocale( l[0].iso );
					}
		   		});
		   	}
		});
		setTimeout( () => {
			  	this.locating = false;
	 	}, 7420);
	}
	web_locate() {
		if (this.trylocate) {
			this.get_locale();
			return;
		} else { 
			this.user.locate( (pos) => {
				if ( pos != false ) {
					this.user.pingLatLng(pos).subscribe( (l) => {
						if ( l[0] ) {
							this.locale = l;
							this.locationTag = l[0].msg;
							this.user.setLocMsg( l[0].msg );
							this.user.setLocKey( l[0].key ); 
							this.user.changeLocale( l[0].iso );
						}
			   		});
			   	}
		 	});
		 	setTimeout( () => {
		 	  	this.locating = false;
		 	}, 7420); // ◮ the wall
		}
	}
	locateTimer() {
		setTimeout(()=>{
			if ( this.hovering ) this.locating = true
		},700);
	}
	setLoc(l?,p?,ci?) {
		this.locationTag = this.cit+', '+this.prov;
		this.user.setLocMsg(this.locationTag);
		this.user.setLocKey(p.id+'I'+ci);
		// this.get_locale();
	}
	onActivate() { }
	cart() {
		this.router.navigate(['cart']);
		this.showCart = true;
	}
	message() {
		this.router.navigate(['/my-account/messages']);
	}
	siteClasses() {
		var a = 'site';
		if ( this.fullApplication ) a = a+' full_application';
		if ( this.showDark ) a = a+' dark';
		if ( this._AAA ) a = a+' foant';
		this._siteClasses = a;
	}
	siteContainerClasses() {
		var a = 'site w';
		if ( this.showDark ) a = a + ' dark';
		if ( this.nil ) a = a + ' nil';
		if ( this.il ) a = a + ' il';
		this._siteContainerClasses=a;
	}
	showInDark() {
		var p = this.location.path();
		$('body').css('background', 'white');
		if (this.showDark||p.includes("/run")) $('body').css('background', 'black');
	}
	toggleDark() { 
		this.showDark = (this.showDark) ? false : true;
		this.marketplace.setDark(this.showDark);
		this.showInDark();
		this.siteClasses();
		this.siteContainerClasses();
	}
	toggleCart() {
		this.showCart = this.showCart ? false : true;
	}
	closeAlerts() {
		this.userMenu = false;
		this.headerAlerts = false;
		this.headerMessages = false;
	}
	hideLogin() { 
		this.headerLogin = false;
	}
	focusSearch() { 
		this.searchBox.nativeElement.focus();
	}
	popCart() {
		this.flashingCart = true;
		setTimeout( () => {
			this.flashingCart = false;
		}, 2000);
	}
	get_countries() {
		this.marketplace.countries().subscribe( (c) => {
			this.countries = c;
		});
	}
	fhov(i?) {
		if (i) {
			this.fh = false;
			this.fv = true;
			setTimeout( () => {
				this.fv = false;
			},1000);
		} else {
			this.fh = true;
		}
	}
	movw(d) {
		if ( d==1 ) {
			this.m_w=true;
			this.mt = setTimeout( () => {
				this.mvp = this.mn;
				this.mw = true;
			}, 7771);
			if ( this.mvp ) this.mw = true;
		} else if ( d==2 ) {
			this.m_w=false;
			clearTimeout(this.mt);
			this.mw = false;
		}
	}
	hideNav() {
		this.headerNotifs = false;
		this.headerAlerts = false;
		this.headerMessages = false;
	}

	goHome() {
		this.mvp = false;
		this.router.navigate(['/']);
	}
	addProduct() { this.router.navigate(['/create']); }
	refreshCartLoop() {
		var pi = 0;
		this.cartLoop = setInterval( () => {
			var pa = this.marketplace.parseCart();
			if ( this.parsedCart !== pa ) {
				this.refreshCart();
				this.parsedCart = pa;
			} pi++;
		}, 300);
	}
	refreshCart() {
		this.marketplace.cartInfo().subscribe( (a) => {
			this.cartInfo = a
			if ( this.cartGrams != a[0].weight ) this.popCart();
			if ( this.cartItems != a[0].length ) this.popCart();
			this.cartGrams = a[0].weight;
			this.cartItems = a[0].length;
			this.cartProducts = this.marketplace.parseCart();
			if ( this.cartProducts != this.lastCartProducts) {
				this.marketplace.products((this.cartProducts)?this.cartProducts:'-1').subscribe( (c) => {
					this.cartContents = c;
					this.itemsInCart = (c[0]) ? true : false;
				});
			}
		});
	}
	initNotifs() {
		this.user.getAlerts().subscribe( (a) => {
			if(a) this.haveAlerts = true;
			this.alerts = a;
		});
		// this.user.getAllMessages().subscribe( (m) => {
		// 	if(m) this.haveMessages = true;
		// 	this.messages = m;
		// 	this.user.countMessages().subscribe( (c) => {
		// 		this.usersMessages = c;
		// 		this.haveMessages = (c>0)?true:false;
		// 	});
		// });
		this.user.getNotifications().subscribe( (n) => {
			if(n) this.haveNotifications = true;
			this.notifications = n;
		});
	}
	emitStart() { this.loader.start(); }
    emitStop() { this.loader.stop(); }
    emitReset() {  this.loader.reset(); }
    emitComplete() { this.loader.complete(); }
	legitimize() {
		switch (window.location.hostname) {
			case "treest.ca":
			case "localhost":
			case "staging.treest.ca":
				this.il = false;
				break;
			default:
				this.il = true;
				break;
		}
		this.siteContainerClasses();
		this.autoUpdater = setInterval( () => {
			var s = localStorage.getItem('loading');
			this.showSpinner = (s=='1')?true:false;
		}, 700);
	}
	searchNow(term?, e?) {
		if (e.keycode == 27) this.search.term = '';
		this.searchTerm = (term!='')?true:false; 
	}
	scrollToTop() {
		$("html, body").animate({ scrollTop: 0 }, "fast");
	}
	overlay_hit() {
		this.headerNotifs=false;
		this.headerAlerts=false;
		this.headerLocale=false;
		this.headerLogin=false;
	}
	authorize() {
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) { 
				this._actv = true;
				setTimeout( () => {
					this._actv = false;
				}, 500);
				if (a[0].code==500) { 
					localStorage.removeItem('Wib');
				} else if (a[0].code==400) {
				} else if (a[0].code==200) {
					this.loading = false;
					this.userFound = true;
					this.enableAdd = true;
					this.mn = (a[0].lastname)?a[0].lastname:(a[0].firstname)?a[0].firstname:/*BIG*/'Tree';this.ug = a[0].gay;
					this.accounts = a;
					this.favicons.activate( 'green' );
					clearInterval(this.authtimer); 
					this.nil = (a[0].iterations>9) ? true : false;
					this.siteContainerClasses();
					if ( this.nil ) {
						this.n = a[0].balance[0].balance;
						this.nn=setInterval( () => {
							this.n=(this.n>0.01)?this.n-0.01:0;
							if (this.n==0) this.nvAgn();
						},15);
					}
					setTimeout( () => {
						this.hideLogin();
					},2000);
				}
			}
		});
	}
	autoAuthorize() {
		if ( localStorage.getItem('Wib') ) {
			this.authorize();
		} else { 
			this.authtimer = setInterval( () => {
				if ( !this.authorizing && localStorage.getItem('Wib') ) {
					this.authorizing = true;
					this.authorize();
				}
			}, 2777);
		}
	}
	logOut(e=0) {
		this.user.logout().subscribe( (loggedOut) => {
			this.autoAuthorize();
	    });
	}
	ngOnInit() {
		localStorage.clear();
		if (this.AutoDarken) this.marketplace.dusk_till_dawn();
		this.favicons.activate( 'gray' );
		this.emitStart();
		this.emitComplete();
		this.get_countries();
		this.initNotifs();
		this.refreshCartLoop();
		this.autoAuthorize();
		this.locationtimer = setInterval( () => {
			if ( this.locale ) {
				clearInterval(this.locationtimer);
				return false;
			}
			this.firstVisit = this.user.firstVisit();
			if ( !this.locale ) this.web_locate();
			if ( !this.firstVisit ) {
				if(this.lk==false && this.user.pastWelcome() ) {
					this.web_locate();
				}
			}
		}, 3600);
		this.darktimer = setInterval( () => {
			this.showDark = this.marketplace.getDark();
			// this.showInDark();
		}, 500);
		this.user.adminStatus().subscribe((is) => { this.isAdmin = is.json().admin; });
		// for (var a = 1; a <= 2; a++) { for (var c = 0; c <= 3; c++) { } };
		this.user.vendorStatus().subscribe((is) => { this.isVendor = is.json().vendor; });
		this.mn = 'Tree';
		this.resizeScreen(); 
		this.legitimize();
		this.user.cp_key().subscribe((b) => { if(!b)this.lili=true; });
		
	}
	ngOnDestroy() {
		clearInterval(this.authtimer);
		clearInterval(this.darktimer);
		clearInterval(this.locationtimer);
	}
	ngOnChange() {
		this.showInDark();
	}
	nvAgn() {
		setTimeout( () => { this.nf = true; }, 5000);
		clearInterval(this.nn);
	}
}
export class Search {
   constructor(
   	public term?: string
   ) {}
}