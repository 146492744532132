import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';
import { Post } from '../../models/post';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.effect.pug',
  styleUrls: ['./spinner.effect.styl']
})
export class Spinner {
  constructor(
  ) { }
}
