import { Component, OnInit } from '@angular/core';

import { Account } from '../../models/account';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.pug',
  styleUrls: ['./settings.component.styl']
})
export class SettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
