import { Component, OnInit, ElementRef,EventEmitter, ViewChild, HostListener, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Account } from '../../models/account';
import { Order } from '../../models/order';
import { Product } from '../../models/product';
import { Graph } from '../../models/graph';
import { storeshow, rotation } from './dashboard.animations';

@Component({
  selector: 'user-dashboard',
  templateUrl: './dashboard.component.pug',
  animations: [ storeshow, rotation ],
  styleUrls: ['./dashboard.component.styl']
})
export class DashboardComponent implements OnInit {

  constructor(
  	private route: Router,
  	private user: UserService,
    private marketplace: MarketplaceService,
    private sanitizer: DomSanitizer
  	) { 
  }

  rsz() {
    var a = this.container.nativeElement.offsetWidth;
    var b = (a / 3)-46; b=(b>70)?b:70;
    if( b < 125 ) b = 125;
    this.cw = b+'px';
    this.ch = b+'px';
    this.view = [b+16,b-10];
  }

	orders: Order;
  sales: Order;
	accounts: Account;
  myproducts: Product;
	accountID: number;
  reviews: boolean;
  username: string;
  showOrders = 2;
  noGraph = false;
  question: boolean;
  shipActive: boolean;
  pickupActive: boolean;
	recentOrders: Order;
	productsPurchased: Product;
  productsCount = 0;
  totalShopped = 0;
  totalShipped = 0;
  totalGrams = 0;
  pb = 0;
  pj = 0;
  oc = 0;
  ox = 0;
  cw = '';
  ch = '';
  circlethird = 30;
  sellingCount = 0;
  currentOrder = true;
  showfavourites = false;
  totalSales = 0;
  totalPurchases = 0;
  forSale = 0;
	saleCount= 0;

  // Graph Settings
  data: Graph;
  single: any[];
  multi: any[];
  view = [268, 400];
  showXAxis = true;
  showYAxis = true;
  ShowItA = 'true';
  ShowIt = 'false';
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = 'Weekdays';
  yAxisLabel = 'Earnings';
  colorScheme = 'flame';

  ChartSelect(ebent) { return false }
  @ViewChild('container') container: ElementRef;
  @ViewChild('circle') circle: ElementRef;
  @Output('page') page: EventEmitter<any> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.rsz();
  }

  getRandomData() {
    this.user.getSalesGraph().subscribe( (i) => {
      this.data = i;
      if (i[0].value==0 && i[1].value==0 && i[2].value==0 && i[3].value==0 && i[4].value==0 && i[5].value==0 && i[6].value==0){
        this.noGraph = true;
      }
    })
  }

  getTotals() {
    this.user.getGramsBought().subscribe( (g) => {
      this.totalGrams = g[0].grams;
    });
    this.user.getProductsDelivered().subscribe( (p) => {
      this.totalShipped = p[0].products;
    });
    this.user.getPagesBrowsed().subscribe( (p) => {
      this.totalShopped = p[0].pages;
    });
  }


  getOrders() {
    this.user.getOrders().subscribe( (o) => {
      this.orders = o;
    });
    this.user.getSales().subscribe( (o) => {
      this.totalSales = o[0].count;
      this.sales = o;
    });
  }

  getProducts() {
    this.marketplace.products('user',this.accountID).subscribe( (o) => {
      this.productsCount = o[0].count;
      this.myproducts = o;
    });
  }

  _bow() {
    if (this.ShowIt == 'true') {
      this._sow();
      setTimeout( () => {this._bow();},700);
      return;
    }
    if ( this.ShowItA=='true' ) {
      this.ShowItA = 'truetrue';
      setTimeout( () => {
        this._bow();
      }, 700);
    } else if (this.ShowItA=='truetrue'){
      this.ShowItA = 'false';
    } else {
      this.ShowItA = 'true';
    }
  }

  _sow() {
    if (this.ShowItA == 'true') {
      this._bow();
      setTimeout( () => {this._sow();},700);
      return;
    }
    if ( this.ShowIt=='true' ) {
      this.ShowIt = 'truetrue';
      setTimeout( () => {
        this._sow();
      }, 700);
    } else if (this.ShowIt=='truetrue'){
      this.ShowIt = 'false';
    } else {
      this.ShowIt = 'true';
    }
  }


  getReviews() {
    this.reviews = false;
  }

  getAccount() {
  	this.user.authorize().subscribe( (acc) => {
    	if( acc && acc[0] ) {
      	this.accounts = acc;
    		this.accountID = acc[0].accountid;
        this.username = acc[0].accountid;
      }
	  });
  }

  activateOrderCard() {
    this.shipActive = true;
    setTimeout( () => {
      this.shipActive = false;
    }, 300);
  }

  changePage(p) {
    this.page.emit(p);
  }

  activateDriverCard() {
    this.pickupActive = true;
    setTimeout( () => {
      this.pickupActive = false;
    }, 300);
  }


  ngOnInit() {
    this.rsz();
    setTimeout( () => { this.rsz(); }, 500);
    this.getRandomData()
  	this.getProducts();
  	this.getAccount();
    this.getReviews();
    this.getTotals();
  }

}
