import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

export const active = trigger('active', [
    state('true', style({
        'background': 'green',
        'top': '1px',
        'left': '1px'
    })),
    state('false', style({
        'background': '#002f04',
        'top': '0px',
        'left': '0px'
    })),
    transition('*=>*', animate('150ms'))
]);
