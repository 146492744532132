import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { Account, Friendly } from '../../models/account';
import { Product } from '../../models/product';
import { Message } from '../../models/message';
import { online } from './single.animations';
@Component({
  selector: 'single-account',
  templateUrl: './single.component.pug',
  animations:[ online ],
  styleUrls: ['./single.component.styl']
})
export class SingleAccountComponent implements OnInit {
  id: string;
  updatingHeader: boolean;
  defaultbackground: string;
  myaccountid: number;
  accountID: number;
  Friend: boolean;
  friend: boolean;
  friendly: string;
  myaccount: Account;
  accounts: Account;
	products: Product;
  messages: Message;
  friend_model: Friendly;
  showDesigner: boolean;
  designSaved: boolean;
  ediable: boolean;
  adding = false; 
  fullBGColor: any;
  showFeat = false;
  movingBG = false;
  messageCount = 0;
  showUserHeader = false;
  friendstatus = false;
  showingEmbdder = false;
  showingSharePanel = false;
  showingIframePreview = false;
  fullBG: any;
  topBGPos: any;
  topBG: any;
  pageBG: any;
  arrayColors = [];
  loggedIn = false;
  Message: boolean;
  messageTimer: any;
  embedCode = '';
  newmessage = new NewMessage('');
  bg = new Background(false,false,'white','white','');
  pro = new ProductCard();
  _f = false;
  ti = true;
  @ViewChild('msgfield') msg: ElementRef;
  @ViewChild('scroll') private scrollToBottom: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.ti = (window.scrollY>150)?false:true;
  }
  constructor( 
    private title: Title,
    private route:  Router, 
    private user: UserService,
    private sanitizer: DomSanitizer,
    private marketplace: MarketplaceService,
    private router: ActivatedRoute ) { 
      this.id = this.router.snapshot.paramMap.get("id");
  }

  _msg() {
    this.newmessage.user = this.accountID;
    this.Message=(this.Message)?false:true;
    setTimeout( () => { 
      this.selectMessage();
      this.ScrollChatWindow();
    }, 250 );
  }

  _tgl_friend_page() {
    this.Message=false;
    if(this.Friend) {
      this.Friend=false;
      this._f = false;
    } else {
      this.Friend=true;
      setTimeout(()=>{ this._f = true; },826);
    }
  }

  updateHeader() {
    this.updatingHeader = (this.updatingHeader)?false:true;
  }

  setRounded() {

  }

  addFriend() {
    this.adding = true;
    this.user.addFriend( this.accountID ).subscribe( (m) => {
      this.adding = false;
      this.friendly = m[0].status;
    });
  }

  befriend() {
    // this.friend = (this.friend)?false:true;
    // this.friendly = (this.friendly)?false:true;
    // setTimeout( () => {
    //   this.Friend = true;
    //   this.friendly = false;
    // },1670);
  }

  saveDesign() {
    this.marketplace.save_page_design(this.bg).subscribe((tlc) => {
      this.designSaved = true;
      setTimeout( () => {
        this.designSaved = false;
        this.showDesigner = false;
      },1750);
    });
  }

  moveBG(e?) {
    this.movingBG = true;
    if ( e ) {
      if ( this.movingBG ) {
        this.bg.off = 100;
        this.setBGOffset();
      }
    }
  }

  removeBG(a='top') {
    this.bg[a]='false';
    this.saveDesign();
  }

  newBGImg(a='top',e?) {
    this.bg[a]='url("'+e+'") ';
    this.setPageBG();
    if ( a == 'top' ) this.saveDesign();
  }

  newLogoImg(a='top',e?) {
    this.bg[a]='url("'+e+'") ';
    this.setHeadingLogo();
    if ( a == 'top' ) this.saveDesign();
  }

  bgChange(e?) {
    if (e) this.fullBGColor = e;
    var b = this.fullBGColor;
    if ( !b ) b = 'linear-gradient( white, #f0f0f0 )';
    this.bg.full = b; // this.sanitizer.bypassSecurityTrustStyle(
    this.fullBG = b; // this.sanitizer.bypassSecurityTrustStyle(
    this.setPageBG();
  }

  setPageBG() {
    if (this.bg && this.bg.top) this.topBG = this.sanitizer.bypassSecurityTrustStyle(this.bg.top); 
    if (this.bg && this.bg.full) this.fullBG = this.sanitizer.bypassSecurityTrustStyle(this.bg.full);
  }

  setHeadingLogo() {
    // if (this.bg && this.bg.top) this.topBG = this.sanitizer.bypassSecurityTrustStyle(this.bg.top); 
  }

  setBGOffset() {
    this.topBGPos = this.sanitizer.bypassSecurityTrustStyle('0px -'+this.bg.off+'px');
  }

  hasFeaturedImage(a) { 
    this.marketplace.featproduct(a).subscribe( (p) => {
      this.showFeat = p;
    })
  }

  ScrollChatWindow() {
    try {
      this.scrollToBottom.nativeElement.scrollTop = this.scrollToBottom.nativeElement.scrollHeight;
    } catch(err) { }
  }

  getMessages() {
    this.user.getMessages( this.accountID ).subscribe( (lite) => {
      if (this.messages != lite)  {
        this.messages = lite;
        this.messageCount = lite[0].count;
      }
    });
  }

  sendMessage() {
    if ( this.newmessage.nomsg && this.newmessage.nomsg!='' ) {
      this.user.sendMessage( this.newmessage ).subscribe( (wimp) => {
        this.newmessage.nomsg = '';
        this.messages = wimp;
        setTimeout( () => { 
          this.ScrollChatWindow();
        }, 50 );
      });
    }
  }

  unfriend() {
    this.adding = true;
    this.user.unFriend( this.accountID ).subscribe( (m) => {
      this.adding = false;
      this.friendly = m[0].status;
    });
  }

  send(e) {
    if ( e.keyCode==13 ) this.sendMessage();
  }

  selectMessage() {
    if (this.msg) this.msg.nativeElement.focus();
  }

  message_timer() {
    this.messageTimer = setInterval( () => {
      this.getMessages();
    }, 500);
  }

  _t(t) { this.title.setTitle(t); }

  myAccount() { this.route.navigate(['/my-account']); }
  addProduct() { this.route.navigate(['/create']); }
  
  ngOnInit() {
    this.bg.rep = 'white';
    // GET USER ACCOUNT DETAILS FOR PAGE
    this.user.account(this.id).subscribe( (acc) => {
      if (acc[0]) {
        this.accounts = acc;
        this.accountID = acc[0].accountid;
        this.friend_model = acc[0].friend;
        this.friendly = acc[0].friend[0].status;
        if ( acc[0].accountid == '' ) this.route.navigate(['/']);
        if ( acc[0].username ) this._t(acc[0].username+' | '+'Tree St.');
        if ( acc[0].username == 'Guest' ) this.route.navigate(['/my-account']);
        // Page design
        if (acc[0].design[0]) {      
          if ( acc[0].design[0].top ) this.bg.top = this.sanitizer.bypassSecurityTrustStyle(acc[0].design[0].top);
          if ( acc[0].design[0].full ) {
            this.bg.full = this.sanitizer.bypassSecurityTrustStyle('url('+acc[0].design[0].full+')');
            this.fullBG =  this.bg.full;}
          if ( acc[0].design[0].pos ) this.bg.pos = this.sanitizer.bypassSecurityTrustStyle(acc[0].design[0].pos);
          if ( acc[0].design[0].rep ) this.bg.rep = this.sanitizer.bypassSecurityTrustStyle(acc[0].design[0].rep);
        }
        // Message Timer
        // this.message_timer();
        // this.getMessages();
        // Current User's details
        this.user.authorize().subscribe( (a) => {
          this.myaccount = a;
          if( a[0].username ) this.hasFeaturedImage(a[0].username);
          if (a[0].accountid && this.accountID) {
            this.myaccountid = a[0].accountid;
            this.loggedIn = true;
            this.ediable = ( a[0].accountid == this.accountID ) ? true : false;
          }
        });
        this.embedCode = '<iframe src="https://treest.ca/iframe/'+this.accountID+'" border="0" style="border:0;width:100%;height:100%;"></iframe>';
        this.setPageBG();
      } else {
        this.accountID = 0;
        // this.router.navigate(['404']);
      }
    });
  }
  _sanitize(url) {
    this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnDestroy() { 
    this._t('Tree Street'); 
    clearInterval(this.messageTimer);
  }
}
export class NewMessage {
   constructor(
    public nomsg: any,
    public user?: any
   ) {}
}
export class ProductCard {
   constructor(
    public round?: number 
   ) {}
}
export class Background {
   constructor(
    public top?: any,
    public full?: any,
    public pos?: any,
    public rep?: any,
    public off?: any,
   ) {}
}