import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {
  transform(value: any, args?: any): any {
  	if ( args && args!=null && args!='') {

		let values = [];
		for (var i = value.length - 1; i >= 0; i--) {
			if ( value[i].categoryName.toLowerCase() == args.toLowerCase()) values.push( value[i] );
		}
		return values;
	} else {
		return value;
	}
  }
}
