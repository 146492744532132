import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'slide-button',
  templateUrl: './slidebutton.component.pug',
  styleUrls: ['./slidebutton.component.styl']
})
export class SlideButtonComponent implements OnInit {

	constructor() { }

	@Output('click') click = new EventEmitter();
	@Input('content') content: string;
	@Input('classes') classes: string;
	@Input('show_inner') in: boolean=true;

	ex: number;
	sa: boolean

  	aoc(e,b=-1,o) {
  		
		if ( b==1 )    this.sa = true;
		if ( this.sa ) this.ex = e.offsetX;

		if ( b==2 )  {

			this.sa = false;
			this.ex = e.srcElement.clientWidth;
			this.click.emit('click');

			if ( o ) {
				setTimeout( () => {
					this.sa = false;
					this[o] = true;
					this.ex = 0.0;
				}, 350) // 
			}
		}

		if ( b ==3 ) {
			this.sa = false;
			this.ex = 0.0;
		}
	}



	ngOnInit() {


	}

}
