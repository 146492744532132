import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';
import { Balances } from '../../../models/balances';
import { Currency } from '../../../models/currency';
import { Exchange } from '../../../models/exchange';

@Component({
  selector: 'wallet-convert',
  templateUrl: './converter.component.pug',
  styleUrls: ['./converter.component.styl']
})
export class ConverterComponent implements OnInit {
	
	@Output('currency') currencyEvent = new EventEmitter();
	@Output('balance') currentBalance = new EventEmitter();
	@Input('showTransactions') showTransactions = true;

	w: any;
	cc = 0;
	wwa = 0;
	_seTrr = 0;
	wwb = 1;
	currency: any;
	wallets: Balances;
	currencies: Currency;
	exchange: Exchange;
	accountGender = '';
	small = false;
	loading = true;
	showErr = false;
	showEmpty = false;
	trade = new Trade(0,0);

	constructor(
  		private market: MarketplaceService,
    	private user: UserService
  	) { }

	update_trade() {
		var currency_value = 0;
		var final_currency_value = 0;
		for (var i = this.cc - 1; i >= 0; i--) {
			if (this.currencies[i].symbol == this.trade.currency) {
				currency_value = this.currencies[i].value;
			}
			if (this.currencies[i].symbol == this.trade.final_currency) {
				final_currency_value = this.currencies[i].value;
			}
		}
		this.trade.final_amount = this.trade.amount / currency_value * final_currency_value;
		this.trade.final_amount = this.trade.final_amount.toFixed(2);
	}

	convert() {
		this.user.convertCurrency(
			this.trade.amount, 
			this.trade.currency, 
			this.trade.final_currency, 
			this.trade.nonce
		).subscribe( (ex) => {
			this.exchange = ex;
			this.getNonce();
		})
	}

	reset() {
		this.trade.amount = 0;
		this.trade.final_amount = 0;
	}

	swap() {
		var a = this.trade.currency;
		var b = this.trade.amount;
		this.trade.currency=this.trade.final_currency;
		this.trade.amount=this.trade.final_amount;
		this.trade.final_currency = a;
		this.trade.final_amount = b;
		this.currency=this.trade.currency;
	}

	get_currency() {
		this.market.getCurrencies().subscribe( (c) => {
			this.currencies = c;
			this.cc = c[0].count;
			for (var i = this.cc - 1; i >= 0; i--) {
				if( !this.trade.final_currency && (this.currency != this.currencies[i].symbol) ) {
					this.trade.final_currency=this.currencies[i].symbol;
				}
				if( this.currencies[i].default ) {
					this.wwa=i;
				}
				if( this.currencies[i].symbol == c ) {
					this.currentBalance.emit(this.currencies[i].balance);
				}
			}
		});
	}

	iscoin(c?) {
	    if ( c.symbol == 'CANN' ) {
	      return false;
	    } else if ( c.symbol == 'POT' ) {
	      return false;
	    } else if ( c.symbol == 'BTC' ) {
	      return false;
	    } else if ( c.symbol == 'DOPE' ) {
	      return false;
	    }  else if ( c.symbol == 'DASH' ) {
	      return false;
	    } else if ( c.symbol == 'ETH' ) {
	      return false;
	    } else if ( c.symbol == 'ETC' ) {
	      return false;
	    } else {
	      return true; 
	    }
	}

	islong(c?) {
	    if ( c.symbol == 'CANN' ) {
	      return false;
	    } else if ( c.symbol == 'ETH' ) {
	      return true;
	    } else if ( c.symbol == 'ETC' ) {
	      return true;
	    } else if ( c.symbol == 'DASH' ) {
	      return true;
	    } else if ( c.symbol == 'BTC' ) {
	      return true;
	    } else {
	      return false; 
	    }
	}

	auto_wallet() {
	    this.w = setInterval( () => {
	      this.get_wallets();
	    }, 6567);
	}

	get_wallets() {
	    this.user.getBalances().subscribe( (d) => {
	      this.wallets = d;
	      // if(!this._seTrr) this.showEmpty= (!this.showEmpty) ? !(d[0].anybalances) : this.showEmpty;
	      this.showErr = d[0].balance_err;
	      this.loading = false;
	    });
	}

	get_row_classes(c) {
		var a = 'currency_row';
		a = (this.currency==c.symbol || (!this.currency&&c.default) ) ? ((this.iscoin(c)) ? 'active a': 'active a') : '';
		if ( c.balance < 0 ) a = a + ' under';
		if ( c.balance == 0 ) a = a + ' nonein';
		if ( c.balance > 0 ) a = a + ' somethin';
		if ( this.accountGender.toLowerCase() == 'f' ) a = a + ' girly';
		if ( !this.showEmpty  && !(this.currency==c.symbol || (!this.currency&&c.default) )) a = (c.balance>0)?a:a+' hide';
		return a;
	}

	get_classes(c) {
		var a = (this.currency==c.symbol || (!this.currency&&c.default) ) ? ((this.iscoin(c)) ? 'active a': 'active a') : '';
		if ( c.balance < 0 ) a = a + ' under';
		if ( c.balance == 0 ) a = a + ' nonein';
		if ( c.balance > 0 ) a = a + ' somethin';
		if ( this.accountGender.toLowerCase() == 'f' ) a = a + ' girly';
		if ( !this.showEmpty && !(this.currency==c.symbol || (!this.currency&&c.default) ) ) a = (c.balance>0)?a:a+' hide';
		return a;
	}

	set_currency(c,i=-1) {
    	if(i>-1) this.wwa = i;
    	this.currency = c.symbol;
    	this.trade.currency = c.symbol;
    	this.currencyEvent.emit(c.symbol);
    	this.currentBalance.emit(c.balance);
    	this.user.set_currency(c.symbol).subscribe((c) => {
		//ok
		});
	}


	getNonce() {
		this.user.getNonce().subscribe( (n) => {
			this.trade.nonce = n[0].code;
		});
	}

	ngOnInit() {
	    this.user.authorize().subscribe( (a) => {
	      if (a && a[0]) {
	      	this.getNonce();
	        this.get_currency();
	        this.accountGender = a[0].sex;
	        this.user.get_currency_empty().subscribe((c) => {
	          this.auto_wallet();
	          this.user.get_currency().subscribe((c) => {
	            this.currency = c;
	            this.trade.currency = c;
	          });
	          this.showEmpty=c;
	        });
	      }
	    });
	}
	
	ngOnDestroy() {
		clearInterval(this.w);
	}
}

export class Trade {
   constructor(
	public amount?: any,
	public final_amount?: any,
	public currency?: string,
	public final_currency?: string,
	public nonce?: any
   ) {}
}
