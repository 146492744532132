import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Friend } from '../../models/friend';

@Component({
  selector: 'friends-menu',
  templateUrl: './friends.menu.component.pug',
  styleUrls: ['./friends.menu.component.styl']
})
export class FriendsMenuComponent implements OnInit {

    constructor(
        private user: UserService
    ) { }

	username = '%username%';
	friends: Friend;

    getFriends() {
        this.user.getFriends().subscribe( (f) => {
            this.friends = f;
        });
    }

    favouriteUser( u ) {
        this.user.favouriteUser( u.username ).subscribe( (u) => {
            this.getFriends();
        });
    }

    ngOnInit() {
        this.getFriends();
    }

}
