/**
* TreeSt.ca master module
* L. Hogan - liam@hogan.re
* Copyright 2019-2020
**/
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpModule } from '@angular/http';
// import { TruncateModule } from '@yellowspot/ng-truncate';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollDispatchModule, VIEWPORT_RULER_PROVIDER, ScrollDispatcher } from '@angular/cdk/scrolling';
import { RouterModule, Routes } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
// import { DragulaModule } from 'ng2-dragula';
import { AdsenseModule } from 'ng2-adsense';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxEditorModule } from 'ngx-editor';
import { QRCodeModule } from 'angularx-qrcode';
import { RecaptchaModule } from 'ng-recaptcha';
import { ClipboardModule } from 'ngx-clipboard';
import { LoadingBarModule } from 'id-loading-bar';
import { CookieService } from 'ngx-cookie-service';
import { ColorPickerModule } from 'ngx-color-picker';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
// import { NgxLinkPreviewModule } from 'ngx-link-preview';
// import { Module as StripeModule } from 'stripe-angular';
// import { CountdownTimerModule } from 'ngx-countdown-timer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularDraggableModule } from 'angular2-draggable';
// import { NgxAnalyticsUirouterModule } from 'ngx-analytics/uiroutermodule';
import { SocketIoModule, SocketIoConfig, Socket} from 'ng-socket-io';
import { SelectDropDownModule } from 'ngx-select-dropdown';
/**
* Side Module
**/
import { AppComponent } from './app.component';
import { AppInterceptor } from './app.interceptors';

import { LocationsComponent } from './locations/locations.component';
import { CartComponent } from './cart/app.cart.sidebar.component';
import { SidebarCartBoxComponent } from './cart/app.cart.box.component';
import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from "./app.favicons";
import { SocialComponent } from './social/social.component';
import { PostComponent } from './social/post/post.component';
import { PostsComponent } from './social/posts/posts.component';
import { FriendsPageComponent } from './social/friends.component';
import { FriendComponent } from './social/friend/friend.component';
import { NewsFeedComponent } from './social/news.feed/news.feed.component';
import { FriendsListComponent } from './social/friends.list/friends.list.component';
import { WelcomeComponent } from './static/welcome/welcome.component';
import { HomePageComponent } from './static/homepage/homepage.component';
import { StaticComponent } from './static/static.component';
import { TermsComponent } from './static/terms/terms.component';
import { NotFoundComponent } from './static/notfound/notfound.component';
import { StrainsComponent  } from './static/strains/top.strains.component';
import { SignUpComponent } from './static/signup/signup.component';
import { UnavailableContent } from './static/unavailable/unavailable.component';
import { SidebarComponent } from './static/sidebar/sidebar.component';
import { MyAccountComponent } from './account/my.account/my.account.component';
import { AccountUpdatedEmail } from './account/account/updated/updated.component';
import { UsersProductsComponent } from './account/products/users-products.component';
import { FavouritesComponent } from './account/favourites/favourites.component';
import { AccountComponent } from './account/account.component';
import { LogoutComponent } from './account/logout/logout.component';
import { AccountEarningsComponent } from './account/earnings/account.earnings.component';
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { FriendsComponent } from './account/friends/friends.component';
import { SoldComponent } from './account/sold/sold.component';
import { FriendsSelectorComponent } from './account/friends-selector/friends.selector.component';
import { FriendsMenuComponent } from './account/friends-menu/friends.menu.component';
import { UsersComponent } from './account/users/users.component';
import { ReviewsComponent } from './account/reviews/reviews.component';
import { SignupComponent } from './account/signup/signup.component';
import { LoginComponent } from './static/login/login.component';
import { AccountLoginComponent } from './account/login/login.component';
import { RecoverComponent } from './account/recover/recover.component';
import { WalletComponent } from './account/wallet/wallet.component';
import { ConverterComponent } from './account/wallet/converter/converter.component';
import { ChangePassComponent } from './account/recover/change-pass/change.pass.component';
import { WalletAccountsComponent } from './account/wallet/accounts/accounts.component';
import { BalancesComponent } from './account/wallet/balances/balances.component';
import { InvoicesComponent } from './account/wallet/invoices/invoices.component';
import { FeaturesComponent } from './products/features/features.component';
import { ReportsComponent } from './account/wallet/reports/reports.component';
import { TransactionsComponent } from './account/wallet/transactions/transactions.component';
import { DepositComponent } from './account/wallet/deposit/deposit.component';
import { WithdrawalComponent } from './account/wallet/withdrawal/withdrawal.component';
import { ViewTransactionIDComponent } from './account/wallet/view.transaction/view.transaction.component';
import { ActivateComponent } from './account/activate/activate.component';
import { SettingsComponent } from './account/settings/settings.component';
import { FavoritesComponent } from './account/favorites/favorites.component';
import { UsersPurchasesComponent } from './account/purchases/users-purchases.component';
import { SingleAccountComponent } from './account/account/single.component';
import { OrdersComponent } from './account/orders/orders.component';
import { ChatComponent } from './account/chat/chat.component';
import { SellingComponent } from './account/selling/selling.component';
import { MyDriverComponent } from './account/driver/my.driver.component';
import { DriverReviewsComponent } from './account/driver/driver.reviews/driver.reviews.component';
import { VendorOnboardingComponent } from './products/add/vendor.onboarding/vendor.onboarding.component';
import { VendorApplicationComponent } from './account/vendor-application/vendor-application-component';
import { DriverDeliveriesComponent } from './account/driver/driver.deliveries/driver.deliveries.component';
import { DriverEarningsComponent } from './account/driver/driver.earnings/driver.earnings.component';
import { SupportComponent } from './account/support/support.component';
import { SupportMessageComponent } from './account/support/support.message/support.message.component';
import { NewSupportComponent } from './account/support/new.support/new.support.component';
import { Spinner } from './static/spinner/spinner.effect';
import { SlideButtonComponent } from './slidebutton/slidebutton.component';
import { SmallButtonComponent } from './smallbutton/smallbutton.component';
import { ProductsComponent } from './products/products.component';
import { ProductsAdComponent } from './products/products.ad';
import { AddProductComponent } from './products/add/add.component';
import { ProductComponent } from './products/single/single.component';
import { SmallFeaturesComponent } from './products/features-small/small.features.component';
import { SearchComponent } from './products/search/search.component';
import { CarouselComponent } from './products/carousel/carousel.component';
import { Triangles } from './checkout/triangles';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutBankComponent } from './checkout/checkout.bank/checkout.bank.component';
import { CheckoutProductsComponent } from './checkout/checkout.products/checkout.products.component';
import { CheckoutPaymentComponent } from './checkout/checkout.payment/checkout.payment.component';
import { CheckoutReviewComponent } from './checkout/checkout.review/checkout.review.component';
import { CheckoutShippingComponent } from './checkout/checkout.shipping/checkout.shipping.component';
import { CheckoutVerifyComponent } from './checkout/checkout.verify/checkout.verify.component';
import { CheckoutCompleteComponent } from './checkout/checkout.complete/checkout.complete';
import { AdvertiseComponent } from './advertise/app.advertise.component';
import { HeaderLoginComponent } from './header/header-login/header-login.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { PopUpComponent } from './header/product-popup/product.popup.component';
import { SidebarComponentComponent } from './sidebar/sidebar.component';
import { IFrameComponent } from './external/iframe/iframe.component';
import { IFrameExampleComponent } from './external/example/example.iframe.component';
import { IFrameProductComponent } from './external/product/iframe.product.component';
import { IFrameAccountComponent } from './external/account/iframe.account.component';
/**
* Services / Pipes
**/
import { MarketplaceService } from './services/marketplace.service';
import { NotificationService } from './services/notification.service';
import { DriverService } from './services/driver.service';
import { SupportService } from './services/support.service';
import { UserService } from './services/user.service';
import { StripHtmlPipe } from './pipes/striphtml.pipe';
import { LocalesPipe } from './pipes/locales.pipe';
import { StrainPipe } from './pipes/strain.pipe';
import { DeliveryPipe } from './pipes/delivery.pipe';
import { PricingPipe } from './pipes/pricing.pipe';
import { PriceminmaxPipe } from './pipes/priceminmax.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { TypePipe } from './pipes/type.pipe';
import { FeaturedPipe } from './pipes/featured.pipe';
import { FirstWordPipe } from './pipes/firstword.pipe';
import { SelectedPipe } from './pipes/selected.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { InStockPipe } from './pipes/instock.pipe';
import { CoinPipe } from './pipes/coin.pipe';
import { LangPipe } from './pipes/lang.pipe';
import { ThcPipe } from './pipes/thc.pipe';
import { CbdPipe } from './pipes/cbd.pipe';
/**
* Vendor Module
**/
import { RulesComponent } from './static/vendor/rules/rules.component';
import { BecomingAVendorComponent } from './static/vendor/becoming-a-vendor/becoming-a-vendor.component';
import { RequestComponent } from './static/vendor/request/request.component';
import { VendorComponent } from './static/vendor/vendor.component';
import { VendorAppComponent } from './vendor/vendor.app.component';
/**
* System Module
**/
import { Auth } from './models/auth';
import { AdminModule } from './admin/admin.module';
import { LogoComponent } from './header/app.logo.component';
import { LoaderComponent } from './header/loader/loader.component';
import { FWShowComponent } from './static/welcome/fw-show/fw-show.component';
import { FunnelComponent } from './static/funnel/funnel.component';
import { ScoreboardComponent } from './static/funnel/scoreboard/scoreboard.component';
import { SplashComponent } from './static/funnel/splash/splash.component';
import { KwComponent } from './static/kw/kw.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { AdComponent } from './static/ad/ad.component';
import { ReceiptComponent } from './static/receipt/receipt.component';
import { ItemsComponent } from './items/items.component';
import { ItemSingleComponent } from './items/single/item.single.component';
import { WarningsComponent } from './static/warnings/warnings.component';
import { AppBarComponent } from './static/appbar/appbar.component';
import { CategoryPipe } from './pipes/category.pipe';
import { SortByPipe } from './pipes/sort.pipe';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction' 
import { ReportOrderComponent } from './order/report/report.order.component';
/**
* Images Module
**/
import { ImagesModule } from './images/images.module';
import { BrowserModule, Title  } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const config: SocketIoConfig = { 
    url: environment.socket, 
    options: { 
      rejectUnauthorized: false, 
      secure: environment.secure_socket
    }
};
@NgModule({
  declarations: [
		AppComponent,
		HomePageComponent,
		CartComponent,
		LocationsComponent,
		AccountComponent,
		LogoutComponent,
		SignupComponent,
		SignUpComponent,
		LoginComponent,
		RecoverComponent,
		StaticComponent,
		NotFoundComponent,
		TermsComponent,
		ProductsComponent,
		ProductsAdComponent,
		FavoritesComponent,
		AddProductComponent,
		ProductComponent,
		SearchComponent,
		SocialComponent,
		WalletComponent,
		ConverterComponent,
		CheckoutBankComponent,
		AdvertiseComponent,
		WalletAccountsComponent,
		AccountLoginComponent,
		ChangePassComponent,
		ReportOrderComponent,
		SmallButtonComponent,
		InvoicesComponent,
		ReportsComponent,
		TransactionsComponent,
		FriendsSelectorComponent,
		SidebarComponentComponent,
		WelcomeComponent,
		HeaderLoginComponent,
		DepositComponent,
		WithdrawalComponent,
		ActivateComponent,
		UserMenuComponent,
		PopUpComponent,
		SellingComponent,
		ReviewsComponent,
		UsersComponent,
		LogoComponent,
		StripHtmlPipe,
		LocalesPipe,
		StrainPipe,
		ChatComponent,
		Spinner,
		Triangles,
		TimeAgoPipe,
		InStockPipe,
		CheckoutComponent,
		MyDriverComponent,
		DashboardComponent,
		DriverReviewsComponent,
		DriverDeliveriesComponent,
		DriverEarningsComponent,
		CheckoutProductsComponent,
		CheckoutPaymentComponent,
		CheckoutReviewComponent,
		CheckoutShippingComponent,
		CheckoutCompleteComponent,
		CheckoutVerifyComponent,
		SingleAccountComponent,
		SupportMessageComponent,
		SupportComponent,
		NewSupportComponent,
		SettingsComponent,
		MyAccountComponent,
		AccountUpdatedEmail,
		FavouritesComponent,
		BalancesComponent,
		OrdersComponent,
		SidebarComponent,
		BecomingAVendorComponent,
		UsersPurchasesComponent,
		SmallFeaturesComponent,
		SidebarCartBoxComponent,
		VendorOnboardingComponent,
		VendorApplicationComponent,
		ViewTransactionIDComponent,
		FeaturesComponent,
		RequestComponent,
		VendorComponent,
		RulesComponent,
		CarouselComponent,
		UnavailableContent,
		FWShowComponent,
		LoaderComponent,
		StrainsComponent,
		ScoreboardComponent,
		SplashComponent,
		FunnelComponent,
		KwComponent,
		SplashComponent,
		SoldComponent,
		FriendsComponent,
		VendorAppComponent,
		UsersProductsComponent,
		AccountEarningsComponent,
		FriendsMenuComponent,
		PostComponent,
		PostsComponent,
		FriendsPageComponent,
		FriendComponent,
		NewsFeedComponent,
		FriendsListComponent,
		BirthdayComponent,
		AdComponent,
		ReceiptComponent,
		SlideButtonComponent,
		WarningsComponent,
		AppBarComponent,
		ItemsComponent,
		ItemSingleComponent,
		IFrameComponent,
		IFrameProductComponent,
		IFrameAccountComponent,
		IFrameExampleComponent,
		DeliveryPipe,
		PricingPipe,
		PriceminmaxPipe,
		FirstWordPipe,
		FeaturedPipe,
		SelectedPipe,
		SearchPipe,
		TypePipe,
		SortByPipe,
		CoinPipe,
		ThcPipe,
		CbdPipe,
		LangPipe,
		CategoryPipe
	],
	imports: [
		AdminModule,
		HttpModule,
		FormsModule,
		QRCodeModule,
		HttpClientModule,
		// NgxChartsModule,
		ClipboardModule,
		// TruncateModule,
		BrowserModule,
		ImagesModule,
		AgmCoreModule.forRoot({
			apiKey: environment.gmapsAPI,
			libraries: ['places'] 
		}),
		AgmDirectionModule,
		UiSwitchModule.forRoot({
			size: 'medium',
			color: '#7fc91d',
			switchColor: '#80FFA2',
			defaultBgColor: '#2ab4e8',
			defaultBoColor : '#e9e9e9'
		}),
		PickerModule,
		NgxEditorModule,
		NgxPayPalModule,
		LoadingBarModule,
		ColorPickerModule,
		ReactiveFormsModule,
		// NgxLinkPreviewModule,
		// CountdownTimerModule,
		InfiniteScrollModule,
		AngularDraggableModule,
		BrowserAnimationsModule,
		SelectDropDownModule,
		ScrollDispatchModule,
		SocketIoModule.forRoot(config), 
		// NgxAnalyticsUirouterModule,
		// StripeModule.forRoot(),
		AdsenseModule.forRoot({
			adClient: environment.adsense_adClient,
			adSlot: environment.adsense_adSlot
		}),
		RecaptchaModule.forRoot(),
		// DragulaModule.forRoot(),
		RouterModule.forRoot(appRoutes, { useHash: (environment.mobile) })
	],
	providers: [
		MarketplaceService,
		UserService,
		DriverService,
		Auth,
		Title,
		CookieService,
		HttpClientModule,
		ScrollDispatcher,
		SupportService,
		NotificationService,
		VIEWPORT_RULER_PROVIDER, 
		{	provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
		{	provide: Favicons, useClass: BrowserFavicons },
		{	provide: BROWSER_FAVICONS_CONFIG,
			useValue: {
				icons: {
					"green": {
						type: "image/png",
						href: "/assets/icons/treest/green/favicon-196x196.png",
						isDefault: false
					},
					"gray": {
						type: "image/png",
						href: "/assets/icons/treest/gray/favicon-196x196.png",
						isDefault: true
					}
				}, cacheBusting: true
			}
		}
	],
	schemas: [ 
		CUSTOM_ELEMENTS_SCHEMA 
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
