import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';

// Countries
export const mapOpened = trigger('mapOpened', [
    state('true', style({
        'height': '420px'
    })),
    state('false', style({
        'height': '160px'
    })),
    transition('*=>*', animate('220ms')),
])
