import { MapsAPILoader } from '@agm/core';
import { DayMode, NiteMode } from '../../map.styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Component, NgZone, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { addressPallet, active, activebox, activebg, fade } from '../checkout.animations';
import { UserAddress, Country, City, Provence, Address, Locale } from '../../models/address';
import { environment } from '../../../environments/environment';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'checkout-shipping',
  templateUrl: './checkout.shipping.component.pug',
  styleUrls: ['./checkout.shipping.component.styl'],
  animations: [ addressPallet, active, activebox, activebg ]
})
export class CheckoutShippingComponent implements OnInit {
	public mapStyle;
	@Input('newAddress') addnewAddress: boolean;
	@Input('justAddress') justAddress: boolean;
	@Input('pgp') collecting_pgp: boolean;
	@Input('pgp_vendor_id') pgp_vendor=0;
	@Input('pgp_vendor_key') pgp_vendor_key="";
	@Input('pgp_vendor_username') pgp_vendor_username="Liam";
	@Input('showSelectAddress') showSelectAddress = false;
	@Input('showSelector') showSelector = true;
	@Input('newAddress') newAddress = true;
	@Input('addressType') addressType = '';
	@Input('title') title = true;
	@Input('_wd') _wd = false;
	@Input('selectPrev') selectPrev = false;
	@Input('enableAddressInput') enableAddressInput = true;
	@Output('orderAddress') orderAddress = new EventEmitter();
	@Output('giftRecipient') giftRecipient = new EventEmitter();
	@Output('newAddress') newAddresses = new EventEmitter();
	@ViewChild('searchElement') public searchElement: ElementRef;
	autocompleteRunning = false;
	shipping = null;
	loaded = false;
	loggedIn = -1;
	sAA = false;
	sA = false;
	sE = false;
	isVendor = false;
	showMap = false;
	addr = '';
	ed: boolean;
	gift: string;
	savedAddresses = -1;
	selectedAddress: any;
	addressError = false;
	addresses: UserAddress;
	pgp_address = new PGP();
	address = new NewAddress();
	showRemove = [];
	countries: Country;
	provinces: Provence;
	cities: City;
	userlocale: Locale;
	darkMarket = environment.darkmarket;
	position = { lat: 43.667079, lng: -79.394187 };

	constructor(
		private marketplace: MarketplaceService,
		private user: UserService,
		private maps: MapsAPILoader,
		private ngZone: NgZone,
	) { }

	countryChanged(e) {
		this.address.countrycode = e.target.value;
		this.getProvinces(true);
	}

	removeAddress( a ) {
		this.marketplace.removeAddress(a.id).subscribe( (c) => {
			this.getAddresses()
		});
	}

	getCountries(b?) {
		this.marketplace.getCountries().subscribe( (c) => {
			this.countries = c;
			if(!this.address.countrycode)this.address.countrycode=c[0].code;
			if (b) this.getProvinces(b);
		});
	}

	getProvinces(a?) {
		this.marketplace.getProvinces(null,this.address.countrycode).subscribe( (p) => {
			this.provinces = p;
			this.address.province = p[0].code;
			if ( !this.address.city ) this.getCity();
			if ( a ) this.getCity();
		});
	}

	getCity(ca?) {
		this.marketplace.getCities(this.address.province, this.address.countrycode).subscribe( (p) => {
			this.cities = p;
			if (ca&&ca!=1&&ca!=true) ca(p);
		});
	}

	_clrph() {
		this.address.phone = null;
	}

	checkPhone(tel, e) {
		if ( e.key=="Escape" ) {
			this.address.phone = null;
			return;
		} else {
			this.address.phone = this.phone(tel);
		}
	}

	phone(tel) {
		var num = tel.replace(/\D/g,''); 
		if(num.length<=9) {
			return '(' + num.substring(0,3) + ') ' + num.substring(3,6) + '-' + num.substring(6,10); 
		} else { 
			return '(' + num.substring(0,3) + ') ' + num.substring(3,6) + '-' + num.substring(6,num.length);
		}
	}

	postal_chk() { 
		if( this.address.postal.match(/^[0-9]+$/) == null ) {
			this.address.postal = String(this.address.postal).length > 7 ? this.address.postal.substr(0,7) : this.address.postal;
			this.address.postal = this.address.postal.replace(/\W/gi, '').replace(/(.{3})/g, '$1 ');
			this.address.postal = this.address.postal.trim();
			this.address.postal = this.address.postal.toUpperCase();
		} else {
			this.address.postal = String(this.address.postal).length > 7 ? this.address.postal.substr(0,7) : this.address.postal;
		}
	}

	saveAddress() {
		if ( !this.address.fullname ) return;
		if ( !this.address.address ) return;
		if ( !this.address.province ) return;
		if ( !this.address.postal ) return;
		if ( !this.address.city ) return;
		if ( !this.address.phone ) return;
		if ( !this.selectedAddress ) {
			this.user.newAddresses(this.address).subscribe((e) => {
				if ( e[0].code == 400 ) this.addressError = e[0].message;
				this.getAddresses();
				this.sA = false;
				this.sAA = true;
				this.newAddress = false;
				this.newAddresses.emit(true);
				this.changeSelectedAddress(e[0]);
				this.resetAddress();
				this.orderAddress.emit( e[0] );
				if (!this.isVendor) this.address.type='home';
				setTimeout( () => {
					this.sAA = false;
					this.newAddress = false;
					this.selectedAddress = false;
					$("html, body").animate({ scrollTop: 0 }, 200);
				}, 2000);
			});
		} else {
			this.user.editAddress(this.address).subscribe((e) => {
				if ( e[0].code == 400 ) this.addressError = e[0].message;
				this.getAddresses();
				this.sA = false;
				this.sAA = true;
				this.newAddresses.emit(true);
				this.changeSelectedAddress(e[0]);
				this.orderAddress.emit(e[0]);
				setTimeout( () => {
					this.sAA = false;
					this.newAddress = false;
					this.selectedAddress = false;
					$("html, body").animate({ scrollTop: 0 }, 200);
				}, 2000);
			});
		}
	}

	saveEncodedAddress() {
		this.sE = true;
		if( this.pgp_address.vendor_key ) {
			if( this.pgp_address.address_key ) {
				this.user.savePGP(this.pgp_address).subscribe((e) => {
					if ( e[0].code == 400 ) this.addressError = e[0].message;
					this.getAddresses();
					this.sA = false;
					this.sAA=true;
					this.newAddress = false;
					this.changeSelectedAddress(e[0],1);
					this.orderAddress.emit( e[0] );
					this.selectedAddress = false;
					this.pgp_address = new PGP();
					setTimeout( () => {
						this.sAA = false;
					}, 2000);
				});
			}
		}
	}

	new_address() {
		if(this.selectPrev) this.orderAddress.emit( false );
		this.newAddress = this.newAddress ? false : true;
	}

	getLatLng() { var a=''; 
		if(this.autocompleteRunning) return;
		this.autocompleteRunning = true;
		setTimeout( () => {
			if(this.address.unit) a=a+ this.address.unit + ' ';
			if(this.address.address) a=a + this.address.address + ' ';
			if(this.address.city) a=a + this.address.city + ' ';
			if(this.address.province) a=a + this.address.province + ' ';
			if(this.address.countrycode) a=a + this.address.countrycode + ' ';
			if(this.address.postal) a=a + this.address.postal;
			this.user.autocompleteAddresses(a).subscribe( (addr) => {
				this.autocompleteRunning = false;
				if(addr && addr[0].features[0]) {
					this.address.longitude = addr[0].features[0].geometry.coordinates[0];
					this.address.latitude = addr[0].features[0].geometry.coordinates[1];
					this.showMap=true;
				}
			});
		},777);
	}

	changeSelectedAddress( address, donotset=0, emit=0 ) {
		if ( this.selectedAddress == address && emit == 0 ) {
			this.newAddress = false;
			this.selectedAddress = false;
			this.resetAddress();
			if (emit) this.orderAddress.emit( false );
		} else {
			if (emit) this.orderAddress.emit( address );
			this.address = address;
			this.address.latitude = Number(address.lat);
			this.address.longitude = Number(address.lng);
			this.addr = address.address;
			this.address.countrycode = address.country_code;
			this.address.delivery = address.delivery;
			if ( !donotset ) this.selectedAddress = address;
			if(!this.selectPrev) {
				this.newAddress = true;
				this.getCity();
			}
		}
	}

	setGiftRecipient(e) {
		this.gift = e;
		this.giftRecipient.emit( this.gift );
	}

	getAddresses() {
		// this.user.lengthAddress().subscribe( (nuts) => {
		// 	this.savedAddresses  = nuts;
		// 	if (nuts > 0) { }
		// });
		this.user.getAddresses().subscribe( (ikbar) => {
			this.addresses = ikbar;
			if(ikbar[0]&&ikbar[0].count>0) {
				this.savedAddresses = ikbar[0].count;
			} else {
				this.savedAddresses = 0;
			}
			if (!this.addresses.phone) this.addresses.phone = '';
			this.loaded = true;
		});
	}

	authorize() {
		this.user.authorize().subscribe( (acc) => {
			if( acc[0] && acc[0].accountid ) {
				this.loggedIn = 1;
			} else {
				this.loggedIn = 0;
			}
		});
	}

	markerDragEnd(e) {
		this.address.latitude = e.coords.lat;
		this.address.longitude = e.coords.lng;
		sessionStorage.setItem('_lat', e.coords.lat);
		sessionStorage.setItem('_lng', e.coords.lng);
	}

	resetAddress() {
		this.address = new NewAddress();
		this.address.type='home';
		this.address.latitude = Number(sessionStorage.getItem('_lat'));
		this.address.longitude = Number(sessionStorage.getItem('_lng'));
	}

	ngOnInit() { 
		// Load the pinpoint map marker from the user cache / IP geocoder
		this.marketplace.locate().subscribe( (u) => {
			if(u[0]) {
				this.userlocale = u;
				if (!this.address.latitude) this.address.latitude = u[0].lat;
				if (!this.address.longitude) this.address.longitude = u[0].lng;
			}
		});
		this.user.vendorStatus().subscribe((is) => {
			this.isVendor = is.json().vendor;
			if (!this.isVendor) this.address.type='home';
			if (this.addressType!='') this.address.type = this.addressType;
		});
		this.resetAddress();
		this.authorize();
		this.getCountries(1);
		this.getAddresses();
		if ( this.addnewAddress ) setTimeout( () => { this.newAddress = true; }, 500);
		if (this.pgp_vendor!=0 && this.pgp_vendor_key!="" && this.darkMarket) {
			this.collecting_pgp = true;
			this.pgp_address.vendor = this.pgp_vendor;
			this.pgp_address.vendor_key = this.pgp_vendor_key;
		}
		// if (this.searchElement) this.loadMap();
		if ( this.marketplace.getDark() ) {
			this.mapStyle = NiteMode;
		} else {
			this.mapStyle = DayMode;
		}
	}

	ngOnChanges() {
	}

}

export class NewAddress {
   constructor(
	public fullname?: string, 
	public address?: string, 
	public unit?: string, 
	public note?: string, 
	public country?: string, 
	public countrycode?: string, 
	public province?: string, 
	public postal?: string, 
	public city?: string, 
	public phone?: string,
	public full?: string,
	public latitude?: number,
	public longitude?: number,
	public encoded?: string,
	public special_instructions?: string,
	public gift?: Gift,
	public type?: string,
	public delivery?: boolean,
	public save?: boolean
   ) {}
}

export class Gift {
	constructor(
		public is_gift?: boolean,
		public address?: string,
		public name?: string,
		public message?: string
	) {}
}

export class PGP {
	constructor(
		public vendor?: number,
		public vendor_key?: string,
		public address_key?: string
	) {}
}

// export class GiftDetails {

// }