import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { slideIn } from '../../app.animations';
import { Product } from '../../models/product';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'product-popup',
  animations: [slideIn],
  templateUrl: './product.popup.component.pug',
  styleUrls: ['./popup.component.component.styl']
})
export class PopUpComponent implements OnInit {

	constructor( 
		private marketplace: MarketplaceService,
		private location: Location 
	) {this.page = this.location.path(); }

	enable = false;
	// leavecount = 5;
	leavecount = 20;

	count = 0;
	page : string;
	width: number;
	height: number;
	alreadyrun: boolean;
	topoffset = false;
	products: Product;

	@Input('show') show = false;
	@Input('highlight') highlight = false;

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event) {
		this.topoffset = (window.scrollY > 5) ? true : false;
	}
	@HostListener('document:mouseleave', ['$event'])
	mouseleave(event) {
		if (!this.enable) return;
		if (this.page.includes("/product")||this.page.includes("/cart")) return false;
		if (this.alreadyrun) return;
		this.count++;
		if ( this.count < this.leavecount ) return;
		if ( event.y < 0 ) {
			this.show = true;
			this.alreadyrun = true;
			setTimeout( () => {
				this.show = false;
			}, 7300);
		}
	}
	@HostListener('document:mouseenter', ['$event'])
	mouseenter(event) {

	}

	ngOnInit() {
		this.page = this.location.path();
		this.marketplace.singlefeatproduct().subscribe((p) => {
			this.products = p;
		});
	}

}

