import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Address } from '../../models/address';

@Component({
  selector: 'unavailable',
  templateUrl: './unavailable.component.pug',
  styleUrls: ['./unavailable.component.styl']
})
export class UnavailableContent implements OnInit {

	constructor(private user: UserService) { }

	locale: Address;
	country: any;
	showbox = true;

	ngOnInit() {
		this.user.locale().subscribe( (l) => {
  			this.locale = l[0].country;
  			this.country = l[0].country;
		});
	}

	
}