import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strain'
})
export class StrainPipe implements PipeTransform {
	transform(strains: any, type?: any): any {
		if (!strains || !type) {
			return strains;
        }

		return strains.filter(item => item.type.indexOf(type) !== -1);
	}
}
