import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'social',
  templateUrl: './social.component.pug',
  styleUrls: ['./social.component.styl']
})
export class SocialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}