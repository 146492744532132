import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Product } from '../../models/product';

@Component({
  selector: 'admin-products',
  templateUrl: './admin-products.component.pug',
  styleUrls: ['./admin-products.component.styl']
})
export class AdminProductsComponent implements OnInit {
	isAdmin = false;
	constructor(
		private marketplace: MarketplaceService,
		private userservice: UserService) { }
	products: Product;

	editProduct(p) {

	}
	getproducts() {
		this.marketplace.products().subscribe((us) => {
			this.products = us;
		});
	}
	
	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) this.getproducts();
		});
	}

}
