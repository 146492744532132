import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'posts',
  templateUrl: './posts.component.pug',
  styleUrls: ['./posts.component.styl']
})
export class PostsComponent implements OnInit {

	constructor( 
		private user: UserService,
	) {	}

	ngOnInit() {
	}

}