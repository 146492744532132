import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';
import { Keyword } from '../../models/keyword';

@Component({
  selector: 'keywords',
  templateUrl: './kw.component.pug',
  styleUrls: ['./kw.component.styl']
})
export class KwComponent implements OnInit {

	words: Keyword;

  constructor(
  	private market: MarketplaceService
  	) { }

  ngOnInit() {
  	this.market.getKeywords().subscribe( (w) => {
  		this.words = w;
  	});
  }

}
