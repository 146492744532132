import { Component, OnInit } from '@angular/core';

import { Subscription } from '../../../models/subscription';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-subscriptions',
  templateUrl: './admin-subscriptions.component.pug',
  styleUrls: ['./admin-subscriptions.component.styl']
})
export class AdminSubscriptionsComponent implements OnInit {
	
	isAdmin = false;
	subscriptions: Subscription;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listSubscriptions().subscribe((sub) => {
				this.subscriptions = sub;
			});
		}
	}
}