import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Promotion } from '../../../models/promotion';

@Component({
  selector: 'admin-promotions',
  templateUrl: './admin-promotions.component.pug',
  styleUrls: ['./admin-promotions.component.styl']
})
export class AdminPromotionsComponent implements OnInit {
	isAdmin = false;
	constructor(
		private marketplace: MarketplaceService,
		private userservice: UserService) { }
	
	promos: Promotion;

	editProduct(p) {

	}
	
	getproducts() {
		this.userservice.listPromotions().subscribe((us) => {
			this.promos = us;
		});
	}
	
	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) this.getproducts();
		});
	}

}
