import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';


export const entryAnimation = trigger('entryAnimation', [
    state('a', style({
        position: 'relative',
        opacity: 1,
        'transform': 'translateX(0%)'
    })),
    state('b', style({
        position: 'absolute',
        'z-index': '-1',
        top: '5px',
        left: '5px'
    })),
    state('c', style({
        position: 'absolute',
        'z-index': '-1',
        top: '5px',
        left: '5px'
    })),
    transition('*=>*', animate('300ms'))
]);

export const starburst = trigger('starburst', [
    state('true', style({
        opacity: 0,
        transform: 'scale(0.7) roate(0deg)'
    })),
    state('false', style({
        opacity: 1,
        transform: 'scale(1.2) roate(40deg)' 
    })),
    transition('*=>*', animate('200ms')),
]);

export const underline = trigger('underline', [
    state('true', style({
        'text-decoration': 'underline'
    })),
    state('false', style({
        'text-decoration': 'none'
    }))
]);

export const showFade = trigger('showFade', [
    state('true', style({
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0
    })),
    transition('*=>*', animate('300ms')),
]);

export const icon = trigger('icon', [
    state('true', style({
        'transform': 'rotateX(0deg)'
    })),
    state('false', style({
        'transform': 'rotateX(-90deg)',
    })),
    transition('false=>*', animate('50ms')),
    transition('true=>*', animate('60ms')),
]);


export const map = trigger('map', [
    state('true', style({
        'display':'block',
        'opacity': 1
    })),
    state('false', style({
        'display':'none',
        'opacity': 0
    })),
    transition('*=>*', animate('0ms'))
]);


export const showstatus = trigger('showstatus', [
    state('true', style({
        'transform': 'rotateY(0deg)'
    })),
    state('false', style({
        'transform': 'rotateY(90deg)'
    })),
    transition('*=>*', animate('180ms'))
]);

export const up = trigger('up', [
    state('true', style({
        'bottom': '80px'
    })),
    state('false', style({
        'bottom': '75px'
    })),
    state('up', style({
        'bottom': '159px'
    })),
    state('lg', style({
        'bottom': '42px',
        'max-height': '200px'
    })),
    transition('*=>*', animate('330ms')),
    transition('*=>up', animate('0ms'))
]);

export const overlay = trigger('overlay', [
    state('true', style({
        'opacity': '1',
        'transform': 'scale(1)'
    })),
    state('false', style({
        'opacity': '0',
        'transform': 'scale(0)'
    })),
    transition('*=>*', animate('450ms ease-out'))
])

export const large = trigger('large', [
    state('0', style({
        'transform': 'scale(0.57)'
    })),
    state('1', style({
        'transform': 'scale(1)'
    })),
    state('2', style({
        'transform': 'scale(0.57)'
    })),
    transition('*=>*', animate('400ms ease-in-out'))
]);