import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../services/marketplace.service';
import { Address } from '../models/address';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.pug',
  styleUrls: ['./locations.component.styl']
})
export class LocationsComponent implements OnInit {

  constructor(
  	private marketplace: MarketplaceService
  ) { }
  locations: Address;
  filters = new LocationFilters();

  get_locations() {

  	this.marketplace.store_locations().subscribe( (l) => {
  		this.locations = l;
  	});

  }

  ngOnInit() {
  }

}

export class LocationFilters {
	constructor(
		public key?: string
	) {}
}