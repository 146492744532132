import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SupportService } from '../../services/support.service';
import { Support } from '../../models/support';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { open, openup, active, stocks, draftsWindow, DropOpen, animateAlert, units, unit, monies, pillbutton, activemode, activetitle, strainselected, childCategory, flyIn } from '../../products/add/add.animations';
@Component({
	selector: 'support',
	templateUrl: './support.component.pug',
	styleUrls: ['./support.component.styl'],
	animations: [ open, openup, active, stocks, DropOpen, draftsWindow, animateAlert, pillbutton, units, unit, monies, activemode, activetitle, strainselected, childCategory, flyIn ]
})
export class SupportComponent implements OnInit {
	constructor(
		private user: UserService,
		private support: SupportService,
		private router: Router,
	) { }
	supportissues: Support;
	countTickets = -1;
	showstatus = [];

	getTickets() {
		this.support.getSupportTickets().subscribe( (resp) => {
			this.supportissues = resp;
			this.countTickets = resp[0].count;
		});
	}

	setTicketStatus(t, status) {
		this.support.setTicketStatus(t.id,status).subscribe( (resp) => {
			if (resp[0]) this.getTickets();
		});	
	}
	
	selectTicket(t) {
	}
	ngOnInit() {
		this.getTickets();
	}
}