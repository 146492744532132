import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const selected = trigger('selected', [
    state('true', style({

    })),
    state('false', style({

    })),
    transition('*=>*', animate('400ms'))
]);