import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from '../../../models/transaction';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'wallet-transactions',
  templateUrl: './transactions.component.pug',
  styleUrls: ['./transactions.component.styl']
})
export class TransactionsComponent implements OnInit {

	constructor(
		private marketplace: MarketplaceService,
		private user: UserService
	) { }

	
	@Input('type') filterType = '';
	@Input('currency') filterCurrency = '';
	@Input('limit') limit = 30;
	@Input('offset') offset = 0;
	@Input('title') title = this.filterType + ' Transactions';

	setTransactions: any;
	countTransactions = -1;
	uncaptured_payments: any;
	transactions: Transaction;

	getTransactions(a=0) {
		this.user.getTransations(this.limit,this.offset,this.filterType,this.filterCurrency).subscribe( (tx) => {
			this.transactions = tx; 
			this.countTransactions = tx[0].count; 
			if(a)this.autoTransactions();
		});
	}

	prevPage() {
		if ( this.offset>0 ){
			this.offset = this.offset - this.limit;
			this.getTransactions();
		} 
	}

	nextPage() {
		if ( this.offset+this.limit < this.countTransactions ) {
			this.offset = this.offset + this.limit;
			this.getTransactions();
		}
	}

	autoTransactions() {
		this.setTransactions = setInterval( () => { 
			this.getTransactions();
		}, 10000);
	}

	iscoin(c?) {
		if ( c == 'CANN' ) {
			return false;
		} else if ( c == 'POT' ) {
			return false;
		} else if ( c == 'BTC' ) {
			return false;
		} else if ( c == 'DOPE' ) {
			return false;
		} else if ( c == 'ETH' ) {
			return false;
		} else if ( c == 'DASH' ) {
			return false;
		} else {
			return true; 
		}
	}

	islong(c?) {
		if ( c == 'CANN' ) {
			return false;
		} else if ( c == 'POT' ) {
			return false;
		} else if ( c == 'BTC' ) {
			return true;
		} else if ( c == 'DOPE' ) {
			return false;
		} else if ( c == 'ETH' ) {
			return true;
		} else if ( c == 'DASH' ) {
			return true;
		} else {
			return false; 
		}
	}

	canceltx(id) {
		this.marketplace.cancel_payment(id).subscribe( (tx) => {
			this.getTransactions();
		});
	}

	ngOnInit() {
		this.getTransactions(1);
	}

	ngOnDestroy() {
		clearInterval(this.setTransactions);
	}

}