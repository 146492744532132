import { Component, OnInit, Input, Output, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { fadeInFull } from '../../app.animations';
import { addressPallet, active, errorstate_thick } from '../checkout.animations';
import { rotation } from '../checkout.animations';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
// import { StripeScriptTag, StripeToken } from "stripe-angular";
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { PayPal } from '../../models/paypal';
import { Card } from '../../models/card';
import { Order } from '../../models/order';
import { Wallet } from '../../models/wallet';
import { Interac } from '../../models/interac';
import { Balances } from '../../models/balances';
import { Country } from '../../models/address';
import { Coin } from '../../models/coin';
import { Nonce } from '../../models/nonce';
import { PaymentMethod } from '../../models/paymentmethod';
import { environment } from '../../../environments/environment';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'checkout-payment',
  templateUrl: './checkout.payment.component.pug',
  styleUrls: ['./checkout.payment.component.styl'],
  animations: [ addressPallet, active, errorstate_thick, rotation, fadeInFull ]
})
export class CheckoutPaymentComponent implements OnInit {
	@Input() justPayment: boolean;
	@Input('newAddress') newAddress = 1;
	@Input('addPayment') addPayment = 0;
	@Input('showSelector') showSelector = 1;
	@Input('showDeposit') showDeposit = 0;
	@Input('currency') accountCurrency = 'CAD';
	@Input('OrderID') OrderID = false;
	@Input('enableCredit') enableCredit = false;
	@Input('paypal_embed') PayPalDirect = true;
	@Output('newCard') anewCard = new EventEmitter();
	@Output('newPaypal') anewPaypal = new EventEmitter();
	@Output('newInterac') anewInterac = new EventEmitter();
	@Output('newMethod') newMethod = new EventEmitter();
	@Output('paymentMethod') paymentMethod = new EventEmitter();
	@Output('paymentProduct') paymentProduct = new EventEmitter();
	@Output('paypalProduct') paypalProduct = new EventEmitter();
	@Output('interacProduct') interacProduct = new EventEmitter();
	@Output('balanceChange') balanceChange = new EventEmitter();
	@ViewChild('stripeCard') stripeCard;
	card: Card;
	coins: Coin;
	order: Order;
	locale: Country;
	wallets: Wallet;
	paypals: PayPal;
	creditCards: Card;
	newCoin: Balances;
	countries: Country;
	interacts: Interac;
	payPalConfig: PayPalConfig;
	paymentProducts: PaymentMethod;
	CCVerificationID: number;
	selectedPaymentProduct = -1;
	paymentProductCount = 0;
	creditCardsCount = 0;
	loaderPercentage = 0;
	isCoinPayment = 0;
	accountTotal = 0;
	interacCount = 0;
	orderAmount = 0;
	orderNumber = 0;
	paypalCount = 0;
	coinsCount = 0;
	DepositPage = 1;
	_l = 0;
	_lu = 0;
	nonce: any;
	stripeTokenization: any;
	paymentType: string;
	AccountID: string;
	cardError: string;
	error: string;
	CoinLookTimer: any;
	paymentError: any;
	selectedMethod: any;
	savedMethods: any;
	orderDate:any;
	orderExp:any;
	methods: any;
	darktimer: any;
	timeouter: any;
	ed: any;
	saving = false;
	showpwd = false;
	showDark = false;
	newPayment = false;
	newBTCPayment = false;
	sendingEtransfer = false;
	sendingDeposit = false;
	refreshOrders = false;
	invalidError = false;
	enablePayPal = false;
	loaded = false;
	noDice = false;
	AddPayPal = false;
	AddInterac = false;
	addDeposit = true;
	addBankAcc = true;
	showCoinQRs = false;
	CreditCardEnabled = false;
	StripeCardEnabled = false;
	InteracEnabled = false;
	BitcoinEnabled = false;
	PayPalEnabled = false;
	CannCoinEnabled = false;
	PotCoinEnabled = false;
	LiteCoinEnabled = false;
	DopeCoinEnabled = false;
	DashCoinEnabled = false;
	EthEnabled = false;
	EtcEnabled = false;
	nonexistant = false;
	orderPaid = 'false';
	extraData: {};
	loggedIn = -1;
	ShowItA = 'true';
	ShowIt = 'false';
	minimumDeposit = 5;
	maximumDeposit = 1000;
	showRemove = [];
	address = '';
	creditError = '';
	selectedCard = '';
	selectedCoinAddress = '';
	sendTo = 'interacs@outlook.com';
	darkMarket = environment.darkmarket;
	yearMin = 2020;
	yearMax = 2232;
	watcherTimer: any;
	expTimer: any;
	address_min: [any];
	address_sec: [any];
	coinError=[];
	loading=[];
	timer: [any];
	c: [any];
	d: [any];
	credit = new NewCard();
	deposit = new Deposit();
	interac = new NewInterac('','','','error');
	paypal = new NewPayPal();
	cannBalance = 0;
	potBalance = 0;
	btcBalance = 0;
	ltcBalance = 0;
	ethBalance = 0;
	etcBalance = 0;
	dopeBalance = 0;
	dashBalance = 0;
	dash = 0;
	dashcoin 		= '----------------------------';
	ethcoin 		= '----------------------------';
	etccoin 		= '----------------------------';
	potcoin 		= '----------------------------';
	litecoin 		= '----------------------------';
	bitcoin 		= '----------------------------';
	dopecoin 		= '----------------------------';
	cannabiscoin 	= '----------------------------';
	StripeOptions  	= {};

	constructor(
			// private StripeScriptTag:StripeScriptTag,
			private marketplace: MarketplaceService,
			private user: UserService ) {

		this.yearMin = new Date().getFullYear();
		this.yearMax = new Date().getFullYear()+100;

	}
	sp_ur_credit() {
		if ( this.credit.number ) this.credit.number = (this.credit.number.length>19)?this.credit.number.substr(0,19):this.credit.number;
		if ( this.credit.number ) this.credit.number = this.credit.number.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
		if ( this.credit.number ) this.credit.number = this.credit.number.trim();
		if ( this.credit.number ) this.credit.last4 = (this.credit.number.length>15)?this.credit.number.substr(15,10):'';
	}
	_class(w){
		let b= (this.selectedMethod=='')?w:w+' _bw';
		let c= (this.selectedMethod==w)?'active '+w:b;
		return c
	}
	_barclas() {
		let a = '';
		if (this.orderPaid=='true') a=a+'green ';
		if (this.orderPaid=='validating') a=a+'yellow ';
		return a;
	}
	ccv_chk() {
		if ( this.credit.ccv ) this.credit.ccv=(this.credit.ccv.length>3)?this.credit.ccv.substr(0,3):this.credit.ccv;
	}
	year_chk() {
		if( !this.credit.exp_year ) return false;
		let ten = this.credit.exp_year+''.substr(0,2);
		this.credit.exp_year = ( Number(this.credit.exp_year) < this.yearMin ) ? '20'+ten : this.credit.exp_year;
		this.credit.exp_year = ( Number(this.credit.exp_year) < this.yearMin) ? '' : this.credit.exp_year;
	}
	month_chk() { 
		this.credit.exp_month = (this.credit.exp_month>12) ? 12 : this.credit.exp_month;
	}
	postal_chk() {
		if( this.credit.postal.match(/^[0-9]+$/) == null ) {
			this.credit.postal = String(this.credit.postal).length > 7 ? this.credit.postal.substr(0,7) : this.credit.postal;
			this.credit.postal = this.credit.postal.replace(/\W/gi, '').replace(/(.{3})/g, '$1 ');
			this.credit.postal = this.credit.postal.trim();
			this.credit.postal = this.credit.postal.toUpperCase();
		} else {
			this.credit.postal = String(this.credit.postal).length > 7 ? this.credit.postal.substr(0,7) : this.credit.postal;
		}
	}
	deposit_chk() { 
		this.deposit.amount = Number.parseFloat(this.deposit.amount).toFixed(2);
	}
	popup( url ) {
		window.open(url,'_blank');
	}
	copi(s=0) {
		this.d[s] = true;
		this.c[s] = true;
		setTimeout( () => { this.c[s] = false; }, 3500);
	}

	expire() {
		var start:any = new Date(this.orderDate),
			end:any = new Date(this.orderExp),
			today:any = new Date(), 
		p = Math.round(((today - start) / (end - start)) * 100);
		this.loaderPercentage = p;
	}
	removePayment(e, a) {
		e.preventDefault();
		this.marketplace.removePayment(a.id).subscribe( (c) => {
			this.getPaymentCards();
		});
	}
	log(e) {
		console.log(e);
	}
	authorize() {
		this.user.authorize().subscribe( (acc) => {
			if( acc[0] && acc[0].accountid ) {
				this.loggedIn = 1;
			} else {
				this.loggedIn = 0;
			}
			this.AccountID = acc[0].accountid;
			this.accountTotal = acc[0].balance;
			this.paypal.email = acc[0].paypal;
		});

		this.user.get_currency().subscribe((c) => {
		   this.accountCurrency = c;
		});
	}
	methodEnabled( method ) {
		if ( this.isCoinPayment == 1 ) return false;
		if ( this.enableCredit == false && method == 'credit' ) return false;
		if ( this.enableCredit == false && method == 'card' ) return false;
		return true;
	}
	clearMethod() {																	this.resetLoader();
		this.selectedMethod =  false;
		this.selectedCoinAddress = null;
		this.paymentMethod.emit( this.selectedMethod );
	}
	changeMethod( method ) {														this.resetLoader();
		if(method=='interac') this.AddInterac=true; 
		if(method=='paypal') this.AddPayPal=true;
		// this.c[]=false;
		// this.loading[method] = true;
		this.addPayment = 0;
		this._bow();
		this.selectedMethod = ( this.selectedMethod == method ) ? false : method;
		this.paymentMethod.emit( this.selectedMethod );
		if ( method == 'cann' ) this.startCounter(5, 'cann');
	}
	changePaymentProduct( product ) {												  this.resetLoader();this._lu=this._lu+1;
		if (!this.enableCredit) return;
		this.addPayment = 0;
		this.isCoinPayment = 0;
		this.selectedMethod = false;
		this.paymentType = 'card';
		this.deposit.method = 'card';
		this.deposit.id = product.id;
		product.id = product.id;
		this.paymentProduct.emit( product );
		this.selectedPaymentProduct = ( this.selectedPaymentProduct == product ) ? '-1' : product;
	}
	changePaymentInterac( interac ) {												  this.resetLoader();this._lu=this._lu+1;
		this.paymentType = 'interac';
		this.selectedMethod = 'interac';
		this.deposit.method = 'interac';
		this.AddInterac=false;
		this.addPayment = 0;
		this.isCoinPayment = 0;
		this.paymentProduct.emit( interac );
		this.interacProduct.emit( interac.id );
		this.deposit.id = interac.id;
		this.selectedPaymentProduct = ( this.selectedPaymentProduct == interac ) ? '-1' : interac;
	}
	changePaymentPayPal( paypal ) {													  this.resetLoader();this._lu=this._lu+1;
		this.paymentType = 'paypal';
		this.selectedMethod = 'paypal';
		this.deposit.method = 'paypal';
		this.AddPayPal=false;
		this.addPayment = 0;
		this.isCoinPayment = 0;
		this.PayPalConfig();
		this.deposit.id = paypal.id;
		this.paypalProduct.emit( this.deposit.id );
		paypal.id = paypal.id;
		this.paymentProduct.emit( paypal );
		this.selectedPaymentProduct = ( this.selectedPaymentProduct == paypal ) ? '-1' : paypal;
	}
	changePaymentCoin( coin ) {														this.resetLoader();
		this.paymentType = 'coin';
		this.deposit.method = 'coin';
		let a = this.symbSwitch( coin.symbol );
		if (this.selectedMethod==a)  {
			this._reset();
		} else {
			this.addPayment = 1;
			this.isCoinPayment = 1;
			this.selectedMethod = a;
			this.selectedPaymentProduct = coin;
			this.selectedCoinAddress = coin.address;
		}
	}
	_reset() {
		this.addPayment = 0;
		this.isCoinPayment = 0;
		this.selectedMethod = -1;
		this.selectedPaymentProduct = -1;
		this.selectedCoinAddress = null;
	}
	symbSwitch(s) {
		var a=null;
		switch (s) {
			case "cann":
				a= 'cannabiscoin';
			break;
			case "dope":
				a= 'dopecoin';
			break;
			case "pot":
				a= 'potcoin';
			break;
			case "eth":
				a= 'ethereum';
			break;
			case "etc":
				a= 'ethereum_classic';
			break;
			case "ltc":
				a= 'litecoin';
			break;
			case "dash":
				a= 'dash';
			break;
			case "btc":
				a= 'bitcoin';
			break;
		}
		return a;
	}
	removeCoin( c ) {
		this.user.removeCoinAddress( c.address ).subscribe( (c) => {
			setTimeout( () => { this.getCoins(); }, 400);
		});
	}
	getCountries() {
		this.marketplace.countries().subscribe( (c) => {
			this.countries = c;
			this.locale = c[0];
		});
	}
	getBalance() { 
		this.user.getBalances().subscribe( (d) => {
			this.wallets = d;
			if (!d[0]) return;
			for (var i = d[0].count - 1; i >= 0; i--) {
				if (d[i].symbol=="DOPE") this.dopeBalance = d[i].balance;
				if (d[i].symbol=="CANN") this.cannBalance = d[i].balance;
				if (d[i].symbol=="POT") this.potBalance = d[i].balance;
				if (d[i].symbol=="BTC") this.btcBalance = d[i].balance;
				if (d[i].symbol=="LTC") this.ltcBalance = d[i].balance;
				if (d[i].symbol=="ETH") this.ethBalance = d[i].balance;
				if (d[i].symbol=="ETC") this.etcBalance = d[i].balance;
				if (d[i].symbol=="DASH") this.dashBalance = d[i].balance;
			}
		});
	}
	getPaymentProducts() {
		this.user.getPaymentProducts().subscribe( (p) => {
			this.paymentProducts = p;
			if(p[0]){
				for (var i = p[0].count - 1; i >= 0; i--) {
					if(p[i].service == "card" 	&& p[i].enabled==1) this.CreditCardEnabled = true;
					if(p[i].service == "paypal-card"&& p[i].enabled==1) this.CreditCardEnabled = true;
					if(p[i].service == "wave"   && p[i].enabled==1) this.CreditCardEnabled = true;
					if(p[i].service == "stripe" && p[i].enabled==1) {
						this.CreditCardEnabled = true;
						this.StripeCardEnabled = true;
					}
					if(p[i].service == "interac"&& p[i].enabled==1) this.InteracEnabled = true;
					if(p[i].service == "paypal" && p[i].enabled==1) this.PayPalEnabled = true;
					if(p[i].service == "cann" 	&& p[i].enabled==1) this.CannCoinEnabled = true;
					if(p[i].service == "btc" 	&& p[i].enabled==1) this.BitcoinEnabled = true;
					if(p[i].service == "pot" 	&& p[i].enabled==1) this.PotCoinEnabled = true;
					if(p[i].service == "ltc" 	&& p[i].enabled==1) this.LiteCoinEnabled = true;
					if(p[i].service == "dope" 	&& p[i].enabled==1) this.DopeCoinEnabled = true;
					if(p[i].service == "dash" 	&& p[i].enabled==1) this.DashCoinEnabled = true;
					if(p[i].service == "eth" 	&& p[i].enabled==1) this.EthEnabled = true;
					if(p[i].service == "etc" 	&& p[i].enabled==1) this.EtcEnabled = true;
				}
			}
		});
	}
	getNonce() {
		this.user.getNonce().subscribe( (n) => {
			this.nonce = n[0].code;
		});
	}
	resetNonce() {
		this.nonce = '';
		this.getNonce();
	}
	getPaymentCards(cb?) {
		this.user.getPaymentCards().subscribe( (c) => {
			this.creditCards = c;
			this.user.paymentProductCount().subscribe( (p) => {
				this.creditCardsCount = p;
				this.loader();
				if(cb) cb(1);
			});
		});
	}
	getInteracs() {
		this.user.getInterac().subscribe( (int) => {
			this.interacts = int;
			this.user.countInterac().subscribe( (p) => {
				this.interacCount = p; 
				this.loader();
			});
		});
	}
	getPayPals() {
		this.user.getPayPal().subscribe( (p) => {
			this.paypals = p;
			this.user.countPayPal().subscribe( (p) => {
				this.paypalCount = p;
				this.loader();
			});
		});
	}
	getCoins() {
		this.user.getCoinAddresses().subscribe( (c) => {
			if(c[0]) {
				this.coins = c;
				this.coinsCount = c[0].count;
				for (var i = 0; i <= this.coinsCount; i++) {
					if ( c[i] ) this.assign_address(c[i].symbol, c[i].address);
				}
				this.loader();
			}
		});
	}
	loader() {
		this._l++;
		if( this._l<3 ) this.loaded = true;
		this._nodice();
		this.loaded = true;
		setTimeout( () => {this.showCoinQRs=true;},3000);
	}
	assign_address( symbol, address ) {
		switch (symbol) {
			case "pot":
				this.potcoin = address;
				this.loading['potcoin'] = false;
			break;
			case "cann":
				this.cannabiscoin = address;
				this.loading['cannabiscoin'] = false;
			break;
			case "eth":
				this.ethcoin = address;
				this.loading['eth'] = false;
			break;
			case "btc":
				this.bitcoin = address;
				this.loading['bitcoin'] = false;
			break;
			case "dope":
				this.dopecoin = address;
				this.loading['dopecoin'] = false;
			break;
			case "ltc":
				this.litecoin = address;
				this.loading['litecoin'] = false;
			break;
			case "etc":
				this.litecoin = address;
				this.loading['etc'] = false;
			break;
			case "dash":
				this.dashcoin = address;
				this.loading['dash'] = false;
			break;
		}
	}
	has_an_address( symbol ) {
		var a = null;
		switch (symbol) {
			case "potcoin":
				if (this.potcoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "cannabiscoin":
				if (this.cannabiscoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "ethereum":
				if (this.ethcoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "ethereum_classic":
				if (this.etccoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "bitcoin":
				if (this.bitcoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "dash":
				if (this.dashcoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "litecoin":
				if (this.litecoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
			case "dopecoin":
				if (this.dopecoin=='----------------------------') {
					a= false;
				} else {
					a= true;
				}
			break;
		}
		return a;
	}
	iscoin(c?) {
		var a = null;
		if ( c.symbol == 'CANN' ) {
			a= true;
		} else if ( c.symbol == 'POT' ) {
			a= true;
		} else if ( c.symbol == 'BTC' ) {
			a= true;
		} else if ( c.symbol == 'DOPE' ) {
			a= true;
		} else if ( c.symbol == 'ETH' ) {
			a= true;
		} else if ( c.symbol == 'ETC' ) {
			a= true;
		} else if ( c.symbol == 'DASH' ) {
			a= true;
		}  else if ( c.symbol == 'LTC' ) {
			a= true;
		} else {
			a= false; 
		}
		return a;
	}
	_cm(e) {
		this.resetLoader();
		this.changeMethod(e);
		this.createAddressIfNeeded(e);
	}
	createAddressIfNeeded(e) {
		if (!this.has_an_address(e)) {
			this.createAddress(e);
		}
	}
	createAddress(coin) {
		this.loading[coin] = true;
		this.user.newCoinAddress(coin).subscribe( (c) => {
			this.timeouter = setTimeout( () => {
				if ( c[0] ) {
					this.coinError[coin]=false;
					this.assign_address(c[0].symbol, c[0].address);
					this.getCoins();
					this.resetLoader();
				} else {
					this.resetLoader();
					this.coinError[coin]=true;
				}
			}, 1353);
		});
	}
	resetLoader() {
		clearTimeout(this.timeouter);
		this.loading['dash'] = false;
		this.loading['potcoin'] = false;
		this.loading['bitcoin'] = false;
		this.loading['dopecoin'] = false;
		this.loading['ethereum'] = false;
		this.loading['ethereum_classic'] = false;
		this.loading['cannabiscoin'] = false;
		this.loading['litecoin'] = false;
		this.coinError['dash'] = false;
		this.coinError['potcoin'] = false;
		this.coinError['bitcoin'] = false;
		this.coinError['dopecoin'] = false;
		this.coinError['ethereum'] = false;
		this.coinError['ethereum_classic'] = false;
		this.coinError['cannabiscoin'] = false;
		this.coinError['litecoin'] = false;
	}

	_nodice() {
		if (!this.loaded) return false;
		this.nonexistant = false;
		if(this.paymentProductCount==0) {
			if(this.creditCardsCount==0) {
				if(this.interacCount==0) {
					if(this.paypalCount==0) {
						if(this.coinsCount==0) { 
							this.nonexistant= true;
							this.addPayment= 1;
						}
					}
				}
			}
		}
	}

	startWatch() {
		if ( this.orderPaid =='true' ) return;
		this.watcherTimer = setInterval( () => {
			this.getWatch();
		}, 6668);
	}

	getWatch() {
		this.checkIfOrderIsPaid(this.orderNumber);
	}

	checkIfOrderIsPaid(n) {
		if ( this.orderPaid=='true' ) return;
		this.user.getpaid(n).subscribe( (s) => {
			if ( this.orderPaid != s ) {
				if ( s=='true' ) this.DepositPage = 4;
				this.refreshOrders = true;
				$("html, body").animate({ scrollTop: 0 }, 200);
				setTimeout( () => { this.orderPaid = s; }, 600 );
			}
		});
	}
	setStripeToken(e) { 
		this.stripeTokenization=e;
		return;

		// Save a card by stripe token 
		// if( this.credit.fullname ) {
		// 	this.marketplace.saveCard(e).subscribe( (c) => {
		// 		if( c[0].code && c[0].code != 200 ) {
		// 			this.card = e;
		// 			this.creditError = c[0].message;
		// 			setTimeout( () => {
		// 				this.creditError = c[0].message;
		// 			}, 500);
		// 		} else { 
		// 			this.changePaymentProduct(this.card);
		// 			this.creditCards = c;
		// 		}
		// 	});
		// } else {
		// 	this.creditError = 'Please add the full name of the cardholder.';
		// }

	}
	onStripeError(e) {
		this.creditError = e.message;
		return false;
	}
	saveCard(b=0) {
		this.saving = true;
		if ( b==0 && this.StripeCardEnabled ) {
			this.stripeCard.createToken();
			setTimeout( () => { this.saveCard(1); }, 700);
			return;
		}
		if ( this.StripeCardEnabled && this.stripeTokenization ) {
			this.credit.exp_month = this.stripeTokenization.card.exp_month;
			this.credit.exp_year = this.stripeTokenization.card.exp_year;
			this.credit.token = this.stripeTokenization.id;
			this.credit.cardtoken = this.stripeTokenization.card.id;
			this.credit.stripeid = this.stripeTokenization.card.id;
			this.credit.last4 = this.stripeTokenization.card.last4;
		}
		this.extraData = {
			"name": this.credit.fullname,
			"address_city": this.credit.city,
			"address_line1": this.credit.address,
			"address_line2": this.credit.unit,
			"address_state": this.credit.province,
			"address_zip": this.credit.postal
		}; 
		if ( this.credit.fullname ) {
			if ( this.credit.number  || this.credit.token ) {
				if ( this.credit.ccv || this.credit.token ) {
					if ( this.credit.exp_month && this.credit.exp_year ) {
						if ( this.credit.postal ) {
							this.credit.nonce = this.nonce;
							this.marketplace.addCard(this.credit).subscribe( (e) => {
								if ( e[0].code == 200 ) {
									this.card = e;
									this.changePaymentProduct( this.card );
									this.newMethod.emit(1);
									this.anewCard.emit(1);
									this.getPaymentCards(() => {
										this.credit = new NewCard();
										this._reset();
									});
									setTimeout( () => {this.resetNonce();}, 2500);
									this.saving=false;
									this.nonce = false;
									this.doo_soemthing();
								} else if ( e[0].code != 200 ) {
									this.creditError = e[0].message;
									setTimeout( () => {this.resetNonce();}, 2500);
								}
							});
						} else { 
							this.creditError = 'Please add your current postal code.';
							this.saving = false;
						}
					} else { 
						this.creditError = 'Please enter the expiry month and year.';
						this.saving = false;
					}
				} else { 
					this.creditError = 'Please enter a valid credit-card CVV code.';
					this.saving = false;
				}
			} else { 
				this.creditError = 'Please enter valid credit-card details.';
				this.saving = false;
			}
		} else {
			this.creditError = 'Please add the name of the card holder.'; 
			this.saving = false;
		}
	}
	addCard() {
		if( this.credit.fullname ) {
			this.credit.nonce = this.nonce;
			this.user.addCreditCard( this.credit ).subscribe( (e) => {
				this.creditCards = e;
				if ( e[0].id && e[0].code == 200 ) {
					this.card = e;
					this.newMethod.emit(1);
					this.anewCard.emit(1);
					this.changePaymentProduct( this.card );
					this.getPaymentCards(() => {
						this.credit = new NewCard();
					});
					this.doo_soemthing();
					setTimeout( () => {this.resetNonce();}, 2500);
				} else if ( e[0].code == 500 ) {
					this.cardError = e[0].message;
					setTimeout( () => {this.resetNonce();}, 2500);
				}
				
			});
		} else { 
			this.creditError = 'Please add the name of the card holder.';
		}
	}
	saveInterac() {
		if (!this.interac.email){this.callError('int-address'); }
		if (!this.interac.fullname) {this.callError('int-name'); }
		if (!this.interac.word) {this.callError('int-word'); }
		if ( this.interac.email && this.interac.word ) {
			this.interac.nonce = this.nonce;
			this.user.addInterac( this.interac ).subscribe( (int) => {
				if ( int[0].id ) {
					this.doo_soemthing();
					this.getInteracs();
					this.saving= false;
					this.interac.fullname = '';
					this.interac.word = '';
					this.interac.email = '';
					this.anewInterac.emit(1);
					this.addPayment=0;
					this.selectedMethod=false;
					this.clearMethod();
					this.changePaymentInterac(int[0]);
					setTimeout( () => {
						this.interac = new NewInterac();
						this.resetNonce();
					}, 2500);
				}
			});
		}
	}
	savePayPal() {
		this.saving = true;
		if (!this.paypal.email){
			this.callError('pp-address'); 
		} else if ( this.paypal.email ) {
			this.paypal.nonce = this.nonce;
			this.user.addPayPal( this.paypal ).subscribe( (pp) => {
				if ( pp[0].id ) {
					this.selectedMethod =false;
					this.saving = false;
					this.doo_soemthing();
					this.getPayPals();
					this.paypalProduct.emit( pp[0].id );
					this.anewPaypal.emit(1);
					setTimeout( () => {
						this.paypal = new NewPayPal();
						this.resetNonce();
					}, 2500);
				}
			});
		}
	}
	doo_soemthing() {
		for (var i = 1; i>0; i--) {
			for (var w = 0; w >= 512; w++) {
				if ( w == 6 ) return true;
			}
		}
	}
	startCounter(m, l='0') {
		this.address_min[l] = m;
		this.address_sec[l] = 0;
		this.timer[l] = setInterval( () => {
			if (this.address_sec[l] == 0) {
				this.address_min[l] = this.address_min[l] -1;
				this.address_sec[l] = 60;
			}
			this.address_sec[l] = this.address_sec[l] - 1;
		}, 1000);
	}
	resetCounter(l=0) {
		this.address_min[l] = 0;
		this.address_sec[l] = 0;
	}
	start_deposit( deposit ) {
		this.error = '';
		this.sendingDeposit = false;
		if ( deposit.amount < this.minimumDeposit ) {
			this.error = 'Minimum amount not met.';
		} else if ( deposit.amount < this.minimumDeposit ) {
			this.error = 'The amount is over the maximum amount.';
		} else {
			this.deposit.id = deposit.id;
			this.deposit.currency = this.accountCurrency;
			this.deposit.nonce = this.nonce;
			switch ( this.paymentType ) {
				case 'interac':
					this.interac.email = 'interacs@outlook.com';
					this.interac.currency = this.accountCurrency;
					this.interac.amount = this.deposit.amount;
					this.DepositPage=2;
					this.marketplace.depositInterac(this.deposit).subscribe( (o) => {
						this.order = o;
						this.orderNumber = o[0].id;
						this.secret(this.orderNumber);
						this.orderAmount = o[0].amount;
						this.orderDate = o[0].created;
						this.orderPaid = o[0].paid;
						this.orderExp = o[0].payment_expiry;
						this.interac.amount = o[0].amount;
						this.interac.body = 'Order '+o[0].id;
						if(o[0].paid) this.balanceChange.emit(this.OrderID);
						this.DepositPage=3;
						if ( this.orderPaid=='true' ) clearInterval(this.watcherTimer);
						setTimeout( () => { 
							this.deposit = new Deposit();
							this.startWatch();
							this.resetNonce();
						}, 1000 );
						this.expTimer = setInterval( ()=>{ this.expire(); }, 1000)
					});
				break;
				case 'paypal':
				break;
				case 'card':
					//
				break;
			}
		}
	}
	lookfornewcoin() {
		this.user.new_coin().subscribe( (sub) => {
			this.newCoin = sub[0].symbol;
		});
	}
	PayPalConfig() {
		if ( this.deposit.amount < this.minimumDeposit ) {
			this.enablePayPal = false;
		} else if ( this.deposit.amount > this.maximumDeposit ) {
			this.enablePayPal = false;
		} else if ( !this.deposit.amount ) { 
			this.enablePayPal = false;
		} else {
			this.enablePayPal = true;
		}
		let PayPalClient = {sandbox: environment.paypalclientID, production: environment.paypalclientID};
		let PaypalEnv = PayPalEnvironment.Sandbox;
		if (environment.production) {
			PaypalEnv = PayPalEnvironment.Production;
		}
		this.deposit.currency = this.accountCurrency;
		this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PaypalEnv, {
			commit: true,
			transactions: [{
				amount: {
					currency: this.accountCurrency,
					total: this.deposit.amount
				}
			}],
			client: PayPalClient,
			button: {
				label: 'paypal',
			},
			onPaymentComplete: (data, actions) => {
				this.orderPaid = 'validating';
				this.deposit.email = this.paypal.email;
				this.deposit.account_id = this.AccountID;
				this.deposit.token = data.paymentToken;
				this.deposit.orderID = data.orderID;
				this.deposit.payerID = data.payerID;
				this.deposit.paymentID = data.paymentID;
				this.deposit.nonce = this.nonce;
				this.marketplace.depositPayPal(this.deposit).subscribe( (o) => {
					this.order = o;
					if ( o[0].code == 200 ) {
						this.OrderID = o[0].id;
						this.orderPaid = 'paid';
						this.DepositPage = 4;
						this.balanceChange.emit(this.OrderID);
						setTimeout( () => { 
							this.deposit = new Deposit();
							this.resetNonce();
						}, 3000 );
					}
				});
			},
			onCancel: (data, actions) => {
				// console.log('OnCancel', data);
			},
			onError: (err) => {
				// console.log('OnError', err);
			}
		});
	}
	ngOnDestroy() {
		clearInterval(this.darktimer);
		clearInterval(this.CoinLookTimer);
		clearInterval(this.watcherTimer);
		clearInterval(this.expTimer);
	}
	ngOnInit() {
		console.log( environment.stripeAPI );
		// this.StripeScriptTag.setPublishableKey( environment.stripeAPI );
		this.getNonce();
		this.authorize();
		this.getInteracs();
		this.getCountries();
		this.getPaymentCards();
		this.getPaymentProducts();
		this.getCoins();
		this.getPayPals();
		this.getBalance();
		this.PayPalConfig();
		this.deposit_chk();
		this.resetNonce();
		this.darktimer = setInterval( () => {
			this.showDark = this.marketplace.getDark();
			this.StripeOptions = {style: {
				base: {
					iconColor: this.showDark?'#fff':'#4f4f22',
					color: this.showDark?'#fff':'#4f4f22',
					border: this.showDark?'1px solid #101010':'4px solid #dadbda',
					backgroundColor: this.showDark?'#101010':'#fff',
					fontSize: '22px',
					fontFamily: 'Arial',
					fontWeight: 'bold',
					'::placeholder': {
						color: this.showDark?'#fff':'#757575',
					},
					'::selection': {
						background: this.showDark?'00a200':'#008000',
						backgroundColor: this.showDark?'00a200':'#008000',
					},
				},
				invalid: {
					iconColor: 'red',
					color: 'red',
				}
			}};
		}, 500);
		// this.CoinLookTimer = setInterval( () => { 
		// 	this.lookfornewcoin();
		// }, 6667);
	}
	callError(method) {
		this.error = method;
		setTimeout( ()=> {
			this.error = '';
		}, 3500);
	}
	secret(int) {
		this.user.getsecret(int).subscribe( (s) => {
		  if ( s=='id_error'||s=='account_error'||s=='server_error' ) {
			this.paymentType = 'err';
			this.paymentError = s.replace('_',' ');
		  } else {
			this.interac.secret = s;
		  }
		});
	}
	changeBankMethod( bank, bankbank ) { 
		switch (bankbank) {
			case 'bmo':
				this.popup("https://www1.bmo.com/onlinebanking/cgi-bin/netbnx/NBmain");
			break;
			case 'cibc':
				this.popup("https://www.cibconline.cibc.com/ebm-resources/public/banking/cibc/client/web/index.html#/signon");
			break;
			case 'desjardins':
				this.popup("https://accweb.mouv.desjardins.com/identifiantunique/identification?domaineVirtuel=desjardins&langueCible=en");
			break;
			case 'hsbc':
				this.popup("https://www.hsbc.ca/online-banking/");
			break;
			case 'laurentian':
				this.popup("https://blcweb.banquelaurentienne.ca/lang/en/BLCDirect/");
			break;
			case 'manulife':
				this.popup("https://www.manulifebank.ca/personal-banking/sign-in.html");
			break;
			case 'motus':
				this.popup("https://www.motusbank.ca/");
			break;
			case 'rbc':
				this.popup("https://www1.royalbank.com/cgi-bin/rbaccess/rbcgi3m01?F6=1&F7=IB&F21=IB&F22=IB&REQUEST=ClientSignin&LANGUAGE=ENGLISH&_ga=2.127570762.691377447.1562987379-2086661565.1562987379");
			break;
			case 'scotiabank':
				this.popup("https://www.scotiaonline.scotiabank.com/online/authentication/authentication.bns");
			break;
			case 'simplii':
				this.popup("https://www.simplii.com/en/banking-simplii/e-transfer.html?utrc=S226:12&&gclid=CjwKCAjwgqbpBRAREiwAF046JfkAmdWSeJ2xN5k51d6elRQ93_uV3nQwViH-5B5UDiAYuH0z2ePqERoC78YQAvD_BwE&gclsrc=aw.ds");
			break;
			case 'tangerine':
				this.popup("https://www.tangerine.ca/app/");
			break;
			case 'td':
				this.popup("https://authentication.td.com/uap-ui/index.html?consumer=easyweb&locale=en_CA#/login/easyweb-getting-started");
			break;
			case 'interac':
				this.popup("https://www.interac.ca/en/interac-e-transfer-consumer.html");
			break;
		}
	}
	_c_change(country){
		this.credit.country_code = country.code; 
		this.credit.country = country.country;
	}
	_bow() {
		if (this.ShowIt == 'true') {
			this._sow();
			setTimeout( () => {
				this._bow();
			},700);
			return;
		}
		if ( this.ShowItA=='true' ) {
			this.ShowItA = 'truetrue';
			setTimeout( () => {
				this._bow();
			}, 700);
		} else if (this.ShowItA=='truetrue'){
			this.ShowItA = 'false';
		} else {
			this.ShowItA = 'true';
		}
	}
	_sow() {
		if (this.ShowItA == 'true') {
			this._bow();
			setTimeout( () => {
				this._sow();
			},700);
			return;
		}
		if ( this.ShowIt=='true' ) {
			this.ShowIt = 'truetrue';
			setTimeout( () => {
				this._sow();
			}, 700);
		} else if (this.ShowIt=='truetrue') {
			this.ShowIt = 'false';
		} else {
			this.ShowIt = 'true';
		}
	}
	setVerification( v ) {
		this.credit.verification = v;
	}
	bnkc(b?) { 
		return 'CANDU'; 
	}
}
export class NewCard {
	constructor(
		public fullname?: string,
		public number?: string,
		public ccv?: string,
		public country?: string,
		public country_code?: string,
		public province?: string,
		public exp_month?: any,
		public exp_year?: string,
		public last4?: any,
		public city?: string,
		public address?: string,
		public unit?: string,
		public identity?: any,
		public postal?: any,
		public token?: any,
		public stripeid?: any,
		public cardtoken?: any,
		public verification?: number,
		public nonce?: string,
		public save?: boolean
	) {}
}
export class NewInterac {
   constructor(
		public from?: string,
		public email?: string,
		public body?: string,
		public secret?: string,
		public word?: string,
		public amount?: number,
		public id?: number,
		public nonce?: string,
		public fullname?: string,
		public currency?: string,
   ) {}
}
export class NewPayPal {
   constructor(
   		public nonce?: string,
		public email?: string,
   ) {}
}
export class Deposit {
	constructor(
		public amount?: any,
		public id?: number,
		public token?: string,
		public email?: string,
		public account_id?: string,
		public orderID?: string,
		public payerID?: string,
		public paymentID?: string,
		public currency?: string,
		public nonce?: string,
		public method?: string
	) {}
}