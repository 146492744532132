import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/product';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'checkout-review',
  templateUrl: './checkout.review.component.pug',
  styleUrls: ['./checkout.review.component.styl']
})
export class CheckoutReviewComponent implements OnInit {

	products: Product;

  constructor(
  		private marketplace: MarketplaceService
  	) { }
	credit = new Card();
	interac = new Interac();
	address = new Address();


	getCartProducts() {
		var cart = this.marketplace.cart();
		this.marketplace.products(cart).subscribe((p) => {
			this.products = p;
		});
	}

  ngOnInit() {
  }

}


export class Card {
   constructor(
	public fullname?: string,
	public address?: string,
	public ccv?: string,
	public type?: string,
	public country?: string,
	public postal?: string,
	public save?: string
   ) {}
}


export class Interac {
   constructor(
	public email?: string,
	public body?: string,
	public secret?: string
   ) {}
}

export class Address {
   constructor(
	public fullname?: string, 
	public address?: string, 
	public unit?: string, 
	public country?: string, 
	public province?: string, 
	public postal?: string, 
	public city?: string, 
	public phone?: string,
	public save?: boolean
   ) {}
}