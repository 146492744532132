import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Account } from '../../models/account';
import { Friend } from '../../models/friend';

@Component({
  selector: 'user-friends',
  templateUrl: './friends.component.pug',
  styleUrls: ['./friends.component.styl']
})
export class FriendsComponent implements OnInit {

  constructor(
  	private user: UserService
  	) { 
  	this.getHome();
  }

  username: string = '%username%';
  friends: Friend;
  loaded = false;
  h = [];

  getHome() { for (var i = 0; i < 9;i++) {this.h.push(i);}}


  getFriends() {
  	this.user.getFriends().subscribe( (f) => {
  		this.friends = f;
  		this.loaded = true;
  	});
  }


  ngOnInit() {
  	this.getFriends();
  }

}
