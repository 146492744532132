import { Account } from './account'

export class Card {
	id: any;
	account_id: number;
	address_id: number;
	user: Account;
	brand: any;
	number: any;
	cardholder: any;
	billing_address: any;
	ccv: number;
	last_four: any;
	year: any;
	exp_year: any;
	month: any;
	exp_month: any;
	country: any;
	country_code: any;
	postal_code: any;
	total_amount: any;
	last_used: any;
	token: any;
	status: any;
	identity: any;
	code: number;
	verified: boolean;
	message: string;
	created_ip: any;
	created: any;
	modified: any;
}