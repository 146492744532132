import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../../services/marketplace.service';
import { Post } from '../../../models/post';

@Component({
  selector: 'scoreboard',
  templateUrl: './scoreboard.component.pug',
  styleUrls: ['./scoreboard.component.styl']
})
export class ScoreboardComponent implements OnInit {

  constructor(
  	private marketplace: MarketplaceService
  ) { }

  score: Post;

  getScoreboard() {
  	this.marketplace.getSocalMediaPosts().subscribe( (goal) => {
  		this.score = goal;
  	});
  }

  ngOnInit() {
  	this.getScoreboard();
  }

}
