import { Component, OnInit } from '@angular/core';
import { Order } from '../../models/order';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'admin-sales',
  templateUrl: './admin-sales.component.pug',
  styleUrls: ['./admin-sales.component.styl']
})
export class AdminSalesComponent implements OnInit {
	
	isAdmin = false;
	sales: Order;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		if ( this.isAdmin ) {
			this.marketplace.orders().subscribe((os) => {
				this.sales = os;
			});
		}
	}
}