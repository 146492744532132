import { Shop, Favourite } from '../../models/sidebar';
import { Message } from '../../models/message';
import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.pug',
  styleUrls: ['./sidebar.styl']
})
export class SidebarComponent implements OnInit {
constructor(private user: UserService) { }

	orders: number;
	watching: number;
	newstore: boolean;
	jaggerMessaged: boolean;

	userShops: Shop;
	userMessages: Message;
	userFavorites: Favourite;

	getShops() {
		this.user.getShops().subscribe((sfp) => {
			this.userShops = sfp;
		});
	}

	getMessages() {
		this.user.getAllMessages().subscribe((msg) => {
			this.userMessages = msg;
		});
	}

	getFavorites() {
		this.user.getFavorites().subscribe((fav) => {
			this.userFavorites = fav;
		});
	}

	ngOnInit() {
		this.getShops();
		this.getMessages();
		this.getFavorites();
	}

} 