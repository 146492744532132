import { Component, OnInit, Output, Input, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Product } from '../../models/product';
@Component({
	selector: 'product-features-small',
	templateUrl: './small.features.component.pug',
	styleUrls: ['small.features.component.styl']
})
export class SmallFeaturesComponent implements OnInit {
	featProducts: Product;
	currencyDelimiter = '$';
	showOnPage = 4
	totalProducts = 0;
	loaded = false;
	newproduct = new SingleProduct();
	@Input('start') start = 0;
	@Input('showOnPage') onPage = 0;
	@Input('random') random = false;
	@Input('title') title = 'Featured';
	@Output('pageChanged') pageChanged = new EventEmitter();
	@Output('cartUpdate') cartUpdate = new EventEmitter();
	constructor( 
		private marketplace: MarketplaceService,
		private user: UserService,
		private router: Router  ) { }
	@HostListener('window:resize', ['$event'])
	onWindowResize(event) {
		this.rez();
	}
	rez() {
		if ( window.innerWidth > 1460 ) this.showOnPage = 4;
		if ( window.innerWidth > 1200 ) this.showOnPage = 3;
		if ( window.innerWidth < 1860 ) this.showOnPage = 4;
		if ( window.innerWidth < 1460 ) this.showOnPage = 3;
		if ( window.innerWidth < 1201 ) this.showOnPage = 2;
		if ( window.innerWidth < 520 ) this.showOnPage = 1;
		if ( window.innerWidth > 1860 ) this.showOnPage = 4;
		if ( this.onPage>0 && this.showOnPage > this.onPage ) this.showOnPage = this.onPage;
	}
	checkoutButton(e) {
		e.preventDefault();
	}
	pageChange(p) {
		this.pageChanged.emit(p);
	}
	getFeat() {
		if ( this.random ) {
			this.marketplace.randomproducts().subscribe( (d) => {
				this.featProducts = d;
				this.totalProducts = d[0].count;
			});
		} else { 
			this.marketplace.featuredproducts().subscribe( (d) => {
				this.featProducts = d;
				this.totalProducts = d[0].count;
			});
		}
	}
	addToCart(p) {
		if ( p.scaled == 'scaled' ) {
			this.marketplace.addToCart(p.id, p.order, 'g');
		} else if ( p.scaled == 'fixed' )  {
			this.marketplace.addToCart(p.id, 1, 'g', null, null, p.quantities[0].name );
		}
		this.cartUpdate.emit();
	}
	ngOnInit() {
		this.title = this.random?'Assorted':'Featured';
		this.rez();
		this.getFeat();
	}
	ngOnChange() {
	}

}
export class SingleProduct {
	constructor(
		public quantity?: number,
		public unit?: string,
		public offer?: number
	) {}
}