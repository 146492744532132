import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Category } from '../../../models/category';

@Component({
  selector: 'admin-categories',
  templateUrl: './admin-categories.component.pug',
  styleUrls: ['./admin-categories.component.styl']
})
export class AdminCategoriesComponent implements OnInit {
	constructor(private marketplace: MarketplaceService,
		private userservice: UserService) { }
	
	categories: Category;
	
	isAdmin = false;
	editCategory(p) {
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		if ( this.isAdmin ) {
			this.marketplace.categories().subscribe((cat) => {
				this.categories = cat;
			});
		}
	}
}

// Love u