import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if ( args && value ) {

			let a = [];

			for (var i = value.length - 1; i >= 0; i--) {

				var regex = RegExp(value[i].name+'*');
				var globalRegex = RegExp(value[i].name+'*','g');
				if ( regex.test(args) ) a.push(value);

				// if (value[i].name == args) a.push(value);

			}

			return (args) ? a : value;

		} else { 
			return value;
		}

	}
}
