import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'notfound',
  templateUrl: './notfound.component.pug',
  styleUrls: ['./notfound.component.styl']
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
