import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'logo',
  templateUrl: './app.logo.pug',
  styleUrls: ['./app.logo.styl']
})
export class LogoComponent implements OnInit {

	constructor(
		private user: UserService
	) {}
	

	LineOnTop = true;

	loading = false;
	mvp = false;
	pos = '';
	_p = true;
	p = 0;

	toggleDark() {

	}

	goHome() {
		
	}

	deceminate() {
		this.p = this.user.getProgress();
		this.sw();
	}

	increment() {
		this.p=this.p+1;
		this.sw();
	}

	sw() {
		switch (this.p) {
			case 1:
				this.pos = 'one'
			break;
			case 2:
				this.pos = 'two'
			break;
			case 3:
				this.pos = 'three'
			break;
			case 4:
				this.pos = 'four'
			break;
			case 5:
				this.pos = 'five'
			break;
			case 6:
				this.pos = 'six'
			break;
			default:
				this.pos = ''
			break;
		}
	}

	ngOnInit() {
		this.pos = '';
		this.deceminate();
		this.sw();
	}

}