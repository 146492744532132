import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const draftsWindow = trigger('draftsWindow', [
    state('true', style({
        opacity: 1,
        'max-height': '360px',
        'margin-top': '15px'
    })),
    state('false', style({ 
        opacity: 0,
        'max-height': '0px',
        'margin-top': '0px'
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const DropOpen = trigger('DropOpen', [
    state('true', style({
        'max-height': '360px'
    })),
    state('false', style({ 
        'max-height': '0px'
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const animateAlert = trigger('animateAlert', [
    state('true', style({
        'max-height': '100px',
        'margin': '0 auto 50px auto',
        'padding': '10px 30px',
        'border-width': '2px'
    })),
    state('false', style({
        'max-height': '0px',
        'margin': '0 auto 0px auto',
        'padding': '0px 0px',
        'border-width': 0
    })),
    transition('false=>true', animate('220ms 199ms ease-out')),
    transition('true=>false', animate('50ms 0ms'))
])
export const active = trigger('active', [
    state('true', style({
        'color': 'rgb(255, 255, 255)',
        'background': 'linear-gradient( to bottom, rgb(122, 193, 42), #66a922)',
        'border': '2px solid rgb(91, 150, 1)',
        'text-shadow': 'rgb(79, 144, 0) 1px 2px 0px'
    })),
    state('false', style({
        'text-shadow': '0 0 0 transparent'
    })),
    transition('*=>*', animate('100ms ease-out'))
]);
export const activemode = trigger('activemode', [
    state('true', style({
        'background': '#639825 !important',
        'border': '2px solid #a5ca6c !important',
        'color': 'white !important',
        'text-shadow': '0px 2px 0 #4d751d !important'
    })),
    state('false', style({
        'background': '#efefef',
        'border': '2px solid #dedbdb',
        'color': 'black',
        'text-shadow': '0 0 0 transparent'
    })),
    transition('*=>*', animate('100ms ease-out'))
]);
export const flyIn = trigger('flyIn', [
    state('false', style({
        'left': '-25px',
        'opacity': 0
    })),
    state('true', style({
        'left': '15px',
        'opacity': 1
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const open = trigger('open', [
    state('false', style({
        'max-height': '0px',
        'overflow': 'hidden',
        'opacity': 0
    })),
    state('true', style({
        'max-height': '225px',
        'overflow': 'visible',
        'opacity': 1
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const openup = trigger('openup', [
    state('false', style({
        'max-height': '0px',
        'overflow': 'hidden',
        'opacity': 0
    })),
    state('true', style({
        'max-height': '300px',
        'overflow': 'visible',
        'opacity': 1
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const childCategory = trigger('childCategory', [
    state('true', style({
        'color': 'white',
        'max-height': '50px'
    })),
    state('false', style({
        'color': 'black',
        'max-height': '0px'
    })),
    transition('*=>*', animate('100ms ease-out'))
]);
export const activetitle = trigger('activetitle', [
    state('true', style({
        'color': 'white'
    })),
    state('false', style({
        'color': 'black'
    })),
    transition('*=>*', animate('50ms'))
]);
export const units = trigger('units', [
    state('g', style({
        'left': '0%'
    })),
    state('oz', style({
        'left': '50%'
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const unit = trigger('unit', [
    state('true', style({
        'text-shadow': 'rgba(0, 0, 0, 0.5) 0px 1px 0px',
        'color': 'white'
    })),
    state('false', style({
        'text-shadow': 'rgba(0, 0, 0, 0) 0px 1px 0px',
        'color': 'black'
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const monies = trigger('monies', [
    state('true', style({
        'background': 'linear-gradient(#03A9F4, #20609e)',
        'text-shadow': '0px 1px 0px rgba(0, 0, 0, 0.5)',
        'color': 'white'
    })),
    state('false', style({
        'background': 'linear-gradient(transparent, transparent)',
        'text-shadow': '0px 1px 0px rgba(0, 0, 0, 0)',
        'color': 'black'
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const strainselected = trigger('strainselected', [
    state('true', style({
    })),
    state('false', style({
        'background': '#bbbbbb',
        'border-color': 'dimgrey'
    })),
    transition('*=>*', animate('200ms ease-out'))
])
export const pillbutton = trigger('pillbutton', [
    state('true', style({
        'background': 'linear-gradient(#6db931, #469e21)',
        'color': '#adfe7d'
    })),
    state('false', style({
        'color': '#000',
        'border': '3px solid #b1b1b1',
        'background': 'linear-gradient(#fff, #d8d8d8)'
    }))
])
export const stocks = trigger('stocks', [
    state('true', style({})),
    state('false', style({
        'margin-top': 0,
        'margin-bottom': 0,
        'padding-top': 0,
        'padding-bottom': 0,
        'height': 0,
        'border': 0
    }))
])