import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Account } from '../../models/account';
import { Friend } from '../../models/friend';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'friend-selector',
  templateUrl: './friends.selector.component.pug',
  styleUrls: ['./friends.selector.component.styl']
})
export class FriendsSelectorComponent implements OnInit {
  constructor(
  	private user: UserService
  	) { }

  	@Output('selected') selector = new EventEmitter();
	selected: Account;
	friends: Friend;

	getFriends() {
	  	this.user.getFriends().subscribe( (f) => {
	  		this.friends = f;
	  	});
	}

	selectFriend(s) {
		this.selected = s;
		this.selector.emit(this.selected);
	}

  ngOnInit() {
  	this.getFriends();
  }

}
