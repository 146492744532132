import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { DriverService } from '../../../services/driver.service';
import { MarketplaceService } from '../../../services/marketplace.service';
import { Position } from '../../../models/position';
import { Order } from '../../../models/order';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'vendor-onboarding',
  templateUrl: './vendor.onboarding.component.pug',
  styleUrls: ['./vendor.onboarding.component.styl']
})
export class VendorOnboardingComponent implements OnInit {
	page = 0;
	loggedIn: boolean;
	awaiting_approval = false;
	_Err: boolean;
	_Agg: boolean;
	_timer: any;
	_timer2: any;
	_timer3: any;
	car = 0;
	license = 0;
	ownership = 0;
	insurance = 0;
	work_permit = 0;
	identifier = 0;
	signature = new Signature(false,'');
	@Input('driver_id') driver_id = 0;
	constructor( 
		private router: Router,
		private user: UserService,
		private marketplace: MarketplaceService,
	) {	}
	privacy_agreement_text() {
		if ( this.signature.name!='' && this.signature.tick ) this._Err=false;
		if ( this.signature.name=='' || !this.signature.tick ) this._Err=true;
		this._Agg=false;
	}
	privacy_agreement() {
		if ( this.signature.tick ) {
			this.signature.tick = false;
		} else {
			this.signature.tick = true;
		}
		this.privacy_agreement_text();
	}
	complete() {
		if ( this.signature.name!='' && this.signature.tick ) {
			this.page=7;
			this._Err=false;
			this._Agg=false;
		} else {
			this._Err=false;
			this._Agg=true;
			this._timer = setTimeout( () => { this.do_sth(); }, 9000);
		}
	}
	authorize() {
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) {
				this.identifier = a[0].accountid;
				this.loggedIn = true;
			}
		});
	}
	ngOnInit() {
		this.authorize();
	}
	_backup() {
		this.page = 5;
		this._Err=false;
		this._Agg=false;
		clearTimeout(this._timer);
	}
	do_sth() {
		for (var i = 0; i <= 360; i++) {
			this.privacy_agreement_text();
		}
	}
}
export class Signature {
   constructor(
   	public tick?: boolean,
   	public name?: string
   ) {}
}