import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const productMenu = trigger('productMenu', [
    state('true', style({
		transform: 'translateY(10px) scale(1) rotateX(0deg)',
		filter: 'blur(0px)',
		display: 'block',
		opacity: 1
    })),
    state('false', style({
		transform: 'translateY(0px) scale(0.9)rotateX(-100deg)',
		filter: ' blur(10px)',
		display: 'none',
		opacity: 0
    })),
    transition('*=>*', animate('420ms ease-out'))
]);
export const properties = trigger('properties', [
    state('true', style({
		'max-height': '500px'
    })),
    state('false', style({
		'max-height': '0px'
    })),
    transition('*=>*', animate('420ms ease-out'))
]);
export const show = trigger('show', [
    state('true', style({
		'display': 'block'
    })),
    state('false', style({
		'display': 'none'
    }))
]);
export const popin = trigger('popin', [
    state('true', style({
        'transform' : 'scale(1)'
    })),
    state('false', style({
        'transform' : 'scale(0)'
    })),
    transition('*=>*', animate('300ms ease-out'))
]);
export const preloading = trigger('preloading', [
    state('true', style({
		'filter': 'blur(9px)'
    })),
    state('false', style({
		'filter': 'blur(0px)'
    })),
    transition('*=>*', animate('300ms ease-out'))
]);
export const image = trigger('image', [
    state('true', style({
		'width': 'auto',
		'height': '250px',
		'overflow': 'hidden',
		'display': 'inline-block',
		'border': '1px solid #d8d8d8',
		'border-radius': '0px',
		'box-shadow': 'none'
    })),
    state('large', style({
		'width': 'auto',
		'overflow': 'hidden',
		'display': 'inline-block',
		'border': '1px solid #d8d8d8',
		'border-radius': '0px',
		'box-shadow': 'none'
    })),
    state('round', style({
		'border-radius': '1000px',
		'width': '250px',
		'height': '250px',
		'overflow': 'hidden',
		'display': 'inline-block',
		'border': '5px solid #e7e7e7',
		'box-shadow': '0 0 12px rgba(255, 255, 255, 0.271)'
    })),
    state('false', style({})),
    transition('*=>*', animate('420ms ease-out'))
]);
export const picture = trigger('picture', [
    state('true', style({
		'float': 'left'
    })),
    state('large', style({})),
    state('round', style({
		'float': 'left'
    })),
    state('false', style({}))
]);
export const ovL = trigger('ovL', [
    state('true', style({
		'opacity': 1
    })),
    state('false', style({
		'opacity': 0
    })),
    transition('*=>*', animate('320ms ease-out'))
]);
export const titlespot = trigger('titlespot', [
    state('true', style({
		'margin-top': '70px'
    })),
    state('large', style({
    })),
    state('round', style({
		'margin-top': '70px'
    })),
    state('false', style({}))
]);
export const showCart = trigger('showCart', [
    state('true', style({
		'border': '2px solid #d6d6d6',
		'border-right-width': '2px',
		'border-bottom-width': '3px',
		'padding': '0 2%',
		'margin': '15px 15px'
    })),
    state('false', style({
		'margin-top': '0',
		'margin-bottom': '0',
		'height': '0',
		'padding': '0',
		'border-top': '0',
		'border-bottom': '0'
    })),
    transition('*=>*', animate('420ms ease-out'))
]);
export const flash = trigger('flash', [
    state('true', style({
		'transform': 'scale(1.1)',
		'border-color': '#f0c75d',
		'background': '#ffeec2'
    })),
    state('false', style({
    	'transform': 'scale(1)',
		'border-color': '#d7d7d7',
		'background': 'radial-gradient(#fff, #ececec)'
    })),
    transition('*=>*', animate('120ms'))
]);
export const nextImageA = trigger('nextImageA', [
    state('true', style({
    	'opacity': 1
    })),
    state('false', style({
    	'opacity': 0,
		'border-radius': '1000px',
		'position': 'absolute',
		'right': '0%',
		'transform': 'scale(0.57) translateY(-33%) translateX(37%) rotateY(-17deg) rotateX(-7deg) rotate(-2deg)',
		'filter': 'brightness(0.9)',
		'box-shadow': '12px 20px 27px #00000033',
		'z-index': '-1'
	})),
    transition('*=>*', animate('1150ms'))
]);
export const lastImageA = trigger('lastImageA', [
    state('true', style({
    	'opacity': 1
    })),
    state('false', style({
    	'opacity': 0,
		'border-radius': '1000px',
		'position': 'absolute',
		'left': '0%',
		'transform': 'scale(0.57) translateY(-33%) translateX(-40%) rotateY(17deg) rotateX(-7deg) rotate(2deg)',
		'filter': 'brightness(0.9)',
		'box-shadow': '12px 20px 27px #00000033',
		'z-index': '-1'
	})),
    transition('*=>*', animate('1150ms'))
]);