import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Card } from '../../../models/card';

@Component({
  selector: 'admin-cards',
  templateUrl: './admin-cards.component.pug',
  styleUrls: ['./admin-cards.component.styl']
})
export class AdminCardsComponent implements OnDestroy, OnInit {
	
	constructor(private marketplace: MarketplaceService,
				private sanitizer: DomSanitizer,
				private userservice: UserService) { }

	isAdmin = false;
	paymentStatus: any;
	uncaptured_payments: any;
	timer: any;
	cards: Card;

	get_cards() {
		this.userservice.allCards().subscribe( (s) => {
			this.cards = s;
		});
	}

	verify(c) {
		this.userservice.verifyCard(c.id).subscribe( (s) => {
			this.get_cards();
		});
	}
	
	ban(c) {
		this.userservice.banCard(c.id).subscribe( (s) => {
			this.get_cards();
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.get_cards();
				this.timer = setInterval( () => {
					this.get_cards();
				}, 20000);
			}
		});
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}