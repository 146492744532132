import { ViewChild, Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef  } from '@angular/core';
import { view } from '../../app.animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Image } from '../../models/image';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { Position } from '../../models/position';
import { Order } from '../../models/order';

@Component({
  selector: 'account-validation',
  templateUrl: './images.account.validation.component.pug',
  styleUrls: ['./images.account.validation.component.styl']
})
export class AccountImageValidation implements OnInit {

	constructor(
		public dome: DomSanitizer,
		public elementRef: ElementRef,
		public dragulaService: DragulaService,
		public user: UserService,
		public marketplace: MarketplaceService) {

		this.cropperSettings = new CropperSettings();
		this.cropperSettings.noFileInput = true;
		this.cropperSettings.rounded = true;

		this.subs.add(this.dragulaService.drop("ORDERS")
			.subscribe(({ name, el, target, source, sibling }) => {
				// console.log( el );
				// this.o();
		}));
	}

	licenseDocument: string;
	identityDocument: string;
	valid = true;

	@Output('id') id: string;
	@Output('newID') newID: EventEmitter<any> = new EventEmitter();
	@Output('reset') reset: EventEmitter<any> = new EventEmitter();
	@Output('newImg') newImg: EventEmitter<any> = new EventEmitter();
	@Output('hasImg') hasImg: EventEmitter<any> = new EventEmitter();
	@Output('identity') identity: EventEmitter<any> = new EventEmitter();

	@Input('id') identifier = '0';
	@Input('prompt') prompt = 'Upload Your Identity Card';
	@Input('desc') desc = 'Click here to select an image.';
	@Input('type') type = '';
	@Input('account_id') account_id = 0;

	@ViewChild('cropper') cropper: ImageCropperComponent; 
	@ViewChild('ViewSoleman') viewsonic: ElementRef;
	@ViewChild('activeImage') activeImage:ElementRef;
	@HostListener('window:resize', ['$event'])
		onResize(event) {
			this.updateHeight();
	}

	subs = new Subscription();
	activeImageElement: any;
	selectedFiles: FileList;
	currentFileUpload: File;
	filesUploaded: boolean;
	fileFinished: boolean;
	cropperSettings: any;
	showViewer: boolean;
	filesLoaded = false;
	countImages = 0;
	sonicHeight: any;
	loaded: boolean;
	waiting: boolean;
	viewing = false;
	selected: any;
	images: Image;
	image: Image;
	loading: any;
	value = 100;
	order: any;
	error: any;
	clo: any;

	selectFile(event) {
		this.selectedFiles = event.target.files;
		this.upload();
	}

	_img(e) {
		this.identity.emit(e);
	}

	e(img, i) {
		this.showViewer = true;
		this.viewing = i;
		this.updateHeight();

		// this.marketplace.removeImage(img.id).subscribe( (e) => {
		// 	this.marketplace.getProductImages(this.identifier)
		// 		.subscribe((v) => {
		//   				this.images = v;
		//   				this.filesUploaded = true;
		// 	});
		// });
		// return e;

	}


	s(img,i) {
		// this.activeImageElement = new HTMLImageElement();
		this.selected = (this.selected==img.id)?false:img.id;
		// this.activeImageElement.src = img.url;
		// this.cropper.setImage( this.activeImageElement );
	}


	x(img) {
		this.clo = img.id;
		this.reset.emit(1);
		setTimeout( () => {
			this.marketplace.removeImage(img.id).subscribe((a) => {
				if (a) {
					this.pi();
					return a;
				}
			});
		}, 250);
	}

	class(img) {
		let a = '';
		if ( this.selected == img.id ) {
			a = a+' active';
		} else if ( this.selected ) {
			a = a+' idle';
		} if (this.clo == img.id ) {
			a = a+' closing';
		} if (this.valid) {
			a = a+' valid';
		} if (this.images[0].count==0) {
			a = a+' hidden';
		}
		return a;
	}

	o(imgs=false, p?) {
		let imgIDs = [];
		this.marketplace.orderImages(this.identifier, imgIDs).subscribe( (orderup) => {
			if ( orderup )  {
				if ( !p ) this.pi();
				console.log('Done reordering!', orderup);
			} else { 
				console.log('Error reordering!', orderup);
			}
		});
	}

	r(img, deg = '90', p?) {
		this.marketplace.rotateImage(img, deg, this.identifier).subscribe( (flip) => {
			if ( flip )  {
				if ( !p ) this.pi();
				this.loading = img.id;
			} else { 
				this.loading = 'error';
			}
		});
	}

	edit(img, contrast='default', saturation='default', brightness='default', p?) {
		this.marketplace.editImage(this.image, contrast, saturation, brightness).subscribe((ed) => {
			if ( ed )  {
				if ( !p ) this.pi();
				this.loading = img.id;
			} else { 
				this.loading = 'error';
			}
    	});
	}

	cnt(i,p?) {
		if (i.title == '' || !i.title) {
			this.loading = 'error';
			return false;
		} else {
			this.loading = true;
			this.marketplace.updateImageTitle(i.id, i.title).subscribe( (ace) => {
				if ( ace )  {
					if ( !p ) this.pi();
					this.loading = i.id;
				} else { 
					this.loading = 'error';
				}
			});
		}
	}

	sn(img) {
		if (img.title == '' || !img.title) {
			this.loading = 'error';
		} else if ( !this.waiting ) {
			this.waiting = true;
			setTimeout( () => {
				this.waiting = false;
				this.cnt(img, true);
			}, 3000);
		}
	}


	c(img, top, right, bottom, left, height, width, skipClass?) {
		this.marketplace.cropImage(img.id, top, right, bottom, left, height, width, this.identifier)
    		.subscribe((c) => {
				if ( c )  {
					if ( !skipClass ) this.pi();
					this.loading = img.id;
				} else { 
					this.loading = 'error';
				}
    	});
	}


	pi() {
		setTimeout(()=>{ this.filesLoaded = false; }, 2420);
		this.user.getIdentityImages(this.identifier, this.type).subscribe((v) => {
			this.countImages = v[0].count;
			if( v[0] ) {
				this.images = v;
				this.filesUploaded = true;
				this.hasImg.emit(1);
				this.identity.emit(v[0].id);
			} else {
				this.filesUploaded = false;
				this.hasImg.emit(0);
				this.identity.emit(0);
			}
		});
		this.pid();
	}

	pid() {
		this.user.getIdentityImageIds(this.identifier, this.type).subscribe((v)=> {
			this.newID.emit(v);
		})
	}

	crop() {

	}

	gamma() {

	}

	grayscale() {

	}

	colorize() {

	}

	brightness() {

	}


	onDrop(event) {
			
	}

	onDrag(event) {

	}

	unslelect() {
		this.viewing = false;
	}

	clearIndicator(s?) {
		setInterval(()=>{
			this.loading = false;
		}, (s)?s:2601);
	}

	isviewing() {
		return (this.viewing)?true:false;
	}

	upload() {
		if ( this.selectedFiles ) {
			for (var i = this.selectedFiles.length - 1; i >= 0; i--) {
		    	this.currentFileUpload = this.selectedFiles.item(i);
		    	if ( this.currentFileUpload.type == 'image/jpeg' || this.currentFileUpload.type == 'image/png' ) {
			    	this.marketplace.uploadIdentityImage(this.currentFileUpload, this.type).subscribe((e) => {
						if ( e[0].error ) { 
							this.error = e[0].error;
						} else {
							this.image = e;
							this.error = false;
							this.filesLoaded = true;
							this.loaded = true;
							this.identity.emit(e[0].id);
							this.newImg.emit(1);
							this.pi();
						}
			    	});
		    	}
		    }
		    // selectedFiles: FileList;
	    }
	}

	updateHeight() {
		if ( this.viewsonic ) {
			var a = (this.viewsonic.nativeElement.offsetHeight)-30;
			this.sonicHeight = this.dome.bypassSecurityTrustStyle(a+'px');
		}
	}

	runResize() {
		
	}

	reordered() {
		this.marketplace.uploadIdentityImage(this.currentFileUpload).subscribe((e) => {
			if ( e[0].error ) {
				this.error = e[0].error;
			} else {
				this.image = e;
				this.error = false;
				this.filesUploaded = true;
				this.pi();
			}
		});
	}

	remove_all() {
		this.user.removeIdentityDocuments().subscribe((v)=> {
			if (v) {
				this.countImages = 0;
				this.pi();	
			}
		});
	}

	authorize() {
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) this.identifier = a[0].accountid;
			this.pi();
		});
	}

	ngOnDestroy() {
		// destroy all the subscriptions at once
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.authorize();
		this.clearIndicator();
	}

}