import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selected'
})
export class SelectedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	
  	let a = [];

	  for (var i = value.length - 1; i >= 0; i--) {
  		if ( args == value.symbol ) a.push(value);
  	}

	  for (var i = value.length - 1; i >= 0; i--) {
  		if ( args != value.symbol ) a.push(value);
  	}

    return a;

  }

}