import { Component, OnInit, Input } from '@angular/core';
import { addressPallet } from '../checkout.animations';
import { MarketplaceService } from '../../services/marketplace.service';
import { Country } from '../../models/address';
import { Product } from '../../models/product';

@Component({
  selector: 'checkout-products',
  templateUrl: './checkout.products.component.pug',
  styleUrls: ['./checkout.products.component.styl'],
  animations: [ addressPallet ]
})
export class CheckoutProductsComponent implements OnInit {

	products: Product;
	countries: Country;
	locale: Country;

	constructor( private marketplace: MarketplaceService ) { }

	ngOnInit() {
		this.marketplace.products(this.marketplace.cart()).subscribe( (c) => {
			this.products = c;
		});
	}

}
