import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'warnings',
  templateUrl: './warnings.component.pug',
  styleUrls: ['./warnings.component.styl']
})
export class WarningsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
