import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';
import { Product } from '../../models/product';

@Component({
  selector: 'users-products',
  templateUrl: './users-products.component.pug',
  styleUrls: ['./users-products.component.styl']
})
export class UsersProductsComponent implements OnInit {

  constructor(
  	private marketplace: MarketplaceService
  ) { }

  products: Product;
  username:string;

  ngOnInit() {
  	this.marketplace.usersProducts(this.username).subscribe( (p) => {
  		this.products = p;
  	});

  }
}