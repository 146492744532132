import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Account } from '../models/account';
import { active } from './admin.animations';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.pug',
  styleUrls: ['./admin.component.styl'],
  animations: [ active ]
})
export class AdminComponent implements OnInit {
	
	menu = 'admin';
	submenu: string;
	isAdmin = false;
	uncaptured_payments: any;
	constructor(private userservice: UserService) { }

	resov(a) { 
		return this.isAdmin;
		// if ( !environment.production ) {
		// 	return true;
		// } else {
		// 	return a;
		// }
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.menu='payments'; 
				this.submenu='payments';
			}
		});
	}

}
