import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { cart, countryList, notifButton } from '../app.animations';
import { MarketplaceService } from '../services/marketplace.service';
import { UserService } from '../services/user.service';

import { Account } from '../models/account';
import { Product } from '../models/product';
import { PaymentMethod } from '../models/paymentmethod';
import { Locale, Address, Country } from '../models/address';

@Component({
  selector: 'cart-box',
  templateUrl: './app.cart.box.component.pug',
  styleUrls: ['app.cart.box.component.styl'],
  animations: [ cart, countryList, notifButton ]
})
export class SidebarCartBoxComponent implements OnInit {























// 		Check app.cart.sidebar.component.ts Instead
































	total = 0;
	cartLocale = false;

	account: Account;
	country: Country;
	countries: Country;
	products: Product;
	featProducts: Product;
	locations: Locale;
	addresses: Address;
	payments: PaymentMethod;
	cartProducts: Product;
	cartTotal = 0;
	cartTaxes = 0;
	cartDelivery = 0;
	cartShipping = 0;

	currencyDelimiter = '$';

	@Input('mode') sidebarMode: boolean;
	@Input('open') showCart: boolean;

	constructor( 
		private marketplace: MarketplaceService,
		private user: UserService,
		private router: Router  ) { }

	checkoutButton(e) {
		e.preventDefault();
		//this.router.navigate('checkout');
	}

	getProducts() {
		var cart = this.marketplace.parseCart();
		this.marketplace.products(cart).subscribe( (p) => {
			this.products = p;
		});
	}

	getAccount() {
		this.user.authorize().subscribe( (a) => {
			this.account = a;
		});
	}

	getPaymentMethods() {
		this.marketplace.paymentMethods().subscribe( (p) => {
			this.payments = p;
		});
	}

	getLocations() {
		this.marketplace.getLocations().subscribe( (l) => {
			this.addresses = l;
		});
	}

	selectUnit() {
		
	}

	cart(s) {
		this.showCart = s;
	}

	getFeat() {
		this.marketplace.products().subscribe( (d) => {
			this.featProducts = d;
		});
	}

	getCart() {
		this.marketplace.cartContents().subscribe( (c) => {
			this.cartProducts = c;
		});
	}

	ngOnInit() {
		this.getFeat();
		this.getCart();
		// setInterval(() => {
		// }, 500);
	}

	ngOnChange() {
		this.cartTaxes = this.cartTotal;
		this.cartShipping = 0;
		this.cartDelivery = 0;

	}

}