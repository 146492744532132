import { Component, OnInit } from '@angular/core';
import { animateAlert } from '../recover.animations';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordChange } from '../../../models/passwordchange';

@Component({
  selector: 'change-pass',
  templateUrl: './change.pass.component.pug',
  styleUrls: ['./change.pass.component.styl'],
  animations: [ animateAlert ]
})
export class ChangePassComponent implements OnInit {

	accountRecoveryError = false;
	accountRecovered = null;
	accountError: string;
	recovery = new Recover();
	result: PasswordChange;
	cancel: boolean;
	username: any;
	loggedIn: any;
	key: string;

	constructor( private user: UserService,
				 private router: Router,
				 private route: ActivatedRoute) { }

	try_to_recover_now() {
		if (!this.recovery.password || !this.recovery.confirmation) {
			this.accountError = 'Please note that all fields are required.';
			this.accountRecoveryError = true;
			this.accountRecovered = false;
		} else if (this.recovery.password == this.recovery.confirmation) {
			this.user.change_password(this.recovery.key, this.recovery.password).subscribe( (u) => {
				this.result = u;
				if ( u[0].code == 200 ) {
					this.username = u[0].username;
					this.accountRecovered = true;
					this.accountError = 'Your password has been updated.';
					setTimeout( () => { this.goLogin(); }, 1000);
				} else { 
					this.accountRecovered = false;
					this.accountRecoveryError = true;
					this.accountError = 'Sorry, an error occurred. Please try again.';
					this.accountError = u[0].err;
				}
			});
		} else if (this.recovery.password != this.recovery.confirmation) {
			this.accountError = 'Please make sure both passwords match.';
			this.accountRecoveryError = true;
			this.accountRecovered = false; 
		}
		setTimeout( () => {
			this.accountError = '';
			this.accountRecoveryError = false;
			this.accountRecovered = false;
		}, 10000);
	}

	goLogin() {
		this.accountRecoveryError = false; 
		this.user.login( this.username, this.recovery.password ).subscribe( (acc) => {
			if ( acc.code == 400 ) {
				this.accountError = acc[0].error;
				this.accountRecoveryError = true;
				setTimeout(()=>{
					this.accountRecoveryError=false;
					this.accountError='';
				},3100);
			} else if ( acc.code == 200 ) {
				this.accountRecovered = true;
				this.accountRecoveryError = false;
				this.accountError = ' Logging you in...';
				setTimeout( () => { window.location.pathname='/'; }, 1200);
			}
		});
	}

	ngOnInit() {
		this.loggedIn = this.user.loggedIn();
		this.key = this.route.snapshot.params.key;
		if ( this.route.snapshot.params.cancel ) {
			this.cancel = true;
		}
		this.recovery.key = this.route.snapshot.params.key;
		this.user.get_username_by_recovery_key(this.key).subscribe( (u) => {
			if ( u[0] ) this.username = u[0].username;
		});
	}

}


export class Recover {
  constructor(
    public id?: number,
    public key?: string,
    public password?: string,
    public confirmation?: string
  ) {  }
}