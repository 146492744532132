import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Account } from '../../../models/account';
import { Balances } from '../../../models/balances';

@Component({
  selector: 'admin-balances',
  templateUrl: './admin-balances.component.pug',
  styleUrls: ['./admin-balances.component.styl']
})
export class AdminBalancesComponent implements OnInit {
	isAdmin = false;
	balances: Balances;
	
	users: Account;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getbals() {
		this.userservice.getAllBalances().subscribe((ca) => {
			this.balances = ca;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getbals();
			}
		});
	}
}
