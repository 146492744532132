import { Component, OnInit } from '@angular/core';
import { Driver } from '../../models/driver';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { DriverService } from '../../services/driver.service';

@Component({
  selector: 'admin-delivery',
  templateUrl: './admin-delivery.component.pug',
  styleUrls: ['./admin-delivery.component.styl']
})
export class AdminDeliveryComponent implements OnInit {
	
	isAdmin = false;
	drivers: Driver;

	constructor(public marketplace: MarketplaceService,
				public driverservice: DriverService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listDrivers().subscribe((dr) => {
				this.drivers = dr;
			});
		}
		
	}
}