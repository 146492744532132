import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';


export const exp = trigger('exp', [
    state('true', style({
        position: 'relative'
    })),
    state('false', style({
        position: 'absolute'
    })),
    transition('*=>*', animate('300ms'))
]);


export const unfold = trigger('unfold', [
    state('true', style({
        opacity: 1,
        left: '0px'
    })),
    state('false', style({
        opacity: 0,
        left: '-4px'
    })),
    transition('*=>*', animate('300ms'))
]);
export const disabled = trigger('disabled', [
    state('false', style({
        opacity: 1,
        'pointer-events': 'all'
    })),
    state('true', style({
        opacity: 0.6,
        'pointer-events': 'none'
    })),
    transition('*=>*', animate('160ms'))
]);
export const unfold_up = trigger('unfold_up', [
    state('true', style({
        opacity: 1,
        transform: 'translateY(0px)'
    })),
    state('false', style({
        opacity: 0,
        transform: 'translateY(4px)'
    })),
    transition('*=>*', animate('300ms'))
]);
