import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

export const online = trigger('online', [
    state('true', style({
        'background': '#49c812',
        'box-shadow': '0 0 3px #49c812'
    })),
    state('false', style({
        'background': '#ffa500',
        'box-shadow': '0 0 3px #ffa500'
    })),
    transition('*=>*', animate('120ms'))
]);
