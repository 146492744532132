import { Component, Input, Output, OnInit, HostListener, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';
import { Transaction } from '../../../models/transaction';
import { Balances } from '../../../models/balances';
import { Currency } from '../../../models/currency';
import { gray } from './balances.animations';
@Component({
  selector: 'wallet-balances',
  templateUrl: './balances.component.pug',
  styleUrls: ['./balances.component.styl'],
  animations: [gray]
})
export class BalancesComponent implements OnInit {

  constructor(
  	private market: MarketplaceService,
    private user: UserService
  	) { }

  @Input('small') small = false;
  @Input('showTitle') showTitle = true;
  @Input('showEmpty') showEmpty = false;
  @Input('balancesBox') balancesBox = true;
  @Input('showTransactions') showTransactions = true;
  @Output('balance') currentBalance = new EventEmitter();
  @Output('currency') currencyEvent = new EventEmitter();
  @ViewChild('balanceWindow') balanceWindow: ElementRef;
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.rsz();
  }
  
	wallets: Balances;
  currencies: Currency;

  balance: number
  currency: any;
  anyBalance: boolean;
  loading = true;
  showErr = false;
  w: any;
  cc = 0;
  wwa = 0;
  _seTrr = 0;
  wwb = 3;
  accountGender = '';

  log(a) {
    //console.log(a);
  }
  
  rsz() {
    var a = this.balanceWindow.nativeElement.offsetWidth;
    if (a == 912) {
      this.wwb = 4;
    } else if (a == 768) {
      this.wwb = 3;
    } else if (a == 576) {
      this.wwb = 2;
    } else if (a < 433) {
      this.wwb = 1;
    }
  }

  iscoin(c?) {
    if ( c.symbol == 'CANN' ) {
      return false;
    } else if ( c.symbol == 'POT' ) {
      return false;
    } else if ( c.symbol == 'BTC' ) {
      return false;
    } else if ( c.symbol == 'DOPE' ) {
      return false;
    }  else if ( c.symbol == 'DASH' ) {
      return false;
    } else if ( c.symbol == 'ETH' ) {
      return false;
    } else if ( c.symbol == 'ETC' ) {
      return false;
    } else {
      return true; 
    }
  }

  islong(c?) {
    if ( c.symbol == 'CANN' ) {
      return false;
    } else if ( c.symbol == 'ETH' ) {
      return true;
    } else if ( c.symbol == 'ETC' ) {
      return true;
    } else if ( c.symbol == 'DASH' ) {
      return true;
    } else if ( c.symbol == 'BTC' ) {
      return true;
    } else {
      return false; 
    }
  }

  get_row_classes(c) {
    var a = 'currency_row';
    a = (this.currency==c.symbol || (!this.currency&&c.default) ) ? ((this.iscoin(c)) ? 'active a': 'active b') : '';
    if ( c.balance < 0 ) a = a + ' under';
    if ( c.balance == 0 ) a = a + ' nonein';
    if ( c.balance > 0 ) a = a + ' somethin';
    if ( this.accountGender.toLowerCase() == 'f' ) a = a + ' girly';
    if ( !this.showEmpty  && !(this.currency==c.symbol || (!this.currency&&c.default) )) a = (c.balance>0)?a:a+' hide';
    return a;
  }

  get_classes(c) {
    var a = (this.currency==c.symbol || (!this.currency&&c.default) ) ? ((this.iscoin(c)) ? 'active a': 'active b') : '';
    if ( c.balance < 0 ) a = a + ' under';
    if ( c.balance == 0 ) a = a + ' nonein';
    if ( c.balance > 0 ) a = a + ' somethin';
    if ( this.accountGender.toLowerCase() == 'f' ) a = a + ' girly';
    if ( !this.showEmpty && !(this.currency==c.symbol || (!this.currency&&c.default) ) ) a = (c.balance>0)?a:a+' hide';
    return a;
  }


  
  set_currency(c,i=-1) {
    if(i>-1) this.wwa = i;
    this.currency = c.symbol;
    this.currencyEvent.emit(c.symbol);
    this.currentBalance.emit(c.balance);
    this.user.set_currency(c.symbol).subscribe((c) => {
      //ok
    });
  }

  get_currency() {
    this.market.getCurrencies().subscribe( (c) => {
      this.currencies = c;
      this.cc = c[0].count;
      for (var i = this.cc - 1; i >= 0; i--) {
        if( this.currencies[i].default ) this.wwa=i;
        if( this.currencies[i].symbol == c ) {
          this.currentBalance.emit(this.currencies[i].balance);
        }
      }
    });
  }

  auto_wallet() {
    this.w = setInterval( () => {
      this.get_wallets();
    }, 6567);
  }

  get_wallets() {
    this.user.getBalances().subscribe( (d) => {
      this.wallets = d;
      // if(!this._seTrr) this.showEmpty= (!this.showEmpty) ? !(d[0].anybalances) : this.showEmpty;
      this.showErr = d[0].balance_err;
      this.loading = false;
    });
  }

  toggleShowEmpty() {
    this.showEmpty=(this.showEmpty)?false:true;
    this._seTrr=1;
    this.wwa=0;
    this.setEmpty();
  }

  setEmpty() {
    this.user.set_currency_empty(this.showEmpty).subscribe((c) => {
      //ok
    });
  }

  ngOnInit() {
    this.rsz();
    this.balance = 0;
    this.loading=true;
    this.user.authorize().subscribe( (a) => {
      if (a && a[0]) {
        this.get_currency();
        this.accountGender = a[0].sex;
        this.user.get_currency_empty().subscribe((c) => {
          this.auto_wallet();
          this.user.get_currency().subscribe((c) => {
            this.currency = c;
            this.currencyEvent.emit(c);
          });
          this.showEmpty=c;
        });
      }
    });
    
  }

  ngOnDestroy() {
    clearInterval(this.w);
  }

}