import { Component, OnInit } from '@angular/core';
import { Notification } from '../../../models/notification';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-notifications',
  templateUrl: './admin-notifications.component.pug',
  styleUrls: ['./admin-notifications.component.styl']
})
export class AdminNotificationsComponent implements OnInit {
	isAdmin = false;
	notifications: Notification;
	constructor(public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		if ( this.isAdmin ) {
			this.userservice.listNotifications().subscribe((notifs) => {
				this.notifications = notifs;
			});
		}
	}
}