import { ViewChild, Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef  } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MarketplaceService } from '../../services/marketplace.service';
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { Image } from '../../models/image';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { view } from './image.animations'

@Component({
  selector: 'image-uploader',
  animations: [ view ],
  templateUrl: './imageuploader.component.pug',
  styleUrls: ['./imageuploader.component.styl']
})
export class ImageUploader {
	
	constructor(
		private dome: DomSanitizer,
		private elementRef: ElementRef,
		private dragulaService: DragulaService,
		public marketplace: MarketplaceService) {

			this.cropperSettings = new CropperSettings();
			this.cropperSettings.noFileInput = true;
			this.cropperSettings.rounded = true;

			this.subs.add(this.dragulaService.drop("ORDERS")
				.subscribe(({ name, el, target, source, sibling }) => {
					console.log( el );
					// this.o();
			}));

	}

	@Output('id') id: string;
	@Input('id') identifier = null;
	@Output('newID') newID = new EventEmitter();
	@ViewChild('cropper') cropper: ImageCropperComponent; 
	@ViewChild('ViewSoleman') viewsonic: ElementRef;
	@ViewChild('activeImage') activeImage:ElementRef;
	@ViewChild('UFileInput') UFileInput:ElementRef;

	@HostListener('window:resize', ['$event'])
		onResize(event) {
			this.updateHeight();
	}

	subs = new Subscription();
	activeImageElement: any;
	selectedFiles: FileList;
	currentFileUpload: File;
	filesUploaded: boolean;
	fileFinished: boolean;
	cropperSettings: any;
	showViewer: boolean;
	filesLoaded = false;
	sonicHeight: any;
	loaded: boolean;
	waiting: boolean;
	viewing = false;
	selected: any;
	images: Image;
	image: Image;
	loading: any;
	value = 100;
	order: any;
	error: any;
	clo: any;

	selectFile(event) {
		this.selectedFiles = event.target.files;
		this.upload();
	}

	e(img, i) {
		this.showViewer = true;
		this.viewing = i;
		this.updateHeight();
		// this.marketplace.removeImage(img.id).subscribe( (e) => {
		// 	this.marketplace.getProductImages(this.identifier)
		// 		.subscribe((v) => {
		//   				this.images = v;
		//   				this.filesUploaded = true;
		// 	});
		// });
		// return e;
	}

	s(img,i) {
		// this.activeImageElement = new HTMLImageElement();
		this.selected = (this.selected==img.id)?false:img.id;
		// this.activeImageElement.src = img.url;
		// this.cropper.setImage( this.activeImageElement );
	}

	x(img) {
		this.clo = img.id;
		setTimeout( () => { // Animation Timer
			this.marketplace.removeImage(img.id).subscribe((a) => {
				if ( a ) {
					this.pi();
					return a;
				}
			});
		}, 250);
	}

	class(img) {
		let a = '';
		if ( this.selected == img.id ) {
			a = a+' active';
		} else if ( this.selected ) {
			a = a+' idle';
		} if (this.clo == img.id ) {
			a = a+' closing';
		}
		return a;
	}

	o(imgs=false, p?) {
		let imgIDs = [];
		this.marketplace.orderImages(this.identifier, imgIDs).subscribe( (orderup) => {
			if ( orderup )  {
				if ( !p ) this.pi();
				console.log('Done reordering!', orderup);
			} else { 
				console.log('Error reordering!', orderup);
			}
		});
	}

	r(img, deg = '90', p?) {
		this.marketplace.rotateImage(img, deg, this.identifier).subscribe( (flip) => {
			if ( flip )  {
				if ( !p ) this.pi();
				this.loading = img.id;
			} else { 
				this.loading = 'error';
			}
		});
	}

	edit(img, contrast='default', saturation='default', brightness='default', p?) {
		this.marketplace.editImage(this.image, contrast, saturation, brightness).subscribe((ed) => {
			if ( ed )  {
				if ( !p ) this.pi();
				this.loading = img.id;
			} else { 
				this.loading = 'error';
			}
    	});
	}

	cnt(i,p?) {
		if (i.title == '' || !i.title) {
			this.loading = 'error';
			return false;
		} else {
			this.loading = true;
			this.marketplace.updateImageTitle(i.id, i.title).subscribe( (ace) => {
				if ( ace )  {
					if ( !p ) this.pi();
					this.loading = i.id;
				} else { 
					this.loading = 'error';
				}
			});
		}
	}

	sn(img) {
		if (img.title == '' || !img.title) {
			this.loading = 'error';
		} else if ( !this.waiting ) {
			this.waiting = true;
			setTimeout( () => {
				this.waiting = false;
				this.cnt(img, true);
			}, 3000);
		}
	}

	c(img, top, right, bottom, left, height, width, skipClass?) {
		this.marketplace.cropImage(img.id, top, right, bottom, left, height, width, this.identifier)
    		.subscribe((c) => {
				if ( c )  {
					if ( !skipClass ) this.pi();
					this.loading = img.id;
				} else { 
					this.loading = 'error';
				}
    	});
	}

	pi() {
		setTimeout(()=>{ this.filesLoaded = false; }, 2420);
		this.marketplace.getProductImages(this.identifier)
			.subscribe((v) => {
    			this.images = v;
    			this.filesUploaded = true;
		});
	}

	pid() {
		this.marketplace.getProductImageIds(this.identifier)
			.subscribe((v)=> {
				this.newID.emit(v);
		})
	}

	crop() {

	}

	gamma() {

	}

	grayscale() {

	}

	colorize() {

	}

	brightness() {

	}

	onDrop(event) {
			
	}

	onDrag(event) {

	}

	unslelect() {
		this.viewing = false;
	}

	clearIndicator(s?) {
		setInterval(()=>{
			this.loading = false;
		}, (s)?s:2601);
	}

	isviewing() {
		return (this.viewing)?true:false;
	}

	upload() {
		if ( this.selectedFiles ) {
			for (var i = this.selectedFiles.length - 1; i >= 0; i--) {
		    	this.currentFileUpload = this.selectedFiles.item(i);
		    	if ( this.currentFileUpload.type == 'image/jpeg' || this.currentFileUpload.type == 'image/png' ) {
			    	this.marketplace.uploadImage(this.currentFileUpload, this.identifier)
			    		.subscribe((e) => {
							if ( e[0].error ) { 
				    			this.error = e[0].error;
				    		} else {
				    			this.image = e;
				    			this.error = false;
				    			this.filesLoaded = true;
				    			this.loaded = true;
				    			this.pi();
				    		}
			    	});
		    	}
		    }
		    // selectedFiles: FileList;
	    }
	}

	updateHeight() {
		if ( this.viewsonic ) {
			var a = (this.viewsonic.nativeElement.offsetHeight)-30;
			this.sonicHeight = this.dome.bypassSecurityTrustStyle(a+'px');
		}
	}

	runResize() {
		
	}

	reordered() {
		this.marketplace.uploadImage(this.currentFileUpload, this.identifier).subscribe((e) => {
			if ( e[0].error ) { 
				this.error = e[0].error;
			} else {
				this.image = e;
				this.error = false;
				this.filesUploaded = true;
				this.pi();
			}
		});
	}

	ngOnDestroy() {
		// destroy all the subscriptions at once
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.clearIndicator();
		let a = 0; setInterval( () => { if (this.identifier != '0' && a < 6) { this.pi();a=a+2; } }, 2180);
	}

}
