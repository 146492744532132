import { Component, OnInit } from '@angular/core';

import { Account } from '../models/account';

@Component({
	selector: 'account',
	templateUrl: './account.component.pug',
	styleUrls: ['./account.component.styl']
})
export class AccountComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
