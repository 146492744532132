import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'post',
  templateUrl: './post.component.pug',
  styleUrls: ['./post.component.styl']
})
export class PostComponent implements OnInit {

	constructor( 
		private user: UserService,
	) {	}

	ngOnInit() {
	}

}