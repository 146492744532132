import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MarketplaceService } from '../services/marketplace.service';
import { Router } from '@angular/router';

@Component({
  selector: 'advertise', 
  templateUrl: './app.advertise.component.pug',
  styleUrls: ['./app.advertise.component.styl']
})
export class AdvertiseComponent implements OnInit {
	
	isAdmin = false;

	constructor(public marketplace: MarketplaceService,
				public router: Router,
				public userservice: UserService) { }

	ngOnInit() {
		this.router.navigateByUrl('/create')
	}	
}