import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'Friends',
  templateUrl: './friends.component.pug',
  styleUrls: ['./friends.component.styl']
})
export class FriendsPageComponent implements OnInit {

	constructor( 
		private user: UserService
	) {	}

	ngOnInit() {
	}

}