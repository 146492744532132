import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../../models/product';
import { environment } from '../../../../environments/environment';
import { open, openup, active, stocks, draftsWindow, DropOpen, animateAlert, units, unit, monies, pillbutton, activemode, activetitle, strainselected, childCategory, flyIn } from '../../../products/add/add.animations';
import { selected } from './support.message.animations';
import { Support, SupportMessage, SupportCategory } from '../../../models/support';
import { UserService } from '../../../services/user.service';
import { SupportService } from '../../../services/support.service';
@Component({
	selector: 'support-message',
	templateUrl: './support.message.component.pug',
	styleUrls: ['./support.message.component.styl'],
	animations: [selected]
})
export class SupportMessageComponent implements OnInit {
	constructor(
		private support: SupportService,
		private user: UserService,
		private route: ActivatedRoute
	) { }
	case = new SupportCase();
	filters = new Filt();
	reply = new Reply();
	products: Product;
	ticket: Support;
	messages: SupportMessage;
	categories: SupportCategory;
	submitError: string;
	nonce: string;
	select: any;
	loaded: boolean;
	supportissues=false;
	notfound: boolean;
	showstatus = false;
	editorConfig = {
		placeholder: "Reply to this support ticket...",
		spellcheck: "true",
		translate: "yes",
		height: "100px",
		minHeight: "150px",
		imageEndPoint: environment.base+'upload/image/?authorization='+this.user.wib(),
		font: 'Arial',
		toolbar: [
			["bold", "italic", "underline", "strikeThrough"],
			["fontName", "fontSize", "color", "orderedList", "unorderedList"],
			["justifyLeft", "justifyCenter", "justifyRight", "indent", "outdent"],
			["orderedList", "unorderedList", "paragraph", "blockquote", "removeBlockquote", "horizontalLine" ],
			["link", "unlink", "image", "video"]
    	]
	};
	load_ticket() {
		this.support.getSupportTicket(this.case.id).subscribe( (c) => {
			if( c[0].count > 0 ) {
				this.case = c[0];
				this.reply.id = this.case.id;
				this.reply.title = 'Re: '+this.case.title;
				this.loaded =true;
			} else {
				this.notfound = true;
				this.loaded = true;
			}
		});
	}
	preview() {
		this.case.nonce=this.nonce;
		this.support.saveSupportTicket(this.case).subscribe((res) => {
			if ( res[0].error ) this.submitError = res[0].error;
			this.ticket = res;
			this.case.id = res[0].id;
			this.get_n();
		});
	}
	submit() {
		this.reply.nonce = this.nonce;
		this.support.createSupportMessage(this.reply).subscribe((res) => {
			if ( res[0].error ) this.submitError = res[0].error;
			this.messages = res;
			this.case.id = res[0].id;
			this.get_n();
		});
	}
	get_n() {
		this.user.getNonce().subscribe((n)=>{
			this.nonce=n[0].code;
		});
	}
	setTicketStatus(t, status) {
		this.support.setTicketStatus(t.id,status).subscribe( (resp) => {
			if (resp[0]) this.load_ticket();
		});	
	}
	get_messages() {
		this.support.getSupportMessages(this.case.id).subscribe( (m) => {
			this.messages = m;
		});
	}
	ngOnInit() {
		this.get_n();
		this.select = this.route.snapshot.paramMap.get("selected");
		this.case.id = this.route.snapshot.paramMap.get("id");
		this.get_messages();
		if( this.case.id ) this.load_ticket();
	}
	typeAgent(a) {
		if(a.vendor) return 'Tree St. Vendor';
		if(a.retailer) return 'Tree St. Retailer';
		if(a.support_agent) return 'Tree St. Support';
		if(a.admin) return 'Tree St. Admin';
		if(!a.admin&&!a.support_agent&&!a.retailer&&!a.vendor) return 'Tree St. Customer';
	}
}
export class Reply {
	constructor(
		public id?: number,
		public nonce?: string,
		public title?: string,
		public body?: string
	) { }
}
export class SupportCase {
  constructor(
		public id?: any,
		public nonce?: string,
		public error?: string,
		public account_id?: number,
		public account?: any,
		public order_id?: number,
		public vendor_id?: number,
		public driver_id?: number,
		public count?: number,
		public email?: string,
		public email_vendor?: any,
		public email_driver?: any,
		public items?: any,
		public products?: any,
		public title?: string,
		public code?: string,
		public messages?: [any],
		public message?: any,
		public category?: any,
		public body?: string,
		public reply?: any,
		public reply_date?: any,
		public second_reply?: any,
		public second_reply_Date?: any,
		public third_reply?: any,
		public third_reply_date?: any,
		public system_reply?: any,
		public system_reply_date?: any,
		public status?: any,
		public complete?: any,
		public date_completed?: any,
		public created?: any,
		public updated?: any
  ) {  }
}
export class Filt {
  constructor(
		public cat?: string
  ) {  }
}