import { Component, OnInit } from '@angular/core';
import { Parcel } from '../../../models/parcel';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-parcels',
  templateUrl: './admin-parcels.component.pug',
  styleUrls: ['./admin-parcels.component.styl']
})
export class AdminParcelsComponent implements OnInit {
	isAdmin = false;
	parcels: Parcel;
	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		if ( this.isAdmin ) {
			this.userservice.listParcels().subscribe((p) => {
				this.parcels = p;
			});
		}
	}
}