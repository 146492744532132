export const environment = {
	name: 'Tree St. (dev)',
	darkmarket: false,
	production: false,
	mobile: false,
	domain: 'local.treest.ca',
	base: 'http://local.treest.ca/ts/',
	googleAnalytics: {
		domain: 'auto',
		trackingId: 'UA-XXXXXXXX-X'
	},
	recaptcha: '6LfndHAUAAAAAFBHS3wiWXlAsn4Bqp2vDBg83zhq',
	gmapsAPI: 'AIzaSyDNl9Nf3I7nj0oPGqIqt1RFeMzB1rauyfs',
	stripeAPI: 'pk_test_jObZqrhEvdByHoTP4PrFiYK7',
	paypalclientID: 'AbJ6d2xMeaXEwekVW1MVdmZy9jujot_FnvqmklnS28YFtCZ-mRhsLWw3Pnxz7osGYa9AMjFpAmgT3j9N',
	adsense_adClient:'ca-pub-7676126699489493',
	adsense_adSlot:7011030053,
	socket: 'https://treest.ca:8181',
	secure_socket: true,
	protector: 'M|!=cQL~ap7p*+3!J;Je99rHrDFw)',
	cryptoKey: 'uDRUMfbzcLmvySLt',
	cryptoIv: 'uESvKSSEW5q5zsen'
};
