import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Category } from '../../../models/category';
import { Order } from '../../../models/order';
import { Transaction } from '../../../models/transaction';

@Component({
  selector: 'admin-transactions',
  templateUrl: './admin-transactions.component.pug',
  styleUrls: ['./admin-transactions.component.styl']
})
export class AdminTransactionsComponent implements OnDestroy, OnInit {
	
	constructor(private marketplace: MarketplaceService,
				private sanitizer: DomSanitizer,
				private userservice: UserService) { }

	isAdmin = false;
	paymentStatus: any;
	uncaptured_payments: any;
	timer: any;
	transactions: Transaction;

	get_transactions() {
		
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.get_transactions();
				this.timer = setInterval( () => {
					this.get_transactions();
				}, 20000);
			} // INTHERE
		});
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}