import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Friendship } from '../../../models/friendship';

@Component({
  selector: 'admin-friends',
  templateUrl: './admin-friends.component.pug',
  styleUrls: ['./admin-friends.component.styl']
})
export class AdminFriendsComponent implements OnInit {
	isAdmin = false;
	
	friends: Friendship;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getusers() {
		this.userservice.listFriendRelations().subscribe((uandi) => {
			this.friends = uandi;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getusers();
			}
		});
	}
}

