import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vendor-rules',
  templateUrl: './rules.component.pug',
  styleUrls: ['./rules.component.styl']
})
export class RulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
