import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Account } from '../../models/account';

@Component({
  selector: 'admin-users',
  templateUrl: './admin-users.component.pug',
  styleUrls: ['./admin-users.component.styl']
})
export class AdminUsersComponent implements OnInit {
	isAdmin = false;
	
	users: Account;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getusers() {
		this.userservice.listUsers().subscribe((us) => {
			this.users = us;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getusers();
			}
		});
	}
}
