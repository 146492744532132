import { Component, OnInit } from '@angular/core';

import { Issue } from '../../../models/issue';

import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';
import { DriverService } from '../../../services/driver.service';

@Component({
  selector: 'admin-issues',
  templateUrl: './admin-issues.component.pug',
  styleUrls: ['./admin-issues.component.styl']
})
export class AdminIssuesComponent implements OnInit {
	
	isAdmin = false;
	issues: Issue;

	constructor(public driverservice: DriverService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listIssues().subscribe((is) => {
				this.issues = is;
			});
		}
		
	}
}