import { Component, OnInit } from '@angular/core';
import { Account } from '../../../models/account';
import { Review } from '../../../models/review';
import { UserService } from '../../../services/user.service';
import { DriverService } from '../../../services/driver.service';

@Component({
  selector: 'driver-reviews',
  templateUrl: './driver.reviews.component.pug',
  styleUrls: ['./driver.reviews.component.styl']
})
export class DriverReviewsComponent implements OnInit {

  constructor(
  	private driver: DriverService,
    private user: UserService
  ) { this.getHome(); }

  reviews: Review;
  accounts: Account;
  username: string = '%username%';
  page:string = 'Orders';
  loaded:boolean;
  h = [];
  getHome() { for (var i = 0; i < 9;i++) {this.h.push(i);}}



  getDriverReviews() {
    this.driver.getReviews().subscribe( (re) => {
      this.reviews = re;
    });
  }

  ngOnInit() {
    
  	// Authorize Account
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) this.username = a[0].username;
			this.accounts = a;
		});

  }

}
