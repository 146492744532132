import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wallet-withdrawal',
  templateUrl: './withdrawal.component.pug',
  styleUrls: ['./withdrawal.component.styl']
})
export class WithdrawalComponent implements OnInit {

  constructor() { }
  balChange:any;
  curr:any;
  len:any;
  ngOnInit() {
  }

}
