import { Component, OnInit, Input, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { Order } from '../../models/order';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'receipt',
  templateUrl: './receipt.component.pug',
  styleUrls: ['./receipt.component.styl']
})
export class ReceiptComponent implements OnInit {
  constructor(
  	private user: UserService,
    private activated: ActivatedRoute,
  	private market: MarketplaceService
  	) {  
      this.orderID = this.activated.snapshot.paramMap.get("order"); 
    }
  order: Order;
  @Input('order') orderID: any;

  ngOnInit() {
    if (!this.orderID) this.orderID = this.activated.snapshot.paramMap.get("order"); 
    this.market.get_order(this.order).subscribe( (o) => {
      this.order = o;
    });
  }

}