import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delivery'
})
export class DeliveryPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if ( args ) {
			let values = [];
			for (var i = value.length - 1; i >= 0; i--) {
				if ( !args || args == 'first' ) values.push(value[i]);
				if ( args == 'delivery' && value[i].pickup ) values.push(value[i]);
				if ( args == 'shipping' && !value[i].pickup ) values.push(value[i]);
			}
			return values;
		} else { 
			return value;
		}
	}
}
