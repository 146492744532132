import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarketplaceService } from '../../../services/marketplace.service';
import { BlockchainTransaction } from '../../../models/transaction';

@Component({
  selector: 'view-transaction',
  templateUrl: './view.transaction.component.pug',
  styleUrls: ['./view.transaction.component.styl']
})
export class ViewTransactionIDComponent implements OnInit {

	constructor(private route: ActivatedRoute,
				private marketplace: MarketplaceService) { }

	tx: string;
	coin: string;
	transaction: BlockchainTransaction;
	message: string;
	get_transaction_details(tx,coin)  {
		this.marketplace.get_blockchain_transaction(tx,coin).subscribe( (res) => {
			console.log(res);
			if(res[0].message) this.message = res[0].message;
			this.transaction = res;
		});
	}

	ngOnInit() {
		this.coin = this.route.snapshot.paramMap.get("coin");
		this.tx = this.route.snapshot.paramMap.get("id");
		if (this.coin && this.tx) {
			this.get_transaction_details(this.coin, this.tx);
		}
	}

}
