import { Component, OnInit } from '@angular/core';
import { Taxes } from '../../../models/taxes';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-taxes',
  templateUrl: './admin-taxes.component.pug',
  styleUrls: ['./admin-taxes.component.styl']
})
export class AdminTaxesComponent implements OnInit {
	
	isAdmin = false;
	taxes: Taxes;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listTaxes().subscribe((os) => {
				this.taxes = os;
			});
		}
	}
}