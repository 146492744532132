import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Auth } from '../../models/auth';
import { OAuth } from '../../models/oauth';
import { Account } from '../../models/account';
import { Registration } from '../../models/registration';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { formerr, formsucc, labelerr, height } from '../../app.animations';
@Component({
  selector: 'headLogin',
  templateUrl: './header-login.component.pug',
  styleUrls: ['./header-login.component.styl'],
  animations: [ formerr, formsucc, labelerr, height ]
})
export class HeaderLoginComponent implements OnInit {
	constructor(private user: UserService,
              private marketplace: MarketplaceService) { }
  @Output('hideWindow') hideWindow = new EventEmitter();
  @Output('loginOption') loginOption = new EventEmitter();
  @Output('loggedIn') loggedIn = new EventEmitter();
  @Output('registering') reging = new EventEmitter();
  @Output('registered') reged = new EventEmitter();
  @Input('option') option = 'form';
  @Input('registering') registering:boolean;
  oAuths: OAuth;
  account: Account;
  registration: Registration;
  auth: Auth;
  login = new Login(true);
  registerButton = 'Register';
  waitms = 1300; ii = 0;
  darkMode = false;
  invalid = false;
  valid = false;
  errMsg = '';
  _dt: any;
	goLogin() {
    this.invalid = false; 
		this.user.login( this.login.username, this.login.password ).subscribe( (acc) => {
      if ( acc.code == 400 ) {
        this.errMsg = acc.error;
        this.invalid = true;
        setTimeout(()=>{
          this.invalid=false;
          this.errMsg='';
        },3100);
      } else if ( acc.code == 200 ) {
        // this.option = 'loading';
        this._lgin();
        this.valid = true;
        this.registration = acc;
        this.nowAuth();
      }
		});
	}
  goRegister() {
    this.invalid = false;
    this.user.register( this.login.email, this.login.password, this.login.username ).subscribe( (acc) => {
      this.reged.emit(acc);
      if ( acc.code == 400 ) {
        this.invalid = true;
        setTimeout( () => { this.invalid = false; }, 3100);
      } else if ( acc.code == 200 ) {
        this.valid = true;
        this.registerButton = 'Logging In...';
      }
    });
  }
  nowAuth() { 
    this.user.authorize().subscribe( (a) => {
      if ( a ) {
        this.loggedIn.emit(a);
        this.account = a;
      }
    });
  }
  hide() { this.hideWindow.emit(1); }
  oAuth(service) {
  }
  _lgin() { 
    this.option = 'loggingin';
    // this._stwait();
  }
  _donelogin() { 
    this.option = 'doneloggingin'; 
    setTimeout(()=>{ 
        // this.option = 'finloggingin'; 
        // location.reload(); 
    }, 1000);
  }
  // _stwait(ms=this.waitms) {
  //   if (!this.valid) ms+1300;
  //   if (ms>0) {
  //     setTimeout(()=>{ this._stwait(ms-10); }, 10);
    // } else if( ms==0 && this.valid ) {
  //     this._donelogin();
  //   } 
  // }
  // _kup(e, a) {
  //   this[a]=e.value;
  // }
  ted() {
    this._lgin();
    this.valid=true;
  }
  regg(a) {
    this.registering=a;
    this.reging.emit(a)
  }
  ngOnInit() {
    this.user.oAuths().subscribe((auths) => {
      this.oAuths = auths;
    });
    this._dt = setInterval( () => {
      this.darkMode = this.marketplace.getDark();
    }, 1000);
  }
  ngOnDestroy() {
    clearInterval(this._dt);
  }
}
export class Login {
   constructor(
   	public remember?: boolean,
   	public email?: string,
    public username?: string,
   	public password?: string,
   ) {}
} 