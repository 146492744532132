import { Component, OnInit } from '@angular/core';
import { animateAlert } from './recover.animations';
import { UserService } from '../../services/user.service';
import { Recovery } from '../../models/recovery';

@Component({
  selector: 'recover',
  templateUrl: './recover.component.pug',
  styleUrls: ['./recover.component.styl'],
  animations: [ animateAlert ]
})
export class RecoverComponent implements OnInit {

	accountRecovered: boolean;
	loggedIn: any;
	recovery = new Recover()
	result: Recovery;

	constructor( private user: UserService) { }

	recover() {
		this.accountRecovered = null;
		this.user.resetpwd( this.recovery.email ).subscribe( (re) => {
			this.result = re;
			if ( re[0].code == 200 ) {
				this.accountRecovered = true;
			} else {
				this.accountRecovered = false;
			}
		});
	}

	ngOnInit() {
		this.loggedIn = this.user.loggedIn();
	}

}


export class Recover {
  constructor(
    public email?: string,
  ) {  }
}