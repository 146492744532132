import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'ad',
  templateUrl: './ad.component.pug',
  styleUrls: ['./ad.component.styl']
})
export class AdComponent implements OnInit {

  constructor() { }

  @Input('name') name: string;
  @Input('size') size: string;

  ngOnInit() {

  }

}