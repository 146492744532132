import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locales'
})
export class LocalesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
	let available = [ 'AR', 'AU', 'CA', 'GE', 'JM', 'LU', 'PE', 'UY', 'US' ];
	return value;
  }
}
