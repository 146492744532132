import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

// Countries
export const view = trigger('view', [
    state('true', style({
		display: 'block'
    })),
    state('false', style({
		display: 'none'
    }))
])