import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { appRoutes } from '../app.routes';
import { Observable } from 'rxjs/Rx';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class NotificationService {
  private baseUrl: string = environment.base;
  private registration: any;
  alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  constructor( private http     : Http,
         private httpClient : HttpClientModule,
         private cookie   : CookieService ) { }
  init() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('sw.js').then(function(reg) {
        this.registration= reg;
        this.registration.addEventListener('updatefound', function() {
          var installingWorker = this.registration.installing;
          console.log('A new worker is being installed:',installingWorker);
        });
      }).catch(function(error) {
        console.log('Worker registration failed:', error);
      });
    } else {
      console.log('Workers are not supported.');
    }
  }
  action_notification() {
    const title = 'Actions Notification';
    const alertBody = 'Hello There';
    const options = {
      body: alertBody,
      badge: '/assets/icons/treest/android-chrome-192x192.png',
      image: '/images/demos/unsplash-farzad-nazifi-1600x1100.jpg',
      actions: [
        {
          action: 'accept-action',
          title: 'Accept',
          icon: '/assets/img/green-o-sm.png'
        },
        {
          action: 'decline-action',
          title: 'Decline',
          icon: '/assets/img/red-o-sm.png'
        }
      ]
    };
    this.registration.showNotification(title, options);
  }
}