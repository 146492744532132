import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Message } from '../../../models/message';

@Component({
  selector: 'admin-messages',
  templateUrl: './admin-messages.component.pug',
  styleUrls: ['./admin-messages.component.styl']
})
export class AdminMessagesComponent implements OnInit {
	isAdmin = false;
	
	messages: Message;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getusers() {
		this.userservice.listMessages().subscribe((ms) => {
			this.messages = ms;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getusers();
			}
		});
	}
}

