import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Category } from '../../../models/category';
import { Order } from '../../../models/order';
import { Deposit } from '../../../models/deposit';

@Component({
  selector: 'admin-deposits',
  templateUrl: './admin-deposits.component.pug',
  styleUrls: ['./admin-deposits.component.styl']
})
export class AdminDepositsComponent implements OnDestroy, OnInit {
	
	constructor(private marketplace: MarketplaceService,
				private sanitizer: DomSanitizer,
				private userservice: UserService) { }

	isAdmin = false;
	paymentStatus: any;
	uncaptured_payments: any;
	timer: any;
	deposits: Deposit;

	get_deposits() {
		
		
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.get_deposits();
				this.timer = setInterval( () => {
					this.get_deposits();
				}, 20000);
			} // INTHERE
		});
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}