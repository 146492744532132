import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activation } from '../../models/activation';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { animateAlert } from '../recover/recover.animations';

@Component({
  selector: 'user-activate',
  templateUrl: './activate.component.pug',
  styleUrls: ['./activate.component.styl'],
  animations: [ animateAlert ]
})
export class ActivateComponent implements OnInit {

	accountActivated: boolean;
	activation = new ActivateForm();
	loggedIn: any;
	result: Activation;

	constructor( private user: UserService,
				 private route: ActivatedRoute) { }

	activate() {
		this.accountActivated = null;
		this.user.activate( this.activation.key ).subscribe( (re) => {
			this.result = re;
			if ( re[0].code == 200 ) {
				this.accountActivated = true;
			} else {
				this.accountActivated = false;
			}
		});
	}

	ngOnInit() {
		this.activation.key = this.route.snapshot.paramMap.get("id");
		this.loggedIn = this.user.loggedIn();
	}

}


export class ActivateForm {
  constructor(
    public key?: string,
  ) {  }
}