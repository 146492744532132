import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Report } from '../../../models/report';
import { Graph } from '../../../models/graph';

@Component({
  selector: 'wallet-reports',
  templateUrl: './reports.component.pug',
  styleUrls: ['./reports.component.styl']
})
export class ReportsComponent implements OnInit {

  reports: Report;
  data: Graph;

  // Graph Settings
  single: any[];
  multi: any[];
  view = [900, 400];
  weekOffset = 0;
  totalOrders = 0;
  totalEarnings = 0;
  gradient = false;
  showLegend = false;
  xAxisLabel = 'Weekdays';
  yAxisLabel = 'Earnings';
  colorScheme = 'flame';
  noearns = '';
  weekStart = '';
  weekEnd = '';

  constructor(
    private user: UserService
  ) {}

  @ViewChild('ChartContainer') container: ElementRef;
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.rsz();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event) {
    if ( event.code == 'Slash' || event.code == 'ArrowRight' ) this.getSalesData(2);
    if ( event.code == 'KeyZ' || event.code == 'ArrowLeft' ) this.getSalesData(1);
  }


  rsz() {
    let a = this.container.nativeElement.offsetWidth;
    this.view = [a, 360];
  }

  none() {
    switch(Math.floor(Math.random() * 3 + 1)) {
      case 1:
        this.noearns = 'Nope- nothing';
      break;
      case 2:
        this.noearns = 'Nothin, bubkis';
      break;
      case 3:
        this.noearns = 'Zip. Zero.';
      break;
    }
  }

  getSalesData(a?) {
    if (a==1) this.weekOffset = this.weekOffset + 1;
    if (a==2) this.weekOffset = this.weekOffset - 1;
    this.user.getSalesGraph(this.weekOffset).subscribe( (i) => {
      this.data = i;
      this.weekStart = i[0].date;
      this.weekEnd = i[i[0].count-1].date;
      this.totalEarnings = i[0].total;
      this.totalOrders = i[0].orders;
      this.none();
      if ( this.totalEarnings == 0 ) this.rsz();
    });
  }

  onSelect(event) {

  }

  ngOnInit() {
  	// this.getReports();
    this.getSalesData();
    this.rsz();
  }

}
