import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'friend',
  templateUrl: './friend.component.pug',
  styleUrls: ['./friend.component.styl']
})
export class FriendComponent implements OnInit {

	constructor( 
		private user: UserService,
	) {	}

	ngOnInit() {
	}

}