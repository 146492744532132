import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/product';

import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'user-selling',
  templateUrl: './selling.component.pug',
  styleUrls: ['./selling.component.styl']
})
export class SellingComponent implements OnInit {

  constructor(
  	private user: UserService,
  	private marketplace: MarketplaceService,
  ) { }

  loading = true;
  loggedIn = false;
  accounts: Account;
  accountID: number;
  productsCount: number;
  products: Product;

  get_account() {
    this.user.authorize().subscribe( (a) => {
      console.log(a);
      if (a && a[0]) {
        this.accountID = a[0].accountid;
        this.loggedIn = true;
      }
    });
  }

  get_selling() {
    this.marketplace.countSellingProducts().subscribe( ( p ) => {
      this.productsCount = p;
    });
  }

  ngOnInit() {
    this.get_account();
    this.get_selling();
  }

}
