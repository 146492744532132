import { Component, OnInit,Input } from '@angular/core';
import { Coin } from '../models/coin';
import { UserService } from '../services/user.service';
import { MarketplaceService } from '../services/marketplace.service';
import { fadeInFull } from '../app.animations';
@Component({
  selector: 'product-ad', 
  templateUrl: './products.ad.card.pug',
  styleUrls: ['./products.component.styl'],
  animations: [fadeInFull]
})
export class ProductsAdComponent implements OnInit {
	@Input('ad') ad = 1;
	display = false;
	loggedIn = false;
	fadeIn = false;
	link: string;
	image: string;
	firstLine: string;
	secondLine: string;
	thirdLine: string;
	fourthLine: string;
	description: string;
	prompt: string;
	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }
	setAd(n) {
		switch (n) {
			case 1:
				this.link = '/create';
				this.firstLine = 'Sell your cannabis here';
				this.secondLine = 'Sell Products Here';
				this.thirdLine = 'On the Tree St. Marketplace';
				this.description = 'Tap into a worldwide cannabis market with <b>low listing fees, delivery options,</b> and paid shipping. Click here to get started selling.';
				this.prompt = 'Sell Cannabis';
			break;
			case 2:
				this.link = '/create';
				this.firstLine = 'Place Your Ad on Tree St.';
				this.secondLine = 'Your Ad Here';
				this.thirdLine = 'Affordable Advertising';
				this.description = 'Tap into a global audience with a low CPM rate and a large target market. Click this box to get started. Publish your ad in just minutes.';
				this.prompt = 'Post Your Ad';
			break;
			default:
			break;
		}
	}

	ngOnInit() {
		this.setAd(this.ad);
		this.userservice.authorize().subscribe( (a) => {
			if (a && a[0]) {
				this.loggedIn = true;
				this.setAd(1);
			} else {
				this.setAd(1);
			}
			this.display = true;
			this.fadeIn = true;
		});
	}
}