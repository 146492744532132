import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Category } from '../../models/category';
import { Order } from '../../models/order';
import { Payment } from '../../models/payment';

@Component({
  selector: 'admin-payments',
  templateUrl: './admin-payments.component.pug',
  styleUrls: ['./admin-payments.component.styl']
})
export class AdminPaymentsComponent implements OnDestroy, OnInit {

	constructor(private marketplace: MarketplaceService,
				private sanitizer: DomSanitizer,
				private userservice: UserService) { }

	isAdmin = false;
	paymentStatus: any;
	uncaptured_payments: any;
	timer: any;
	payments: Payment;

	get_pending_payments() {
			this.userservice.getPayments().subscribe( (s) => {
				this.payments = s;
			});
	}

	mark_order_paid(pm?) {
		
	}	

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.get_pending_payments();
				this.timer = setInterval( () => {
					this.get_pending_payments();
				}, 20000);
			} // INTHERE
		});
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}