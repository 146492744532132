import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../models/product';

import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'user-favourites',
  templateUrl: './favourites.component.pug',
  styleUrls: ['./favourites.component.styl']
})
export class FavouritesComponent implements OnInit {

	product: Product;

	constructor( private marketplace: MarketplaceService ) { }

	ngOnInit() {
		
	}

}