import { Component, Input, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { Message } from '../../models/message';
import { exp,unfold,unfold_up,disabled } from './chat.animations';
import { environment } from '../../../environments/environment';

import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'user-chat',
  animations: [ exp,unfold,unfold_up,disabled ],
  templateUrl: './chat.component.pug',
  styleUrls: ['./chat.component.styl']
})
export class ChatComponent implements OnInit {

	sI = 0;
	op = false;
	newChat = false;
	isFriend = false;
	message: Message;
	Chat = new ChatInterface();
	Link = new NewLink();
	conversation: Message;
	showEmojiPicker = false;
	showLinkPicker = false;
	showAttachmentPicker = false;
	showSendMoney = false;
	showFriendAdder = false;
	blocked = false;
	email_alerts = false;
	isAdmin = false;
	selectedConversation: any;
	countconversations: number;
	apiURLRouter = environment.base+'messages/url_meta/';
	@Input('mode') sidebarMode: boolean;
	@Input('open') showCart: boolean;

	constructor( 
		private http: Http,
		private marketplace: MarketplaceService,
		private user: UserService ) { }

	newConversation() {

	}
	public apiCallbackFn = (route: string) => {
	   return this.http.get(route);
	};
	getConversations() {
		this.user.getConversations().subscribe( (c) => {
			this.conversation = c;
			this.user.countConversations().subscribe( (c) => {
				this.countconversations = c;
			})
		})
	}
	sele(t ){
		this.showLinkPicker=false;
		this.showAttachmentPicker=false;
		this.showFriendAdder=false;
		this.showSendMoney=false;
		this[t]=true;
	}
	showOpts() {
		if(this.sI>10) return;
		var int = setInterval(()=>{
			this.sI=this.sI+1;
			if(this.sI>10) clearInterval(int);
		},50);
	}

	addEmoji(event) {
		console.log(event);
		this.Chat.message = this.Chat.message+event.emoji.native
	}

	ngOnInit() {
		this.user.adminStatus().subscribe((is) => {this.isAdmin = is.json().admin;});
		this.getConversations();
	}

}

export class ChatInterface {
  constructor(
    public message?: string
  ) {  }
}

export class NewLink {
  constructor(
	public title?: string,
	public url?: string
  ) {  }
}