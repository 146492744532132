import { Component, OnInit } from '@angular/core';
import { Coin } from '../../models/coin';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'admin-coin', 
  templateUrl: './admin.coin.component.pug',
  styleUrls: ['./admin.coin.component.styl']
})
export class AdminCoinsComponent implements OnInit {
	
	isAdmin = false;
	coins: Coin;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});
		if ( this.isAdmin ) {
			this.userservice.listCoins().subscribe((ss) => {
				this.coins = ss;
			});
		}
	}
}