import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { addressPallet,active,green,errorstate_thick,rotation, errorstates,truestates  } from '../checkout.animations';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
// import { StripeScriptTag, StripeToken } from "stripe-angular";
import { PayPal } from '../../models/paypal';
import { Nonce } from '../../models/nonce';
import { Bank } from '../../models/bank';
import { Payment } from '../../models/payment';
import { Cheque } from '../../models/cheque';
import { Country } from '../../models/address';
import { Interac } from '../../models/interac';
import { Card } from '../../models/card';
import { Currency } from '../../models/currency';
import { Balances } from '../../models/balances';
import { PaymentMethod } from '../../models/paymentmethod';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'checkout-bank',
	templateUrl: './checkout.bank.component.pug',
	styleUrls: ['./checkout.bank.component.styl'],
	animations: [ addressPallet, active, green, errorstate_thick, rotation, errorstates,truestates ]
})
export class CheckoutBankComponent implements OnInit {

	@Input() justPayment: boolean;
	@Input('creditCards') creditCards: Card;
	@Input('showSelector') showSelector = 1;
	@Input('currency') accountCurrency = null;
	@Input('balance') accountBalance: number;
	@Output('paymentProduct') paymentProduct = new EventEmitter();
	@Output('newCard') newCard = new EventEmitter();
	@Output('paymentMethod') paymentMethod = new EventEmitter();
	@Output('balanceChange') balanceChange = new EventEmitter();

	s: boolean;
	ed: any;
	zph: any;
	stripeCard: any;
	coin= new Coin();
	bank= new NewBank('CA','CAD');
	auth = new NewAuth();
	paypal = new NewPayPal();
	cheque = new NewCheque();
	interac = new NewInterac();
	withdrawal = new Withdrawal();
	cheques: Cheque;
	locale: Country;
	countries: Country;
	accountBalances: Balances;
	paymentProducts: PaymentMethod;
	interacts: Interac;
	bankAccounts: Bank;
	coins: Coin;
	paypals: PayPal;
	nonce: Nonce; 
	TxID = 0;
	covid = null;
	selectedCheque = null;
	requireSMSAuth = null;
	AuthorizedToWithdrawal = false;
	enableAutoPayments = false;
	paypalSurcharge = 0;
	interacSurcharge = 0;
	minimumWithdrawl = 5;
	WithdrawalPage = 1;
	maxAmount = 200;
	maxAmountBtc = 0.2;
	maxAmountDash = 4;
	maxAmountCann = 20000;
	maxAmountPot = 20000;
	maxAmountDope = 20000;
	maxAmountEth = 1;
	maxAmountEtc = 32;
	savedMethods: any;
	interacCount = 0;
	paypalCount = 0;
	coinsCount = 0;
	banksCount = 0;
	addCheque = 0;
	addINAcc = 0;
	addPayPal = 0;
	interacPayment = 0;
	paypalPayment = 0;
	_sel_w = 92;
	addCoin = 0;
	withdrawalCoin = 0;
	addWithdrawal = 0;
	selectedBankAcc = -1;
	cannBalance = 0;
	btcBalance = 0;
	potBalance = 0;
	ethBalance = 0;
	etcBalance = 0;
	dopeBalance = 0;
	dashBalance = 0;
	litecoinBalance = 0;
	last_sms = 0;
	interacError: string;
	countryCode: string;
	paymentCardsCount: number;
	paymentProductCount: number;
	cardError: string;
	coinError: boolean;
	showpwd: boolean;
	ethererium_classic = '';
	ethererium = '';
	address = '';
	bankError = '';
	ShowItA = 'true';
	ShowIt = 'false';
	currencySymbol = '$';
	clientEmailAddress = '';
	selectedCurrencyValue: any;
	selectedBankAddress: any;
	selectedChequeAddress: any;
	showRemove = [];
	sending = false;
	saving = false;
	_loaded = false;
	loaded = false;
	_swd = false;
	chequePayment = false;
	error_resending_sms = false;
	code_error = false;
	last_four = false;
	sms_error = false;
	sms_auth_error = false;
	last_four_error = false;
	sms_code_error = false;
	code_pass = false;
	sms_code_pass = false;
	resending_sms = false;
	newPayment = false;
	newBTCPayment = false;
	invalidError = false;
	CreditCardEnabled = false;
	StripeCardEnabled = false;
	InteracEnabled = false;
	PayPalEnabled = false;
	CannCoinEnabled = false;
	PotCoinEnabled = false;
	BitcoinEnabled = false;
	DopeCoinEnabled = false;
	EthEnabled = false;
	EtcEnabled = false;
	LtcEnabled = false;
	btc_withdrawal = false;
	cann_withdrawal = false;
	pot_withdrawal = false;
	dope_withdrawal = false;
	eth_withdrawal = false;
	etc_withdrawal = false;
	dash_withdrawal = false;
	ltc_withdrawal = false;
	DashCoin = false;
	DashCoinEnabled = false;
	ChequeEnabled = true;
	authorizing = false;
	sending_sms = false;
	screenHeight: any;
	screenWidth: any;
	selectedCoinAddress ='';
	selectedPaymentProduct: any;
	wd_error = '';
	sms_sent = false;
	selectedMethod: any;
	selectedCoin: any;
	paymentType: any;
	addBankAcc: any;
	addPPAcc: any;
	methods: any;
	timer: any;
	error: any;

	constructor( 
			// private StripeScriptTag:StripeScriptTag,
			private marketplace: MarketplaceService,
			private user: UserService ) {

	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.resizeScreen(event);
	} resizeScreen(e?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		var a = this.screenWidth;
		if ( this.screenWidth<=305 ) {
			this._sel_w = a-247;
		} else if ( this.screenWidth<=430 ) {
			this._sel_w = a-257;
		} else if ( this.screenWidth<=500 ) {
			this._sel_w = a-267;
		} else if (this.screenWidth<=560) { 
			this._sel_w = a-247;
		} else if (this.screenWidth<=620) { 
			this._sel_w = a-287;
		} else { 
			this._sel_w = 92;
		}
	}

	checkPhone(tel, e) {
		if ( e.key=="Escape" ) {
			this.auth.phone = null;
			return;
		} else {
			this.auth.phone = this.phone(tel);
		}
	}

	phone(tel) {
		var num = tel.replace(/\D/g,'');
		return '(' + num.substring(0,3) + ') ' + num.substring(3,6) + '-' + num.substring(6,10); 
	}

	/**
	* Accouint Withdrawl System 
	**/ 

	StartETransfer() {
		this.error = ''; this.sending = false;
		if( this.iscoin(this.accountCurrency) ) {
			this.error = 'Currency not available.';
		} else if ( this.withdrawal.amount < this.minimumWithdrawl ) {
			this.error = 'Minimum amount not met.';
		} else if ( this.withdrawal.amount > this.maxAmount ) {
			this.error = 'Maximum amount exceeded.';
		} else {
			this.sending = true; // Sending e-Transfer 
			this.withdrawal.currency = this.accountCurrency;
			this.withdrawal.nonce = this.nonce[0].code;
			this.user.outgoing_et(this.withdrawal).subscribe( (result) => {
				if ( result[0].code == 200 ) {
					this.TxID = result[0].id;
					this.sending = false;
					this.WithdrawalPage = 2;
					this.balanceChange.emit(this.TxID);
					setTimeout(()=>{
						this.withdrawal = new Withdrawal();
						this.getNonce();
					},2000);
				} else if ( result[0].code == 400 ) {
					this.error = result[0].message;
				}
			});
		}
	}

	StartBankWithdrawl() {
		this.error = ''; this.sending = false;
		if( this.iscoin(this.accountCurrency) ) {
			this.error = 'Currency not available.';
		} else if ( this.withdrawal.amount < this.minimumWithdrawl ) {
			this.error = 'Minimum amount not met.';
		} else if ( this.withdrawal.amount > this.maxAmount ) {
			this.error = 'Maximum amount exceeded.';
		} else {
			this.sending = true; // Sending bank withdrawal
			this.withdrawal.currency = this.accountCurrency;
			this.withdrawal.nonce = this.nonce[0].code;
			this.user.outgoing_bw(this.withdrawal).subscribe( (result) => {
				if ( result[0].code == 200 ) {
					this.TxID = result[0].id;
					this.sending = false;
					this.WithdrawalPage = 2;
					this.balanceChange.emit(this.TxID);
					setTimeout(()=>{
						this.withdrawal = new Withdrawal();
						this.getNonce();
					},2000);
				}
			});
		}
	}

	StartPayPalWithdrawl() {
		this.error = ''; this.sending = false;
		if( this.iscoin(this.accountCurrency) ) {
			this.error = 'Currency not available.';
		} else if ( this.withdrawal.amount < this.minimumWithdrawl ) {
			this.error = 'Minimum amount not met.';
		} else if ( this.withdrawal.amount > this.maxAmount ) {
			this.error = 'Maximum amount exceeded.';
		}else {
			this.sending = true; // Sending PayPal payment 
			this.withdrawal.currency = this.accountCurrency;
			this.withdrawal.nonce = this.nonce[0].code;
			this.user.outgoing_pp(this.withdrawal).subscribe( (result) => {
				if ( result[0].code == 200 ) {
					this.WithdrawalPage = 2;
					this.sending = false;
					this.TxID = result[0].id;
					this.balanceChange.emit(this.TxID);
					setTimeout(()=>{
						this.withdrawal = new Withdrawal();
						this.getNonce();
					},2000);
				}
			});
		}
	}

	StartCoinWithdrawl() {
		this.error = ''; this.sending = false;
		if ( this.withdrawal.amount < this.minimumWithdrawl ) {
			this.error = 'Minimum amount not met.';
		} else if ( this.withdrawal.amount > this.maxAmount ) {
			this.error = 'Maximum amount exceeded.';
		}else {
			this.sending = true;
			this.withdrawal.currency = this.selectedCoin;
			this.withdrawal.nonce = this.nonce[0].code;
			this.user.outgoing_co(this.withdrawal).subscribe( (result) => {
				if ( result[0].code == 200 ) {
					this.WithdrawalPage = 2;
					this.sending = false;
					this.TxID = result[0].id;
					this.balanceChange.emit(this.TxID);
					setTimeout(()=>{
						this.withdrawal = new Withdrawal();
						this.getNonce();
					},2000);
				}
			});
		}
	}

	/**
	* Payment Selector
	**/ 

	changePaymentInterac( interac ) {
		this.unselectPayments();
		if ( interac != 'reset' )  {
			this.withdrawal.inc = interac.id;
			this.withdrawal.account = interac.email;
			this.withdrawal.word = interac.word;
			this.paymentType = 'interac';
			this.selectedMethod='interac'; 
			this.interacPayment = 1; 
			this.paymentProduct.emit( interac );
			this.selectedPaymentProduct = ( this.selectedPaymentProduct == interac ) ? '-1' : interac;
		}
	}

	changePaymentCheque( cheque ) {
		this.unselectPayments();
		if ( cheque != 'reset' )  {
			this.selectedCheque = cheque;
			this.withdrawal.inc = cheque.id;
			this.withdrawal.account = cheque.email;
			this.chequePayment = true;
			this.paymentType = 'cheque';
			this.selectedMethod='cheque';
			this.paypalPayment = 1; 
			this.addCheque = 0;
			this.paymentProduct.emit( cheque );
			this.selectedPaymentProduct = ( this.selectedPaymentProduct == cheque ) ? '-1' : cheque;
			this.covid=false;
		}
	}

	changePaymentPaypal( paypal ) {
		this.unselectPayments();
		if ( paypal != 'reset' )  {
			this.paypal = paypal;
			this.withdrawal.inc = paypal.id;
			this.withdrawal.account = paypal.email;
			this.paymentType = 'paypal';
			this.selectedMethod='paypal';
			this.paypalPayment = 1; 
			this.paymentProduct.emit( paypal );
			this.selectedPaymentProduct = ( this.selectedPaymentProduct == paypal ) ? '-1' : paypal;
		}
	}
	changePaymentBankAccount( account ) {
		this.unselectPayments();
		if( this.selectedBankAcc == account ) {
			this.selectedBankAcc = 0;
		} else {
			this.addBankAcc = 1;
			this.addWithdrawal = 1;
			this.selectedBankAcc = account;
		}
		this.paymentProduct.emit( this.selectedBankAcc );
	}
	changePaymentCoin( coin, c ) {
		this.withdrawal.coin = coin;
		this.withdrawal.amount = 0;
		this.unselectPayments();
		if( this.selectedCoin == coin ) {
			this.selectedCoin = 0;
			this.addCoin = 0;
		} else {
			this.addCoin = 1;
			// this.withdrawalCoin = 1;
			this.selectedCoin = coin;
		}
		this.paymentProduct.emit( this.selectedCoin );
	}
	unselectPayments() {
		this.WithdrawalPage = 1;
		this.addINAcc = 0;
		this.addPPAcc = 0;
		this.addCoin = 0;
		this.withdrawalCoin = 0;
		this.paymentType = '';
		this.selectedCoin = '';
		this.selectedMethod = ''; 
		this.selectedBankAcc = -1;
		this.addPayPal =0;
		this.interacPayment = 0;
		this.paypalPayment = 0;
		// this.showSelector = 0;
		this.addBankAcc = 0;
		this.addWithdrawal = 0;
		this.selectedPaymentProduct = '-1';
	}
	/**
	* Saving Account Payment Products
	**/
	saveInterac() {
		this.saving=true;
		this.interac.nonce = this.nonce[0].code;
		this.user.addInterac(this.interac).subscribe( (c) => {
			
			if ( c[0].code == 200 ) 
			{
			 	this.interacts = c;
			}
				else if( c[0].code && c[0].code != 200 ) 
			{
				this.interacError = c[0].message;
				setTimeout( () => {
					this.interacError = c[0].message;
				}, 500);
			}
			
			this.saving=false;
		});
	}

	saveBank() {
		this.saving = true;
		this.bank.nonce = this.nonce[0].code;
		this.user.saveBankAccount( this.bank ).subscribe( (e) => {
			this.saving = false;
			if( this.bank.account_holder_name ) {
				this.bankAccounts = e;
				this.getBankAccounts();
			} else { 
				this.bankError = 'Please add the name of the card holder.';
			}
		})
	}

	saveCoin( sativa ) {
		if( this.coin.address.length >= 34) {
			this.s=true; this.coin.type = sativa; this.coins = null;
			this.coin.nonce = this.nonce[0].code;
			this.user.saveCoinAddress(this.coin.type, this.coin.address).subscribe( (c) => {
				this.s = false;
				if( c[0].code && c[0].code != 200 ) {
					this.coinError = c[0].message;
					setTimeout( () => {
						this.coinError = c[0].message;
					}, 500);
				} else {
					this.coinsCount = (c[0]) ? c[0].count : 0;
					if ( this.coinsCount > 0 ) this.getCoinAddresses();
					this.addCoin = 0;
				}
			});
		}
	}

	savePayPal() {
		this.saving = true;
		if (!this.paypal.email) { this.callError('pp-address'); }
		if ( this.paypal.email ) {
			this.paypal.nonce = this.nonce[0].code;
			this.user.addPayPal( this.paypal ).subscribe( (pp) => {
				this.addPayPal = 0;
				this.selectedMethod = '';
				if ( pp[0].id ) {
					this.saving = false;
					this.getPayPals();
				}
			});
		}
	}

	saveCheque() {
		this.saving = true;
		this.cheque.address = this.selectedChequeAddress;
		if (!this.cheque.address) { this.callError('cheque-address'); }
		if ( this.cheque.address ) {
			this.cheque.currency = this.accountCurrency;
			this.cheque.nonce = this.nonce[0].code;
			this.user.addCheque( this.cheque ).subscribe( (c) => {
				this.addCheque = 0;
				this.selectedMethod = '';
				if ( c[0].id ) {
					this.saving = false;
					this.getCheques();
				}
			});
		}
	}
	setStripeToken(e){}
	// setStripeToken(e:StripeToken) {
	// 	this.bank.token = e.id;
	// 	if( this.bank.account_holder_name ) {
	// 		this.user.saveBankAccount(this.bank).subscribe( (b) => {
	// 			if( b[0].code && b[0].code != 200 ) {
	// 				this.bankError = b[0].message;
	// 				setTimeout( () => {
	// 					this.bankError = b[0].message;
	// 				}, 500);
	// 			} else { 
	// 				this.bankAccounts = b;
	// 			}
	// 		});
	// 	} else {
	// 		this.bankError = 'Please add the full name of the cardholder.';
	// 	}
	// 	return false;
	// }
	onStripeError(e) {
		this.bankError = e.message;
		return false;
	}

	// Authorization Codes

	resend_auth_codes() {
		this.user.send_withdrawal_sms().subscribe( (a) => {
			if (a[0].valid == true) this.sms_sent = true;
		});
	}

	withdrawal_coin() {
		if( this.check_auth_codes() ) {

		}
	}

	check_auth_codes() {
		if (!this.withdrawal.sms_code&&!this.withdrawal.code){
			this._code_err();
			this._sms_err();
			return false;
		}
		if ( this.withdrawal.code ) {
			if ( this.withdrawal.sms_code ) {
				this.user.check_sms_and_email_key(this.withdrawal.code, this.withdrawal.sms_code).subscribe( (s) => {
					if ( s[0].valid ) {
						setTimeout( () => { this.code_pass = true; },400);
						setTimeout( () => { this.sms_code_pass = true; }, 800);
						setTimeout( () => { this.WithdrawalPage = 3; }, 2000);
						return true;
					} else {
						this._withdrawl_err();
						this._code_err();
						this._sms_err();
					}
				});
			} else {
				this._sms_err();
			}
		} else {
			this._code_err();
		}
	}

	authcode(k,e?) {
		if ( e.key=="Escape" ) {
			this.withdrawal.code = null;
			return;
		} else {
			this.withdrawal.code = this.authcheck(k); 
		}
	}	

	authcodesms(k,e?) {
		if ( e.key=="Escape" ) {
			this.withdrawal.sms_code = null;
			return;
		} else {
			this.withdrawal.sms_code = this.authcheck(k); 
		}
	}

	authcheck(tel) {
		var num = tel.toString().replace(/\D/g,'');
		if (num.length>0) return num.substring(0,3)+' '+num.substring(3,6);
		if (num.length==0) return num;
	}

	_withdrawl_err() {
			this.wd_error = 'Auth Failure';
			setTimeout( () => { this.wd_error = ''; }, 2500);
	}

	_code_err() {
			this.code_error = true;
			setTimeout( () => { this.code_error = false; }, 2500);
	}

	_sms_err() {
		this.sms_code_error = true;
		setTimeout( () => { this.sms_code_error = false; }, 2500);
	}

	// Selectors

	_cm(c) {
		this.coin.address = '';
		this.changeMethod(c);
		this.WithdrawalPage = 1;
	}

	selectAddress( address ) { 
		this.selectedBankAddress = address.id;
	}

	selectChequeAddress( address ) {
		this.selectedChequeAddress = address.id;
	}

	changeMethod( method, bank?, inumb? ) {
		this.addCoin = 0;
		this.addINAcc = 0;
		this.addPayPal = 0;
		this.addCheque = 0;
		this.withdrawalCoin = 0; 
		this.selectedCheque = 0;
		this.selectedPaymentProduct = 0;
		this.chequePayment = false;
		this.selectedMethod = '';
		this.paymentType = '';
		if(inumb)this.bank.institution_number = inumb;
		if(!inumb)this.bank.institution_number = null;
		if( bank ) {this.bank.bank_name = bank;
		} else { this.bank.bank_name = method; }
		if ( method == 'paypal' ) 				this.addPayPal 		=1;
		if ( method == 'interac' ) 				this.addINAcc 		=1;
		if ( method == 'cheque' ) 				this.addCheque 		=1;
		if ( method == 'dash' ) 				this.withdrawalCoin =1;
		if ( method == 'dashcoin' ) 			this.withdrawalCoin =1;
		if ( method == 'litecoin' ) 			this.withdrawalCoin =1;
		if ( method == 'bitcoin' ) 				this.withdrawalCoin =1;
		if ( method == 'potcoin' ) 				this.withdrawalCoin =1;
		if ( method == 'dopecoin' ) 			this.withdrawalCoin =1;
		if ( method == 'ethererium' ) 			this.withdrawalCoin =1;
		if ( method == 'ethererium_classic' ) 	this.withdrawalCoin =1;
		if ( method == 'cannabiscoin' ) 		this.withdrawalCoin =1;
		this.addBankAcc = false; 				this._bow();
		this.selectedMethod = method;
		this.paymentMethod.emit( this.selectedMethod );
	}

	// Status Getters

	getCountries() {
		this.marketplace.countries().subscribe( (c) => {
			this.countries = c;
			this.locale = c[0];
		});
	}

	getCheques() {
		this.marketplace.cheques().subscribe( (c) => {
			this.cheques = c;
			this.locale = c[0];
		});
	}

	getInteracs() {
		this.user.getInterac().subscribe( (int) => {
			this._toss();
			this.interacts = int;
			this.interacCount = (int[0])?int[0].count:0;
		});
	}

	getBankAccounts() {
		this.user.getBankAccounts().subscribe( (a) => {
			this._toss();
			this.bankAccounts = a;
			this.banksCount = (a[0])? a[0].count:0;
			this.minimumWithdrawl = (a[0])? a[0].minimumWithdrawl:this.minimumWithdrawl;
			this.maxAmount = (a[0])? a[0].maximumWithdrawl:this.maxAmount;
		})
	}

	getCoinAddresses() {
		this.user.getCoinOutputAddresses().subscribe( (ap) => {
			this._toss();
			this.coinsCount = (ap[0])?ap[0].count:0;
			if( this.coinsCount ) this.coins = ap;
		})
	}

	getPayPals() {
		this.user.getPayPal().subscribe( (pp) => {
			this._toss();
			this.paypals = pp; 
			this.paypalCount = (pp[0])?pp[0].count:0;
		});
	}

	// Removers

	removePayment(a) {
		this.user.removeBankAccount(a.bank_id).subscribe( (c) => {
			setTimeout( () => { this.getBankAccounts(); }, 400);
		});
	}

	removeInterac( i ) {
		this.user.removeInteracAccount( i.email ).subscribe( (c) => {
			setTimeout( () => { this.getInteracs(); }, 400);
		});
	}

	removePayPal( c ) {
		this.user.removePayPal( c.email ).subscribe( (c) => {
			setTimeout( () => { this.getPayPals(); }, 400);
		});
	}

	removeCoin( c ) {
		this.user.removeCoinAddress( c.address ).subscribe( (c) => {
			setTimeout( () => { this.getCoinAddresses(); }, 400);
		});
	}

	// Style and Logic

	_class(a,b='bank',d='') { return this.bnkc(a,b,d); }

	bnkc(a,c='bank',d='') {
		var b= (this.selectedMethod=='')?c:c+' _bw';
		if(c=='bank') return (this.selectedMethod==c&&this.bank.bank_name==a) ? 'active '+c : b;
		if(c!='bank') return (this.selectedMethod==a) ? 'active '+c+' '+d : b+' '+d;
	}
	
	_toss() {
		this.loaded = true;
	}

	_bnkclass(b) {
		var a = (!b.name&&!b.bank_name)?'hidden_box':'';
		var c = (this.selectedBankAcc == b) ? 'active '+b.bank_name : b.bank_name;
		return (a+' '+c);
	}

	_noacc() {
		if (!this.loaded) return false;
		if (this.interacCount == 0){
			if (this.paypalCount == 0){
				if (this.banksCount == 0){
					if (this.coinsCount == 0){
						return true;
					}
				}
			}
		}
		return false;
	}

	_bow() {
		if (this.ShowIt == 'true') {
			this._sow();
			setTimeout( () => {
				this._bow();
			},700);
			return;
		}
		if ( this.ShowItA=='true' ) {
			this.ShowItA = 'truetrue';
			setTimeout( () => {
				this._bow();
			}, 700);
		} else if (this.ShowItA=='truetrue'){
			this.ShowItA = 'false';
		} else {
			this.ShowItA = 'true';
		}
	}

	_sow() {
		if (this.ShowItA == 'true') {
			this._bow();
			setTimeout( () => {
				this._sow();
			},700);
			return;
		}
		if ( this.ShowIt=='true' ) {
			this.ShowIt = 'truetrue';
			setTimeout( () => {
				this._sow();
			}, 700);
		} else if (this.ShowIt=='truetrue') {
			this.ShowIt = 'false';
		} else {
			this.ShowIt = 'true';
		}
	}

	_btncls(i) {
		var a = 'seperator save process';
		if ( i == 'int' && this.saving ) a = a+' act';
		return a; 
	}
	_btnclss(i) {



		var a = 'seperator save addcoin';
		if ( i == 'int' && this.saving ) a = a+' act';
		return a; 
	}

	_am_plus(s,max=0) {
		if ( max == 0 ) return;
		var i = this._increment_by(s);
		this.withdrawal.amount=(this.withdrawal.amount<this.maxAmount)?this.withdrawal.amount+i:this.withdrawal.amount;
		if(this.withdrawal.amount>max)this.withdrawal.amount=max;
	}

	_am_minus(s) {
		var i = this._increment_by(s);
		this.withdrawal.amount=(this.withdrawal.amount>0)?this.withdrawal.amount-i:this.withdrawal.amount;
		if(this.withdrawal.amount<0)this.withdrawal.amount=0;
	}

	_increment_by(s) {
		var a = null;
		switch (s) {
			case "cann":
			case "pot":
			case "dope":
				a= 10;
			break;
			case "btc":
				a= 0.0001;
			break;
			case "ltc":
			case "etc":
			case "eth":
				a= 0.01;
			break;
			default:
				a= 1;
			break;
		}
		return a;
	}

	getmaxAmount(s) {
		var c=null;
		switch (s) {
			// case "cann":
			// case "pot":
			// case "dope":
			// 	return 10;
			// 	break;
			// case "btc":
			// 	return 0.0001;
			// 	break;
			// case "eth":
			// 	return 0.01;
			// 	break;
			default:
				c= this.maxAmount;
				break;
		}
		return c;
	}

	max() {
		this.user.getMaxOut().subscribe((max) => {
			this.maxAmount = max;
		});
	}

	min() {
		this.user.getMinOut().subscribe((min) => {
			this.minimumWithdrawl = min;
		});
	}

	maxAm() {
		if ( this.accountBalance > this.maxAmount ) {
			return this.maxAmount;
		} else {
			return this.accountBalance;
		}
	}

	iscoin(c?) {
		var a = null;
		if ( c.symbol == 'CANN' ) {
			a= true;
		} else if ( c.symbol == 'POT' ) {
			a= true;
		} else if ( c.symbol == 'LTC' ) {
			a= true;
		} else if ( c.symbol == 'BTC' ) {
			a= true;
		} else if ( c.symbol == 'DOPE' ) {
			a= true;
		} else if ( c.symbol == 'DASH' ) {
			a= true;
		} else if ( c.symbol == 'ETH' ) {
			a= true;
		} else if ( c.symbol == 'ETC' ) {
			a= true;
		} else {
			a= false; 
		}
		return a;
	}

	getAccBalance() {
		this.user.getBalances().subscribe( (bal) => {
			this.accountBalances = bal;
			for (var i = 0; i <= bal[0].count; i++) {
				if (bal[i]&&bal[i].symbol=="ETC") this.etcBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="ETH") this.ethBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="DOPE") this.dopeBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="CANN") this.cannBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="POT") this.potBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="BTC") this.btcBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="DASH") this.dashBalance = bal[i].balance;
				if (bal[i]&&bal[i].symbol=="LTC") this.litecoinBalance = bal[i].balance;
			}
		});
	}
	getNonce() {
		this.user.getNonce().subscribe( (n) => {
			this.nonce = n;
		});
	}
	_noacc_onfile() {
		this.changePaymentInterac('reset');
		this.addBankAcc=(this.addBankAcc)?false:true;
		this.showSelector=1;
		this.selectedPaymentProduct = false
	}

	add_method(a=0) {
		if (!a) setTimeout( () => {this.resetSelection();}, 200);
		if (a) this.resetSelection();
	}

	resetSelection() {
		this.bank.bank_name = '';
		this.selectedMethod = '';
		this.addPayPal = 0; this.addINAcc = 0;
	} 

	getPaymentProducts() {
		this.user.getPaymentProducts().subscribe( (p) => {
			this.loaded = true;
			this.paymentProducts = p;
			if(p[0]){
				this.paymentProductCount = (p[0])?p[0].count:0;
				for (var i = p[0].count - 1; i >= 0; i--) {
					if(p[i].service == "card" && p[i].enabled==1) this.CreditCardEnabled = true;
					if(p[i].service == "stripe" && p[i].enabled==1) this.StripeCardEnabled = true;
					if(p[i].service == "interac" && p[i].enabled==1) this.InteracEnabled = true;
					if(p[i].service == "paypal" && p[i].enabled==1) this.PayPalEnabled = true;
					if(p[i].service == "cann" && p[i].enabled==1) this.CannCoinEnabled = true;
					if(p[i].service == "btc" && p[i].enabled==1) this.BitcoinEnabled = true;
					if(p[i].service == "ltc" && p[i].enabled==1) this.LtcEnabled = true;
					if(p[i].service == "pot" && p[i].enabled==1) this.PotCoinEnabled = true;
					if(p[i].service == "dope" && p[i].enabled==1) this.DopeCoinEnabled = true;
					if(p[i].service == "eth" && p[i].enabled==1) this.EthEnabled = true;
					if(p[i].service == "etc" && p[i].enabled==1) this.EtcEnabled = true;
					if(p[i].service == "dash" && p[i].enabled==1) this.DashCoinEnabled = true;
				}
			}
		});
	}

	phone_save() {
		this.user.send_sms( this.auth.code ).subscribe( (a) => {
			if (a[0].id) {
				this.last_four = true;
			} else {
				this.sms_error = true;
			}
			this.sending_sms = false;
		})
	}

	sms_send() {
		let d = Date.now();
		if (this.last_sms > 0 && d-this.last_sms < 30000) {
			this.error_resending_sms = true;
			setTimeout(()=>{this.error_resending_sms=false;}, 4000);
			return false;
		} this.last_sms = d;
		this.last_four_error = false;
		this.sending_sms = false;
		this.sms_error = false;
		if(this.zph) {
			var lastChar = this.zph.slice(-4);
			if ( this.auth.last_four == lastChar ) {
				this.sending_sms = true;
				this.resending_sms = true;
				this.user.send_sms( this.zph ).subscribe( (a) => {
					if (!a[0].valid) this.sms_error = true;
					if ( a[0].valid) this.last_four = true;
					this.sending_sms = false;
					this.resending_sms = false;
				})
			} else { this.last_four_error = true; }
		}
	}

	sms_authorize() {
		this.authorizing = true;
		this.auth.code = this.auth.code.replace(/\s/g, '');
		this.sending_sms = true;
		this.user.check_auth_key( this.auth.code ).subscribe( (a) => {
			if (a[0].valid == true) {
				this.AuthorizedToWithdrawal = true;
			} else {
				this.sms_auth_error = true;
				this.AuthorizedToWithdrawal = false;
				setTimeout( () => { this.sms_auth_error = false; }, 4000);
			}
			this.user.setSMSTokenAuth(this.AuthorizedToWithdrawal);
			this.sending_sms = false;
			this.authorizing = false;
		})
	}

	ngOnInit() {
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) {
				this.clientEmailAddress = a[0].email;
				if( !this.accountCurrency ) this.accountCurrency = a[0].currency;
				if( !this.zph && a[0].phone != null ) this.zph = a[0].phone;
				if( !a[0].phone || a[0].phone == null ) this.requireSMSAuth = false;
				for( var i = a[0].balance.length - 1; i >= 0; i-- ) {
					if( this.accountCurrency==a[0].balance[i].symbol ) {
						this.selectedCurrencyValue = a[0].balance[i].balance;
					}
				}
				if (this.zph) this.requireSMSAuth = true;
				this._loaded = true;
				this.user.getNonce().subscribe( (n) => {
					this.nonce = n;
				});
			}
		});
		this.min();
		this.max();
		this.getPayPals();
		this.getCheques();
		this.getInteracs();
		this.getCountries();
		this.resizeScreen();
		this.getAccBalance();
		this.resetSelection();
		this.getBankAccounts();
		this.getCoinAddresses();
		this.getPaymentProducts();
		this.withdrawal.amount = 0;
		this.interac.body = 'Order #';
		this.AuthorizedToWithdrawal=this.user.getSMSTokenAuth();
		// this.StripeScriptTag.setPublishableKey( environment.stripeAPI );
		this.timer = setInterval( () => {this.countryCode = this.user.getLocaleCountry();}, 4000);
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}

	callError(method) {
		this.error = method;
		setTimeout( ()=> {
			this.error = '';
		}, 3500);
	}

}

export class NewBank {
   constructor(
	public country: string,
	public currency: string,
	public account_holder_name?: string,
	public account_holder_type?: string,
	public routing_number?: number,
	public institution_number?: number,
	public account_number?: number,
	public bank_name?: string,
	public token?: any,
	public nonce?: string,
	public save?: boolean
   ) {}
}

export class NewInterac {
   constructor(
	public fullname?: string,
	public email?: string,
	public body?: string,
	public auto?: string,
	public word?: string,
	public nonce?: string,
	public amount?: number
   ) {}
}

export class Withdrawal {
   constructor(
	public address?: any,
	public account?: any,
	public amount?: number,
	public currency?: string,
	public word?: string,
	public auto?: boolean,
   	public sms_code?: string,
   	public code?: string,
   	public coin?: string,
   	public nonce?: string,
	public inc?: any
   ) {}
}

export class NewPayPal {
   constructor(
	public email?: string,
	public currency?: string,
   	public nonce?: string,
	public amount?: number
   ) {}
}

export class Coin {
   constructor(
	public type?: string,
	public address?: string,
   	public nonce?: string,
	public amount?: number
   ) {}
}

export class NewAuth {
   constructor(
	public code?: string,
	public phone?: string,
	public last_four?: string,
   ) {}
}

export class NewCheque {
   constructor(
   	public currency?: string,
	public type?: string,
	public address?: string,
   	public nonce?: string,
	public amount?: number
   ) {}
}