import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'sidebar-buttons',
  templateUrl: './sidebar.component.pug',
  styleUrls: ['./sidebar.component.styl']
})
export class SidebarComponentComponent implements OnInit {

	constructor() { }

	ngOnInit() {

	}
}