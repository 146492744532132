import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

export const slideDown = trigger('slideDown', [
    state('true', style({
        'max-height': '200px',
        'opacity': 1
    })),
    state('false', style({
        'max-height': '0px',
        'opacity': 0
    })),
    transition('*=>*', animate('120ms'))
]);

export const spinRound = trigger('spinRound', [
    state('true', style({
        'transform': 'rotate(-180deg)',
        'color': 'rgb(74, 160, 0)',
    	'border-color': '#73cc25'
    })),
    state('false', style({
        'transform': 'rotate(0deg)',
        'color': '#b3b3b3'
    })),
    transition('*=>*', animate('210ms'))
]);
