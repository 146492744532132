import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Account } from '../../models/account';
import { Product } from '../../models/product';
import { Address } from '../../models/address';
import { Order } from '../../models/order';
import { addressPallet, errorstate, full } from '../checkout.animations';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'checkout-complete',
  templateUrl: './checkout.complete.pug',
  styleUrls: ['./checkout.complete.styl'],
  animations: [ addressPallet, errorstate, full ],
  providers: [ MarketplaceService, UserService ]
})
export class CheckoutCompleteComponent implements OnInit {

  constructor( private marketplace: MarketplaceService,
      // private route: Router,
      private route: ActivatedRoute,
			  private user: UserService ) {}

  @Input('paymentType') paymentType: string;
  @Input('orderNumber') orderNumber: any = 0;
  _pc=false;
  c = [false];
  showpwd = false;
  addBankAcc = true;
  addDeposit = true;
  ez_code = '---/---';
  orderPaid = 'false';
  paymentCancelled = false;
  refreshOrders = false;
  currentOrder = true;
  orderExists = -1;
  loaderPercentage = 0;
  orderAmount = '';
  paymentError = '';
  currencyCode = 'CAD';
  accountid:number;
  loaderTimer:any;
  watcherTimer:any;
  expTimer:any;
  orderDate:any;
  orderExp:any;
  orderCountown:any;
  order: Order;
  interac = new NewInterac('','','','error');

  popup( url ) {
    window.open(url,'_blank');
  }

  copi(s=0) {
    this.c[s] = true;
    // setTimeout( () => { this.c[s] = false; }, 3500);
  }

  expire() {
    var start:any = new Date(this.orderDate),
        end:any = new Date(this.orderExp),
        today:any = new Date(), 
    p = Math.round(((today - start) / (end - start)) * 100);
    this.loaderPercentage = p;
  }
  
  countdown_seconds(t=30) {
    var d= new Date();
    d.setSeconds(d.getSeconds()+t)
    this.orderCountown = d;
  }

  getOrderProducts( orderID? ) {
    this.user.getOrder( orderID, 1, 0, this.accountid ).subscribe( (o) => {
      if(o[0]) {
        this.order = o;
        this.secret(orderID);
        this.orderExists=1;
        //this.orderNumber = o[0].id;
        this.paymentType = o[0].ordertype; // 'int';
        this.currencyCode = o[0].currency; // 'CAD';
        this.ez_code = o[0].ez_code; // 'int';
        this.orderAmount = o[0].amount;
        this.orderDate = o[0].created;
        this.orderExp = o[0].payment_expiry;
        this.interac.amount = o[0].amount;
        this.interac.body = 'Order '+orderID;
        this.expTimer = setInterval( () => {
          this.expire();
        },1000);
        if (!o[0].id) this.orderExists=0;
      }
    });
  }

  authorize() {
    this.user.authorize().subscribe( (acc) => {
      this.interac.from = acc[0].email;
      this.accountid = acc[0].accountid;
      this.getOrderProducts(this.orderNumber);
    });
  }

  secret(int) {
    this.user.getsecret(int).subscribe( (s) => {
      if ( s=='id_error'||s=='account_error'||s=='server_error' ) {
        this.paymentType = 'err';
        this.paymentError = s.replace('_',' ');
      } else {
        this.interac.secret = s;
      }
    });
  }

  cancel_card() { this._pc=true;
    this.marketplace.cancel_payment(this.orderNumber).subscribe( (s) => {
      if ( s[0].id==this.orderNumber && s[0].status==402 ) {
        this.paymentCancelled = true; this._pc=false;
      }
    });
  }

  ngOnInit() {
    this.authorize();
    this.interac.email = 'interacs@outlook.com';
    this.interac.amount = this.orderAmount;
    this.interac.currency = this.currencyCode;
    this.orderNumber = this.route.snapshot.paramMap.get("id");
    this.getOrderProducts(this.orderNumber);
    this.startWatch();
  }

  ngOnDestroy() {
    clearInterval(this.loaderTimer);
    clearInterval(this.watcherTimer);
    clearInterval(this.expTimer);
  }

  ngAfterInit() {
    $("html, body").animate({ scrollTop: 0 }, 200);
  }

  checkIfOrderIsPaid(n) {
    if ( this.orderPaid == 'true' ) return;
    this.user.getpaid(n).subscribe( (s) => {
      if ( this.orderPaid != s ) {
        this.orderPaid = s; 
        // $("html, body").animate({ scrollTop: 0 }, 200);
        // setTimeout( () => { 
        //   if(s == 'verification') this.countdown_seconds(30);
        //   if(s == 'true') this.refreshOrders = true;
        //   
        // }, 600 );
      }
    });
  }

  startWatch() {
    this.watcherTimer = setInterval( () => {
      if ( this.orderPaid == 'true' ) {
        clearInterval( this.watcherTimer );
        return;
      } else {
        this.checkIfOrderIsPaid(this.orderNumber);
      }
    }, 5000);
  }

  changeMethod( bank, bankbank ) { 
    switch (bankbank) {
      case 'bmo':
        this.popup("https://www1.bmo.com/onlinebanking/cgi-bin/netbnx/NBmain");
      break;
      case 'cibc':
        this.popup("https://www.cibconline.cibc.com/ebm-resources/public/banking/cibc/client/web/index.html#/signon");
      break;
      case 'desjardins':
        this.popup("https://accweb.mouv.desjardins.com/identifiantunique/identification?domaineVirtuel=desjardins&langueCible=en");
      break;
      case 'hsbc':
        this.popup("https://www.hsbc.ca/online-banking/");
      break;
      case 'laurentian':
        this.popup("https://blcweb.banquelaurentienne.ca/lang/en/BLCDirect/");
      break;
      case 'manulife':
        this.popup("https://www.manulifebank.ca/personal-banking/sign-in.html");
      break;
      case 'motus':
        this.popup("https://www.motusbank.ca/");
      break;
      case 'rbc':
        this.popup("https://www1.royalbank.com/cgi-bin/rbaccess/rbcgi3m01?F6=1&F7=IB&F21=IB&F22=IB&REQUEST=ClientSignin&LANGUAGE=ENGLISH&_ga=2.127570762.691377447.1562987379-2086661565.1562987379");
      break;
      case 'scotiabank':
        this.popup("https://www.scotiaonline.scotiabank.com/online/authentication/authentication.bns");
      break;
      case 'simplii':
        this.popup("https://www.simplii.com/en/banking-simplii/e-transfer.html?utrc=S226:12&&gclid=CjwKCAjwgqbpBRAREiwAF046JfkAmdWSeJ2xN5k51d6elRQ93_uV3nQwViH-5B5UDiAYuH0z2ePqERoC78YQAvD_BwE&gclsrc=aw.ds");
      break;
      case 'tangerine':
        this.popup("https://www.tangerine.ca/app/");
      break;
      case 'td':
        this.popup("https://authentication.td.com/uap-ui/index.html?consumer=easyweb&locale=en_CA#/login/easyweb-getting-started");
      break;
      case 'interac':
        this.popup("https://www.interac.ca/en/interac-e-transfer-consumer.html");
      break;
    }
  }
  bnkc(b?) { return 'candu'; }
}
export class NewInterac {
   constructor(
  public from?: string,
  public email?: string,
  public body?: string,
  public secret?: string,
  public amount?: string,
  public currency?: string,
   ) {}
}