import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Review } from '../../../models/review';

@Component({
  selector: 'admin-reviews',
  templateUrl: './admin-reviews.component.pug',
  styleUrls: ['./admin-reviews.component.styl']
})
export class AdminReviewsComponent implements OnInit {
	isAdmin = false;
	constructor(
		private marketplace: MarketplaceService,
		private userservice: UserService) { }

	reviews: Review;

	getproducts() {
		this.userservice.listReviews().subscribe((us) => {
			this.reviews = us;
		});
	}
	
	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) this.getproducts();
		});
	}

}
