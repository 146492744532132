import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'search',
  templateUrl: './search.component.pug',
  styleUrls: ['./search.component.styl']
})
export class SearchComponent implements OnInit {
  constructor() { }
  search: string;
  ngOnInit() {
  }
}
