import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'home',
  templateUrl: './homepage.component.pug',
  styleUrls: ['./homepage.component.styl']
})
export class HomePageComponent implements OnInit {

  constructor(
    private market: MarketplaceService
  ) { }

  ngOnInit() {

  }

}
