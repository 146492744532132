import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = [];
		for (var i = 0; i < value.length; i++) {
			if ( value[i].lang == args ) values.push(value[i]);
		}
		return values;
	}
}

