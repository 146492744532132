import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wallet-addresses',
  templateUrl: './accounts.component.pug',
  styleUrls: ['./accounts.component.styl']
})
export class WalletAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
