import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'friends-list',
  templateUrl: './friends.list.component.pug',
  styleUrls: ['./friends.list.component.styl']
})
export class FriendsListComponent implements OnInit {

	constructor( 
		private user: UserService,
	) {	}

	ngOnInit() {
	}

}