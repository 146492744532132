import { Component, OnInit } from '@angular/core';
import { Coupon } from '../../../models/coupon';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-coupons',
  templateUrl: './admin-coupons.component.pug',
  styleUrls: ['./admin-coupons.component.styl']
})
export class AdminCouponsComponent implements OnInit {
	isAdmin = false;
	coupons: Coupon;

	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {
		
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listCoupons().subscribe((c) => {
				this.coupons = c;
			});
		}
	}
}