import { Routes } from '@angular/router';

import { AppComponent } from './app.component';

import { AccountComponent } from './account/account.component';
import { LogoutComponent } from './account/logout/logout.component';
import { SignupComponent } from './account/signup/signup.component';
import { RecoverComponent } from './account/recover/recover.component';
import { ChangePassComponent } from './account/recover/change-pass/change.pass.component';
import { NotFoundComponent } from './static/notfound/notfound.component';
import { LoginComponent } from './static/login/login.component';
import { SingleAccountComponent } from './account/account/single.component';
import { UsersPurchasesComponent } from './account/purchases/users-purchases.component';
import { AddProductComponent } from './products/add/add.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './products/single/single.component';
import { SearchComponent } from './products/search/search.component';
import { CartComponent } from './cart/app.cart.sidebar.component';
import { UsersComponent } from './account/users/users.component';
import { ChatComponent } from './account/chat/chat.component';
import { MyAccountComponent } from './account/my.account/my.account.component';
import { AccountUpdatedEmail } from './account/account/updated/updated.component';
import { ActivateComponent } from './account/activate/activate.component';
import { SupportComponent } from './account/support/support.component';
import { NewSupportComponent } from './account/support/new.support/new.support.component';
import { SupportMessageComponent } from './account/support/support.message/support.message.component';
import { ViewTransactionIDComponent } from './account/wallet/view.transaction/view.transaction.component';
import { StrainsComponent } from './static/strains/top.strains.component';
import { LocationsComponent } from './locations/locations.component';
import { WelcomeComponent } from './static/welcome/welcome.component';
import { SignUpComponent } from './static/signup/signup.component';
import { HomePageComponent } from './static/homepage/homepage.component';
import { WarningsComponent } from './static/warnings/warnings.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SocialComponent } from './social/social.component';
import { CheckoutCompleteComponent } from './checkout/checkout.complete/checkout.complete';
import { RulesComponent } from './static/vendor/rules/rules.component';
import { UnavailableContent } from './static/unavailable/unavailable.component';
import { BecomingAVendorComponent } from './static/vendor/becoming-a-vendor/becoming-a-vendor.component';
import { RequestComponent } from './static/vendor/request/request.component';
import { VendorComponent } from './static/vendor/vendor.component';
import { VendorAppComponent } from './vendor/vendor.app.component';
import { TermsComponent } from './static/terms/terms.component';
import { ReceiptComponent } from './static/receipt/receipt.component';
import { ScoreboardComponent } from './static/funnel/scoreboard/scoreboard.component';
import { SplashComponent } from './static/funnel/splash/splash.component';
import { FunnelComponent } from './static/funnel/funnel.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { WalletComponent } from './account/wallet/wallet.component';
import { FavoritesComponent } from './account/favorites/favorites.component';
import { Spinner } from './static/spinner/spinner.effect'
import { FWShowComponent } from './static/welcome/fw-show/fw-show.component';
import { ImageUploader } from './images/imageuploader/imageuploader.component';
import { ItemsComponent } from './items/items.component';
import { AdvertiseComponent } from './advertise/app.advertise.component';
import { ItemSingleComponent } from './items/single/item.single.component';
import { IFrameComponent } from './external/iframe/iframe.component';
import { IFrameExampleComponent } from './external/example/example.iframe.component';
import { IFrameProductComponent } from './external/product/iframe.product.component';
import { IFrameAccountComponent } from './external/account/iframe.account.component';

export const appRoutes: Routes = [
  { path: '', component: HomePageComponent, data: { title: 'Tree Street Cannabis' } },
  { path: 'undefined', component: HomePageComponent, data: { title: 'Tree Street Cannabis' } },
  { path: 'shop', component: HomePageComponent, data: { title: 'Tree Street Cannabis' } },
  { path: 'store', component: HomePageComponent, data: { title: 'Tree Street Cannabis' } },
  { path: 'cart', component: CartComponent, data: { title: 'Tree Street Cart' } },
  { path: 'checkout', component: CartComponent, data: { title: 'Tree Street Checkout' } },
  { path: 'checkout-complete', component: CheckoutCompleteComponent, data: { title: 'Tree Street | Checkout Complete' } },
  { path: 'checkout-complete/:id', component: CheckoutCompleteComponent, data: { title: 'Tree Street | Checkout Complete' } },
  { path: 'checkout-success', component: CheckoutCompleteComponent, data: { title: 'Tree Street | Checkout Successful' } },
  { path: 'checkout-success/:id', component: CheckoutCompleteComponent, data: { title: 'Tree Street | Checkout Successful' } },
  { path: 'category/:id', component: ProductsComponent, data: { title: 'Tree Street' } },
  { path: 'recover', component: RecoverComponent, data: { title: 'Tree Street' } },
  { path: 'change-password', component: ChangePassComponent, data: { title: 'Tree Street' } },
  { path: 'change-password/:key', component: ChangePassComponent, data: { title: 'Tree Street' } },
  { path: 'cancel-change-password/:cancel/:key', component: ChangePassComponent, data: { title: 'Tree Street' } },
  { path: 'strains', component: StrainsComponent, data: { title: 'Tree Street' } },
  { path: 'locations', component: LocationsComponent, data: { title: 'Tree Street' } },
  { path: 'stores', component: LocationsComponent, data: { title: 'Tree Street' } },
  { path: 'shops', component: LocationsComponent, data: { title: 'Tree Street' } },

  { path: 'register', component: SignUpComponent, data: { title: 'Tree Street' } },
  { path: 'register/:id', component: SignUpComponent, data: { title: 'Tree Street' } },
  { path: 'signup', component: SignUpComponent, data: { title: 'Tree Street' } },
  { path: 'sign-up', component: SignUpComponent, data: { title: 'Tree Street' } },
  { path: 'signup/:id', component: SignUpComponent, data: { title: 'Tree Street' } },
  { path: 'login', component: LoginComponent, data: { title: 'Tree Street' } },
  { path: 'logout', component: LogoutComponent, data: { title: 'Tree Street' } },
  { path: 'logout/:fwdpath', component: LogoutComponent, data: { title: 'Tree Street' } },
  
  { path: 'stand', component: VendorAppComponent, data: { title: 'Tree Street' } },
  { path: 'vendor', component: SingleAccountComponent, data: { title: 'Tree Street' } },
  { path: 'vendor/:id', component: SingleAccountComponent, data: { title: 'Tree Street' } },
  { path: 'products', component: ProductsComponent, data: { title: 'Tree Street' } },
  { path: 'product', component: ProductComponent, data: { title: 'Tree Street' } },
  { path: 'product/:id', component: ProductComponent, data: { title: 'Tree Street' } },
  
  { path: 'users', component: UsersComponent, data: { title: 'Tree Street' } },
  { path: 'user/:id', component: SingleAccountComponent, data: { title: 'Tree Street' } },
  { path: 'user', component: SingleAccountComponent, data: { title: 'Tree Street' } },
  { path: 'chat', component: ChatComponent, data: { title: 'Tree Street' } },
  { path: 'messages', component: ChatComponent, data: { title: 'Tree Street' } },
  
  { path: 'social', component: SocialComponent, data: { title: 'Tree Street Social Wall' } },
  { path: 'new', component: AddProductComponent, data: { title: 'Tree Street | New Product' } },
  { path: 'new/id', component: AddProductComponent, data: { title: 'Tree Street | New Product' } },
  { path: 'create', component: AddProductComponent, data: { title: 'Tree Street | New Product' } },
  { path: 'create/:id', component: AddProductComponent, data: { title: 'Tree Street | New Product' } },
  { path: 'activate', component: ActivateComponent, data: { title: 'Tree Street | Activate Account' } },
  { path: 'activate/:id', component: ActivateComponent, data: { title: 'Tree Street | Activate Account' } },
  { path: 'edit/:id', component: AddProductComponent, data: { title: 'Tree Street | Edit Product' } },
  { path: 'newproduct', component: AddProductComponent, data: { title: 'Tree Street | New Product' } },
  { path: 'search', component: SearchComponent, data: { title: 'Tree Street | Search' } },
  { path: 'rules', component: RulesComponent, data: { title: 'Tree Street | Rules' } },
  { path: 'becoming_a_vendor', component: BecomingAVendorComponent, data: { title: 'Tree Street | Becoming a Vendor' } },
  { path: 'vendor_request', component: RequestComponent, data: { title: 'Tree Street | Request Vendor' } },
  { path: 'terms', component: TermsComponent, data: { title: 'Tree Street | Terms' } },
  { path: 'privacy', component: TermsComponent, data: { title: 'Tree Street | Terms' } },
  { path: 'receipt/:order', component: ReceiptComponent, data: { title: 'Receipt | Proof of Purchase' } },
  { path: 'unavailable', component: UnavailableContent, data: { title: 'Tree Street | Unavailable' } },
  { path: 'fw', component: FWShowComponent, data: { title: 'Tree Street | Fire' } },
  { path: 'wallet', component: WalletComponent, data: { title: 'Tree Street | Wallet' } },
  { path: 'wallet/:page', component: WalletComponent, data: { title: 'Tree Street | Wallet' } },
  { path: 'favorites', component: FavoritesComponent, data: { title: 'Tree Street | Favorites' } },  
  { path: 'scoreboard', component: ScoreboardComponent, data: { title: 'Tree Street | Scoreboard' } },
  { path: 'funnel', component: FunnelComponent, data: { title: 'Tree Street | Buy & Sell' } },
  { path: 'splash', component: SplashComponent, data: { title: 'Tree Street | Buy & Sell' } },
  { path: 'my', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'account', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'account/:page', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'account-updated/:mode', component: AccountUpdatedEmail, data: { title: 'Tree Street | Account Updated' } },
  { path: 'account-updated/:mode/:email', component: AccountUpdatedEmail, data: { title: 'Tree Street | Account Updated' } },
  { path: 'myaccount', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'myaccount/:page', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/:page', component: MyAccountComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support', component: SupportComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/new', component: NewSupportComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/edit', component: NewSupportComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/new/:id', component: NewSupportComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/edit/:id', component: NewSupportComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/ticket/:id', component: SupportMessageComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/ticket/:id/:selected', component: SupportMessageComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/message/:id', component: SupportMessageComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'my-account/support/message/:id/:selected', component: SupportMessageComponent, data: { title: 'Tree Street | My Account' } },
  { path: 'view-transaction', component: ViewTransactionIDComponent, data: { title: 'Tree Street | View Transaction ID' } },
  { path: 'view-transaction/:id', component: ViewTransactionIDComponent, data: { title: 'Tree Street | View Transaction ID' } },
  { path: 'view-transaction/:coin/:id', component: ViewTransactionIDComponent, data: { title: 'Tree Street | View Transaction ID' } },
  { path: 'warning', component: WarningsComponent, data: { title: 'Tree Street | Package Warnings' } },
  { path: 'warnings', component: WarningsComponent, data: { title: 'Tree Street | Package Warnings' } },
  { path: 'items', component: ItemsComponent, data: { title: 'Tree Street | Products' } },
  { path: 'advertise', component: AdvertiseComponent, data: { title: 'Tree Street | Advertise' } },

  { path: 'iframe/:id', component: IFrameComponent, data: { title: 'Online Shop' } },
  { path: 'iframe_product/:id', component: IFrameProductComponent, data: { title: 'Online Shop | Product' } },
  { path: 'iframe_account/:id', component: IFrameAccountComponent, data: { title: 'Online Shop | Account' } },
  { path: 'iframe_example/:id', component: IFrameExampleComponent, data: { title: 'Online Shop' } },
  { path: 'iframe_example', component: IFrameExampleComponent, data: { title: 'Online Shop' } },
  
  { path: 'img', component: ImageUploader, data: { title: 'Image Uploader' } },

  { path: 'r/:refer', component: WelcomeComponent, data: { title: 'Tree Street' } },
  { path: '404', component: NotFoundComponent, data: { title: 'Tree Street' } },
  { path: ':id', component: SingleAccountComponent, data: { title: 'Tree Street | Buy & Sell | View User' } },
  { path: '**', component: SingleAccountComponent, data: { title: 'Tree Street | Buy & Sell | Page Not Found' } }
];