import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { tap } from "rxjs/operators";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor() { }
  //function which will be called for all http calls
  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    // how to update the request Parameters
    const updatedRequest = request.clone({
      headers: request.headers.set("Authorization", "Some-dummyCode")
    });

    console.log(request);

    //logging the updated Parameters to browser's console
    console.log("Before making api call : ", updatedRequest);

    return next.handle(request).pipe(tap( 
        (event) => {
          if (event instanceof HttpResponse) {
            console.log("api call success :", event);
          }
        }, 
        (error) => {
          if (event instanceof HttpResponse) {
            console.error(event);
          }
        }));
  }
}