import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Order } from '../../../models/order';

@Component({
  selector: 'admin-gifts',
  templateUrl: './admin-gifts.component.pug',
  styleUrls: ['./admin-gifts.component.styl']
})
export class AdminGiftsComponent implements OnInit {
	isAdmin = false;
	
	gifts: Order;
	enalrgedImage: any;
	constructor(private userservice: UserService) { }

	getusers() {
		this.userservice.listGifts().subscribe((gifs) => {
			this.gifts = gifs;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.getusers();
			}
		});
	}
}
