import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ad-funnel',
  templateUrl: './funnel.component.pug',
  styleUrls: ['./funnel.component.styl']
})
export class FunnelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
