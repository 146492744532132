import { Component, HostListener, NgZone, OnInit, ViewChild, ElementRef, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { productMenu, ovL, properties, showCart, flash, nextImageA, lastImageA, image, picture, titlespot } from '../../products/single/single.animations';
import { red } from '../../products/products.animations';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Product } from '../../models/product';
import { Info } from '../../models/info';
import { Item } from '../../models/item';
import { PaymentMethod } from '../../models/paymentmethod';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'item',
  templateUrl: './item.single.component.pug',
  styleUrls: ['./item.single.component.styl'],
  animations: [ red, image, picture, titlespot, productMenu, properties, showCart, flash, ovL, nextImageA, lastImageA ]
})
export class ItemSingleComponent implements OnInit {

	// @Output('cartUpdate') = new EventEmitter():

	constructor(
  		private router: Router,
  		private location: Location,
  		private route: ActivatedRoute,
  		private marketplace: MarketplaceService,
  		private user: UserService,
  		private ngZone: NgZone
  	) { 

		  router.events.subscribe( (event: Event) => {
            //if (event instanceof NavigationStart)

            if (event instanceof NavigationEnd)
     			this.changeProduct();
            //if (event instanceof NavigationError)
              
        });

  	}

  	id: any;
  	cart: string;
  	imagePage = 0;
  	totalImages = 1;
  	TAX_PER_GRAM = 0;
  	FEE_PER_GRAM = 0;
  	SHIPPING = 10;
  	items: Item;
  	cartInfo: Info;
	otherItems: Item;
	authorsItems: Item;
	newReview = new Review();
	newQuestion = new Question();
	currencyDelimiter = '$';
	totalWegihtInCart: number;
	nextProductTitle: string;
	inititalQuantity = 0;
	showConcentration = false;
	showproperties = false;
	productMenu = false;
	itemsInCart = false;
	isFavorite = false;
	selectedunit = [];
	inCart = false;
	loaded = false;
	htmlView = true;
	taxtotal: number;
	authorID: number;
	productID: number;
	flashItem = null;
	cartProducts: any;
	authorName: any;
	autoCart: any;
	selected: any;
	oldScroll = 0;
	scrollY = 0;
	cart_count = 0;
	totalprice = 0;
	totalweight = 0;
	shOv = 'false';
	maximgheight = 'auto';
	sM = true;
	iM = 'false';
	
	si = 0; // selectedimage: number=0;
	ap = 0; // account's products: number=0;
	apc = 0;
	cw = '';
	ch = '';
	view = [300, 300];

	t = new Array();
	dots = new Array();
	hover = new Array();
	cartContents: Product;
	paymentoptions: PaymentMethod;
	single = new SingleProduct(1,'g');
	DotMatrics(a) {for(var l=1;l<=a;l++){this.dots.push(l);}}//<3

	@ViewChild('container') container: ElementRef;
	@ViewChild('Image') image: ElementRef;

	back() {
		this.router.navigate(['/items'])
	}

	next() {
		this.router.navigate(['/edit'])
	}

	viewCart() {

	}

	nextImage() {
		this.imagePage=(this.imagePage>0)?this.imagePage-1:this.imagePage;
	}
	prevImage() {
		this.imagePage=(this.imagePage < this.totalImages-1)?this.imagePage+1:this.imagePage;
	}


	selectUnit(o,si?) {

		let i = this.selectedunit.indexOf(o.name);

		// Select multiple units at a time...
		// if (i > -1) {
		// 	if(si) this.si = 0;
		// 	this.totalprice = Number(Number(this.totalprice) - Number(o.price));
		// 	this.totalweight = Number(Number(this.totalweight) - Number(o.weight));
		// 	this.selectedunit.splice(i,1);
		// } else { 
		// 	if(si) this.si = (si <= this.totalImages) ? si : 0;
		// 	this.totalprice = Number(Number(this.totalprice) + Number(o.price));
		// 	this.totalweight = Number(Number(this.totalweight) + Number(o.weight));
		// 	this.selectedunit.push(o.name);
		// }

		// Select one unit at a time...
		if (i > -1) {
			this.selectedunit = [];
			this.totalprice = Number(0);
			this.totalweight = Number(0);
		} else {
			this.selectedunit = [];
			if(si) this.si = (si <= this.totalImages) ? si : 0;
			this.totalprice = Number(Number(o.price));
			this.totalweight = Number(Number(o.weight));
			this.selectedunit.push(o.name);
		}

	}

	unit_selected(o) {
		if ( !this.selectedunit ) return false
		let i = this.selectedunit.indexOf(o.name);
		return (i>-1)?true:false;
	}

	getPaymentOptions() {
		this.marketplace.paymentOptions().subscribe((opt) => {
			this.paymentoptions = opt;
		});
	}

	edit($event, a) {
		this.router.navigate(['/edit',a.id])
	}


	publish($event, a) {
		this.marketplace.publish( a ).subscribe( ( e ) => {
			console.log(e);
			if(e) {
				this.getMarketProduct();
			}
		})
	}

	unpublish($event, a) {
		this.marketplace.unpublish( a ).subscribe( ( e ) => {
			console.log(e);
			if(e) {
				this.getMarketProduct();
			}
		})
	}

	getCart() {
		this.cartProducts = this.marketplace.parseCart();
		
		this.marketplace.products((this.cartProducts)?this.cartProducts:'-1').subscribe( (c) => {
			this.cartContents = c;
			this.itemsInCart = (c[0]) ? true : false;
		});


		this.marketplace.cartInfo().subscribe( (c) => {
			this.cartInfo = c;
			this.totalWegihtInCart = c[0].weight;
			this.cart_count = c[0].length;
		});
	}

	addToCart(product, quantity = this.single.quantity) {
		if ( this.items[0].scaled == 'fixed' ) {
			for (var i = 0; i < Object(this.selectedunit).length; i++) {
				if ( isNaN(this.selectedunit[i]) ) {
					this.marketplace.addToCart( product, quantity, '', false, false, this.selectedunit[i]  );
				} else {
					this.marketplace.addToCart( product, quantity, this.items[0].unit, this.selectedunit[i]  );
				}
			}
		} else {
			this.marketplace.addToCart(product, quantity);
		}

		this.getCart();
	}


	flash( id ) {
		if ( !this.flashItem ) {
			this.flashItem = id;
			setTimeout( () => {
				this.flashItem = null;
			}, 700 );
		}
	}


	getAuthorsItems(id) {
		// this.marketplace.randomproducts(null,id).subscribe( (ap) => {
		// 	this.authorsItems = ap;
		// 	this.apc = ap[0].count;
		// });
	}

	taxrig(item?) {
		if ( item ) this.single.quantity = (this.single.quantity>item.quantity) ? item.quantity : this.single.quantity;
		this.taxtotal = (this.single.quantity * this.TAX_PER_GRAM);
	}

	// getCartAuto() { 
	// 	this.autoCart = setInterval( () => {
	// 		if ( this.cartProducts != this.marketplace.cart() ) this.getCart();
	// 	}, 750);
	// 	this.getCart();
	// }

	favorite() {
		this.marketplace.favorite( String(this.productID) ).subscribe( (bool) => {
			this.isFavorite = (this.isFavorite) ? false : true;
			this.shOv = 'fav';
		});
	}

	getMarketProduct() {
		this.loaded = false;
		this.marketplace.item(this.id).subscribe((r) => {
			this.items = r;
			this.productID = r[0].id;
			this.authorID = r[0].accountid;
			this.authorName = r[0].account[0].username;
			this.totalImages = r[0].imagecount;
			this.isFavorite = r[0].favorite;
			this.loaded = true;
			this.getAuthorsItems(r[0].accountid);
			this.DotMatrics(r[0].imagecount);
			
			// this.ugn(r[0].id);
			// console.log(r[0].thc);
			// console.log(r[0].total_thc);
			// console.log(r[0].total_cbd);
			// console.log(r[0].cbd);
			if( r[0].thc && r[0].total_thc && r[0].total_cbd && r[0].cbd ) {
				if ( r[0].thc != '0.000000' || r[0].total_thc != '0.000000' || r[0].total_cbd != '0.000000' || r[0].cbd != '0.000000' ) {
					this.showConcentration = true;
				}
			}

			setTimeout( () => {this.rsz();}, 200);
		});
	}

	ugn(id) {
		let nc = this.marketplace.numberInCart(id);
		this.single.quantity = nc;
		this.inititalQuantity = nc; 
	}

	ucb() { return ((this.single.quantity != this.inititalQuantity) || (this.single.quantity==0)); }

	arrowup(prod) {
		this.single.quantity = (this.items[0].quantity > this.single.quantity) ? this.single.quantity + 1 : this.single.quantity;
		this.addToCart(this.productID, this.single.quantity);
	}

	arrowdown(prod) {
		this.single.quantity = (this.single.quantity > 0) ? this.single.quantity - 1 : this.single.quantity;
		this.addToCart(this.productID, this.single.quantity);
	}

	prevProduct() {
		this.marketplace.getPrevProduct(this.id).subscribe( (numb) => {
			this.changeProduct(numb, true);
		});
	}
	nextProduct() {
		this.marketplace.getNextProduct(this.id).subscribe( (numb) => {
			this.changeProduct(numb, true);
		});
	}

	changeProduct(id?, s=true) {
		this.id = (id) ? id : this.route.snapshot.paramMap.get("id");
		this.getMarketProduct();
		if ( s ) $("html, body").animate({ scrollTop: 0 }, "fast");
	}

	favoriteAuthor(p) {
		this.user.favoriteauthor(p.id).subscribe( (bool) => {
			if ( bool ) p.favorite = (p.favorite) ? false : true;
		});
	}

	isAuthorFavorite( p ) {
		return this.user.isauthorfavorite(p.id);
	}

	checkout() {
		this.router.navigate(['cart']);
	}

	ngOnInit() {

		this.location.subscribe((x) => {
			this.changeProduct();
		});
		
		this.getMarketProduct();
		this.getPaymentOptions();
		this.getCart();
		this.taxrig();
		
		this.nextProductTitle = 'true';
		setTimeout( () => {this.rsz();}, 300);
		setTimeout( () => {this.rsz();}, 700);
		setTimeout( () => {this.rsz();}, 1000);
		$("html, body").animate({ scrollTop: 0 }, "fast");
	}




	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.rsz();
	}



	@HostListener('document:keyup', ['$event'])
	onKeyupHandler(event) {
		setTimeout( () => {
			this.shOv = 'false';
		}, 2200)
	}
	@HostListener('document:keydown', ['$event'])
	onKeydownHandler(event) {
		if ( event.code == 'ArrowUp' || event.code == 'KeyX' ) {
			this.ap=(this.ap>0)?this.ap-1:0;
		}
		if ( event.code == 'ArrowDown' || event.code == 'Period' ) {
			this.ap=(this.ap<this.apc-3)?this.ap+1:this.ap;
		}
		if ( event.code == 'KeyC' || event.code == 'KeyK' ) {
			this.router.navigate(['/cart']);
		}
		if ( event.code == 'KeyS' ) {
			let t = $(window).scrollTop() + 120;
			$('html,body').animate({ scrollTop: t }, 'fast');
		}
		if ( event.code == 'KeyW' || event.code == 'KeyL' ) {
			this.chM();
			$("html, body").animate({ scrollTop: 0 }, "fast");
			this.shOv = 'img';
		}
		if ( event.code == 'KeyA' ) {
			this.prevProduct();
			this.shOv = 'prev';
		}
		if ( event.code == 'KeyD' ) {
			this.nextProduct();
			this.shOv = 'next';
		}
		if ( event.code == 'KeyF' ) {
			this.favorite();
		}
		if ( event.code == 'ControlLeft' || event.code == 'ShiftLeft'  || event.code == 'ControlRight' || event.code == 'ShiftRight' ) {
			this.shOv='ctl';
		}
	}

	chM() {
		// if ( !this.iM || this.iM=='false' ) { this.iM = 'large'; }
		// else if ( this.iM=='large' ) { this.iM = 'true'; }
		// else if ( this.iM=='true' ) { this.iM = 'round'; }
		// else if ( this.iM=='round' ) { this.iM = 'false'; }
		if ( !this.iM || this.iM=='false' ) { this.iM = 'round'; }
		else if ( this.iM=='round' ) { this.iM = 'false'; }
	}

	rsz() {
		if( !this.container ) return;
		let a = this.container.nativeElement.offsetWidth;
		let b = (a > 70) ? a-20 : 100;
		
		if ( a < 768 ) {
			this.sM = false;
		} else {
			this.sM = true;
		}

		if ( a < 768 ) {
			this.cw = b+'px';
			this.ch = b+'px';
		} else { 
			this.cw = '';
			this.ch = '';
		}
	}



}


export class Question {
	constructor(
		public body?: string
	) {}
}

export class Review {
	constructor(
		public body?: string,
		public rating?: any
	) {}
}

export class SingleProduct {
	constructor(
		public quantity?: number,
		public unit?: string,
		public offer?: number
	) {}
}