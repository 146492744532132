import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'featured'
})
export class FeaturedPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if ( args && value) {
			let values = [];
			for (var i = value.length - 1; i >= 0; i--) {
				if ( value[i].homepagefeatured ) values.push(value[i]);
			}
			return values;
		} else { 
			return value;
		}
	}
}
