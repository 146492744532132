import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketplaceService } from '../../../services/marketplace.service';
import { UserService } from '../../../services/user.service';
import { Category } from '../../../models/category';
import { Order } from '../../../models/order';
import { Payment } from '../../../models/payment';

@Component({
  selector: 'admin-withdrawls',
  templateUrl: './admin-withdrawls.component.pug',
  styleUrls: ['./admin-withdrawls.component.styl']
})
export class AdminWithdrawlsComponent implements OnDestroy, OnInit {
	
	constructor(private marketplace: MarketplaceService,
				private sanitizer: DomSanitizer,
				private userservice: UserService) { }

	isAdmin = false;
	paymentStatus: any;
	uncaptured_payments: any;
	timer: any;
	withdrawls: Payment;

	get_withdrawls() {
		this.userservice.getWithdrawls().subscribe( (s) => {
			this.withdrawls = s;
		});
	}

	ngOnInit() {
		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
			if ( this.isAdmin ) {
				this.get_withdrawls();
				this.timer = setInterval( () => {
					this.get_withdrawls();
				}, 20000);
			}
		});
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}