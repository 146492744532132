import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.pug',
  styleUrls: ['./loader.component.styl']
})
export class LoaderComponent implements OnInit {

	width: number;

	constructor() { }

	ngOnInit() {
		this.width = 0;
	}

}
