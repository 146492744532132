import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { welcomebox, inputError } from '../../app.animations';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.pug',
  styleUrls: ['./signup.component.styl'],
    animations: [ inputError, welcomebox ]
})

export class SignupComponent implements OnInit {

  years: any[];
  year: any;
  months: any[];
  month: any;
  days: any[];
  day: any;

	constructor(
 		private router: Router,
     private route: ActivatedRoute,
      	private user: UserService) { 
  }

	newUser = new Register()

  emailError = false;
  usernameError = false;
  passwordError = false;
  passwordConfirmationError = false;

	register() {
		this.router.navigate(['/signup']);
	}

	login() {
		this.user.login( this.newUser.email, this.newUser.password );
	}

  username() {
    console.log( this.user.checkUser(this.newUser.name) );
  }

  email() {
    console.log( this.user.checkEmail(this.newUser.email) );
  }

  ngOnInit() {
    this.newUser.email = this.route.snapshot.paramMap.get('email');
  }

}

export class Register {
  constructor(
    public email?: string,
    public name?: string,
    public password?: string, 
    public confirmation_password?: string, 
    public legal?: boolean,
    public recaptcha?: boolean
  ) {  }
}