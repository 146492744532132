import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClientModule, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Rx';
import { UserService } from './user.service';
import { Image } from '../models/image';
import { MarketplaceService } from './marketplace.service';
import { Country, Address, UserAddress, Locale } from '../models/address';
import { Model, Year, Brand, Car } from '../models/car';
import { Driver } from '../models/driver';
import { Marker } from '../models/marker';
import { DriverStatus } from '../models/driverstatus';
import { Earning } from '../models/earning';
import { Balances } from '../models/balances';
import { Mission } from '../models/mission';
import { Trip } from '../models/trip';
import { Order } from '../models/order';
import { Review } from '../models/review';
@Injectable()
export class DriverService {
	private baseUrl: string = environment.base;
	stop_location = 1;
	alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	position = {};
	constructor( private http 		: Http,
				 private user 		: UserService,
				 private marketplace: MarketplaceService,
				 private httpClient : HttpClientModule,
				 private cookie 	: CookieService ) { }


	whos_driver(accountid): Observable<Driver> { 
		var Data = `${this.baseUrl}driver/id/`;
		var Authorization = new Headers();
			Authorization.append('Authorization', this.user.wib());
		var Account = new URLSearchParams();
			Account.append('accountid', accountid);
		var Operation = new RequestOptions({ headers: Authorization });
		var Result = this.http
			.post(Data, Account, Operation)
			.map(mapDrivers)
			.catch(nocrash)
		return Result;
	}
	locate(cb) {
		if (this.stop_location == 1) { return; }
		else if (this.stop_location == 0 && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition( (Ge) => {
				var B = Ge.coords.latitude;
				var C = Ge.coords.longitude;
				if (B && C) {
					this.position = { lat: B, lng: C };
				} else { this.position = { lat: 1,lng: 1 };}
				if (cb) cb(this.position); 
				if (!cb) return this.position;
			}, this.locationError);
		} else {
			alert("Tree St. is not supported by this browser. :(");
			cb(false);
		}
	}
	emit_location( lat, lng ): Observable<Driver> {
		var Url = `${this.baseUrl}driver/location/`;
		var Head = new Headers();
		var lat, lng;
		var Params = new URLSearchParams();
			Params.append('lat', lat);
			Params.append('lng', lng);
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Status = this.http
			.post(Url, Params, Opts)
			.map(mapDrivers)
			.catch(nocrash)
		return Status;
	}
	driversOnline(): Observable<Driver> { 
		var Positon = `${this.baseUrl}driver/`;
		var Authorization = new Headers();
			Authorization.append('Authorization', this.user.wib());
		var Options = new RequestOptions({ headers: Authorization });
		var Operation = this.http
			.get(Positon, Options)
			.map(mapDrivers)
			.catch(nocrash)
		return Operation;
	}
	status( lat, lng ): Observable<Driver> {
		var Url = `${this.baseUrl}driver/status/`;
		var Head = new Headers();
		var Params = new URLSearchParams();
			if(lat > 0) Params.append('lat', lat);
			if(lng > 0) Params.append('lng', lng);
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Status = this.http
			.post(Url, Params, Opts)
			.map(mapDrivers)
			.catch(nocrash)
		return Status;
	}
	fill_ride(): Observable<Driver> { 
		var Loca = `${this.baseUrl}driver/new/`;
		var Fuel = new Headers();
			Fuel.append('Authorization', this.user.wib());
		var Tank = new RequestOptions({ headers: Fuel });
		var Burn = this.http.get(Loca, Tank).map(mapDrivers).catch(nocrash)
		return Burn;
	}
	directions(): Observable<Locale> {
		var Url = `${this.baseUrl}driver/directions/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var order = this.http
			.get(Url, Opts)
			.map(mapLocation)
			.catch(nocrash)
		return order;
	}
	getDirections(loc = '', lat,lng): Observable<Locale> {
		var Url = `${this.baseUrl}driver/custom/directions/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('from_lat', lat);
			Params.append('from_lng', lng);
			Params.append('to', loc);
		var Opts = new RequestOptions({ headers: Head });
		var order = this.http
			.post(Url,Params, Opts)
			.map(mapLocation)
			.catch(nocrash)
		return order;
	}
	find_drivers(): Observable<Driver> {
		var Ganj = `${this.baseUrl}driver/account/search/`;
		var Shop = new Headers();
			Shop.append('Authorization', this.user.wib());
		var Head = new RequestOptions({ headers: Shop });
		var FindDrivers = this.http
			.get(Ganj, Head)
			.map(mapDrivers)
			.catch(nocrash)
		return FindDrivers;
	}
	getTrips(): Observable<Trip> {
		var Ganj = `${this.baseUrl}driver/trips/`;
		var Shop = new Headers();
			Shop.append('Authorization', this.user.wib());
		var Head = new RequestOptions({ headers: Shop });
		var GoodTrip = this.http
			.get(Ganj, Head)
			.map(mapTrips)
			.catch(nocrash)
		return GoodTrip;
	}
	getCars(): Observable<Car> {
		var wheel = `${this.baseUrl}driver/cars/`;
		var cloud = new Headers();
			cloud.append('Authorization', this.user.wib());
		var head = new RequestOptions({ headers: cloud });
		var GoodRide = this.http
			.get(wheel, head)
			.map(mapCars)
			.catch(nocrash)
		return GoodRide;
	}
	getBalance(): Observable<Balances> {
		var Url = `${this.baseUrl}driver/balance/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Bal = this.http
			.get(Url, Opts)
			.map(mapBalance)
			.catch(nocrash)
		return Bal;
	}
	get_work_permit(): Observable<string> {
		var Url = `${this.baseUrl}driver/permit/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var workpermit = this.http
			.get(Url, Opts)
			.map(getPermit)
			.catch(nocrash)
		return workpermit;
	}
	getEarnings(): Observable<Earning> {
		var Money = `${this.baseUrl}driver/earnings/`;
		var Custard = new Headers();
			Custard.append('Authorization', this.user.wib());
		var RULE = new RequestOptions({ headers: Custard });
		var CREAM = this.http
			.get(Money, RULE)
			.map(mapEarnings)
			.catch(nocrash)
		return CREAM;
	}
	earnings(): Observable<Earning> {
		var Url = `${this.baseUrl}driver/live/earnings/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var cash = this.http
			.get(Url, Opts)
			.map(mapEarnings)
			.catch(nocrash)
		return cash;
	}
	missions(): Observable<Mission> {
		var Url = `${this.baseUrl}driver/missions/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var mish = this.http
			.get(Url, Opts)
			.map(mapMissions)
			.catch(nocrash)
		return mish;
	}
	orders(): Observable<Order> {
		var Url = `${this.baseUrl}driver/orders/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var mish = this.http
			.get(Url, Opts)
			.map(mapOrders)
			.catch(nocrash)
		return mish;
	}	
	neworders(): Observable<Order> {
		var Url = `${this.baseUrl}driver/orders/new/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var mish = this.http
			.get(Url, Opts)
			.map(mapOrders)
			.catch(nocrash)
		return mish;
	}
	trips(): Observable<Trip> {
		var Url = `${this.baseUrl}driver/trips/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var mish = this.http
			.get(Url, Opts)
			.map(mapTrips)
			.catch(nocrash)
		return mish;
	}	
	newtrips(): Observable<Trip> {
		var Url = `${this.baseUrl}driver/trips/new/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var mish = this.http
			.get(Url, Opts)
			.map(mapTrips)
			.catch(nocrash)
		return mish;
	}
	acceptOrder(o): Observable<Trip> {
				var Url = `${this.baseUrl}driver/orders/accept/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('order', o.id);
		var Opts = new RequestOptions({ headers: Head });
		var rev = this.http
			.post(Url, Params, Opts)
			.map(mapTrip)
			.catch(nocrash)
		return rev;
	}
	getReviews(): Observable<Review> {
		var Url = `${this.baseUrl}driver/reviews/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var rev = this.http
			.get(Url, Opts)
			.map(mapReviews)
			.catch(nocrash)
		return rev;
	}
	activate(): Observable<Driver> {
		var Place = `${this.baseUrl}driver/go/online/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var InThe = new RequestOptions({ headers: Head });
		var Clouds = this.http
			.get(Place, InThe)
			.map(mapDrivers)
			.catch(nocrash)
		return Clouds;
	}
	deactivate(): Observable<Driver> {
		var Url = `${this.baseUrl}driver/go/offline/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var offlinedriver = this.http
			.get(Url, Opts)
			.map(mapDrivers)
			.catch(nocrash)
		return offlinedriver;
	}
	years(): Observable<Year>{
		var Url = `${this.baseUrl}driver/car/years/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var offlinedriver = this.http
			.get(Url, Opts)
			.map(mapYears)
			.catch(nocrash)
		return offlinedriver;
	}
	makes(year): Observable<Brand>{
		var Url = `${this.baseUrl}driver/car/makes/`;
		var Params = new URLSearchParams();
			Params.append('year', year);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var offlinedriver = this.http
			.post(Url, Params, Opts)
			.map(mapBrands)
			.catch(nocrash) 
		return offlinedriver;
	}
	models(year,make): Observable<Model>{
		var Url = `${this.baseUrl}driver/car/models/`;
		var Params = new URLSearchParams();
			Params.append('year', year);
			Params.append('make', make);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var offlinedriver = this.http
			.post(Url, Params, Opts)
			.map(mapModels)
			.catch(nocrash)
		return offlinedriver;
	}
	save_car(year, make, model, plate='', color='', name=''): Observable<Car> {
		var Url = `${this.baseUrl}driver/car/save/`;
		var Head = new Headers();
		var Params = new URLSearchParams();
			Params.append('year', year);
			Params.append('make', make);
			Params.append('model', model);
			Params.append('plate', plate);
			Params.append('color', color);
			Params.append('name', name);
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Status = this.http
			.post(Url, Params, Opts)
			.map(mapCars)
			.catch(nocrash)
		return Status;
	}
	getDriverImages(id, type): Observable<Image> {
		var Params = new URLSearchParams();
			Params.append('driverID', id);
			Params.append('type', type);
		var Url = `${this.baseUrl}driver/images/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapImage)
			.catch(nocrash);
		return create;
	}
	getDriverImageIds(id, type) {
		var Params = new URLSearchParams();
			Params.append('driverID', id);
			Params.append('type', type);
			Params.append('output', 'id');
		var Url = `${this.baseUrl}driver/images/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapImageIDs)
			.catch(nocrash);
		return create;
	}
	switchMode(m): Observable<Driver> {
		var Params = new URLSearchParams();
			Params.append('mode', m);
		var Url = `${this.baseUrl}driver/cycling/mode/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapDrivers)
			.catch(nocrash);
		return create;
	}
	locationError(error) {
		switch (error.code) {
			case 3:
				console.log(error);
			break;
			case 2:
				console.log(error);
			break;
			case 1:
				console.log(error);
			break;
		}
	}
}
function mapDrivers(response:Response): Driver {
	var res = response.json().drivers.map(toDriver);
	return res;
}
function mapDriverStatus(response:Response): DriverStatus {
	var res = response.json().status.map(toDriverStatus);
	return res;
}
function toDriverStatus(r:any): DriverStatus {
	var route = <DriverStatus>({
		id: r.id,
		name: r.name,
		trips: r.trips,
		online: r.online,
	});
	return route;
}
function toDriver(r:any): Driver {
	var stop = <Driver>({
		id: r.id,
		account_id: r.account_id,
		car_id: r.car_id,
		trip_id: r.trip_id,
		online: r.online,
		driving: r.driving,
		pickup: r.pickup,
		dropoff: r.dropoff,
		location: r.location,
		lat: r.lat,
		lng: r.lng,
		enabled: r.enabled,
		destination: r.destination,
		destination_lat: r.destination_lat,
		destination_lng: r.destination_lng,
		amount: r.amount,
		trips_today: r.trips_today,
		total_trips: r.total_trips,
		total_hours: r.total_hours,
		total_amount: r.total_amount,
		privacy_enabled: r.privacy_enabled,
		total_tips: r.total_tips,
		rating: r.rating,
		lastonline: r.lastonline,
		licence_number: r.licence_number,
		licence_expiry: r.licence_expiry,
		licence_picture: r.licence_picture,
		ownership: r.ownership,
		insurance: r.insurance,
		work_permit: r.work_permit,
		created: r.created,
		modified: r.modified
	});
	return stop;
}
function mapLocation(response:Response): Locale {
	var res = response.json().locate.map(toLocation);
	return res;
}
function toLocation(r:any): Locale {
	var pal = <Locale>({
		id: r.id,
		lat: r.lat,
		lng: r.lng,
		iso: r.iso,
		msg: r.msg,
		city: r.city,
		valid: r.valid,
		country: r.country,
		location: r.location,
		subdivision: r.subdivision,
	});
	return pal;
}
function mapDirections(response:Response): UserAddress {
	var map = response.json().directions.map(toLocation);
	return map;
}
function toDirections(r:any): UserAddress {
	var hoose = <UserAddress>({
		id: r.id,
		fullname: r.fullname,
		account_id: r.account_id,
		vendor: r.vendor,
		address: r.address,
		unit: r.unit,
		country: r.country,
		country_code: r.country_code,
		city: r.city,
		lat: r.lat,
		lng: r.lng,
		position: r.position,
		first_name: r.first_name,
		last_name: r.last_name,
		province: r.province,
		postal: r.postal,
		phone: r.phone,
		note: r.note,
		public: r.public,
		description: r.description,
		images: r.images,
		special_instructions: r.special_instructions,
		open_hours: r.open_hours,
		delivery_available: r.delivery_available,
		pickup_available: r.pickup_available,
		rating: r.rating,
		encrypted: r.encrypted,
		vendor_key: r.vendor_key,
		address_key: r.address_key,
		ip_created: r.ip_created,
		views: r.views,
		sales: r.sales,
		total_revenue: r.total_revenue,
		total_pickups: r.total_pickups,
		total_dropoffs: r.total_dropoffs,
		total_cancellations: r.total_cancellations,
		created: r.created,
		modified: r.modified
	});
	return hoose;
}
function mapBalance(response: Response):Balances {
	return response.json().balance.map(toBalances);
}
function toBalances(r:any): Balances {
    var balances = <Balances>({
        balance: r.balance,
        default: r.default,
        enabled: r.enabled,
        id: r.id,
        name: r.name,
        symbol: r.symbol,
        updated: r.updated,
        count: r.count,
        value: r.value,
    });
    return balances;
}
function mapEarning(response: Response): Earning {
	var ern = response.json().earnings.map(toEarning);
	return ern;
}
function mapEarnings(response:Response): Earning {
	var dola = response.json().earnings.map(toEarning);
	return dola;
}
function toEarning(r:any): Earning {
	var fur = <Earning>({
		id: r.id,
		x: r.x,
		y: r.y
	});
	return fur;
}
function mapModels(response:Response): Model {
	var ndz = response.json().models.map(toModels);
	return ndz;
}
function toModels(r:any): Model {
	var mo = <Model>({
		model: r.model
	});
	return mo;
}
function mapYears(response:Response): Year {
	var re = response.json().years.map(toYears);
	return re;
}
function toYears(r:any): Year {
	var ty = <Year>({
		year: r.year
	});
	return ty;
}
function mapBrands(response:Response): Brand {
	var me = response.json().brands.map(toBrands);
	return me;
}

function toBrands(r:any): Brand {
	var br = <Brand>({
		brand: r.brand
	});
	return br;
}
function mapMissions(response:Response): Mission {
	var map = response.json().missions.map(toMission);
	return map;
}
function toMission(r:any): Mission {
	var mishe = <Mission>({
		id: r.id,
	});
	return mishe;
}
function mapReviews(response:Response): Review {
	var sayword = response.json().reviews.map(toReview);
	return sayword;
}
function toReview(r:any): Review {
	var starr = <Review>({
		author_id: r.author_id,
		vendor_id: r.vendor_id,
		driver_id: r.driver_id,
		product: r.product,
		item: r.item,
		rating: r.rating,
		body: r.body,
		created: r.created
	});
	return starr;
}
function mapCar(response:Response): Car {
	var car = response.json().car.map(toCar);
	return car;
}
function mapCars(response:Response): Car {
	var fleet = response.json().cars.map(toCar);
	return fleet;
}
function toCar(r:any): Car {
	var oooshiny = <Car>({
		id: r.id,
		account_id: r.account_id,
		name: r.name,
		brand: r.brand,
		model: r.the_model,
		year: r.year,
		owner: r.owner,
		ownership: r.ownership,
		license: r.license,
		insurance: r.insurance,
		mileage: r.mileage,
		mpg: r.mpg,
		gas: r.gas,
		color: r.color,
		image: r.image,
		plate: r.plate,
		note: r.note,
		total_money: r.total_money,
		total_distance: r.total_distance,
		total_rides: r.total_rides,
		total_hours: r.total_hours,
		total_grams_served: r.total_grams_served,
		created: r.created,
		modified: r.modified,
	});
	return oooshiny;
}
function getPermit(response:Response): string {
	var permission = response.json().workpermit;
	return permission;
}
function mapTrip(response:Response): Trip {
	var acids = response.json().trips.map(toTrip);
	return acids;
}
function mapTrips(response:Response): Trip {
	var farmm = response.json().trips.map(toTrip);
	return farmm;
}
function toTrip(r:any): Trip {
	var tree = <Trip>({
		id: r.id,
		account_id: r.account_id,
		order_id: r.order_id,
		status: r.status,
		driver: r.driver,
		drivers: r.drivers,
		driver_location: r.driver_location,
		locale: r.locale,
		on_route: r.on_route,
		complete: r.complete,
		notes: r.notes,
		pickup_address: r.pickup_address,
		pickup_lat: r.pickup_lat,
		pickup_lng: r.pickup_lng,
		address: r.address,
		destination_lat: r.destination_lat,
		destination_lng: r.destination_lng,
		distance_traveled: r.distance_traveled,
		mpg: r.mpg,
		carbon_offset: r.carbon_offset,
		price: r.price,
		percentage: r.percentage,
		tip: r.tip,
		bonus: r.bonus,
		fare: r.fare,
		eta: r.eta,
		created: r.created,
		started: r.started,
		modified: r.modified,
		delivered: r.delivered
	});
	return tree;
}
function mapImages(response:Response): Image {
	var mp = response.json().images.map(toImage);
	return mp;
}
function mapImage(response:Response): Image {
	var im = response.json().image.map(toImage);
	return im;
}
function mapImageIDs(response:Response) {
	var is = response.json().imageIDs;
	return is;
}
function toImage(r:any): Image {
	var img = <Image>({
		alt:r.alt,
		base:r.base,
		created:r.created,
		description:r.description,
		error:r.error,
		height:r.height,
		id:r.id,
		params:r.params,
		product_id:r.product_id,
		size:r.size,
		tags:r.tags,
		title:r.title,
		updated_by:r.updated_by,
		url:r.url,
		width:r.width
	});
	return img;
}
function mapOrders(response:Response): Order {
	var responce = response.json().orders.map(toOrder);
	return responce;
}
function toOrder(r:any): Order {
	var user = <Order>({
		account: r.account,
		accountid: r.accountid,
		address: r.address,
		amount: r.amount,
		buyer_review: r.buyer_review,
		canceled: r.canceled,
		cardid: r.cardid,
		city: r.city,
		clientId: r.clientId,
		code: r.code,
		count: r.count,
		country: r.country,
		coupon: r.coupon,
		created: r.created,
		currencycode: r.currencycode,
		customization: r.customization,
		fulfillment_status: r.fulfillment_status,
		delivered: r.delivered,
		delivery: r.delivery,
		driver: r.driver,
		driverid: r.driverid,
		email: r.email,
		eta: r.eta,
		ez_code: r.ez_code,
		fee: r.fee,
		giftid: r.giftid,
		id: r.id,
		ip: r.ip,
		items: r.items,
		location: r.location,
		modified: r.modified,
		name: r.name,
		orderdate: r.orderdate,
		ordertype: r.ordertype,
		paid: r.paid,
		payment_expiry: r.payment_expiry,
		payment_id: r.payment_id,
		paypal: r.paypal,
		paypal_vendor: r.paypal_vendor,
		phone: r.phone,
		postal: r.postal,
		products: r.products,
		products_count: r.products_count,
		provence: r.provence,
		rating: r.rating,
		recurring: r.recurring,
		return_number: r.return_number,
		returned: r.returned,
		seller_review: r.seller_review,
		shipped: r.shipped,
		shipping_price: r.shipping_price,
		shippingid: r.shippingid,
		status: r.status,
		subtotal: r.subtotal,
		tax: r.tax,
		total: r.total,
		tracking_number: r.tracking_number,
		trip: r.trip,
		tripid: r.tripid,
		username: r.username,
		vendors: r.vendors,
		weight: r.weight,
		x: r.x
	});
	return user;
}
function nocrash(error: any) {
	var errorMsg = error.message || `Yaea`
	console.error(error);
	return Observable.throw(errorMsg);
}