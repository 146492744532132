import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '../../models/account';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { error, success } from '../../app.animations';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'my-account',
  animations: [error, success],
  templateUrl: './my.account.component.pug',
  styleUrls: ['./my.account.component.styl']
})
export class MyAccountComponent implements OnInit {

  constructor(
  	  private user: UserService,
      private marketplace: MarketplaceService,
      private router: Router,
      private location: Location,
      private activated: ActivatedRoute
  	) {
      this.page = this.activated.snapshot.paramMap.get("page");
      this.router.events.subscribe((val) => {
        var p = this.location.path();
        if (p == '/account/email_updated') {
          this.showLogin = false;
        } else {
          this.showLogin = true;
        }
      });
    if (!this.page) this.page = 'dashboard';
  }

  darkmarket = environment.darkmarket;
  update = new Update();
  tf = new TwoFactorKey('');
  accounts: Account;
  accountID: number;
  tFAImage: string;
  email: string;
  ha: any;
  chU: boolean;
  toRU: boolean;
  dark: boolean;
  isAdmin: boolean;
  isVendor: boolean;
  show_menu: boolean;
  tFAError: boolean;
  tFASetup: boolean;
  tFAValid: boolean;
  emailError: boolean;
  usernameError: boolean;
  passwordError: boolean;
  passwordSuccess: boolean;
  toggleSettings: boolean;
  oldPasswordError: boolean;
  oldPasswordTrue: boolean;
  emailChangeStatus: boolean;
  usernameChangeStatus: boolean;
  passwordChangeStatus: boolean;
  loggedIn = null;
  showLogin = null;
  page:string = 'dashboard';
  username: string = 'My Account';
  usernameResult = "";
  passwordResult = "";
  pictureResult = "";
  emailResult = "";
  comp = "";

  login(event) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/my-account',this.page]);
    });
  }

  toggleDark() {
    this.dark = (this.dark) ? false : true;
    this.marketplace.setDark(this.dark);
  }

  getUserInfo() {this.loggedIn = false;
    this.user.authorize().subscribe( (a) => {
      this.accounts = a;
      if ( a[0].accountid ) {
        this.loggedIn = true;
        this.tFASetup = (a[0].twofactor)?true:false;
        this.accountID = a[0].accountid;
        this.username = a[0].username;
        this.email = a[0].email;
      }
    });
  }

  checkUsername(event) {
    var r = new RegExp(/^[a-z0-9_-]{3,16}$/,"igm");
    if ( r.test(this.update.username) ) {
      this.usernameError = false;
      if ( this.update.username ) {
        this.user.checkUser(this.update.username).subscribe( (errrrr) => {
          if(errrrr) {
            this.usernameError = true;
          } else {
            this.usernameError = false;
          }
        });
      }
    } else {
      this.usernameError = true;
    }
  }

  checkEmail() {
    if ( this.update.email ) {
      this.user.checkEmail(this.update.email).subscribe( (emmmmm) => {
        if(emmmmm) {
          this.emailError = true;
        } else {
          this.emailError = false;
        }
      });
    }
  }

  checkPassword() {
    this.passwordError = (this.update.password==this.update.passwordconfirm) ? false : true; 
    if ( !this.passwordError && this.update.password!=null ) {
      var a = ( this.update.password.length < 6 ) ? false : true;
      this.passwordError = a;
      this.passwordSuccess = a;
    }
  }

  saveUsername() {
    if (this.update.username && this.update.username!=undefined){
      this.user.changeUsername(this.update.username).subscribe( (u) => {
        this.chU=false;
        this.getUserInfo();
      });
    }
  }

  saveEmail() {
    if ( this.update.email ) {
      this.user.changeEmail(this.update.email, this.comp).subscribe( (u) => { 
        this.toRU=false;
        this.getUserInfo();
      });
    } else {

    }
  }

  savePassword() {
    if (this.update.password && this.update.oldpassword && (this.update.password == this.update.passwordconfirm) ) {
      this.user.changePassword(this.update.password, this.update.oldpassword).subscribe( (u) => { 
        if ( u[0].code == 200 ) {
          this.passwordChangeStatus = true;
          this.update.oldpassword = '';
          this.update.passwordconfirm ='';
          this.update.password ='';
          this.getUserInfo();
          this.ha = 0;
        } else {
          this.passwordChangeStatus = false;
          this.getUserInfo();
        }
      });
    }
  }

  generateTFA() {
    this.user.twofactorsetup().subscribe( (res) => {
      this.tFAImage = res[0].image;
    });
  }

  checkTFA() {
    this.user.twofactor(this.tf.authkey).subscribe( (res) => {
      if( res[0].valid ) {
        this.tFAValid = true;
        this.tFAImage = null;
      } else {
        this.tFAValid = false;
      }
    });
  }

  checkOldPwd() {
    this.user.check_old_password(this.update.oldpassword).subscribe( (res) => {
      this.oldPasswordTrue = res;
      this.oldPasswordError = res;
    });
  }

  ngOnInit() {
    this.comp = navigator.userAgent;
		this.getUserInfo();
    this.dark = this.marketplace.getDark();
    if (!this.page) this.page = 'dashboard';
    this.user.adminStatus().subscribe((is) => {this.isAdmin = is.json().admin;});
    this.user.vendorStatus().subscribe((is) => {this.isVendor = is.json().vendor;});
  }

}

export class Update {
   constructor(
	  public password?: string,
    public oldpassword?: string,
    public passwordconfirm?: string,
	  public username?: string,
    public email?: string,
	  public image?: string,
  ) {}
} 

export class TwoFactorKey {
   constructor(
    public authkey: string
  ) {}
} 
