import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../models/product';

import { MarketplaceService } from '../../services/marketplace.service';

@Component({
  selector: 'user-favorites',
  templateUrl: './favorites.component.pug',
  styleUrls: ['./favorites.component.styl']
})
export class FavoritesComponent implements OnInit {


	constructor( private marketplace: MarketplaceService ) { }

	ngOnInit() {
		// favs are found by triggering the input in the template
	}

}