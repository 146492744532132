import { Injectable } from '@angular/core';import { Observable } from 'rxjs/Rx';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './user.service';
import { Support, SupportMessage, SupportCategory } from '../models/support';

@Injectable()
export class SupportService {
	private baseUrl: string = environment.base;
	constructor( private http 		: Http,
				 private httpClient : HttpClientModule,
				 private user 		: UserService,
				 private cookie 	: CookieService ) { }

	createSupportTicket(t): Observable<Support> { 
		var Url = `${this.baseUrl}support/new/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if(t.id) Params.append('id',t.id);
			if(t.nonce) Params.append('nonce',t.nonce);
			if(t.error) Params.append('error',t.error);
			if(t.account) Params.append('account',t.account);
			if(t.account_id) Params.append('account_id',t.account_id);
			if(t.order_id) Params.append('order',t.order_id);
			if(t.vendor_id) Params.append('vendor',t.vendor_id);
			if(t.driver_id) Params.append('driver',t.driver_id);
			if(t.customer_id) Params.append('customer',t.customer_id);
			if(t.product_id) Params.append('product',t.product_id);
			if(t.transaction_id) Params.append('transaction',t.transaction_id);
			if(t.count) Params.append('count',t.count);
			if(t.email) Params.append('email',t.email);
			if(t.email_vendor) Params.append('email_vendor',t.email_vendor);
			if(t.email_driver) Params.append('email_driver',t.email_driver);
			if(t.items) Params.append('items',t.items);
			if(t.products) Params.append('products',t.products);
			if(t.title) Params.append('title',t.title);
			if(t.code) Params.append('code',t.code);
			if(t.messages) Params.append('messages',t.messages);
			if(t.message) Params.append('message',t.message);
			if(t.category) Params.append('category',t.category);
			if(t.balance) Params.append('balance',t.balance);
			if(t.body) Params.append('body',t.body);
			if(t.reply) Params.append('reply',t.reply);
			if(t.reply_date) Params.append('reply_date',t.reply_date);
			if(t.second_reply) Params.append('second_reply',t.second_reply);
			if(t.second_reply_Date) Params.append('second_reply_Date',t.second_reply_Date);
			if(t.third_reply) Params.append('third_reply',t.third_reply);
			if(t.third_reply_date) Params.append('third_reply_date',t.third_reply_date);
			if(t.system_reply) Params.append('system_reply',t.system_reply);
			if(t.system_reply_date) Params.append('system_reply_date',t.system_reply_date);
			if(t.status) Params.append('status',t.status);
			if(t.complete) Params.append('complete',t.complete);
			if(t.date_completed) Params.append('date_completed',t.date_completed);
			if(t.created) Params.append('created',t.created);
			if(t.updated) Params.append('updated',t.updated);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupport)
			.catch(handleError);
		return pro;
	}

	createSupportMessage(t): Observable<SupportMessage> { 
		var Url = `${this.baseUrl}support/new/message/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',t.id);
			Params.append('nonce',t.nonce);
			Params.append('title',t.title);
			Params.append('body',t.body);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupportMessage)
			.catch(handleError);
		return pro;
	}

	saveSupportTicket(t): Observable<Support> { 
		var Url = `${this.baseUrl}support/save/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if(t.id)Params.append('id',t.id);
			if(t.nonce)Params.append('nonce',t.nonce);
			if(t.error)Params.append('error',t.error);
			if(t.account)Params.append('account',t.account);
			if(t.account_id)Params.append('account_id',t.account_id);
			if(t.order_id)Params.append('order_id',t.order_id);
			if(t.vendor_id)Params.append('vendor_id',t.vendor_id);
			if(t.driver_id)Params.append('driver_id',t.driver_id);
			if(t.customer_id)Params.append('customer_id',t.customer_id);
			if(t.product_id)Params.append('product_id',t.product_id);
			if(t.transaction_id)Params.append('transaction_id',t.transaction_id);
			if(t.count)Params.append('count',t.count);
			if(t.email)Params.append('email',t.email);
			if(t.email_vendor)Params.append('email_vendor',t.email_vendor);
			if(t.email_driver)Params.append('email_driver',t.email_driver);
			if(t.items)Params.append('items',t.items);
			if(t.products)Params.append('products',t.products);
			if(t.title)Params.append('title',t.title);
			if(t.code)Params.append('code',t.code);
			if(t.messages)Params.append('messages',t.messages);
			if(t.message)Params.append('message',t.message);
			if(t.category)Params.append('category',t.category);
			if(t.balance)Params.append('balance',t.balance);
			if(t.body)Params.append('body',t.body);
			if(t.reply)Params.append('reply',t.reply);
			if(t.reply_date)Params.append('reply_date',t.reply_date);
			if(t.second_reply)Params.append('second_reply',t.second_reply);
			if(t.second_reply_Date)Params.append('second_reply_Date',t.second_reply_Date);
			if(t.third_reply)Params.append('third_reply',t.third_reply);
			if(t.third_reply_date)Params.append('third_reply_date',t.third_reply_date);
			if(t.system_reply)Params.append('system_reply',t.system_reply);
			if(t.system_reply_date)Params.append('system_reply_date',t.system_reply_date);
			if(t.status)Params.append('status',t.status);
			if(t.complete)Params.append('complete',t.complete);
			if(t.date_completed)Params.append('date_completed',t.date_completed);
			if(t.created)Params.append('created',t.created);
			if(t.updated)Params.append('updated',t.updated);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupport)
			.catch(handleError);
		return pro;
	}

	setTicketStatus(t,status): Observable<Support> { 
		var Url = `${this.baseUrl}support/setstatus/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',t);
			Params.append('status',status);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupport)
			.catch(handleError);
		return pro;
	}

	getSupportTickets(): Observable<Support> { 
		var Url = `${this.baseUrl}support/tickets/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.get(Url, Opts)
			.map(mapSupport)
			.catch(handleError);
		return pro;
	}

	supportCategories(): Observable<SupportCategory> { 
		var Url = `${this.baseUrl}support/categories/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var cat = this.http
			.get(Url, Opts)
			.map(mapSupportCategories)
			.catch(handleError);
		return cat;
	}

	getSupportTicket(ticket): Observable<Support> { 
		var Url = `${this.baseUrl}support/ticket/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('ticket', ticket);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupport)
			.catch(handleError);
		return pro;
	}

	getSupportMessages(ticket): Observable<SupportMessage> { 
		var Url = `${this.baseUrl}support/messages/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('ticket', ticket);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapSupportMessage)
			.catch(handleError);
		return pro;
	}

	getSupportableOrders(): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/orders/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.get(Url, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

	getSupportableDrivers(order): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/drivers/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if(order) Params.append('id',order);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

	getSupportableCustomers(): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/customers/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.get(Url, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

	getSupportableVendors(): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/vendors/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.get(Url, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

	getSupportableProducts(order): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/products/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if(order) Params.append('id',order);
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.post(Url, Params, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

	getSupportableTransactions(): Observable<any[]> { 
		var Url = `${this.baseUrl}support/get/transactions/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var pro = this.http
			.get(Url, Opts)
			.map(mapOption)
			.catch(handleError);
		return pro;
	}

}
function mapOption(response: Response): any[]
{
	var rest = response.json().opts;
	return rest;
}
function mapSupportMessage(response: Response): SupportMessage 
{
	var rest = response.json().messages.map(toSupportMessage);
	return rest;
}
function toSupportMessage(r:any): SupportMessage 
{
	var sm = <SupportMessage>({
		id: r.id,
		support_ticket_id: r.support_ticket_id,
		from_account_id: r.from_account_id,
		account: r.account,
		from_email: r.from_email,
		order_id: r.order_id,
		code: r.code,
		title: r.title,
		body: r.body,
		to_emails: r.to_emails,
		products: r.products,
		items: r.items,
		read: r.read,
		created: r.created,
		modified: r.modified
	});
	return sm;
}
function mapSupport(response: Response): Support 
{
	var rest = response.json().ticket.map(toSupport);
	return rest;
}
function toSupport(r:any): Support 
{
	var s = <Support>({
		id : r.id,
		account_id: r.account_id,
		account: r.account,
		order_id: r.order_id,
		vendor_id: r.vendor_id,
		vendor: r.vendor,
		driver_id: r.driver_id,
		driver: r.driver,
		email: r.email,
		email_vendor: r.email_vendor,
		email_driver: r.email_driver,
		count: r.count,
		items: r.items,
		products: r.products,
		title: r.title,
		code: r.code,
		messages: r.messages,
		category: r.category,
		body: r.body,
		reply: r.reply,
		reply_date: r.reply_date,
		second_reply: r.second_reply,
		second_reply_Date: r.second_reply_Date,
		third_reply: r.third_reply,
		third_reply_date: r.third_reply_date,
		system_reply: r.system_reply,
		system_reply_date: r.system_reply_date,
		status: r.status,
		complete: r.complete,
		date_completed: r.date_completed,
		created: r.created,
		updated: r.updated
	});
	return s;
}
function mapSupportCategories(response: Response): SupportCategory 
{
	var rest = response.json().category.map(toSupportCategory);
	return rest;
}
function toSupportCategory(r:any): SupportCategory 
{
	var sc = <SupportCategory>({
		id: r.id,
		name: r.name,
		type: r.type,
		description: r.description,
		enabled: r.enabled,
		total_cases: r.total_cases
	});
	return sc;
}
function handleError(error: any) 
{
	var errorMsg = error.message || `Yikes!`
	console.error(error);
	return Observable.throw(errorMsg);
}