import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '../../services/marketplace.service';
import { UserService } from '../../services/user.service';
import { Earning } from '../../models/earning';
import { Graph } from '../../models/graph';

@Component({
  selector: 'account-earnings',
  templateUrl: './account.earnings.component.pug',
  styleUrls: ['./account.earnings.component.styl']
})
export class AccountEarningsComponent implements OnInit {

	constructor( 
		private marketplace: MarketplaceService,
		private user: UserService
	) { }


	graphs: Graph;
	earnings: Earning;

	add() {
    	
    }

	getEarnings() {
		this.user.getSalesGraph().subscribe( (e) => {
			this.graphs = e;
		});
	}

	ngOnInit() {
		this.getEarnings();
	}

}
