import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MarketplaceService } from '../../services/marketplace.service';
import { addressPallet, active } from '../checkout.animations';

@Component({
  selector: 'checkout-verify',
  templateUrl: './checkout.verify.component.pug',
  styleUrls: ['./checkout.verify.component.styl'],
  animations: [ addressPallet, active ]
})
export class CheckoutVerifyComponent implements OnInit {


  newIdentity: boolean;

  constructor(
  		private user: UserService,
		private marketplace: MarketplaceService
  	) { }

  getIdentity() {


  }


  uploadIdentity() {


  }

 

  ngOnInit() {
  }

}
