import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.pug',
  styleUrls: ['./signup.component.styl']
})
export class SignUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
