import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.pug',
  styleUrls: ['./carousel.component.styl']
})
export class CarouselComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
