import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Account } from '../../models/account';
import { Wallet } from '../../models/wallet';
import { Currency } from '../../models/currency';
import { UserService } from '../../services/user.service';
import { PaymentMethod } from '../../models/paymentmethod';
@Component({
  selector: 'wallet',
  templateUrl: './wallet.component.pug',
  styleUrls: ['./wallet.component.styl']
})
export class WalletComponent implements OnInit {
  constructor(
  	private user: UserService,
  	private router: Router,
  	private activated: ActivatedRoute
  ) { 
	this.page = this.activated.snapshot.paramMap.get("page");
	if (!this.page) this.page = 'balances';
  }
	accounts: Account;
	wallets: Wallet;
	loading: boolean;
	isGuest = true;
	show_menu = false;
	loggedIn = null;
	page = 'balances';

	login(event) {
	    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
	        this.router.navigate(['/wallet',this.page]);
	    });
	}

	get_wallets() {
		this.user.getBalances().subscribe( (d) => {
			this.wallets = d;
		});
	}

	getUserInfo() {this.loggedIn = false;
		this.user.authorize().subscribe( (a) => {
			this.accounts = a;
			if ( a[0].accountid ) {
				this.isGuest = (a[0].guest==true||a[0].guest=='true')?true:false;
				this.loggedIn = true;
			}
		});
	}

	ngOnInit() {
		this.getUserInfo();
		// this.get_wallets();
		if (!this.page) this.page = 'balances';
	}

	keyPg( e, a ) {
		if ( e.keyCode == 13 ) this.page = a;
		if ( e.keyCode == 32 ) this.page = a;
		if ( e.keyCode == 39 ) this.page = a;
	}

}