import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const FilterInput = trigger('FilterInput', [
    state('true_mobile', style({
        'max-width': '100%',
        'min-width': '255px',
        padding: '0 20px',
        opacity: 1,
        'z-index': 20,
        'margin-top': '0px',
        border: '3px solid rgba(56, 56, 56, 0.152)'
    })),
    state('true1', style({
        'max-width': '100%',
        'min-width': '255px',
        padding: '0 20px',
        opacity: 1,
        border: '3px solid rgba(184, 213, 236, 0.52)',
        'z-index': 20
    })),
    state('false', style({
        width: '0%',
        padding: '0',
        opacity: 0,
        'z-index': 10,
        border: '3px solid #fff'
    })),
    transition('*=>*', animate('400ms'))
]);
export const selector = trigger('selector', [
    state('false', style({
        'width': '30%'
    })),
    state('small', style({
        'width': '31%'
    })),
    state('true', style({
        'width': '34%'
    })),
    transition('*=>*', animate('200ms'))
]);
export const childOpen = trigger('childOpen', [
    state('false', style({
        'transform': 'translateY(0px)',
        'opacity': 0
    })),
    state('true', style({
        'transform': 'translateY(4px)',
        'opacity': 1
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const rotate = trigger('rotate', [
    state('true', style({
        'transform': 'rotateX(180deg)',
    })),
    state('false', style({
        'transform': 'rotateX(0deg)',
    })),
    transition('*=>*', animate('156ms ease-out'))
]);
export const filters = trigger('filters', [
    state('true', style({
        'transform': 'rotateX(0deg)',
        'opacity': '1',
        'pointer-events': 'all'
    })),
    state('false', style({
        'transform': 'rotateX(90deg)',
        'opacity': '0',
        'pointer-events': 'none'
    })),
    transition('*=>*', animate('156ms ease-out'))
]);
export const filterSelectors = trigger('filterselectors', [
    state('true', style({
        'border': '3px solid #e4e4e4'
    })),
    state('false', style({
        'border': '3px solid white'
    })),
    transition('*=>*', animate('400ms'))
]);
export const selectormenu = trigger('selectormenu', [
    state('true', style({
        'transform': 'rotateX(0deg)',
        'box-shadow': 'rgba(7, 23, 9, 0.35) 5px 6px 18px',
        'opacity': '1',
        'top': '24px',
        'width': '120%',
        'left': '-12%'
    })),
    state('false', style({
        'transform': 'rotateX(90deg)',
        'box-shadow': 'rgba(7, 23, 9, 0) 5px 6px 10px',
        'opacity': '0',
        'top': '24px',
        'width': '96%',
        'left': '0%'
    })),
    transition('*=>*', animate('156ms ease-in-out'))
])
export const Sticky = trigger('sticky', [
    state('prone', style({
        'position': 'fixed',
        'top': 0,
		'left': 0,
        'z-index': 10,
        // 'transform': 'translateY(-200px)',
        'box-shadow': '0px 0px 0px #0003'
    })),
    state('true', style({
        'position': 'fixed',
        'top': 0,
		'left': 0,
        'z-index': 10,
        // 'transform': 'translateY(10px)',
        'box-shadow': '2px 2px 10px #0003'
    })),
    state('false', style({
        'position': 'relative',
        'top': 0,
        'transform': 'translateY(0px)'
    })),
    transition('*=>*', animate('100ms'))
]);
export const Fade = trigger('fade', [
    state('true', style({
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0
    })),
    transition('*=>*', animate('400ms'))
]);
export const FadeInSlow = trigger('FadeInSlow', [
    state('true', style({
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0
    })),
    transition('*=>*', animate('3700ms'))
]);
export const FadeSlow = trigger('FadeSlow', [
    state('true', style({
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0
    })),
    transition('*=>*', animate('1700ms'))
]);
export const FadeQuick = trigger('FadeQuick', [
    state('true', style({ 
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0,
        'pointer-events': 'none'
    })),
    transition('true=>false', animate('140ms')),
    transition('false=>true', animate('140ms 440ms')),
]);
export const productsPane = trigger('productsPane', [
    state('1', style({
        'opacity': 1,
        'transform': 'translateY(0px)',
        'display': 'block'
    })),
    state('fast', style({
        'opacity': 1,
        'transform': 'translateY(0px)',
        'display': 'block'
    })),
    state('0', style({
        'opacity': 0,
        'transform': 'translateY(30px)',
        'display': 'none'
    })),
    transition('0=>1', animate('350ms')),
    transition('1=>0', animate('0ms')),
    transition('1=>fast', animate('0ms')),
    transition('0=>fast', animate('0ms')),
]);
export const red = trigger('red', [
    state('true', style({
        'color': '#dc0404'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('400ms'))
]);
export const gold = trigger('gold', [
    state('true', style({
        'color': '#ffd700'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('400ms'))
]);
export const rp = trigger('rp',[
    state('false', style({
        'right': '130px'
    })),
    state('true', style({
        'right': '130px'
    }))
]);
export const progress = trigger('progress', [
    state('false', style({
        'cursor': 'default'
    })),
    state('true', style({
        'cursor': 'progress'
    }))
]);
export const FeatAd = trigger('FeatAd', [
    state('false', style({
        'max-height':'0px',
        'min-height':'0px'
    })),
    state('true', style({
        'max-height':'280px',
        'min-height':'64px'
    }))
]);