import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'news-feed',
  templateUrl: './news.feed.component.pug',
  styleUrls: ['./news.feed.component.styl']
})
export class NewsFeedComponent implements OnInit {

	constructor( 
		private user: UserService,
	) {	}

	ngOnInit() {
	}

}