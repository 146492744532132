import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '../../../models/invoice';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'wallet-invoices',
  templateUrl: './invoices.component.pug',
  styleUrls: ['./invoices.component.styl']
})
export class InvoicesComponent implements OnInit {

  constructor(private user: UserService) { }
  uncaptured_payments: any;
  countInvoices = -1;
  invoices: Invoice;

  @Input('limit') limit = 30;
  @Input('offset') offset = 0;

  getInvoices() {
  	this.user.getInvoices().subscribe( (i) => {
  		this.invoices = i;
      this.countInvoices = i[0].count;
  	})
  }

  ngOnInit() {
  	this.getInvoices();
  }

}
