import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';

export const finderInput = trigger('finderInput', [
    state('true', style({
        width: '83%',
        padding: '0 20px',
        border: '3px solid #a4ca6c',
        background: 'white'
    })),
    state('false', style({
        width: '0%',
        padding: '0',
        border: '3px solid rgba(0,0,0,0)',
        background: 'transparent'
    })),
    transition('*=>*', animate('120ms'))
]);
//<3
export const view = trigger('view', [
    state('true', style({
        display: 'block'
    })),
    state('false', style({
        display: 'none'
    }))
])

export const fadeInFull = trigger('fadeInFull', [
    state('true', style({
        'opacity' : '1'
    })),
    state('false', style({
        'opacity' : '0'
    })),
    transition('*=>*', animate('170ms ease-out'))
])

export const sideDown = trigger('sideDown', [
    state('true', style({
        'opacity' : '1',
        'max-height': '340px'
    })),
    state('false', style({
        'opacity' : '0',
        'max-height': '0px',
        'margin': '0px',
        'padding-top': '0px',
        'padding-bottom': '0px'
    })),
    transition('*=>*', animate('300ms ease-out'))
])

export const fadeSlow = trigger('fadeSlow', [
    state('true', style({
        'opacity' : '1'
    })),
    state('false', style({
        'opacity' : '0'
    })),
    transition('*=>*', animate('1700ms ease-out'))
])

// Countries
export const finderLocale = trigger('finderLocale', [
    state('true', style({
        width: '250px'
    })),
    state('false', style({
        width: '63px'
    }))
])

export const cartLoading = trigger('cartLoading', [
    state('true', style({
        color: 'black'
    })),
    state('false', style({
        color: 'gray'
    }))
])

export const slideIn = trigger('slideIn', [
    state('true', style({
        'transform': 'translateY(0%)'
    })),
    state('false', style({
        'transform': 'translateY(-260%)'
    })),
    transition('*=>*', animate('260ms ease-out'))
])

export const labelerr = trigger('labelerr', [
    state('true', style({
        color: 'red',
        'text-shadow': 'none'
    })),
    state('false', style({
    }))
])

export const bubble = trigger('bubble', [
    state('true', style({
        visibility: 'visible',
        opacity: 1
    })),
    state('false', style({
        visibility: 'hidden',
        opacity: 0
    })),
])

export const height = trigger('height', [
    state('true', style({
        height: '345px'
    })),
    state('false', style({
        height: '270px'
    })),
    transition('*=>*', animate('150ms ease-out')) 
])

export const slieDownDelivery = trigger('slieDownDelivery', [
    state('true', style({
        'min-height': '15px',
        visibility: 'visible',
        'margin-bottom': '15px',
        'height': '45px',
        opacity: 1
    })),
    state('false', style({
        'min-height': '0px',
        visibility: 'hidden',
        'margin-bottom': '0px',
        'height': '0px',
        opacity: 0
    }))
])


export const cartUnits = trigger('cartUnits ', [
    state('true', style({
        'bottom': '47px',
        'transform': 'scale(1)',
        'opacity': 1
    })),
    state('false', style({
        'bottom': '40px',
        'transform': 'scale(0.9)',
        'opacity': 0
    })),
    transition('*=>*', animate('120ms'))
]);

export const formerr = trigger('formerr', [
    state('true', style({
        'border-color': 'red',
        'background': '#ffdedb'
    })),
    state('false', style({

    })),
    transition('*=>*', animate('120ms'))    
]);

export const formsucc = trigger('formsucc', [
    state('true', style({
        'border': '1px solid green',
        'border-bottom': '2px solid green',
        '-webkit-text-fill-color': '#fff',
        '-webkit-box-shadow': '0 0 0 30px #5aa917 inset'
    })),
    state('false', style({

    })),
    transition('*=>*', animate('120ms'))
])

export const cartBubble = trigger('cartBubble', [
    state('true', style({
        'transform': 'translateY(0px) scaleY(1)',
        'opacity': '1',
        'visibility': 'visible'
    })),
    state('false', style({
        'transform': 'translateY(50px) scaleY(0)',
        'opacity': '0',
        'visibility': 'hidden'
    })),
    transition('*=>*', animate('220ms'))
])

export const selectedunits = trigger('selectedunits', [
    state('true', style({
        'transform': 'scale(1.1)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('100ms'))
])

export const countryList = trigger('countryList', [
    state('true', style({
        opacity: 1,
        display: 'block'
    })),
    state('false', style({
        opacity: 0,
        display: 'none'
    })),
    transition('*=>*', animate('120ms ease-out')),
])
// Search Bar
export const finderWidth = trigger('finderWidth', [
    state('true', style({
        width: '80%',
        'margin-right': '20px'
    })),
    state('false', style({
        width: '0px',
        'margin-right': '0px'
    })),
    transition('*=>*', animate('120ms'))
])

export const gramReveal = trigger('gramReveal', [
    state('true', style({
        'max-width': '72px'
    })),
    state('false', style({
        'max-width': '0px'
    })),
    transition('*=>*', animate('600ms'))
])

export const error = trigger('error', [
    state('false', style({
        'border-color': 'red'
    })),
    transition('*=>*', animate('600ms'))
])

export const success = trigger('success', [
    state('true', style({
        'border-color': '#80d238'
    })),
    transition('*=>*', animate('600ms'))
])

export const localeLabel = trigger('localeLabel', [
    state('true', style({
        display: 'inline',
        opacity: 1
    })),
    state('false', style({
        display: 'none',
        opacity: 0
    })),
    transition('* => *', animate('0ms 300ms'))
])

// export const overlay = trigger('overlay', [
//     state('true', style({
//         opacity: 1,
//         display: 'block'
//     })),
//     state('false', style({
//         opacity: 0,
//         display: 'none'
//     })),
//     transition('* => *', animate('0ms'))
// ])


export const notifBox = trigger('notifBox', [
    state('true', style({
        transform: 'translateY(10px) scale(1) rotateX(0deg)',
        filter: ' blur(0px)',
        display: 'block',
        opacity: 1
    })),
    state('false',  style({
        transform: 'translateY(0px) scale(0.9) rotateX(-100deg)',
        filter: ' blur(1px)',
        display: 'none',
        opacity: 0
    })),
    transition('false=>true', animate('120ms 199ms ease-out')),
    transition('true=>false', animate('50ms 0ms'))
])

export const loginBox = trigger('loginBox', [
    state('true', style({
        display: 'block',
        opacity: 1
    })),
    state('false', style({
        display: 'none',
        opacity: 0
    })),
    transition('*=>*', animate('150ms ease-out')),
])

export const userBox = trigger('userBox', [
    state('true', style({
        transform: 'scale(1)',
        display: 'block',
        opacity: 1
    })),
    state('false', style({
        transform: 'scale(0.9)',
        display: 'none',
        opacity: 0
    })),
    transition('*=>*', animate('200ms'))
])

export const cart = trigger('cart', [
    state('true', style({
        'transform': 'translateX(0%) rotateY(0deg)',
        'box-shadow': '10px 10px 29px rgba(0,0,0,0.55)'
    })),
    state('false', style({
        'transform': 'translateX(100%) rotateY(0deg)',
        'box-shadow': '0px 0px 0px rgba(0,0,0,0)'
    })),
    state('full', style({
        'width': '98%',
        'transform': 'translateX(0%) rotateY(0deg)',
        'box-shadow': '10px 10px 29px rgba(0,0,0,0)'
    })),
    transition('*=>*', animate('120ms ease-out'))
])

export const show = trigger('show', [
    state('true', style({
        'transform' : 'scale(1)'
    })),
    state('false', style({
        'transform' : 'scale(0)'
    })),
    transition('*=>*', animate('300ms ease-out'))
])

export const fade = trigger('fade', [
    state('true', style({
        'opacity' : '0.75'
    })),
    state('false', style({
        'opacity' : '0'
    })),
    transition('*=>*', animate('2311ms ease-out'))
])

export const FadeInSlow = trigger('FadeInSlow', [
    state('true', style({
        'opacity' : '1'
    })),
    state('false', style({
        'opacity' : '0'
    })),
    transition('*=>*', animate('2611ms ease-out'))
])


export const active = trigger('active', [
    state('true', style({
        'border-color': '#9dd44d',
        'box-shadow': '0 0 10px #b7ff4e'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('300ms'))
])

export const flashcart = trigger('flashcart', [
    state('true', style({
        'z-index': 100,
        transform: 'scale(1.3)',
    })),
    state('false', style({
        'z-index': 6,
        transform: 'scale(1)',
    })),
    transition('*=>*', animate('200ms ease-out'))
])

export const welcomebox = trigger('welcomebox', [
    state('true', style({
        // transform: 'scale(1.04)',
        'box-shadow': '16px 16px 41px rgba(0,0,0,0.35)'
    })),
    state('false', style({
        transform: 'scale(1)',
        'box-shadow': '12px 12px 29px rgba(0,0,0,0.35)'
    })),
    transition('*=>*', animate('80ms'))
])

export const notifButton = trigger('notifButton', [
    state('true', style({
        opacity: 1,
        display: 'none',
        transform: 'scale(1)',
        'box-shadow': '5px 3px 10px rgba(0,0,0,0.1)'
    })),
    state('false', style({
        opacity: 0,
        display: 'none',
        transform: 'scale(0)',
        'box-shadow': '5px 3px 200px rgba(0,0,0,0)'
    })),
    transition('*=>*', animate('200ms ease-out'))
])

export const hidewelcome = trigger('hidewelcome', [
    state('true', style({
        opacity: '1',
        display: 'inline-block'
    })),
    state('false', style({
        opacity: '0',
        display: 'none'
    })),
    transition('*=>*', animate('360ms ease-out'))
])

export const inputError = trigger('inputError', [
    state('true', style({
        border: '2px solid #c55441'
    })),
    state('false', style({
        border: '2px solid #dadbda'
    })),
    transition('*=>*', animate('150ms ease-out'))
])

export const ex = trigger('ex', [
    state('true', style({
        'transform': 'scale(1.27)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('450ms ease-out'))
])

export const overlay = trigger('overlay', [
    state('true', style({
        'opacity': '1',
        'transform': 'scale(1)'
    })),
    state('false', style({
        'opacity': '0',
        'transform': 'scale(0)'
    })),
    transition('*=>*', animate('450ms ease-out'))
])

