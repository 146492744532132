import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.pug',
  styleUrls: ['./terms.component.styl']
})
export class TermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
