import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'account-updated',
  templateUrl: './updated.component.pug',
  styleUrls: ['./updated.component.styl']
})
export class AccountUpdatedEmail implements OnInit {

    constructor(
        private router: Router,
        private activated: ActivatedRoute,
        private user: UserService
    ) {
        this.mode = this.activated.snapshot.paramMap.get("mode");
        this.newemail = this.activated.snapshot.paramMap.get("email");
    }

    mode: string;
    newemail: string;

    shop() {
        this.router.navigate(['/shop']);
    }
    
    account() {
        this.router.navigate(['/my-account']);
    }

    ngOnInit() {
    }

}
