import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Account } from '../../models/account';

@Component({
  selector: 'user-sold',
  templateUrl: './sold.component.pug',
  styleUrls: ['./sold.component.styl']
})
export class SoldComponent implements OnInit {

  constructor(
  	private user: UserService
  	) { }
  
  myaccount: Account
  accountid: number;
  loaded: boolean;
  drafts: boolean;

  authorize() {
  	this.user.authorize().subscribe( (a) => {
        this.myaccount = a;
        this.accountid = a[0].accountid;
        this.loaded = true
    });
  }

  ngOnInit() {
  	this.authorize();
  } 

}
