import { Component, OnInit, Input } from '@angular/core';

import { MarketplaceService } from '../services/marketplace.service';
import { UserService } from '../services/user.service';

import { Account } from '../models/account';
import { Address } from '../models/address';
import { Order } from '../models/order';

import { addressPallet, errorstate } from './checkout.animations';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.pug',
  styleUrls: ['./checkout.component.styl'],
  animations: [ addressPallet, errorstate ],
  providers: [ MarketplaceService, UserService ]
})
export class CheckoutComponent implements OnInit {


  constructor( private marketplace: MarketplaceService,
			  private user: UserService ) {}

  order = new NewOrder();
  completeOrder: Order;

  show = 1;
  showReview = false;
  percentage = 33;

  cart: any;
  address: number;
  accountID: number;
	orderID: number;
  shippingAddress: any;
  paymentMethod: any;
  paymentProduct: any;

  getCart() {
    this.cart = this.marketplace.cart(); 
  }

  increment() {
    this.show=(this.show<3)?this.show+1:this.show;
    this.percentage = ( this.percentage < 99 ) ? this.percentage + 33 : 100;
  }

  set_shippingAddress( e ) {
    this.shippingAddress = e;
    this.order['shippingAddress'] = e;
  }
  set_paymentMethod( e ) {
    this.paymentMethod = e;
    this.order['method'] = e;
  }
  set_paymentProduct( e ) {
    this.paymentProduct = e;
    this.order['paymentProduct'] = e;
  }

  purchase() {
    // this.marketplace.purchase( this.order ).subscribe( (p) => {
    //   this.completeOrder = p;
    // })
  }

	ngOnInit() {
    this.getCart();
	}

}


export class NewOrder {
  constructor(
    method?: any,
    credit?: any,
    currency?:string,
    products?: any,
    items?: any,
    shippingAddress?: any,
    shippingMethod?: any,
    paymentProduct?: any,
    gift?: string,
    is_gift?: boolean,
    amount?: number
   ) {}
}