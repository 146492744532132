import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'coin'
})
export class CoinPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if ( args && value) {
			let values = [];
			for (var i = value.length - 1; i >= 0; i--) {
				if ( value[i].symbol && args ){
					if ( value[i].symbol.toLowerCase() == args.toLowerCase() ) {
						values.push( value[i] );	
					}
				}
			}
			return values;
		} else { 
			return value;
		}
	}
}
