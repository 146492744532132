import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar',
  templateUrl: './appbar.component.pug',
  styleUrls: ['./appbar.component.styl']
})
export class AppBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
