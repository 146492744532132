import { Component, OnInit, Output, Input,EventEmitter } from '@angular/core';
import { slideDown, spinRound } from './birthday.animations';

@Component({
  selector: 'birthday-selector',
  templateUrl: './birthday.component.pug',
  animations: [ slideDown, spinRound ],
  styleUrls: ['./birthday.component.styl']
})
export class BirthdayComponent implements OnInit {

	@Input('errors') errors:boolean;
	@Output('age') age = new EventEmitter();
	@Output('date') date = new EventEmitter();

	year: any;
	month: any;
	day: any;

	years: any;
	months: any;
	days: any;

	showmonth: boolean;
	showday: boolean;
	showyear: boolean;

	fullDate = '';
	currentYear = new Date().getFullYear();

	constructor() {

		this.years = [];
		this.months = [];
		this.days = [];

  		for (var i = 1; i < 33; i++) {
  			this.days.push(i);
  		}

		this.months.push('January');
		this.months.push('Feburary');
		this.months.push('March');
		this.months.push('April');
		this.months.push('May');
		this.months.push('June');
		this.months.push('July');
		this.months.push('August');
		this.months.push('September');
		this.months.push('October');
		this.months.push('November');
		this.months.push('December');

  		for (var i = this.currentYear; i >= 1900; i--) {
  			this.years.push(i);
  		}

	}

	changeDate() {

		var m = 0;
		for (var i = this.months.length - 1; i >= 0; i--) {
			if ( this.months[i] == this.month ) m = i;
		}

		this.month = ( this.month ) ? this.month : ''; 
		this.day = ( this.day ) ? this.day : ''; 
		this.year = ( this.year ) ? this.year : ''; 
		this.fullDate = m + '/' + this.day + '/' + this.year;
		this.date.emit( this.fullDate );

		if ( this.year ) this.age.emit( this.currentYear - this.year );

	}

	hideall(a?) {
		if(a!='showmonth')this.showmonth = false;
		if(a!='showday')this.showday = false;
		if(a!='showyear')this.showyear = false;
	}

	ngOnInit() {
	}

}
