import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';

export const rotation = trigger('rotation', [
    state('true', style({
        'color': '#000',
        'transform': 'rotate(180deg)'
    })),
    state('truetrue', style({
        'color': 'rgba(0, 0, 0, 0.5)',
        'transform': 'rotate(360deg)'
    })),
    state('false', style({
        'color': 'rgba(0, 0, 0, 0.5)',
        'transform': 'rotate(0deg)'
    })),
    transition('false=>true', animate('730ms cubic-bezier(0.5, -0.68, 0.5, 1.5)')),
    transition('true=>truetrue', animate('730ms cubic-bezier(0.5, -0.68, 0.5, 1.5)')),
    transition('truetrue=>false', animate('0ms'))
]);

export const storeshow = trigger('storeshow', [
    state('true', style({
        'overflow': 'visible',
        'max-height': '550px',
        'opacity': 1
    })),
    state('truetrue', style({
        'overflow': 'hidden',
        'max-height': '0px',
        'opacity': 0
    })),
    state('false', style({
        'overflow': 'hidden',
        'max-height': '0px',
        'opacity': 0
    })),
    transition('false=>true', animate('420ms ease-out')),
    transition('true=>truetrue', animate('420ms ease-out')),
    transition('truetrue=>false', animate('0ms'))
]);