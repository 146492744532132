import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'report-order',
  templateUrl: './report.order.component.pug',
  styleUrls: ['./report.order.component.styl']
})
export class ReportOrderComponent implements OnInit {

  constructor() { } 

  ngOnInit() {
  }

}
