import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '../../models/account';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'my-driver',
  templateUrl: './my.driver.component.pug',
  styleUrls: ['./my.driver.component.styl']
})
export class MyDriverComponent implements OnInit {

  constructor(
  	  private user: UserService,
      private router: Router,
      private activated: ActivatedRoute
  	) { 
    this.page = this.activated.snapshot.paramMap.get("page");
  }


  accounts: Account;
  username: string = 'Driver';
  page:string = 'Deliveries';
  update = new Update();

  ngOnInit() {
  		// Authorize Account
		this.user.authorize().subscribe( (a) => {
			// if (a && a[0]) this.username = a[0].username;
			this.accounts = a;
		});

  }

}

export class Update {
   constructor(
	public password?: string,
	public username?: string,
	public image?: string,
   ) {}
} 
