import { Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminDeliveryComponent, } from './admin-delivery/admin-delivery.component';
import { AdminIssuesComponent } from './admin-delivery/admin-issues/admin-issues.component';

import { AdminNotificationsComponent, } from './admin-delivery/admin-notifications/admin-notifications.component';
import { AdminParcelsComponent } from './admin-delivery/admin-parcels/admin-parcels.component';
import { AdminReturnsComponent } from './admin-delivery/admin-returns/admin-returns.component';
import { AdminCoinsComponent } from './admin-coin/admin.coin.component';

import { AdminPaymentsComponent } from './admin-payments/admin-payments.component';
import { AdminWithdrawlsComponent } from './admin-payments/admin-withdrawls/admin-withdrawls.component';
import { AdminTransactionsComponent } from './admin-payments/admin-transactions/admin-transactions.component';
import { AdminCardsComponent } from './admin-payments/admin-cards/admin-cards.component';
import { AdminDepositsComponent } from './admin-payments/admin-deposits/admin-deposits.component';

import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminCategoriesComponent } from './admin-products/admin-categories/admin-categories.component';
import { AdminPromotionsComponent } from './admin-products/admin-promotions/admin-promotions.component';
import { AdminReviewsComponent } from './admin-products/admin-reviews/admin-reviews.component';
import { AdminStatisticsComponent } from './admin-products/admin-statistics/admin-statistics.component';

import { AdminSalesComponent } from './admin-sales/admin-sales.component';
import { AdminCouponsComponent } from './admin-sales/admin-coupons/admin-coupons.component';
import { AdminDisputesComponent } from './admin-sales/admin-disputes/admin-disputes.component';
import { AdminSubscriptionsComponent } from './admin-sales/admin-subscriptions/admin-subscriptions.component';
import { AdminTaxesComponent } from './admin-sales/admin-taxes/admin-taxes.component';

import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminBalancesComponent } from './admin-users/admin-balances/admin-balances.component';
import { AdminFriendsComponent } from './admin-users/admin-friends/admin-friends.component';
import { AdminGiftsComponent } from './admin-users/admin-gifts/admin-gifts.component';;
import { AdminMessagesComponent } from './admin-users/admin-messages/admin-messages.component';;
import { AdminPointsComponent } from './admin-users/admin-points/admin-points.component';;

export const adminRoutes: Routes = [
	{ path: 'administrator', component: AdminComponent, data: { title: 'Admin | Tree St.' } },
	{ path: 'administrator/notifications', component: AdminNotificationsComponent, data: { title: 'Notifications | Tree St.' } },
	{ path: 'administrator/coins', component: AdminCoinsComponent, data: { title: 'Coins | Tree St.' } },
	{ path: 'administrator/parcels', component: AdminParcelsComponent, data: { title: 'Parcels | Tree St.' } },
	{ path: 'administrator/returns', component: AdminReturnsComponent, data: { title: 'Returns | Tree St.' } },
	{ path: 'administrator/payments', component: AdminPaymentsComponent, data: { title: 'Payments | Tree St.' } },
	{ path: 'administrator/withdrawls', component: AdminWithdrawlsComponent, data: { title: 'Withdrawls | Tree St.' } },
	{ path: 'administrator/transactions', component: AdminTransactionsComponent, data: { title: 'Transactions | Tree St.' } },
	{ path: 'administrator/cards', component: AdminCardsComponent, data: { title: 'Cards | Tree St.' } },
	{ path: 'administrator/deposits', component: AdminDepositsComponent, data: { title: 'Deposits | Tree St.' } },
	{ path: 'administrator/products', component: AdminProductsComponent, data: { title: 'Products | Tree St.' } },
	{ path: 'administrator/categories', component: AdminCategoriesComponent, data: { title: 'Categories | Tree St.' } },
	{ path: 'administrator/promotions', component: AdminPromotionsComponent, data: { title: 'Promotions | Tree St.' } },
	{ path: 'administrator/reviews', component: AdminReviewsComponent, data: { title: 'Reviews | Tree St.' } },
	{ path: 'administrator/statistics', component: AdminStatisticsComponent, data: { title: 'Statistics | Tree St.' } },
	{ path: 'administrator/sales', component: AdminSalesComponent, data: { title: 'Sales | Tree St.' } },
	{ path: 'administrator/coupons', component: AdminCouponsComponent, data: { title: 'Coupons | Tree St.' } },
	{ path: 'administrator/disputes', component: AdminDisputesComponent, data: { title: 'Disputes | Tree St.' } },
	{ path: 'administrator/subscriptions', component: AdminSubscriptionsComponent, data: { title: 'Subscriptions | Tree St.' } },
	{ path: 'administrator/taxes', component: AdminTaxesComponent, data: { title: 'Taxes | Tree St.' } },
	{ path: 'administrator/users', component: AdminUsersComponent, data: { title: 'Users | Tree St.' } },
	{ path: 'administrator/balances', component: AdminBalancesComponent, data: { title: 'Balances | Tree St.' } },
	{ path: 'administrator/friends', component: AdminFriendsComponent, data: { title: 'Friends | Tree St.' } },
	{ path: 'administrator/gifts', component: AdminGiftsComponent, data: { title: 'Gifts | Tree St.' } },
	{ path: 'administrator/messages', component: AdminMessagesComponent, data: { title: 'Messages | Tree St.' } },
	{ path: 'administrator/points', component: AdminPointsComponent, data: { title: 'Points | Tree St.' } }
];