import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { appRoutes } from '../app.routes';
import { Observable } from 'rxjs/Rx';
import * as CryptoJS from 'crypto-js';
import { UserAddress, Address, Locale } from '../models/address';
import { PasswordChange } from '../models/passwordchange';
import { Shop, Favourite } from '../models/sidebar';
import { PaymentMethod } from '../models/paymentmethod';
import { Subscription } from '../models/subscription';
import { Notification } from '../models/notification';
import { Registration } from '../models/registration';
import { Auth, TwoFactorAuth } from '../models/auth';
import { Transaction } from '../models/transaction';
import { Friendship } from '../models/friendship';
import { Withdrawal } from '../models/withdrawal';
import { Statistic } from '../models/statistic';
import { Promotion } from '../models/promotion';
import { Balances } from '../models/balances';
import { Activation } from '../models/activation';
import { Exchange } from '../models/exchange';
import { Recovery } from '../models/recovery';
import { Favorite } from '../models/favorite';
import { Account } from '../models/account';
import { Interac } from '../models/interac';
import { Invoice } from '../models/invoice';
import { Country } from '../models/country';
import { Report } from '../models/report';
import { Payment } from '../models/payment';
import { Message } from '../models/message';
import { Dispute } from '../models/dispute';
import { Parcel } from '../models/parcel';
import { Coupon } from '../models/coupon';
import { Friend } from '../models/friend';
import { Return } from '../models/return';
import { Review } from '../models/review';
import { Driver } from '../models/driver';
import { PayPal } from '../models/paypal';
import { Cheque } from '../models/cheque';
import { Image } from '../models/image';
import { Taxes } from '../models/taxes';
import { Order } from '../models/order';
import { Graph } from '../models/graph';
import { OAuth } from '../models/oauth';
import { Point } from '../models/point';
import { Issue } from '../models/issue';
import { Nonce } from '../models/nonce';
import { Bank } from '../models/bank';
import { Card } from '../models/card';
import { Coin } from '../models/coin';
import { Item } from '../models/item';
@Injectable()
export class UserService {
    private baseUrl: string = environment.base;
    authKey: string;
    position={};
    stop_location = 0;
    observedLocation: any;
    gettingBalance = false;
    userInfo: Registration;
    alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    constructor( private http       : Http,
                 private auth       : Auth,
                 private httpClient : HttpClientModule,
                 private cookie     : CookieService ) { }
    stop_loc() { this.stop_location=1; }
    // Authorize 👨💨 a user
    authorize(): Observable<Account> {
        var Url = `${this.baseUrl}st/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var AllNew = this.http
            .get(Url, Opts)
            .map(mapAuth)
            .catch(handleError)
        return AllNew;
    }
    guest(): Observable<Registration> {
        var Url = `${this.baseUrl}guest/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var AllNew = this.http
            .get(Url, Opts)
            .map(this.mapRegistration)
            .catch(handleError)
        return AllNew;
    }
    loggedIn() { 
        var O = `${this.baseUrl}logged_in/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var A = new RequestOptions({ headers: H });
        return this.http.get(O, A)
        .catch(handleError);
    }
    register(email, password, username): Observable<Registration> { 
        var Note = `${this.baseUrl}createuser/`;
        var App = new URLSearchParams();
            App.append('email', email);
            App.append('username', btoa('TreeSt'+username));
            App.append('password', btoa('WTF420'+password));
        return this.http.post(Note, App)
            .map(this.mapRegistration)
            .catch(handleError);
    }
    login(username, password): Observable<Registration> {
        var Hos = new URLSearchParams();
            Hos.append('username', btoa('TreeSt'+username));
            Hos.append('password', btoa('WTF420'+password));
        var Loc = `${this.baseUrl}authenticate/`;
        return this.http
            .post(Loc, Hos)
            .map(this.mapRegistration)
            .catch(handleError);
    }
    activate(key): Observable<Activation> {
        var Hos = new URLSearchParams();
            Hos.append('key', key);
        var Loc = `${this.baseUrl}activate/`;
        return this.http
            .post(Loc, Hos)
            .map(mapActivation)
            .catch(handleError);
    }
    getNonce(): Observable<Nonce> {
        var Path = `${this.baseUrl}nonce/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var anoseforthings = this.http
            .post(Path, Opts)
            .map(mapNonce)
            .catch(handleError);
        return anoseforthings;
    }
    checkNonce(check): Observable<Nonce> {
        var Path = `${this.baseUrl}nonce/check/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Op = new URLSearchParams();
            Op.append('nonce', check);
        var Opts = new RequestOptions({ headers: Head });
        var anoseforthings = this.http
            .post(Path, Opts)
            .map(mapNonce)
            .catch(handleError);
        return anoseforthings;
    }
    resetpwd(email, phone?): Observable<Recovery> {
        var Op = new URLSearchParams();
            Op.append('email', email);
            if (phone) Op.append('phone', phone);
        var Lok = `${this.baseUrl}reset_password/`;
        return this.http.post(Lok, Op).map(mapRecover)
        .catch(handleError);
    }
    // Tree Street ☘ logging in...  ☘
    twofactor(key): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}2fa/check/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            App.append('a', key);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }    
    twofactorsetup(): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}2fa/setup/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    notify_user() {
    }
    setSMSTokenAuth(on?) {
        if (on) {
            var z = Date.now()+9812894138+'';
            this.cookie.set('$_mT',z);
        } else {
            this.clearSMSTokenAuth();
        }
    }
    getSMSTokenAuth() {
        var z = Date.now()+9812894138;
        var t = this.cookie.get('$_mT');
        if ( z-Number(t) > 21600000 ) {
            this.cookie.delete('$_mT');
            return false;
        } else {
            return true;
        }
    }
    clearSMSTokenAuth() { this.cookie.delete('$_mT'); }
    send_withdrawal_sms(): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}secure/withdrawal/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            // App.append('_p', phone);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    send_sms( phone ): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}secure/sms/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            App.append('_p', phone);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    send_call( phone ): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}secure/call/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            App.append('_p', phone);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    check_auth_key( key ): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}secure/check/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            App.append('_c', key);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    check_sms_and_email_key( code, key ): Observable<TwoFactorAuth> {
        var Url = `${this.baseUrl}secure/email/check/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var App  = new URLSearchParams();
            App.append('_k', code);
            App.append('_c', key);
        var VeryCool = this.http
            .post(Url, App, Opts)
            .map(mapTwoFactor)
            .catch(handleError)
        return VeryCool;
    }
    get_username_by_recovery_key(key): Observable<Account> {
        var Url = `${this.baseUrl}find_user_by_key/`;
        var App  = new URLSearchParams();
            App.append('key', key);
        return this.http.post(Url, App)
            .map(mapAuth)
            .catch(handleError);
    }
    cp_key(): Observable<boolean> {
        var Url = `${this.baseUrl}protector/`;
        return this.http.get(Url).map(mapKey).catch(handleError);
    }
    change_password(key, password): Observable<PasswordChange> {
        var Url = `${this.baseUrl}recover_account/`;
        var App  = new URLSearchParams();
            App.append('key', key);
            App.append('password', btoa('WTF420'+password));
        return this.http.post(Url, App)
            .map(mapPasswordChange)
            .catch(handleError);
    }
    forceLocate(callback) {
        this.stop_location = 0;
        this.locate(callback);
    }
    locate(COz?) {
        if (this.stop_location == 1) return;
        if (this.stop_location == 0) {
            if (!navigator.geolocation) {
                COz(false);
                return false;
            }
            navigator.geolocation.getCurrentPosition( (Ge) => {
                var B = Ge.coords.latitude;
                var C = Ge.coords.longitude;
                if (B && C) {
                    this.position = { 
                        lat: B,
                        lng: C
                    };
                } else {
                    this.position = { 
                        lat: 1,
                        lng: 1
                    };
                }
                if (COz) COz(this.position); 
                if (!COz) return this.position;
            }, this.locationError);
        } else {
            COz(false);
        }
    } 
    watch_location(_callback, W=0) {
        if (this.stop_location == 1) return;
        if (navigator.geolocation) {
            this.observedLocation = navigator.geolocation.watchPosition( (Ge) => {
                var Si = Ge.coords.latitude;
                var Li = Ge.coords.longitude;
                if (Si && Li) {
                    this.position = {lat: Si,lng: Li};
                }
                if (W!=0) {
                    this.clear_watch(W);
                }
                _callback(this.position);
            }, this.locationError);
        } else {
            console.error("Geolocation is not supported by this browser."); _callback(false);
        }
    } 
    getFriends():Observable<Friend> { 
        var N = `${this.baseUrl}friend/list/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
        return this.http.get(N, Ze)
            .map(mapFriends)
            .catch(handleError);
    } //🐻 🐧 🤠 🐵
    addFriend( userid ):Observable<Friendship> { 
        var N = `${this.baseUrl}friend/add/`;
        var Gran = new Headers();
            Gran.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Gran });
        var Ar = new URLSearchParams();
            Ar.append('id', userid);
        return this.http.post(N, Ar, Ze)
            .map(mapFriendship)
            .catch(handleError);
    } // 🐶 🌟 👨 
    friendRequests():Observable<Friend> { 
        var N = `${this.baseUrl}friend/requests/`;
        var Gran = new Headers();
            Gran.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Gran });
        var Ar = new URLSearchParams();
        return this.http.post(N, Ar, Ze)
            .map(mapFriends)
            .catch(handleError);
    } //💫  ✨  🌟  
    acceptFriend( userid ):Observable<Friendship> { 
        var N = `${this.baseUrl}friend/accept/`;
        var Gran = new Headers();
            Gran.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Gran });
        var Ar = new URLSearchParams();
            Ar.append('id', userid);
        return this.http.post(N, Ar, Ze)
            .map(mapFriendship)
            .catch(handleError);
    } // 💫  ✨  🌟  

    rejectFriend( userid ):Observable<Friendship> { 
        var N = `${this.baseUrl}friend/reject/`;
        var Gran = new Headers();
            Gran.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Gran });
        var Ar = new URLSearchParams();
            Ar.append('id', userid);
        return this.http.post(N, Ar, Ze)
            .map(mapFriendship)
            .catch(handleError);
    } // 🤠  🎗  🤖
    unFriend( userid ):Observable<Friendship> { 
        var N = `${this.baseUrl}friend/remove/`;
        var Gran = new Headers();
            Gran.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Gran });
        var Ar = new URLSearchParams();
            Ar.append('id', userid);
        return this.http.post(N, Ar, Ze)
            .map(mapFriendship)
            .catch(handleError);
    } // ⚫  ☹️  😢
    startChatting( user ) {
        var users = [user]
        localStorage.setItem( 'tus', JSON.stringify(users) );
    }
    getChattingUsers() {
        var u = JSON.parse(localStorage.getItem('tus'));
        var Ur = `${this.baseUrl}account/info/`;
        var Hg = new URLSearchParams();
        if (u) Hg.append('users', u);
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
        return this.http.post(Ur, Hg, Ze).map(mapLocations);
    }
    favouriteUser( userid ):Observable<Friendship> { 
        var N = `${this.baseUrl}friend/favourite/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
        var Ar = new URLSearchParams();
            Ar.append( 'userid', userid );
        return this.http.post(N, Ar, Ze)
            .map(mapFriendship)
            .catch(handleError);
    }
    pingLatLng(s):Observable<Locale> {
        var Ur = `${this.baseUrl}locate/latlng/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
            Ze.params = s;
        return this.http.get(Ur, Ze).map(mapLocations);
    }
    cacheRegistration(newUser) {
        localStorage.setItem('Tud', newUser);
    }
    locationError(error) {
        switch (error.code) {
            case 3:
                console.log(error);
            break;
            case 2:
                console.log(error);
            break;
            case 1:
                this.stop_location = 1;
                console.log(error);
            break;
        }
    }
    clear_watch(seconds=0) {
        if (this.stop_location == 1) return;
        if (navigator.geolocation) {
            if ( seconds > 0 ) {
                var x = seconds * 1000;
                setTimeout( () => {
                    navigator.geolocation.clearWatch(this.observedLocation);
                },x);
            } else {
                navigator.geolocation.clearWatch(this.observedLocation);
            }
        }
    }
    setLocKey(k) { if (k && k != 'undefined') return localStorage.setItem('Tlc', k); }
    setLocMsg(k) { if (k && k != 'undefined') return localStorage.setItem('Tlm', k); }
    locKey() { return localStorage.getItem('Tlc'); }
    locMsg() { return localStorage.getItem('Tlm'); }
    locale(): Observable<Locale> {
        var O = `${this.baseUrl}locate/`;
        var Oz = new URLSearchParams();
            if (this.locKey()) Oz.append('loc', String(this.locKey()));
        var Ozz = new Headers();
            Ozz.append('Authorization', this.wib());
        var Hoo = new RequestOptions({ headers: Ozz });
        return this.http.post(O, Oz, Hoo).map(mapLocale)
    }
    changeLocale( code ) {
        localStorage.setItem('Tsc', code);
    }
    getLocale() { 
        return localStorage.getItem('Tsc');
    }
    getLocaleCountry() { 
        return localStorage.getItem('Tsc');
    }
    set_currency(c): Observable<string> {
        var Pro = `${this.baseUrl}currency/set/`;
        var Ffw = new Headers();
            Ffw.append('Authorization', this.wib());
        var Hob = new RequestOptions({ headers: Ffw });
        var Sup = new URLSearchParams();
            Sup.append('c', c);
        return this.http.post(Pro, Sup, Hob)
            .map(mapCurrency)
            .catch(handleError);
    }
    get_currency(): Observable<string> {
        var Monie = `${this.baseUrl}user/currency/`;
        var Wzz = new Headers();
            Wzz.append('Authorization', this.wib());
        var Woo = new RequestOptions({ headers: Wzz });
        return this.http.get(Monie, Woo)
            .map(mapCurrency)
            .catch(handleError);
    } 
    set_currency_empty(show_empty): Observable<string> {
        var Pro = `${this.baseUrl}currency/set/empty/`;
        var Ffw = new Headers();
            Ffw.append('Authorization', this.wib());
        var Hob = new RequestOptions({ headers: Ffw });
        var Sup = new URLSearchParams();
            if(show_empty) Sup.append('show_empty', show_empty);
        return this.http.post(Pro, Sup, Hob)
            .map(mapCurrency)
            .catch(handleError);
    }
    get_currency_empty(): Observable<boolean> {
        var Pro = `${this.baseUrl}currency/get/empty/`;
        var Ffw = new Headers();
            Ffw.append('Authorization', this.wib());
        var Hob = new RequestOptions({ headers: Ffw });
        return this.http.get(Pro, Hob)
            .map(mapCurrencyBool)
            .catch(handleError);
    }
    set_applied_coupon(coupon): Observable<Coupon> {
        var Path = `${this.baseUrl}user/set_coupon/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Op = new URLSearchParams();
            Op.append('coupon', coupon);
        var Opts = new RequestOptions({ headers: Head });
        var appliedthatmyself = this.http
            .post(Path, Opts)
            .map(mapCoupons)
            .catch(handleError);
        return appliedthatmyself;
    }
    recover(email, phone?): Observable<Recovery> {
        var Op = new URLSearchParams();
            Op.append('email', email);
            if (phone) Op.append('phone', phone);
        var Lok = `${this.baseUrl}recover_account/`;
        return this.http.post(Lok, Op).map(mapRecover)
        .catch(handleError);
    }
    share_email(email) {
        var wzz = new URLSearchParams();
        if (email.to) wzz.append('to', email.to);
        if (email.subject) wzz.append('subject', email.subject);
        if (email.message) wzz.append('message', email.message);
        var O = `${this.baseUrl}share_email/`;
        return this.http.post(O, wzz);
    }
    logout(): Observable<boolean> {
        localStorage.removeItem('Wib');
        sessionStorage.removeItem('acc');
        var Close = `${this.baseUrl}logout/`;
        var Feelz = new Headers();
            Feelz.append('Authorization', this.wib());
        var Ganj = new RequestOptions({ headers: Feelz });
        return this.http
            .get(Close, Ganj)
            .map(mapBoolean)
            .catch(handleError);
    }
    welcome(email,name,password,captcha): Observable<Registration> {
        var Ozz = new URLSearchParams();
            Ozz.append('email', email);
            Ozz.append('user', btoa('TreeSt'+name));
            Ozz.append('password', btoa('WTF420'+password));
            Ozz.append('recaptcha', captcha);
        var Oz = `${this.baseUrl}createuser/`;
        var user = this.http.post(Oz, Ozz)
        .map(this.mapRegistration)
        .catch(handleError);
        return user;
    }
    firstVisit() { 
        var Ozzzz = Date.now()+'';
        var Welcome = this.cookie.get('Vi');
        if ( !Welcome ) {
            if ( environment.production ) this.auth.eyes();
            this.cookie.set('Vi', Ozzzz);
            return true;
        }
    }
    set_alert_volumes(Ozzzz = '0.4') { 
        this.cookie.set('Al', Ozzzz);
    }
    alertVolumes() { 
        var Vol = this.cookie.get('Al');
        if(!Vol) Vol = '0.4';
        return Vol;
    }
    getProgress() { 
        var Progress = this.cookie.get('Pro');
        if ( Progress ) {
            var Prog = parseInt(Progress);
            var p = (Prog/457582);
            return p;
        } else { 
            return 0;
        }
    }
    stepProgress(t=0) { 
        if( t == 0 ) {
            var Progress = this.cookie.get('Pro');
            var p = 0;var Prog = 0;
            if ( Progress ) {
                var Prog = parseInt(Progress);
            }
            if (!Prog||Prog==0) {
                p = 1;
            } else  {
                p = (Prog/457582) + 1;
            }
            var n = (457582*p);
            this.cookie.set('Pro', n.toString());
        } else if (t>0) {
            var Progress = this.cookie.get('Pro');
            var p = 0;var Prog = 0;
            if ( Progress ) {
                var Prog = parseInt(Progress);
            }
            if (!Prog||Prog==0) {
                p = 1;
            } else if (Prog<t) {
                p = t;
            }
            var n = (457582*t);
            this.cookie.set('Pro', n.toString());
        }
        return true;
    }
    getShops(): Observable<Shop> {
        var O2 = `${this.baseUrl}alerts/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var N = new RequestOptions({ headers: H });
        return this.http.get(O2, N).map(mapShops)
    }
    getFavorites(): Observable<Favourite> {
        var N = `${this.baseUrl}alerts/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var Oh = new RequestOptions({ headers: H });
        return this.http.get(N, Oh).map(mapFavorite);
    }
    getAlerts(): Observable<Notification> {
        var On = `${this.baseUrl}alerts/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var N = new RequestOptions({ headers: H });
        return this.http.get(On, N).map(mapAlerts);
    }
    getConversations(): Observable<Message> {
        var Of = `${this.baseUrl}conversations/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var Aa = new URLSearchParams();
            Aa.append( 'authorization', this.wib() )
        var N = new RequestOptions({ headers: H });
        return this.http.post(Of,Aa,N).map(mapConversations);
    }
    countConversations(): Observable<number> {
        var Op = `${this.baseUrl}conversations/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var Aa = new URLSearchParams();
            Aa.append( 'authorization', this.wib() )
        var N = new RequestOptions({ headers: H });
        return this.http.post(Op,Aa,N).map(countConversations);
    }
    getMessages( user ):Observable<Message> {
        var N = `${this.baseUrl}messages/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var MsgFilters = new URLSearchParams();
            MsgFilters.append( 'user', user )
        var Ze = new RequestOptions({ headers: Grph });
        return this.http.post(N, MsgFilters, Ze).map(mapMessages).catch(handleError);
    }
    getAllMessages():Observable<Message> {
        var N = `${this.baseUrl}all_messages/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
        return this.http.get(N, Ze).map(mapMessages).catch(handleError);
    }
    sendMessage( message ):Observable<Message> {
        var N = `${this.baseUrl}messages/say/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var Ze = new RequestOptions({ headers: Grph });
        var Ar = new URLSearchParams();
            Ar.append( 'message', message.nomsg );
            Ar.append( 'friend', message.user );
        return this.http.post(N, Ar, Ze).map(mapMessages).catch(handleError);
    }
    testMessages(): Observable<Message> {
        var Oz = `${this.baseUrl}test_messages/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var Aa = new URLSearchParams();
            Aa.append( 'authorization', this.wib() )
        var N = new RequestOptions({ headers: H });
        return this.http.post(Oz,Aa,N).map(mapMessages);
    }
    countMessages(user): Observable<number> {
        var N = `${this.baseUrl}messages/`;
        var Grph = new Headers();
            Grph.append('Authorization', this.wib());
        var MsgFilters = new URLSearchParams();
            MsgFilters.append( 'user', user )
        var Ze = new RequestOptions({ headers: Grph });
        return this.http.post(N, MsgFilters, Ze).map(countMessages).catch(handleError);
    }
    getNotifications(): Observable<Notification> {
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = `${this.baseUrl}notifications/`;
        var N = new RequestOptions({ headers: H });
        return this.http.get(O, N).map(mapNotifs);
    }
   
    getMaxOut(): Observable<number> {
        var Air = `${this.baseUrl}balance/max/`;
        var N = this.http
            .get(Air).map(mapMax)
            .catch(handleError);
        return N;
    }
    getMinOut(): Observable<number> {
        var Air = `${this.baseUrl}balance/min/`;
        var N = this.http
            .get(Air).map(mapMin)
            .catch(handleError);
        return N;
    }
    getBalanceEnabled(): Observable<boolean> {
        var Air = `${this.baseUrl}balance/max/`;
        var N = this.http
            .get(Air).map(mapEnabled)
            .catch(handleError);
        return N;
    }
    accountBalance(): Observable<number> {
        var Air = `${this.baseUrl}balance/account/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var N = this.http
            .post(Air, H, O)
            .map(mapBalance)
            .catch(handleError);
        return N;
    }
    getAccountBalanceCurrency(): Observable<string> {
        var Air = `${this.baseUrl}balance/account/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var Si = this.http
            .post(Air, H, O)
            .map(mapBalanceCurrency)
            .catch(handleError);
        return Si;
    }
    getBalances(): Observable<Balances> {
        // if (this.gettingBalance) return Balances[];
        // this.gettingBalance = true;
        var Air = `${this.baseUrl}balances/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var Ba = this.http
            .post(Air, H, O)
            .map(mapBalances)
            .catch(handleError);
        return Ba;
    }
    new_coin(): Observable<Balances> {
        var Air = `${this.baseUrl}balances/new_coin/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var Ba = this.http
            .post(Air, H, O)
            .map(mapNewCoin)
            .catch(handleError);
        return Ba;
    }
    getPaymentProducts(): Observable<PaymentMethod> {
        var Air = `${this.baseUrl}payment_products/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var Si = this.http
            .post(Air, H, O)
            .map(mapPaymentProducts)
            .catch(handleError);
        return Si;
    }
    paymentProductCount(): Observable<number> {
        var Air = `${this.baseUrl}cards/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var pot = this.http
            .post(Air, H, O)
            .map(countCards)
            .catch(handleError);
        return pot;
    }
    getInvoices(): Observable<Invoice> {
        var Air = `${this.baseUrl}invoices/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var ear = this.http
            .post(Air, H, O)
            .map(mapInvoices)
            .catch(handleError);
        return ear;
    }
    getReports(): Observable<Report> {
        var Air = `${this.baseUrl}reports/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var rep = this.http
            .post(Air, H, O)
            .map(mapReports)
            .catch(handleError);
        return rep;
    }
    getReviews(): Observable<Review> {
        var Air = `${this.baseUrl}reviews/`;
        var H = new Headers();
            H.append('Authorization', this.wib());
        var O = new RequestOptions({ headers: H });
        var rev = this.http
            .post(Air, H, O)
            .map(mapReviews)
            .catch(handleError);
        return rev;
    }
    getOrderReviews(orderID): Observable<Review> {
        var URL = `${this.baseUrl}orders/reviews/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib());
        var Params = new URLSearchParams();
            Params.append('order', orderID);
        var Opts = new RequestOptions({ headers: Header });
        var p = this.http
            .post(URL, Params, Opts)
            .map(mapReviews)
            .catch(handleError);
        return p;
    }
    getGramsBought(): Observable<Statistic> {
        var Art = `${this.baseUrl}stats/grams_bought/`;
        var Square = new Headers();
            Square.append('Authorization', this.wib());
        var Oval = new RequestOptions({ headers:Square });
        var Reveal = this.http
            .post(Art, Square, Oval)
            .map(mapStatistics)
            .catch(handleError);
        return Reveal;
    }
    getProductsDelivered(): Observable<Statistic> {
        var A = `${this.baseUrl}stats/products_delivered/`;
        var D = new Headers();
            D.append('Authorization', this.wib());
        var B = new RequestOptions({ headers: D });
        var Aa = this.http
            .post(A, D, B)
            .map(mapStatistics)
            .catch(handleError);
        return Aa;
    }
    getPagesBrowsed(): Observable<Statistic> {
        var B = `${this.baseUrl}stats/pages_browsed/`;
        var D = new Headers();
            D.append('Authorization', this.wib());
        var C = new RequestOptions({ headers: D });
        var F = this.http
            .post(B, D, C)
            .map(mapStatistics)
            .catch(handleError);
        return F;
    }
    post_review(review,n?): Observable<Review> {
        var Url = `${this.baseUrl}reviews/post/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib()        );
        var Params = new URLSearchParams();
            Params.append('product',           review.product);
            Params.append('item',                 review.item);
            Params.append('order',               review.order);
            Params.append('vendor',             review.vendor);
            Params.append('driver',             review.driver);
            Params.append('rating',             review.rating);
            Params.append('body',                 review.body);
            Params.append('nonce',                          n);
        var Opts = new RequestOptions({ headers: Header });
        var p = this.http
            .post(Url, Params, Opts)
            .map(mapReviews)
            .catch(handleError);
        return p;
    }
    post_report(rep,n?): Observable<Report> {
        var Url = `${this.baseUrl}report/post/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib()         );
        var Params = new URLSearchParams();
            Params.append('product',               rep.product);
            Params.append('item',                     rep.item);
            Params.append('order',                   rep.order);
            Params.append('vendor',                 rep.vendor);
            Params.append('driver',                 rep.driver);
            Params.append('rating',                 rep.rating);
            Params.append('body',                     rep.body);
            Params.append('reply',                   rep.reply);
            Params.append('refund_offered', rep.refund_offered);
            Params.append('nonce', n);
        var Opts = new RequestOptions({ headers: Header });
        var r = this.http
            .post(Url, Params, Opts)
            .map(mapReport)
            .catch(handleError);
        return r;
    }
    oAuths() {
        var O2 = `${this.baseUrl}oauths/`;
        return this.http.get(O2).map(mapOAuths)
    }
    account(id?): Observable<Account> { 
        var Url = `${this.baseUrl}usermodel/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Params = new URLSearchParams();
            Params.append('id', id);
        var OOpts = new RequestOptions({ headers: Head });
        return this.http.post(Url, Params, OOpts).map(mapAccounts)
    }
    checkEmail(email): Observable<boolean> { 
        var Parms = `?email=`+email;
        var Url = `${this.baseUrl}checkemail/`+Parms;
        return this.http
            .get(Url)
            .map(returnBoolean)
            .catch(handleError);
    }
    changeEmail(email, nav): Observable<Account> { 
        var Url = `${this.baseUrl}updateuser/`;
        var O2 = new URLSearchParams();
            O2.append('email', String(email));
            O2.append('comp', String(nav));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        return this.http
            .post(Url, O2, Treez)
            .map(updatedUserdata)
            .catch(handleError);
    }
    changeUsername(username): Observable<Account> { 
        var Url = `${this.baseUrl}updateuser/`;
        var O2 = new URLSearchParams();
            O2.append('username', String(username));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        return this.http
            .post(Url, O2, Treez)
            .map(updatedUserdata)
            .catch(handleError);
    }
    changePassword(password, old_one): Observable<Account> {
        var Url = `${this.baseUrl}updateuser/`;
        var pwd = btoa('WTF420'+password);
        var old = btoa('WTF420'+old_one);
        var O2 = new URLSearchParams();
            O2.append('password', String(pwd));
            O2.append('old_password', String(old));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        return this.http
            .post(Url, O2, Treez)
            .map(updatedUserdata)
            .catch(handleError);
    }
    checkUser(username): Observable<boolean> {
        var Url = `${this.baseUrl}checkuser/?username=`+username;
        return this.http
            .get(Url)
            .map(returnBoolean)
            .catch(handleError);
    }
    mapRegistration(a) {
        var b = a.json().registration[0];
        if ( b.code != 200 ) {
            return b;
        } else {
            localStorage.removeItem('Wib');
            this.authKey = b.jwt+'';
            localStorage.setItem('Wib', this.authKey);
            // if ( this.cookie && this.cookie.get('Wib') ) this.cookie.delete('Wib');
            // if ( this.cookie ) this.cookie.set('Wib', this.authKey);
            return b;
        }
    }
    savePGP(p): Observable<UserAddress> {
        var Buz = `${this.baseUrl}new_pgp/`;
        var O2 = new URLSearchParams();
            if ( p.vendor )        O2.append('vendor', String(p.vendor));
            if ( p.vendor_key )    O2.append('vendor_key', String(p.vendor_key));
            if ( p.address_key )   O2.append('address_key', String(p.address_key));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        var Aqua = this.http.post(Buz, O2, Treez)
            .map(mapUserAddress)
            .catch(handleError)
        return Aqua;
    }
    newAddresses(addr): Observable<UserAddress> {
        var Buz = `${this.baseUrl}new_addresses/`;
        var x = (addr.countrycode) ? addr.countrycode : addr.country;
        var O2 = new URLSearchParams();
            if ( addr.fullname )    O2.append('fullname', String(addr.fullname));
            if ( addr.address )     O2.append('address', String(addr.address));
            if ( addr.unit )        O2.append('unit', String(addr.unit));
            if ( addr.note )        O2.append('note', String(addr.note));
            if ( x )                O2.append('country', String(x));
            if ( addr.province )    O2.append('province', String(addr.province));
            if ( addr.postal )      O2.append('postal', String(addr.postal));
            if ( addr.city )        O2.append('city', String(addr.city));
            if ( addr.phone )       O2.append('phone', String(addr.phone));
            if ( addr.latitude )    O2.append('latitude', String(addr.latitude));
            if ( addr.longitude )   O2.append('longitude', String(addr.longitude));
            if ( addr.delivery_available )    O2.append('delivery_available', String(addr.delivery_available));
            if ( addr.special_instructions ) O2.append('special_instructions', String(addr.special_instructions));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        var Aqua = this.http.post(Buz, O2, Treez)
            .map(mapUserAddress)
            .catch(handleError)
        return Aqua;
    }
    editAddress(addr): Observable<UserAddress> {
        var Buz = `${this.baseUrl}edit_addresses/`;
        var Oz = new URLSearchParams();
            if ( addr.id )          Oz.append('id', String(addr.id));
            if ( addr.fullname )    Oz.append('fullname', String(addr.fullname));
            if ( addr.address )     Oz.append('address', String(addr.address));
            if ( addr.unit )        Oz.append('unit', String(addr.unit));
            if ( addr.note )        Oz.append('note', String(addr.note));
            if ( addr.country )     Oz.append('country', String(addr.countrycode));
            if ( addr.province )    Oz.append('province', String(addr.province));
            if ( addr.postal )      Oz.append('postal', String(addr.postal));
            if ( addr.city )        Oz.append('city', String(addr.city));
            if ( addr.phone )       Oz.append('phone', String(addr.phone));
            if ( addr.latitude )    Oz.append('latitude', String(addr.latitude));
            if ( addr.longitude )   Oz.append('longitude', String(addr.longitude));
            if ( addr.special_instructions ) Oz.append('special_instructions', String(addr.special_instructions));
            Oz.append('delivery_available', String(addr.delivery_available));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Treez = new RequestOptions({ headers: Head });
        var Aqua = this.http.post(Buz, Oz, Treez)
            .map(mapUserAddress)
            .catch(handleError)
        return Aqua;
    }
    getAddresses(): Observable<UserAddress> {
        var Url = `${this.baseUrl}addresses/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Dresses = this.http
            .get(Url, Opts)
            .map(mapUserAddress)
            .catch(handleError)
        return Dresses;
    }
    autocompleteAddresses(addr): Observable<UserAddress> {
        var Url = `${this.baseUrl}autocomplete/`;
        var Oz = new URLSearchParams();
            Oz.append('address', String(addr));
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Dresses = this.http
            .post(Url, Oz, Opts)
            .map(mapUserAddress)
            .catch(handleError)
        return Dresses;
    }
    lengthAddress(): Observable<number> {
        var Url = `${this.baseUrl}addresses/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Ada = this.http
            .get(Url, Opts)
            .map(countAddress)
            .catch(handleError)
        return Ada;
    }
    changeOrderAddress(orderID, newAddress): Observable<boolean> {
        var Src = `${this.baseUrl}order/change_address/`;
        var Mix = new Headers();
            Mix.append('Authorization', this.wib());
        var Aux = new URLSearchParams();
            if ( newAddress.address ) {
                Aux.append('orderId', String(orderID));
                Aux.append('address', String(newAddress.address));
            }
        var Mains = new RequestOptions({ headers: Mix });
        var bool = this.http
                    .post(Src, Aux, Mains)
                    .map(changeAddress)
                    .catch(handleError)
        return bool;
    }
    getOrder(id=0,l=12,o=0,acc=0): Observable<Order> {
        var Url = `${this.baseUrl}orders/?id=`+id+`&limit=`+l+`&offset=`+o;
        if ( acc > 0 ) Url = Url + `&account=`+acc;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Chess = this.http
            .get(Url, Opts)
            .map(mapOrders)
            .catch(handleError)
        return Chess;
    }
    getOrders(id=0,l=12,o=0,acc=0): Observable<Order> {
        var Url = `${this.baseUrl}orders/?id=`+id+`&limit=`+l+`&offset=`+o;
        if ( acc > 0 ) Url = Url + `&account=`+acc;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Plebs = this.http
            .get(Url, Opts)
            .map(mapOrders)
            .catch(handleError)
        return Plebs;
    }
    getSales(id=0,l=12,o=0): Observable<Order> {
        var Url = `${this.baseUrl}sales/?id=`+id+`&limit=`+l+`&offset=`+o;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Shillings = this.http
            .get(Url, Opts)
            .map(mapOrders)
            .catch(handleError)
        return Shillings;
    }
    getpaid(id=0): Observable<any> {
        var Url = `${this.baseUrl}orders/paid/?id=`+id;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Restraunt = this.http
            .get(Url, Opts)
            .map(isOrderPaid)
            .catch(handleError)
        return Restraunt;
    }
    getSalesGraph(x?,t?): Observable<Graph> {
        var Url = `${this.baseUrl}graph/sales/`;
        var Params = new URLSearchParams();
            if ( x ) Params.append('x', x);
            if ( t ) Params.append('t', t);
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var g = this.http
            .post(Url, Params, Opts)
            .map(mapGraph)
            .catch(handleError)
        return g;
    }
    getPaymentCards(): Observable<Card> {
        var Url = `${this.baseUrl}cards/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var Bill = this.http
            .get(Url, Opts)
            .map(mapCards)
            .catch(handleError)
        return Bill;
    }
    getTransations(limit, offset, type, currency): Observable<Transaction> {
        var Url = `${this.baseUrl}transactions/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Params = new URLSearchParams();
            Params.append('limit',limit);
            Params.append('offset',offset);
            if(currency)Params.append('currency',currency);
            if(type)Params.append('type',type);
        var Opts = new RequestOptions({ headers: Head });
        var TXT = this.http
            .post(Url, Params, Opts)
            .map(mapTransactions)
            .catch(handleError)
        return TXT;
    }

    outgoing_et( details ): Observable<Withdrawal> {
        var Wd = new URLSearchParams();
            Wd.append('n', details.nonce);
            Wd.append('inc', details.inc);
            Wd.append('amount', details.amount);
            Wd.append('currency', details.currency);
            Wd.append('auto', details.auto);
        var Sec = new Headers();
            Sec.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Sec });
        var Uri = `${this.baseUrl}user/withdrawal/etransfer/`;
        var w = this.http
            .post( Uri, Wd, Opts )
            .map( mapWithdrawal )
            .catch(handleError);
        return w;
    }
    outgoing_bw( details ): Observable<Withdrawal> {
        var Wd = new URLSearchParams();
            Wd.append('n', details.nonce);
            Wd.append('inc', details.inc);
            Wd.append('amount', details.amount);
            Wd.append('currency', details.currency);
            Wd.append('auto', details.auto);
        var Sec = new Headers();
            Sec.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Sec });
        var Uri = `${this.baseUrl}user/withdrawal/bank/`;
        var w = this.http
            .post( Uri, Wd, Opts )
            .map( mapWithdrawal )
            .catch(handleError);
        return w;
    }
    outgoing_pp( details ): Observable<Withdrawal> {
        var Wd = new URLSearchParams();
            Wd.append('inc', details.inc);
            Wd.append('amount', details.amount);
            Wd.append('currency', details.currency);
            Wd.append('auto', details.auto);
            Wd.append('account', details.account);
        var Sec = new Headers();
            Sec.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Sec });
        var Uri = `${this.baseUrl}user/withdrawal/paypal/`;
        var w = this.http
            .post( Uri, Wd, Opts )
            .map( mapWithdrawal )
            .catch(handleError);
        return w;
    }    
    outgoing_co( details ): Observable<Withdrawal> {
        var Wd = new URLSearchParams();
            Wd.append('coin', details.coin);
            Wd.append('amount', details.amount);
            Wd.append('currency', details.currency);
            Wd.append('auto', details.auto);
            Wd.append('account', details.account);
        var Sec = new Headers();
            Sec.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Sec });
        var Uri = `${this.baseUrl}user/withdrawal/coin/`;
        var w = this.http
            .post( Uri, Wd, Opts )
            .map( mapWithdrawal )
            .catch(handleError);
        return w;
    }
    addCreditCard( card ): Observable<Card> {
        var Bong = new URLSearchParams();
            Bong.append('fullname', card.fullname);
            Bong.append('n', this.cc( card.number ));
            Bong.append('c', this.cc( card.ccv ));
            Bong.append('country',card.country);
            if ( card.country_code ) Bong.append('country',card.country_code);
            Bong.append('postal', card.postal);
            Bong.append('exp_month',card.exp_month );
            Bong.append('exp_year',card.exp_year );
            Bong.append('save', (card.save) );
        var Pipe = `${this.baseUrl}card/add/`;
        var Lighter = new Headers();
            Lighter.append('Authorization', this.wib());
        var Hit = new RequestOptions({ headers: Lighter });
        return this.http
            .post(Pipe, Bong, Hit)
            .map(mapCards)
            .catch(handleError);
    }
    getPayPal(): Observable<PayPal> {
        var Pipe = `${this.baseUrl}paypal/get/email/`;
        var Bowl = new Headers();
            Bowl.append('Authorization', this.wib());
        var Pot = new RequestOptions({ headers: Bowl });
        var Fire = this.http
            .get(Pipe, Pot)
            .map(mapPayPal)
            .catch(handleError);
        return Fire;
    }
    countPayPal(): Observable<number> {
        var Pape = `${this.baseUrl}paypal/get/email/`;
        var Weed = new Headers();
            Weed.append('Authorization', this.wib());
        var Light = new RequestOptions({ headers: Weed });
        var Toke = this.http
            .get(Pape, Light)
            .map(countPayPals)
            .catch(handleError);
        return Toke;
    }
    check_old_password(pwd): Observable<boolean> {
        var Params = new URLSearchParams();
            Params.append('pwd', btoa('WTF420'+pwd));
        var Path = `${this.baseUrl}check_pwd/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newCard = this.http
            .post(Path, Params, Opts)
            .map(mapValidPwd)
            .catch(handleError);
        return newCard;
    }
    addPayPal( paypal ): Observable<PayPal> {
        var Light = new URLSearchParams();
            Light.append('email', paypal.email);
        var Roll = `${this.baseUrl}paypal/email/`;
        var Friend = new Headers();
            Friend.append('Authorization', this.wib());
        var Tokes = new RequestOptions({ headers: Friend });
        var Smoked = this.http
            .post(Roll, Light, Tokes)
            .map(mapPayPal)
            .catch(handleError);
        return Smoked;
    }
    addCheque( cheque ): Observable<Cheque> {
        var Light = new URLSearchParams();
            Light.append('address',  cheque.address);
            Light.append('currency', cheque.currency);
        var Roll = `${this.baseUrl}cheques/add/`;
        var Friend = new Headers();
            Friend.append('Authorization', this.wib());
        var Tokes = new RequestOptions({ headers: Friend });
        var Smoked = this.http
            .post(Roll, Light, Tokes)
            .map(mapCheques)
            .catch(handleError);
        return Smoked;
    }
    getInterac(): Observable<Interac> {
        var Path = `${this.baseUrl}interac/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var card = this.http
            .get(Path, Opts)
            .map(mapInterac)
            .catch(handleError);
        return card;
    }
    getsecret( sec = 0 ): Observable<string> {
        var Path = `${this.baseUrl}sec/?id=`+sec;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var sx = this.http
            .get(Path, Opts)
            .map(mapSecret)
            .catch(handleError);
        return sx;
    }
    countInterac(): Observable<number> {
        var Path = `${this.baseUrl}interac/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var card = this.http
            .get(Path, Opts)
            .map(countInteracs)
            .catch(handleError);
        return card;
    }
    addInterac( inter ): Observable<Interac> {
        var Params = new URLSearchParams();
            Params.append('email', inter.email);
            Params.append('word',  inter.word);
            Params.append('fullname',  inter.fullname);
        var Path = `${this.baseUrl}interac/add/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newCard = this.http
            .post(Path, Params, Opts)
            .map(mapInterac)
            .catch(handleError);
        return newCard;
    }
    setCurrency(currency) {
        var BankParams = new URLSearchParams();
            if( currency ) BankParams.append('currency', currency);
        var Path = `${this.baseUrl}currency/change/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var resetSw = this.http
            .post(Path, BankParams, Opts)
            .map(mapBanks)
            .catch(handleError);
        return resetSw;
    }
    convertCurrency(amount, from_currency, to_currency, nonce): Observable<Exchange> {
        var SrgParams = new URLSearchParams();
            if( from_currency ) SrgParams.append('from_currency', from_currency);
            if( to_currency ) SrgParams.append('to_currency', to_currency);
            if( amount ) SrgParams.append('amount', amount);
            if( nonce ) SrgParams.append('nonce', nonce);
        var Path = `${this.baseUrl}currency/exchange/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newDay = this.http
            .post(Path, SrgParams, Opts)
            .map(mapExchange)
            .catch(handleError);
        return newDay;
    }
    saveCoinAddress(coin,address): Observable<Coin> {
        var CoinParams = new URLSearchParams();
            if( coin ) CoinParams.append('coin', coin);
            if( address ) CoinParams.append('address', address);
        var Path = `${this.baseUrl}coin/add/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newCoins = this.http
            .post(Path, CoinParams, Opts)
            .map(mapCoins)
            .catch(handleError);
        return newCoins;
    }
    saveBankAccount(bank): Observable<Bank> {
        var BankParams = new URLSearchParams();
            if( bank.id ) BankParams.append('id', bank.id);
            if( bank.country ) BankParams.append('c_ad', bank.country);
            if( bank.currency ) BankParams.append('c_an', bank.currency);
            if( bank.account_holder_name ) BankParams.append('a_fre', this.s(this.r(124)+bank.account_holder_name+this.r(78)));
            if( bank.account_holder_type ) BankParams.append('a_wre', this.s(this.r(77)+bank.account_holder_type+this.r(21)));
            if( bank.routing_number ) BankParams.append('c_gar', this.s(this.r(55)+bank.routing_number+this.r(86)));
            if( bank.account_number ) BankParams.append('a_msr', this.s(this.r(44)+bank.account_number+this.r(43)));
            if( bank.bank_name ) BankParams.append('b_fw', this.s(this.r(77)+bank.bank_name+this.r(86)));
            if( bank.token ) BankParams.append('t', bank.token);
            if( bank.save ) BankParams.append('s', bank.save);
        var Path = `${this.baseUrl}bank/add/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newBank = this.http
            .post(Path, BankParams, Opts)
            .map(mapBanks)
            .catch(handleError);
        return newBank;
    }
    removeBankAccount(bankid): Observable<Bank> {
        var BankParams = new URLSearchParams();
            BankParams.append('id', bankid);
        var Path = `${this.baseUrl}bank/remove/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var rmBank = this.http
            .post(Path, BankParams, Opts)
            .map(mapBanks)
            .catch(handleError);
        return rmBank;
    }
    removeInteracAccount(email): Observable<Interac> {
        var Int = new URLSearchParams();
            Int.append('email', email);
        var Path = `${this.baseUrl}interac/remove/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var rmInc = this.http
            .post(Path, Int, Opts)
            .map(mapInterac)
            .catch(handleError);
        return rmInc;
    }
    removePayPal(email): Observable<PayPal> {
        var PP = new URLSearchParams();
            PP.append('email', email);
        var Pth = `${this.baseUrl}paypal/remove/`;
        var Hed = new Headers();
            Hed.append('Authorization', this.wib());
        var Opp = new RequestOptions({ headers: Hed });
        var rmPP = this.http
            .post(Pth, PP, Opp)
            .map(mapPayPal)
            .catch(handleError);
        return rmPP;
    }
    removeCoinAddress(address): Observable<Coin> {
        var PP = new URLSearchParams();
            PP.append('address', address);
        var Pth = `${this.baseUrl}coin/remove/`;
        var Hed = new Headers();
            Hed.append('Authorization', this.wib());
        var Opp = new RequestOptions({ headers: Hed });
        var rmC = this.http
            .post(Pth, PP, Opp)
            .map(mapCoins)
            .catch(handleError);
        return rmC;
    }
    newCoinAddress(coin): Observable<Coin> {
        var PP = new URLSearchParams();
        var Pth = `${this.baseUrl}`+coin+`/new/`;
        var Hed = new Headers();
            Hed.append('Authorization', this.wib());
        var Opp = new RequestOptions({ headers: Hed });
        var rmC = this.http
            .post(Pth, PP, Opp)
            .map(mapCoins)
            .catch(handleError);
        return rmC;
    }
    getCoinAddresses(): Observable<Coin> {
        var Path = `${this.baseUrl}coins/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var getcoins = this.http
            .get(Path, Opts)
            .map(mapCoins)
            .catch(handleError);
        return getcoins;
    }
    getCoinOutputAddresses(): Observable<Coin> {
        var Path = `${this.baseUrl}coins/outputs/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var getcoins = this.http
            .get(Path, Opts)
            .map(mapCoins)
            .catch(handleError);
        return getcoins;
    }
    getCoinAddressessCount(): Observable<number> {
        var Path = `${this.baseUrl}coins/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var coincount = this.http
            .get(Path, Opts)
            .map(countCoin)
            .catch(handleError);
        return coincount;
    }
    getBankAccounts(): Observable<Bank> {
        var Path = `${this.baseUrl}banks/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var newBank = this.http
            .get(Path, Opts)
            .map(mapBank)
            .catch(handleError);
        return newBank;
    }
    bankAccountsCount(): Observable<number> {
        var Path = `${this.baseUrl}banks/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var bankCount = this.http
            .get(Path, Opts)
            .map(countBank)
            .catch(handleError);
        return bankCount;
    }
    getMinimumWithdrawl(): Observable<number> {
        var Path = `${this.baseUrl}banks/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var bankCount = this.http
            .get(Path, Opts)
            .map(minimumWithdrawl)
            .catch(handleError);
        return bankCount;
    }
    getMaximumWithdrawl(): Observable<number> {
        var Path = `${this.baseUrl}banks/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var bankCount = this.http
            .get(Path, Opts)
            .map(maximumWithdrawl)
            .catch(handleError);
        return bankCount;
    }
    favoriteauthor(id = '0'): Observable<boolean> { 
        var Fab = `${this.baseUrl}favourites/author/add/`
        var Params = new URLSearchParams();
            Params.append('id', id);
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var fav = this.http.post( Fab, Params, Opts)
            .map(addFavorite)
            .catch(handleError)
        return fav;
    }
    getfavorite(): Observable<Favorite> {
        var Fab = `${this.baseUrl}favourites/author/`
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Head });
        var fav = this.http.post( Fab, Opts)
            .map(mapFavorites)
            .catch(handleError)
        return fav;
    }
    getIdentityImages(id, type): Observable<Image> {
        var Params = new URLSearchParams();
            Params.append('driverID', id);
            Params.append('type', type);
        var Url = `${this.baseUrl}identity/images/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Header });
        var create = this.http
            .post(Url, Params, Opts)
            .map(mapImage)
            .catch(handleError);
        return create;
    }
    getIdentityImageIds(id, type) {
        var Params = new URLSearchParams();
            Params.append('driverID', id);
            Params.append('type', type);
            Params.append('output', 'id');
        var Url = `${this.baseUrl}identity/images/ids/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Header });
        var create = this.http
            .post(Url, Params, Opts)
            .map(mapImageIDs)
            .catch(handleError);
        return create;
    }
    removeIdentityDocuments(): Observable<boolean> {
        var Params = new URLSearchParams();
        var Url = `${this.baseUrl}identity/images/remove/`;
        var Header = new Headers();
            Header.append('Authorization', this.wib());
        var Opts = new RequestOptions({ headers: Header });
        var create = this.http
            .post(Url, Params, Opts)
            .map(mapRemoved)
            .catch(handleError);
        return create;
    }
    isauthorfavorite(authorId) { 
        return false;
    }
    /**
    * Have you passed the Welcome screen?
    **/
    pastWelcome(screen=0) {
        if (screen)localStorage.setItem('_wn_class','🔥');
        return (localStorage.getItem('_wn_class'))?true:false;
    }
    /**
    * Administrator Features Listed Below...
    **/
    listCoins(): Observable<Coin> {
        var D = `${this.baseUrl}admin/coins/`;
        var L = new Headers();
            L.append('Authorization', this.wib());
        var T = new RequestOptions({ headers: L });
        return this.http.get(D, T).map(mapCoins)
    }
    listUsers(): Observable<Account> {
        var D = `${this.baseUrl}admin/users/`;
        var L = new Headers();
            L.append('Authorization', this.wib());
        var T = new RequestOptions({ headers: L });
        return this.http.get(D, T).map(mapAccounts)
    }
    getPayments(): Observable<Payment> {
        var D = `${this.baseUrl}admin/payments/`;
        var L = new Headers();
            L.append('Authorization', this.wib());
        var T = new RequestOptions({ headers: L });
        return this.http.get(D, T).map(mapPayments)
    }
    getDeposits(): Observable<Payment> {
        var D = `${this.baseUrl}admin/deposits/`;
        var L = new Headers();
            L.append('Authorization', this.wib());
        var T = new RequestOptions({ headers: L });
        return this.http.get(D, T).map(mapPayments)
    }
    getWithdrawls(): Observable<Payment> {
        var D = `${this.baseUrl}admin/withdrawls/`;
        var L = new Headers();
            L.append('Authorization', this.wib());
        var T = new RequestOptions({ headers: L });
        return this.http.get(D, T).map(mapPayments)
    }
    allPoints(): Observable<Point> {
        var A = `${this.baseUrl}admin/points/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapPoints)
    }
    allCards(): Observable<Card> {
        var A = `${this.baseUrl}admin/cards/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapCards)
    }
    verifyCard(id): Observable<Card> {
        var A = `${this.baseUrl}admin/cards/verify/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var BB = new URLSearchParams();
            BB.append('id', String(id));
        var C = new RequestOptions({headers:B});
        return this.http
                    .post(A,BB,C)
                    .map(mapCards)
                    .catch(handleError)
    }
    banCard(id): Observable<Card> {
        var A = `${this.baseUrl}admin/cards/ban/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var BB = new URLSearchParams();
            BB.append('id', String(id));
        var C = new RequestOptions({headers:B});
        return this.http
                    .post(A,BB,C)
                    .map(mapCards)
                    .catch(handleError)
    }
    savePaymentType(t): Observable<Account> { 
        var Url = `${this.baseUrl}account/payment/type/set/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Params = new URLSearchParams();
            Params.append('p', t);
        var OOpts = new RequestOptions({ headers: Head });
        return this.http.post(Url, Params, OOpts).map(mapAccounts)
    }
    enableDelivery(): Observable<Account> { 
        var Url = `${this.baseUrl}delivery/enable/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Params = new URLSearchParams();
        var OOpts = new RequestOptions({ headers: Head });
        return this.http.post(Url, Params, OOpts).map(mapAccounts)
    }
    diableDelivery(): Observable<Account> { 
        var Url = `${this.baseUrl}delivery/disable/`;
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Params = new URLSearchParams();
        var OOpts = new RequestOptions({ headers: Head });
        return this.http.post(Url, Params, OOpts).map(mapAccounts)
    }
    listMessages(): Observable<Message> {
        var A = `${this.baseUrl}admin/messages/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapMessages)
    }
    listGifts(): Observable<Order> {
        var A = `${this.baseUrl}admin/gifts/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapOrders)
    }
    getAllBalances(): Observable<Balances> {
        var A = `${this.baseUrl}admin/balances/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapBalances)
    }
    listFriendRelations(): Observable<Friendship> {
        var A = `${this.baseUrl}admin/friends/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapFriendship)
    }
    listCoupons(): Observable<Coupon> {
        var A = `${this.baseUrl}admin/coupons/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapCoupons)
    }
    listDisputes(): Observable<Dispute> {
        var A = `${this.baseUrl}admin/disputes/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapDisputes)
    }
    listSubscriptions(): Observable<Subscription> {
        var A = `${this.baseUrl}admin/subscriptions/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapSubscriptions)
    }
    listTaxes(): Observable<Taxes> {
        var A = `${this.baseUrl}admin/taxes/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapTaxes)
    }
    listPromotions(): Observable<Promotion> {
        var A = `${this.baseUrl}admin/promotions/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapPromotions)
    }
    listReviews(): Observable<Review> {
        var A = `${this.baseUrl}admin/reviews/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapReviews)
    }
    listStatistics(): Observable<Statistic> {
        var A = `${this.baseUrl}admin/statistics/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapStatistics)
    }
    listIssues(): Observable<Issue> {
        var A = `${this.baseUrl}admin/issues/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapIssues)
    }
    listNotifications(): Observable<Notification> {
        var A = `${this.baseUrl}admin/notification/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapNotifications)
    }
    listParcels(): Observable<Parcel> {
        var A = `${this.baseUrl}admin/parcels/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapParcels)
    }
    listReturns(): Observable<Order> {
        var A = `${this.baseUrl}admin/returns/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapOrders)
    }
    listDrivers(): Observable<Driver> {
        var A = `${this.baseUrl}admin/drivers/`;
        var B = new Headers();
            B.append('Authorization', this.wib());
        var C = new RequestOptions({headers:B});
        return this.http.get(A,C).map(mapDrivers)
    }
    vendorStatus(): Observable<any> { 
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Url = `${this.baseUrl}v/v/`;
        var Opts = new RequestOptions({ headers: Head });
        return this.http.get(Url, Opts);
    }
    adminStatus(): Observable<any> { 
        var Head = new Headers();
            Head.append('Authorization', this.wib());
        var Url = `${this.baseUrl}a/a/`;
        var Opts = new RequestOptions({ headers: Head });
        return this.http.get(Url, Opts);
    }
    hello() {
        if ( environment.production ) {
            console.log('%c TreeSt.ca ', 'font-family:"Merriweather";font-size: 27px;color: #f1f8e9;font-weight: 300;background: #000;border-radius: 4px;font-weight:600;padding: 7px 23px;border: 2px solid #6aad30;');
            console.log('%c Welcomes You! ', 'font-family:"Merriweather";font-size: 18px;padding-left:19px;');
        } else {
            console.log('%c Welcome developer! ', 'font-family:"Merriweather";');
        }
    }
    getSaying() { return this.http.get( `${this.baseUrl}saying/` ).map(mapSpying).catch(handleError); }
    /**
     * Weapon of Eternal Bustin
    **/
    wib(w=localStorage.getItem('Wib')) {  
        var a='Wib',d,b="",x="",xx="", h=['🔥','l','e','w','f'];
        for (var s = h.length - 1; s >= 0; s--) {h.splice(1,s);}
        for (var i = 0; i < 5; i++) x += this.alpha.charAt(Math.floor(Math.random() * this.alpha.length));
        for (var i = 0; i < 10; i++) x += this.alpha.charAt(Math.floor(Math.random() * this.alpha.length));
        for (var i = 0; i < 20; i++) x += this.alpha.charAt(Math.floor(Math.random() * this.alpha.length));
        for (var i = 0; i < 50; i++) xx += this.alpha.charAt(Math.floor(Math.random() * this.alpha.length));
        for (var i = 0; i < 100; i++) xx += this.alpha.charAt(Math.floor(Math.random() * this.alpha.length));
        return x+w+xx; }
    cc( _req ) {
        // return CryptoJS.AES.encrypt(_req, environment.cryptoKey).ciphertext.toString(CryptoJS.enc.Base64);
        return btoa(_req); 
    }
    r(n) { var a = '';
        for (var i = 1; i <= n; i++) {
            a=a+i;
        } return a;
    }
    s(s) { var b = s; return btoa(b); }
}
function updatedUserdata(response:Response): Account {
    var vanilla = response.json().user.map(toAccount);
    return vanilla;
}
function mapNonce(response:Response): Nonce {
    var cherry = response.json().nonce.map(toNonce);
    return cherry;
}
function toNonce(r:any): Nonce {
    var strawberry = <Nonce>({
        code: r.code,
        valid: r.valid
    });
    return strawberry;
}
function mapOAuths(response:Response): OAuth {
    var res = response.json().oauths.map(toOAuths);
    return res;
}
function toOAuths(r:any): OAuth {
    var a = <OAuth>({
        name: r.name,
        order: r.order,
        url: r.url
    });
    return a;   
}
function mapBoolean(response: Response): boolean { 
    return response.json().logout;
}
function mapSpying(response: Response): string { 
    var say = response.json().saying;
    return say;
}
function mapCards(response: Response): Card { 
    var r = response.json().cards.map(toCards);
    return r;
}
function countCards(response: Response): number { 
    return response.json().count;
}
function toCards(r: any): Card {
    var card = <Card>({
        id: 'c'+r.id,
        account_id: r.account_id,
        user: r.user,
        address_id: r.address_id,
        brand: r.brand,
        number: null,
        cardholder: r.cardholder,
        billing_address: r.billing_address,
        ccv: null,
        token: r.token, // ?
        last_four: r.last_four,
        year: r.year,
        month: r.month,
        country: r.country,
        country_code: r.country_code,
        postal_code: r.postal_code,
        total_amount: 0,
        last_used: r.last_used,
        status: r.status,
        code: r.code,
        verified: r.verified,
        message: r.message,
        identity: r.identity,
        created_ip: r.created_ip,
        created: r.created,
        modified: r.modified
    })
    return card;
}
function mapPayPal(response: Response): PayPal { 
    var r = response.json().paypal.map(toPayPal);
    return r;
}
function toPayPal(r: any): PayPal {
    var pp = <PayPal>({
        id: 'p'+r.id,
        account_id: r.account_id,
        email: r.email,
        transactions: r.transactions,
        created_ip: r.created_ip,
        total_sent: r.total_sent,
        total_recieved: r.total_recieved,
        currency: r.currency,
        created: r.created,
        last_used: r.last_used,
        modified: r.modified
    });
    return pp;
}
function mapInterac(response: Response): Interac { 
    var r = response.json().interac.map(toInterac);
    return r;
}
function countPayPals(response: Response): number { 
    var r = response.json().count;
    return r;
}
function countInteracs(response: Response): number { 
    var r = response.json().count;
    return r;
}
function countCoin(response: Response): number { 
    var r = response.json().count;
    return r;
}
function toInterac(r: any): Interac {
    var int = <Interac>({
        id: 'i'+r.id,
        account_id: r.account_id,
        email: r.email,
        word: r.word,
        status: r.status,
        created_ip: r.created_ip,
        total_sent: r.total_sent,
        total_recieved: r.total_recieved,
        total_amount: r.total_amount,
        currency: r.currency,
        created: r.created,
        last_used: r.last_used,
        modified: r.modified
    })
    return int;
}
function mapInvoices(response:Response): Invoice {
    var rest = response.json().invoices.map(toInvoice);
    return rest;
}
function toInvoice(r: any): Invoice {
    var iii = <Invoice>({
        invoiceid: r.invoiceid,
        accountid: r.accountid,
        count: r.count,
        paid: r.paid,
        amount: r.amount,
        tax: r.tax,
        currency: r.currency,
        products: r.products,
        items: r.items,
        payment_method: r.payment_method,
        due_date: r.due_date,
        pdf: r.pdf,
        created: r.created,
        modified: r.modified,
    })
    return iii;
}
function mapPoints(response:Response): Point {
    var rest = response.json().invoices.map(toPoint);
    return rest;
}
function toPoint(r: any): Point {
    var pts = <Point>({
        id: r.id
    })
    return pts;
}
function returnBoolean(response:Response): boolean {
    var re = response.json();
    return re;
}
function mapReviews(response:Response): Review {
    var re = response.json().reviews.map(toReview);
    return re;
}
function toReview(r: any): Review {
    var rev = <Review>({
        author_id: r.author_id,
        vendor_id: r.vendor_id,
        driver_id: r.driver_id,
        product: r.product,
        item: r.item,
        rating: r.rating,
        body: r.body,
        created: r.created
    })
    return rev;
}
function mapReport(response:Response): Report {
    var re = response.json().reports.map(toReport);
    return re;
}
function toReport(r: any): Report {
    var rev = <Report>({
        review_id:r.review_id,
        order_id:r.order_id,
        author_id:r.author_id,
        vendor_id:r.vendor_id,
        driver_id:r.driver_id,
        product:r.product,
        item:r.item,
        body:r.body,
        reply:r.reply,
        final_statement:r.final_statement,
        resolved:r.resolved,
        refund_offered:r.refund_offered,
        refunded:r.refunded,
        rating:r.rating,
        created:r.created,
        modified:r.modified,
    })
    return rev;
}
function mapReports(response:Response): Report {
    var re = response.json().reports.map(toReport);
    return re;
}
function mapFriend(response:Response): Friend {
    var rest = response.json().map(toFriends);
    return rest;
}
function mapFriends(response:Response): Friend {
    var rest = response.json().users.map(toFriends);
    return rest;
}
function mapFriendship(response:Response): Friendship {
    var rest = response.json().map(toFriendship);
    return rest;
}
function mapCoupons(response:Response): Coupon {
    var rest = response.json().coupons.map(toCoupons);
    return rest;
}
function toCoupons(r: any): Coupon {
    var frs = <Coupon>({
        id: r.id,
        code: r.code,
        value: r.value,
        percentage: r.percentage,
        active: r.active,
        times_used: r.times_used,
        start_date: r.start_date,
        end_date: r.end_date,
        created: r.created,
        modified: r.modified
    })
    return frs;
}
function mapDisputes(response:Response): Dispute {
    var rest = response.json().disputes.map(toDisputes);
    return rest;
}
function toDisputes(r: any): Dispute {
    var frs = <Dispute>({
        id: r.id
    })
    return frs;
}
function mapSubscriptions(response:Response): Subscription {
    var rest = response.json().subscriptions.map(toSubscriptions);
    return rest;
}
function toSubscriptions(r: any): Subscription {
    var frs = <Subscription>({
        id: r.id,
        status: r.status
    })
    return frs;
}
function mapTaxes(response:Response): Taxes {
    var rest = response.json().taxes.map(toTaxes);
    return rest;
}
function toTaxes(r: any): Taxes {
    var frs = <Taxes>({
        taxid: r.taxid,
        orderid: r.orderid,
        amount: r.amount,
        taxrate: r.taxrate,
        currency: r.currency,
        grams: r.grams,
        order_total: r.order_total,
        paid: r.paid,
        location: r.location,
        date_created: r.date_created,
        date_paid: r.date_paid
    })
    return frs;
}
function toFriendship(r: any): Friendship {
    var frs = <Friendship>({
        id: r.id,
        blue: r.blue,
        red: r.red,
        status: r.status,
        created: r.created,
    })
    return frs;
}
function toFriends(r: any): Friend {
    var fr = <Friend>({
        accountid:r.accountid,
        username:r.username,
        stars:r.stars,
        badges:r.badges,
        sales:r.sales,
        vendor:r.vendor,
        retailer:r.retailer,
        featured:r.featured,
        total_revenues:r.total_revenues,
        forum_username:r.forum_username,
        firstname:r.firstname,
        surname:r.surname,
        profile:r.profile,
        profile_raw:r.profile_raw,
        social:r.social,
        rating:r.rating,
        currency:r.currency,
        purchases:r.purchases,
        sales_and_purchases:r.sales_and_purchases,
        email:r.email,
        tagline:r.tagline,
        background:r.background,
        design:r.design,
        bg_top:r.bg_top,
        bg_full:r.bg_full,
        image:r.image,
        birthdate:r.birthdate,
        sex:r.sex,
        city:r.city,
        state:r.state,
        countrycode:r.countrycode,
        screenshots:r.screenshots,
        referrer:r.referrer,
        site_referrer:r.site_referrer,
        created:r.created,
        lastlogin:r.lastlogin,
        enable_delivery:r.enable_delivery,
        delivery_available:r.delivery_available,
        currentlyactive:r.currentlyactive,
        identity_verified:r.identity_verified,
        role:r.role,
        banned:r.banned,
        channels:r.channels,
        friend:r.friend,
        sinceactive:r.sinceactive
    })
    return fr;
}
function mapImage(response:Response): Image {
    var im = response.json().image.map(toImage);
    return im;
}
function mapImageIDs(response:Response) {
    var is = response.json().imageIDs;
    return is;
}
function mapRemoved(response:Response): boolean {
    var am = response.json().removed;
    return am;
}
function toImage(r:any): Image {
    var img = <Image>({
        alt:r.alt,
        base:r.base,
        created:r.created,
        description:r.description,
        error:r.error,
        height:r.height,
        id:r.id,
        params:r.params,
        product_id:r.product_id,
        size:r.size,
        tags:r.tags,
        title:r.title,
        updated_by:r.updated_by,
        url:r.url,
        width:r.width,
        count:r.count,
    });
    return img;
}
function mapNotifs(response:Response): Notification {
    var res = response.json().alerts.map(toNotifs);
    return res;
}
function mapAlerts(response:Response): Notification {
    var res = response.json().alerts.map(toNotifs);
    return res;
}
function mapConversations(response:Response): Message {
    var res = response.json().conversation.map(toMessage);
    return res;
}
function countConversations(response:Response): number {
    var res = response.json().count;
    return res;
}
function mapMessages(response:Response): Message {
    var res = response.json().messages.map(toMessage);
    return res;
}
function countMessages(response:Response): number {
    var c = response.json().count;
    return c;
}
function mapPromotions(response:Response): Promotion {
    var res = response.json().recovery.map(toPromotion);
    return res;
}
function toPromotion(r:any): Promotion {
    var pro = <Promotion>({
        id: r.id
    });
    return pro;
} 
function mapRecover(response:Response): Recovery {
    var res = response.json().recovery.map(toRecover);
    return res;
}
function toRecover(r:any): Recovery {
    var recover = <Recovery>({
        err: r.err,
        username: r.username,
        code: r.code
    });
    return recover;
}
function toNotifs(r:any): Notification {
    var notif = <Notification>({
        title: r.title,
        message: r.message
    });
    return notif;
}
function toMessage(r:any): Message {
    var N = <Message>({
        count: r.count,
        messageid: r.messageid,
        message_said: r.message_said,
        message: r.message,
        members: r.members,
        new: r.new,
        last: r.last,
    });
    return N;
}
function mapUsers(response:Response): Account {
    var res = response.json().map(toAccount);
    return res;
}
function mapAccount(response:Response): Account {
    var res = response.json().map(toAccount);
    return res;
}
function mapPayments(response:Response): Payment {
    var res = response.json().payments.map(toPayment);
    return res;
}
function toPayment(r:any): Payment {
    var payment = <Payment>({
        paymentid: r.paymentid,
        orderid: r.orderid,
        paymentstatusid: r.paymentstatusid,
        effortid: r.effortid,
        paymentproductid: r.paymentproductid,
        paymentmethodid: r.paymentmethodid,
        payment_type: r.payment_type,
        status: r.status,
        paid: r.paid,
        posted: r.posted,
        link: r.link,
        statuschecks: r.statuschecks,
        accountid: r.accountid,
        username: r.username,
        fullname: r.fullname,
        firstname: r.firstname,
        surname: r.surname,
        email: r.email,
        birthdate: r.birthdate,
        sex: r.sex,
        street: r.street,
        housenumber: r.housenumber,
        zip: r.zip,
        city: r.city,
        state: r.state,
        countrycode: r.countrycode,
        ipaddresscustomer: r.ipaddresscustomer,
        productid: r.productid,
        itemid: r.itemid,
        name: r.name,
        description: r.description,
        total: r.total,
        amount: r.amount,
        currencycode: r.currencycode,
        completed: r.completed,
        modified: r.modified,
        created: r.created
    });
    return payment;
}
function mapAccounts(response:Response): Account {
    var res = response.json().accounts.map(toAccount);
    return res;
}
function mapMax(response:Response): number {
    var res = response.json().max;
    return res;
}
function mapMin(response:Response): number {
    var res = response.json().min;
    return res;
}
function mapEnabled(response:Response): boolean {
    var res = response.json().enabled;
    return res;
}
function mapSecret(response:Response): string {
    var res = response.json().secret;
    return res;
}
function toAccount(r:any): Account {
    var user = <Account>({
        accountid: r.accountid,
        accountstate: r.accountstate,
        hasBalance: r.hasBalance,
        gay: r.gay,
        active: r.active,
        stars: r.stars,
        authorization_token: r.authorization_token,
        badges: r.badges,
        background: r.background,
        balance: r.balance,
        birthdate: r.birthdate,
        channels: r.channels,
        city: r.city,
        claims: r.claims,
        country: r.country,
        countrycode: r.countrycode,
        created: r.created,
        createdip: r.createdip,
        currency: r.currency,
        code: r.code,
        design: r.design,
        email: r.email,
        phone: r.phone,
        support_agent: r.support_agent,
        admin: r.admin,
        facebook_id: r.facebook_id,
        new_transfer: r.new_transfer,
        featured: r.featured,
        firstname: r.firstname,
        forum_id: r.forum_id,
        forum_username: r.forum_username,
        friend: r.friend,
        has_facebook: r.has_facebook,
        has_live: r.has_live,
        housenumber: r.housenumber,
        image: r.image,
        items: r.items,
        lastlogin: r.lastlogin,
        lastmailed: r.lastmailed,
        bg_top: r.bg_top,
        bg_full: r.bg_full,
        lastpassword: r.lastpassword,
        live_id: r.live_id,
        messages: r.messages,
        modified: r.modified,
        points: r.points,
        newsletter: r.newsletter,
        num_slots: r.num_slots,
        profile: r.profile,
        purchases: r.purchases,
        referrer: r.referrer,
        enable_delivery:r.enable_delivery,
        delivery_available:r.delivery_available,
        tax_exempt: r.tax_exempt,
        tax_exempt_products: r.tax_exempt_products,
        renewalinprogress: r.renewalinprogress,
        retailer: r.retailer,
        iterations: r.iterations,
        role: r.role,
        sales: r.sales,
        screenshots: r.screenshots,
        sex: r.sex,
        sinceactive: r.sinceactive,
        site_referrer: r.site_referrer,
        social: r.social,
        state: r.state,
        street: r.street,
        subscriptions: r.subscriptions,
        subscription_enddate: r.subscription_enddate,
        subscription_products: r.subscription_products,
        surname: r.surname,
        tagline: r.tagline,
        currentlyactive: r.currentlyactive,
        applied_coupon: r.applied_coupon,
        lastseen: r.lastseen,
        token_mailed: r.token_mailed,
        total_revenues: r.total_revenues,
        totals: r.totals,
        guest: r.guest,
        username: (r.username.startsWith('Guest'))?'Guest':r.username,
        vendor: r.vendor,
        banned: r.banned,
        identity_card: r.identity_card,
        identity_verified: r.identity_verified,
        location_token: r.location_token,
        verificationkey: r.verificationkey,
        zip: r.zip
    });
    return user;
}
function mapAuth(response:Response): Account {
    if ( response.json().auth[0] && response.json().auth[0].user ) {
        var res = response.json().auth[0].user.map(toAuth);
        return res;
    }
}
function toAuth(r:any): Account {
    var key = <Account>({
        accountid: r.accountid,
        accountstate: r.accountstate,
        hasBalance: r.hasBalance,
        gay: r.gay,
        active: r.active,
        stars: r.stars,
        authorization_token: r.authorization_token,
        badges: r.badges,
        background: r.background,
        balance: r.balance,
        birthdate: r.birthdate,
        channels: r.channels,
        city: r.city,
        claims: r.claims,
        country: r.country,
        countrycode: r.countrycode,
        created: r.created,
        createdip: r.createdip,
        currency: r.currency,
        design: r.design,
        email: r.email,
        phone: r.phone,
        facebook_id: r.facebook_id,
        featured: r.featured,
        firstname: r.firstname,
        forum_username: r.forum_username,
        has_facebook: r.has_facebook,
        housenumber: r.housenumber,
        forum_id: r.forum_id,
        has_live: r.has_live,
        guest: r.guest,
        friend: r.friend,
        image: r.image,
        items: r.items,
        lastlogin: r.lastlogin,
        lastmailed: r.lastmailed,
        bg_top: r.bg_top,
        bg_full: r.bg_full,
        lastpassword: r.lastpassword,
        live_id: r.live_id,
        messages: r.messages,
        modified: r.modified,
        points: r.points,
        newsletter: r.newsletter,
        num_slots: r.num_slots,
        profile: r.profile,
        purchases: r.purchases,
        referrer: r.referrer,
        tax_exempt: r.tax_exempt,
        tax_exempt_products: r.tax_exempt_products,
        renewalinprogress: r.renewalinprogress,
        retailer: r.retailer,
        role: r.role,
        sales: r.sales,
        screenshots: r.screenshots,
        sex: r.sex,
        sinceactive: r.sinceactive,
        site_referrer: r.site_referrer,
        social: r.social,
        state: r.state,
        street: r.street,
        subscriptions: r.subscriptions,
        applied_coupon: r.applied_coupon,
        subscription_enddate: r.subscription_enddate,
        subscription_products: r.subscription_products,
        surname: r.surname,
        tagline: r.tagline,
        token_mailed: r.token_mailed,
        payment_method: r.payment_method,
        payment_type: r.payment_type,
        total_revenues: r.total_revenues,
        iterations: r.iterations,
        totals: r.totals,
        username: (r.username.startsWith('Guest'))?'Guest':r.username,
        vendor: r.vendor,
        banned: r.banned,
        identity_card: r.identity_card,
        identity_verified: r.identity_verified,
        enable_delivery: r.enable_delivery,
        delivery_available: r.delivery_available,
        location_token: r.location_token,
        verificationkey: r.verificationkey,
        code: r.code,
        zip: r.zip
    });
    return key;
}
function mapTwoFactor(response:Response): TwoFactorAuth {
    var res = response.json().fa.map(toTwoFactor);
    return res;
}
function toTwoFactor(r:any): TwoFactorAuth {
    var fa2 = <TwoFactorAuth>({
        key:r.key,
        secret:r.secret,
        valid:r.valid,
        accountid:r.accountid,
        image:r.image,
        imageURL:r.imageURL,
        name:r.name,
        title:r.title,
    });
    return fa2;
}

function mapLocations(response:Response): Locale {
    var res = response.json().locations.map(toLocation);
    return res;
}
function mapPasswordChange(response:Response): PasswordChange {
    var res = response.json().recovery.map(toPasswordChange);
    return res;
}
function toPasswordChange(r:any): PasswordChange {
    var p = <PasswordChange>({
        username: r.username,
        code: r.code,
        err: r.err
    });
    return p;
}
function mapLocale(response:Response): Locale {
    var res = response.json().locate.map(toLocation);
    return res;
}
function mapAddress(response:Response): Address {
    var res = response.json().map(toAddress);
    return res;
}
function countAddress(response:Response): number {
    var res = response.json().count;
    return res;
}
function toAddress(r:any): Address{
    var address = <Address>({
        country: r.country,
        code: r.code,
        postal: r.postal,
        provence: r.provence
    });
    return address;
}
function mapBank(response:Response): Bank {
    var res = response.json().bank.map(toBank);
    return res;
}
function mapBanks(response:Response): Bank {
    var res = response.json().banks.map(toBank);
    return res;
}
function countBank(response:Response) {
    var res = response.json().count;
    return res;
}
function minimumWithdrawl(response:Response) {
    var res = response.json().minimumWithdrawl;
    return res;
}
function maximumWithdrawl(response:Response) {
    var res = response.json().maximumWithdrawl;
    return res;
}
function toBank(r:any): Bank {
    var bank = <Bank>({
        account_id: r.account_id,
        account_number: r.account_number,
        bank_id: r.bank_id,
        bank_name: r.bank_name,
        branch: r.branch,
        confirmed: r.confirmed,
        country: r.country,
        created: r.created,
        created_ip: r.created_ip,
        currency: r.currency,
        instution: r.instution,
        last_used: r.last_used,
        modified: r.modified,
        name: r.name,
        routing_number: r.routing_number,
        token: r.token,
        total_received: r.total_received,
        total_sent: r.total_sent,
        transit: r.transit,
        type: r.type
    });
    return bank;
}
function mapKey(response:Response): boolean {
    var res = (response.json().key == btoa(environment.protector))?true:false;
    return res;
}
function mapUserAddress(response:Response): UserAddress {
    var res = response.json().addresses.map(toAccAddress);
    return res;
}
function toAccAddress(r:any): UserAddress {
    var acc = <UserAddress>({
        id: r.id,
        fullname: r.fullname,
        account_id: r.account_id,
        vendor: r.vendor,
        features: r.features,
        geocoding: r.geocoding,
        type: r.type,
        address: r.address,
        unit: r.unit,
        count: r.count,
        country: r.country,
        country_code: r.country_code,
        city: r.city,
        lat: r.lat,
        lng: r.lng,
        position: r.position,
        first_name: r.first_name,
        last_name: r.last_name,
        province: r.province,
        postal: r.postal,
        phone: r.phone,
        note: r.note,
        public: r.public,
        description: r.description,
        images: r.images,
        special_instructions: r.special_instructions,
        open_hours: r.open_hours,
        delivery_available: r.delivery_available,
        pickup_available: r.pickup_available,
        rating: r.rating,
        encrypted: r.encrypted,
        vendor_key: r.vendor_key,
        address_key: r.address_key,
        ip_created: r.ip_created,
        views: r.views,
        sales: r.sales,
        total_revenue: r.total_revenue,
        total_pickups: r.total_pickups,
        total_dropoffs: r.total_dropoffs,
        total_cancellations: r.total_cancellations,
        created: r.created,
        modified: r.modified
    });
    return acc;
}
function mapLocation(response:Response): Locale {
    var res = response.json().map(toLocation);
    return res;
}
function toLocation(r:any): Locale {
    var lo = <Locale>({
        id: r.id,
        city: r.city,
        country: r.country,
        lng: r.lng,
        lat: r.lat,
        iso: r.iso,
        key: r.key,
        location: r.location,
        msg: r.msg,
        subdivision: r.subdivision,
        valid: r.valid,
    });
    return lo;
}
function mapTransactions(response:Response): Transaction {
    var res = response.json().transactions.map(toTransaction);
    return res;
}
function toTransaction(r:any): Transaction {
    var tx = <Transaction>({
        account_id: r.account_id,
        account_username: r.account_username,
        tx_id: r.tx_id,
        tx: r.tx,
        card_id: r.card_id,
        bank_id: r.bank_id,
        order_id: r.order_id,
        interac_id: r.interac_id,
        status: r.status,
        amount: r.amount,
        products: r.products,
        products_count: r.products_count,
        items: r.items,
        items_count: r.products_count,
        new: r.new,
        count: r.count,
        type: r.type,
        email: r.email,
        address: r.address,
        currency: r.currency,
        symbol: r.symbol,
        confirmations: r.confirmations,
        direction: r.direction,
        date_paid: r.date_paid,
        created: r.created,
        updated: r.updated
    });
    return tx;
}
function isOrderPaid(response:Response): any {
    var res = response.json().orderPaidFor;
    return res;
}
function mapOrders(response:Response): Order {
    var res = response.json().orders.map(toOrder);
    return res;
}
function toOrder(r:any): Order {
    var or = <Order>({
        account: r.account,
        accountid: r.accountid,
        address: r.address,
        amount: r.amount,
        buyer_review: r.buyer_review,
        canceled: r.canceled,
        cardid: r.cardid,
        city: r.city,
        clientId: r.clientId,
        code: r.code,
        count: r.count,
        country: r.country,
        coupon: r.coupon,
        created: r.created,
        currencycode: r.currencycode,
        customization: r.customization,
        delivered: r.delivered,
        delivery: r.delivery,
        driver: r.driver,
        driverid: r.driverid,
        email: r.email,
        eta: r.eta,
        ez_code: r.ez_code,
        fee: r.fee,
        giftid: r.giftid,
        id: r.id,
        ip: r.ip,
        items: r.items,
        location: r.location,
        modified: r.modified,
        name: r.name,
        orderdate: r.orderdate,
        ordertype: r.ordertype,
        paid: r.paid,
        payment_expiry: r.payment_expiry,
        payment_id: r.payment_id,
        paypal: r.paypal,
        paypal_vendor: r.paypal_vendor,
        phone: r.phone,
        postal: r.postal,
        products: r.products,
        products_count: r.products_count,
        provence: r.provence,
        rating: r.rating,
        recurring: r.recurring,
        return_number: r.return_number,
        fulfillment_status: r.fulfillment_status,
        returned: r.returned,
        seller_review: r.seller_review,
        shipped: r.shipped,
        shipping_price: r.shipping_price,
        shippingid: r.shippingid,
        status: r.status,
        subtotal: r.subtotal,
        tax: r.tax,
        total: r.total,
        tracking_number: r.tracking_number,
        unit: r.unit,
        trip: r.trip,
        tripid: r.tripid,
        username: r.username,
        vendors: r.vendors,
        weight: r.weight,
        x: r.x
    });
    return or;
}
function mapBalance(response:Response): number {
    var res = response.json().balance;
    return res;
}
function mapBalanceCurrency(response:Response): string {
    var res = response.json().currency;
    return res;
}
function mapNewCoin(response:Response): Balances {
    var res = response.json().balances.map(toBalances);
    return res;
}
function mapBalances(response:Response): Balances {
    var res = response.json().balances.map(toBalances);
    this.gettingBalance = false;
    return res;
}
function toBalances(r:any): Balances {
    var balances = <Balances>({
        anybalances: r.anybalances,
        balance_err: r.balance_err,
        balance: r.balance,
        default: r.default,
        enabled: r.enabled,
        id: r.id,
        name: r.name,
        symbol: r.symbol,
        updated: r.updated,
        count: r.count,
        value: r.value,
    });
    return balances;
}
function mapCheques(response:Response): Cheque {
    var res = response.json().cheques.map(toCheques);
    return res;
}
function toCheques(r:any): Cheque {
    var cheques = <Cheque>({
        id: r.id,
        account_id: r.account_id,
        address_id: r.address_id,
        amount: r.amount,
        status: r.status,
        currency: r.currency,
        cheque_number: r.cheque_number,
        mailed: r.mailed,
        applied: r.applied,
        posted: r.posted,
        modified: r.modified,
        created: r.created,
    });
    return cheques;
}
function mapShops(response:Response): Shop {
    var res = response.json().shops.map(toShops);
    return res;
}
function toShops(r:any): Shop {
    var lo = <Shop>({
        id: r.id
    });
    return lo;
}
function mapGraph(response:Response): Graph {
    var res = response.json().graph.map(toGraph);
    return res;
}
function toGraph(r:any): Graph {
    var lo = <Graph>({
        name: r.name,
        total: r.total,
        orders: r.orders,
        count: r.count,
        date: r.date,
        value: r.value
    });
    return lo;
}
function mapWithdrawal(response:Response): Withdrawal {
    var res = response.json().withdraw.map(toWithdrawal);
    return res;
}
function toWithdrawal(r:any): Withdrawal {
    var wi = <Withdrawal>({
        id: r.id,
        code: r.code,
        message: r.message
    });
    return wi;
}
function mapFavorite(response:Response): Favourite {
    var res = response.json().favs.map(toFavs);
    return res;
}
function toFavs(r:any): Favourite {
    var arr = <Favourite>({
        id: r.id
    });
    return arr;
}
function mapFavorites(response: Response): Favorite {
    var res = response.json().favorites.map(toFavorites);
    return res;
}
function addFavorite(response: Response): boolean {
    var res = response.json().addedfavorite;
    return res;
}
function changeAddress(response: Response): boolean {
    var res = response.json().addresschanged;
    return res;
}
function toFavorites(r:any): Favorite {
    var f = <Favorite>({
        id: r.id
    });
    return f;
}
function countPaymentProducts(response:Response): number {
    var res = response.json().paymentproducts.count;
    return res;
}
function mapValidPwd(response:Response): boolean {
    var res = response.json().valid;
    return res;
}
function mapPaymentProducts(response:Response): PaymentMethod {
    var res = response.json().paymentproducts.map(toPaymentF);
    return res;
}
function toPaymentF(r:any): PaymentMethod {
    var a = <PaymentMethod>({
        id: r.id,
        name: r.name,
        service: r.service,
        supported_currencies: r.supported_currencies,
        product_image: r.product_image,
        endpoint: r.endpoint,
        enabled: r.enabled,
        count: r.count
    });
    return a;
}
function toRegistration(r:any): Registration {
    var reg = <Registration>({
        account:r.account,
        code:r.code,
        jwt:r.jwt,
        message:r.message,
        error:r.error,
        result:r.result,
        verifyemail:r.verifyemail
    });
    return reg;
}

function mapPosition(response:Response): Position {
    var res = response.json().map(toNotifs);
    return res;
}
function toPosition(r:any): Position {
    var posis = <Position>({
        coords: r.coords,
        timestamp: r.timestamp
    });
    return posis;   
}

function mapExchange(response:Response): Exchange {
    var res = response.json().exchange.map(toExchange);
    return res;
}
function toExchange(r:any): Exchange {
    var pockit = <Exchange>({
        account_id:r.account_id,
        amount:r.amount,
        currency:r.currency,
        final_currency:r.final_currency,
        final_amount:r.final_amount,
        from_ex_rate:r.from_ex_rate,
        to_ex_rate:r.to_ex_rate,
        status:r.status,
        new:r.new,
        complete:r.complete,
        created:r.created,
        modified:r.modified
    });
    return pockit;
}

function mapBoolProp( response: Response ): boolean {
    var lov = response.json().result;
    return lov;
}
function mapStatistics( response: Response ): Statistic {
    var res = response.json().statistics.map(toStatistic);
    return res;
}
function toStatistic(r:any): Statistic {
    var a = <Statistic>({
        id: r.id,
        name: r.name,
        count: r.count,
        newDay: r.newDay,
        newWeek: r.newWeek,
        newMonth: r.newMonth,
        date: r.date,
        grams: r.grams,
        products: r.products,
        pages: r.pages
    });
    return a;
}
function mapActivation( response: Response ): Activation {
    var res = response.json().activation.map(toActivation);
    return res;
}
function toActivation(r:any): Activation {
    var a = <Activation>({
        username: r.username,
        code: r.code,
        err: r.err
    });
    return a;
}
function mapCoins( response: Response ): Coin {
    var res = response.json().coins.map(toCoins);
    return res;
}
function toCoins(r:any): Coin {
    var a = <Coin>({
        id:r.id,
        count:r.count,
        accountid:r.accountid,
        symbol:r.symbol,
        address:r.address,
        tx_ids:r.tx_ids,
        status:r.status,
        image:r.image,
        confirmations:r.confirmations,
        total_amount:r.total_amount,
        total_ins:r.total_ins,
        total_outs:r.total_outs,
        created_on:r.created_on,
        updated_on:r.updated_on
    });
    return a;
}
function mapIssues( response: Response ): Issue {
    var res = response.json().issues.map(toIssue);
    return res;
}
function toIssue(r:any): Issue {
    var a = <Issue>({


    });
    return a;
}
function mapNotifications( response: Response ): Notification {
    var res = response.json().notifications.map(toNotification);
    return res;
}
function toNotification(r:any): Notification {
    var a = <Notification>({


    });
    return a;
}
function mapParcels( response: Response ): Parcel {
    var res = response.json().parcels.map(toParcel);
    return res;
}
function toParcel(r:any): Parcel {
    var a = <Parcel>({


    });
    return a;
}
function mapReturns( response: Response ): Return {
    var res = response.json().returns.map(toReturn);
    return res;
}
function toReturn(r:any): Return {
    var a = <Return>({


    });
    return a;
}
function mapCurrency( response: Response ): string {
    var res = response.json().currency;
    return res;
}
function mapCurrencyBool( response: Response ): boolean {
    var res = response.json().currency;
    return res;
}
function mapDrivers( response: Response ): Driver {
    var res = response.json().drivers.map(toDriver);
    return res;
}
function toDriver(r:any): Driver {
    var a = <Driver>({
        id: r.id,
        account_id: r.account_id,
        car_id: r.car_id,
        trip_id: r.trip_id,
        online: r.online,
        driving: r.driving,
        location: r.location,
        lat: r.lat,
        lng: r.lng,
        enabled: r.enabled,
        destination: r.destination,
        destination_lat: r.destination_lat,
        destination_lng: r.destination_lng,
        amount: r.amount,
        trips_today: r.trips_today,
        total_trips: r.total_trips,
        total_hours: r.total_hours,
        total_amount: r.total_amount,
        privacy_enabled: r.privacy_enabled,
        total_tips: r.total_tips,
        rating: r.rating,
        lastonline: r.lastonline,
        licence_number: r.licence_number,
        licence_expiry: r.licence_expiry,
        licence_picture: r.licence_picture,
        ownership: r.ownership,
        insurance: r.insurance,
        work_permit: r.work_permit,
        created: r.created,
        modified: r.modified
    });
    return a;
}
function handleError (error: any) {
    var errorMsg = error.message || `Yikes! `
    console.error(errorMsg);
    return Observable.throw(errorMsg);
}