import { Component, OnInit } from '@angular/core';
import { Return } from '../../../models/return';
import { UserService } from '../../../services/user.service';
import { MarketplaceService } from '../../../services/marketplace.service';

@Component({
  selector: 'admin-returns',
  templateUrl: './admin-returns.component.pug',
  styleUrls: ['./admin-returns.component.styl']
})
export class AdminReturnsComponent implements OnInit {
	
	isAdmin = false;
	returns: Return;
	
	constructor(public marketplace: MarketplaceService,
				public userservice: UserService) { }

	ngOnInit() {

		this.userservice.adminStatus().subscribe((is) => {
			this.isAdmin = is.json().admin;
		});

		if ( this.isAdmin ) {
			this.userservice.listReturns().subscribe((rt) => {
				this.returns = rt;
			});
		}
	}
}