import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'iframer',
  templateUrl: './iframe.component.pug',
  styleUrls: ['./iframe.component.styl']
})
export class IFrameComponent implements OnInit {

	constructor( 
		private activated: ActivatedRoute
	) {	
		this.storeID = this.activated.snapshot.paramMap.get("id");
	}

	storeID: string;

	ngOnInit() {
	}

}