import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ImagesModule } from './app/images/images.module';
import { environment } from './environments/environment';
import 'hammerjs';
// let onDeviceReady = () => {
//   platformBrowserDynamic().bootstrapModule(AppModule);
// };
// document.addEventListener('deviceready', onDeviceReady, false);
if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));