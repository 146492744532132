import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wallet-deposit',
  templateUrl: './deposit.component.pug',
  styleUrls: ['./deposit.component.styl']
})
export class DepositComponent implements OnInit {


  constructor() { }
  curr = null;
  ngOnInit() {
  }

}
