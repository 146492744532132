import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'accountiframer',
  templateUrl: './iframe.account.component.pug',
  styleUrls: ['./iframe.account.component.styl']
})
export class IFrameAccountComponent implements OnInit {

	constructor( 
		private user: UserService
	) {	}

	ngOnInit() {
	}

}