import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vendor',
  templateUrl: './vendor.component.pug',
  styleUrls: ['./vendor.component.styl']
})
export class VendorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
