import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../../models/product';
import { environment } from '../../../../environments/environment';
import { open, openup, active, stocks, draftsWindow, DropOpen, animateAlert, units, unit, monies, pillbutton, activemode, activetitle, strainselected, childCategory, flyIn } from '../../../products/add/add.animations';
import { Support, SupportCategory } from '../../../models/support';
import { DriverService } from '../../../services/driver.service';
import { UserService } from '../../../services/user.service';
import { SupportService } from '../../../services/support.service';
declare var jquery:any; declare var $ :any;
@Component({
	selector: 'new-support',
	templateUrl: './new.support.component.pug',
	styleUrls: ['./new.support.component.styl'],
	animations: [ open, openup, active, stocks, DropOpen, draftsWindow, animateAlert, pillbutton, units, unit, monies, activemode, activetitle, strainselected, childCategory, flyIn ]
})
export class NewSupportComponent implements OnInit {
	constructor(
		private support: SupportService,
		private user: UserService,
		private driver: DriverService,
		private route: ActivatedRoute
	) { }
	case = new SupportCase();
	filters = new Filt();
	products: Product;
	ticket: Support;
	categories: SupportCategory;
	submitError: string;
	nonce: string;
	dropdownConfig = {
        displayKey:"description", //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
        limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
        searchPlaceholder:'Search', // label thats displayed in search input,
        searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
        clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
        inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
	editorConfig = {
		placeholder: "Describe your support issue in as much detail as you'd like. Please include a description what needs to be done to fix your issue.",
		spellcheck: "true",
		translate: "yes",
		minHeight: "160px",
		imageEndPoint: environment.base+'upload/image/?authorization='+this.user.wib(),
		font: 'Arial',
		toolbar: [
			["bold", "italic", "underline", "strikeThrough"],
			["fontName", "fontSize", "color", "orderedList", "unorderedList"],
			["justifyLeft", "justifyCenter", "justifyRight", "indent", "outdent"],
			["orderedList", "unorderedList", "paragraph", "blockquote", "removeBlockquote", "horizontalLine" ],
			["link", "unlink", "image", "video"]
    	]
	};

	OrderdropdownOptions = [];
	DriverdropdownOptions = [];
	CustomerdropdownOptions = [];
	VendordropdownOptions = [];
	ProductdropdownOptions = [];
	TransactiondropdownOptions = [];

	get_options() {
		this.support.getSupportableOrders().subscribe( (a) => {
			this.OrderdropdownOptions = a;
		});
		this.support.getSupportableCustomers().subscribe( (a) => {
			this.CustomerdropdownOptions = a;
		});
		this.support.getSupportableVendors().subscribe( (a) => {
			this.VendordropdownOptions = a;
		});
		this.support.getSupportableTransactions().subscribe( (a) => {
			this.TransactiondropdownOptions = a;
		});
	}
	get_products(id) {
		this.case.order_id = null;
		this.ProductdropdownOptions = [];
		let a = '';
		for (var i = 6; i <= 12; i++) {
			a=a+id.value[i];
		}
		this.support.getSupportableProducts(a).subscribe( (a) => {
			this.ProductdropdownOptions = a;
		});
		this.support.getSupportableDrivers(a).subscribe( (a) => {
			this.DriverdropdownOptions = a;
		});
	}
	load_ticket() {
		this.support.getSupportTicket(this.case.id).subscribe( (c) => {
			this.case = c[0];
		});
	}
	get_categories() {
		this.support.supportCategories().subscribe( (c) => {
			this.categories = c;
		});
	}
	find_drivers() {
		this.driver.find_drivers().subscribe( (d) => {

		})
	}
	preview() {
		this.case.nonce=this.nonce;
		this.support.saveSupportTicket(this.case).subscribe((res) => {
			if ( res[0].error ) this.submitError = res[0].error;
			this.ticket = res;
			this.case.id = res[0].id;
			this.get_n();
		});
	}
	submit() {
		this.case.nonce=this.nonce;
		this.support.createSupportTicket(this.case).subscribe((res) => {
			if ( res[0].error ) this.submitError = res[0].error;
			this.ticket = res;
			this.case.id = res[0].id;
			this.get_n();
		});
	}
	get_n() {
		this.user.getNonce().subscribe((n)=>{
			this.nonce=n[0].code;
		});
	}
	ngOnInit() {
		this.get_n();
		this.get_categories();
		this.get_options();
		this.case.id = this.route.snapshot.paramMap.get("id");
		if( this.case.id ) this.load_ticket();
		$("html, body").animate({ scrollTop: 0 }, "fast");
	}
}
export class SupportCase {
  constructor(
		public id?: any,
		public nonce?: string,
		public error?: string,
		public account?: any,
		public account_id?: number,
		public order_id?: number,
		public vendor_id?: number,
		public driver_id?: number,
		public customer_id?: number,
		public product_id?: number,
		public transaction_id?: number,
		public count?: number,
		public email?: string,
		public email_vendor?: any,
		public email_driver?: any,
		public items?: any,
		public products?: any,
		public title?: string,
		public code?: string,
		public messages?: [any],
		public message?: any,
		public category?: any,
		public balance?: any,
		public body?: string,
		public reply?: any,
		public reply_date?: any,
		public second_reply?: any,
		public second_reply_Date?: any,
		public third_reply?: any,
		public third_reply_date?: any,
		public system_reply?: any,
		public system_reply_date?: any,
		public status?: any,
		public complete?: any,
		public date_completed?: any,
		public created?: any,
		public updated?: any
  ) {  }
}
export class Filt {
  constructor(
		public cat?: string
  ) {  }
}