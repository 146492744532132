import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { trigger, state, animate, transition, style } from '@angular/animations';

// Countries
export const animateAlert = trigger('animateAlert', [
    state('true', style({
        'max-height': '100px',
        'margin': '0 auto 50px auto',
        'padding': '10px 30px',
        'border-width': '2px'
    })),
    state('false', style({
        'max-height': '0px',
        'margin': '0 auto 0px auto',
        'padding': '0px 0px',
        'border-width': 0
    })),
    transition('false=>true', animate('220ms 199ms ease-out')),
    transition('true=>false', animate('50ms 0ms'))
])
