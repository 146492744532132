import { Address, Provence, Country, Locale } from '../models/address';
import {ViewportRuler,ScrollDispatcher} from '@angular/cdk/scrolling'; 
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { red, gold, rotate, selector, selectormenu, productsPane, childOpen, filters, Sticky, FilterInput, Fade, progress, FadeQuick, FeatAd, FadeInSlow, FadeSlow } from './products.animations';
import { fadeInFull } from '../app.animations';
import { Location } from '@angular/common';
import { MarketplaceService } from '../services/marketplace.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { Product } from '../models/product';
import { Category } from '../models/category';
import { Favorite } from '../models/favorite';
import { Brand } from '../models/brand';
declare var jquery:any;
declare var $ :any;
@Component({
selector: 'products',
templateUrl: './products.component.pug',
styleUrls: ['./products.component.styl'],
animations: [ rotate, red, gold, selector, FeatAd, FadeQuick, fadeInFull, FadeInSlow, FadeSlow, selectormenu, productsPane, childOpen, filters, Sticky, FilterInput, Fade, progress ]
})
export class ProductsComponent implements OnInit {
	constructor(
		private router: Router,
		private location: Location,
		private marketplace: MarketplaceService,
		private user: UserService) {
			this.resizeScreen();
			this.pA = new Array();
			for (var i = 0; i <= 1000; i++) this.pA[i] = false;
			this.router.events.subscribe((val) => {
				this._size_screen();
			});
			window.addEventListener('storage', () => {
				this.onScroll(Number(localStorage.getItem('top')),Number(localStorage.getItem('height')),Number(localStorage.getItem('scrollHeight')));
			});
	}
	@HostListener('window:scroll', ['$event'])
	scroll(event) {
		this.onScroll();
	}
	@Input('title') title: string = 'Products';
	@Input('showFilters') showFilters: boolean = true;
	@Input('favorites') onlyfavs: boolean = false;
	@Input('wh') wh: boolean = false;
	@Input('fav') fav: boolean = true;
	@Input('edit') edit: boolean = false;
	@Input('format') format: boolean = true;
	@Input('inline') inline: boolean = false;
	@Input('owner') owner: boolean = false;
	@Input('titleMoreLink') titleMoreLink: any = false;
	@Input('titleMoreURL') titleMoreURL = '/shop';
	@Input('showMore') showMore: boolean = true;
	@Input('forceCard') forceCard: boolean;
	@Input('featured') showfeatured: boolean;
	@Input('userFilter') userFilter: string;
	@Input('typeFilter') filterType = 'cannabis';
	@Input('limit') limit = '28';
	@Input('offset') offset = '0';
	@Input('showTopFeatured') TopFeatured = true;
	@Input('lengthwise') lengthwise = false;
	@Input('randomize') randomize = false;
	@Input('fakeloade') fakeloade: number = 20;
	@Input('showSold') showSold: boolean = false;
	@Input('showInStock') showInStock: boolean = true;
	@Input('forceTitle') forceTitle: boolean = false;
	@Input('featButton') featButton: any = false;
	@Input('includeDrafts') includeDrafts: boolean = false;
	@Input('searchTerm') searchTerm: any;
	@Input('showAds') showAds: boolean = true;
	@Output('cartUpdate') cartUpdate = new EventEmitter();
	@Output('favUpdate') favUpdate = new EventEmitter();
	@Output('fullyLoaded') fullyLoaded = new EventEmitter();
	@ViewChild('divToScroll') divToScroll: ElementRef;
	@ViewChild('ProductFilter') ProductFilter: ElementRef;
	@ViewChild('searchBox') searchBox: ElementRef;
	@ViewChild('numberInput') numberInput: ElementRef;
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.resizeScreen(event);
	} 
	onScroll(a=window.pageYOffset,b=window.innerHeight,c=document.body.scrollHeight) {
		if(!this.showFilters&&!this.showMore) return;
		this.filterWindow = ( a > 60) ? 'prone' : false; 
		// flip the filter window to floating mode
		if ( (b + a + 2100) >= c ) {
			if ( !this.onlyfavs && this.productsCount > 0 ) this.show_more(); } 
			// autoload when scrolling near the bottom  2100 pixels
	}
	resizeScreen(e?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		if ( this.screenWidth < 993) {
			this.isMobile = true;
			if (!this.onlyfavs) this.showTopFeatured = false;
		} else {
			this.isMobile = false; 
			if (!this.onlyfavs) this.showTopFeatured = this.TopFeatured;
		} if ( this.screenWidth < 1200 ) {
			this.forceCard = true;
			this.isTablet = true;
		} else { 
			this.isTablet = false;
			this.forceCard = false;
		}
		// this.setLimit();
	}
	// @HostListener('document:keydown', ['$event'])
	// onKeydownHandler(event) {
	// 	if ( event.code == 'KeyX' )
	// 		this.router.navigate(['cart']);
	// 	if ( event.code == 'KeyZ' ) 
	// 		this.change_page();
	// 	if ( event.code == 'Slash' ) 
	// 		this.change_page(1);
	// 	if ( event.code == 'Period' ) 
	// 		this.goToTop();
	// 	if ( event.code == 'Space' ){ 
	// 		this._sp=true;
	// 		setTimeout(()=>{ this._sp=false; }, 200);
	// 	}
	// }
	goToCheckout() { this.router.navigate(['/cart']); }
	ProductsUP(a=0) { this.load_layers[a]=[{'i':a, 'display':'1', 'method': 'products'}]; } // I
	OrderUP() { for( var l = 0; l <= this.fakeloade; l++ ) { this.load[l]=[{'i':l}]; } }    //<3U
	filter = new Filter('homepage_feature','latest','local',true);
	
	orders = [
		{'title': 'Featured', 		'by': 'homepage_feature',  	'icon':'fas fa-bullseye', 		'sort': 'DESC' },
		{'title': 'Popularity', 	'by': 'sold',  		'icon':'fas fa-fire', 					'sort': 'DESC' },
		{'title': 'Distance', 		'by': 'distance', 	'icon':'fas fa-map',  					'sort': 'ASC'},
		{'title': 'Rating', 		'by': 'rating', 	'icon':'fas fa-star',  					'sort': 'DESC'},
		{'title': 'Price Lowest to Highest', 'by': 'prices', 	'icon': 'fas fa-dollar-sign',	'sort': 'ASC'},
		{'title': 'Price Highest to Lowest', 'by': 'prices', 	'icon': 'fas fa-dollar-sign',	'sort': 'DESC'},
		{'title': 'THC Highest to Lowest', 'by': 'thc', 	'icon': 'fas fa-cannabis', 			'sort': 'DESC'},
		{'title': 'THC Lowest to Highest', 'by': 'thc', 	'icon': 'fas fa-cannabis', 			'sort': 'ASC'},
		{'title': 'CBD Highest to Lowest', 'by': 'cbd', 	'icon': 'fas fa-joint',  			'sort': 'DESC'},
		{'title': 'CBD Lowest to Highest', 'by': 'cbd', 	'icon': 'fas fa-joint', 			'sort': 'ASC'}
	];
	times = [ 
		{'title': 'Latest First', 			'by': 'created',  'icon':'fas fa-clock',   'sort': 'DESC' },
		{'title': 'Oldest First', 			'by': 'created',  'icon':'far fa-clock',   'sort': 'ASC' },
		{'title': 'Posted Today', 			'by': 'created_today',  'icon':'fas fa-history', 'sort': 'DESC' },
		{'title': 'Posted This Week', 		'by': 'created_week',  'icon':'fas fa-history', 'sort': 'DESC' },
		{'title': 'Posted This Month', 		'by': 'created_month',  'icon':'fas fa-history', 'sort': 'DESC' },
	];
	single = [new SingleProduct(1, 'g')];
	toggle = new Toggle();
	products: Array<Product>;
	locale: Locale;
	brands: Brand;
	featured: Product;
	preloaded: Product;
	favorites: Product;
	countries: Country;
	locations: Country;
	categories: Category;
	cartProducts: Product;
	subdivisions: Provence;
	filterSearch = 'false';
	filtering = 'Showing 100 products'
	currencyDelimiter = "$";
	showFeaturedAd= 'true';
	order = 'sold';
	time: 'latest';
	unit = 'g';
	locationMsg: string;
	showBackground = 10;
	numberofBackground = 3;
	productsCount = 0;
	favCount = 0;
	scale = 1;
	min_cat = 0;
	max_cat = 6;
	load_layer = 0;
  	TAX_PER_GRAM = 0;
  	FEE_PER_GRAM = 0;
  	SHIPPING = 10;
	ProductFiltersCount = 0;
  	ProductOrdersCount = 0;
  	filter_sidebar_left = -100;
  	cartProductsLength:number;
	categoriesLength:number;
	screenHeight: number;
	screenWidth: number;
	details: number;
	dark: boolean;
	smallerLayout= false;
	loading = false;
	continuousLoader = true;
	showFavorites = false;
	showDetails = false;
	fadeInAd = false;
	fadeInT = false;
	fadeIn = false;
	showInStockb4 = true;
	showTHCCBD = false;
	showTHCCBDb4=false;
	isMobile = false;
	loadedCategories = false;
	filters = false;
	ifilter = false;
	show = false;
	showAfterLoader = [];
	showPublishSw = false;
	showcrossout = false;
	preloading = false;
	pageloading = true;
	favloading = true;
	loadingitems = false;
	noProducts = false;
	allincart = false;
	isTablet = false;
	isAdmin = false;
	loggedIn = false;
	showQ = false;
	_sp = false;
	videoBackground = true;
	showTopFeatured: boolean;
	products_checksum:any;
	locationTag: any;
	sci: any;
	located: any;
	locating: any;
	cartLoop: any;
	parsedCart: any;
	selectedTime: any;
	selectedLocation: any;
	setOrder: any;
	autoLocales: any;
	filterCategory: any;
	sliderPositions: [any];
	cartitem = [];
	imgloader = [];
	loadedImage = [];
	filterWindow: any;
	filterSubCategory: any;
	a: any;
	b: any;
	doc: any;
	left: any;
	top: any;
	timer: any;
	pA: any;
	_A = [];
	_B = [];
	load = [];
	load_layers = [];
	movingSlider = [];
	first_page() { return (Number(this.offset)>0)?false:true; }
	next_page() { return (Number(this.limit)>this.productsCount)?false:true; }
	updateFilterCount() {
		this.ProductFiltersCount = 0;
		if ( this.filter.type=='cannabis') {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.filter.type=='product' ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.showTHCCBD ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.showInStock ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.filter.pricing == 'scaled' ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.filter.pricing == 'fixed' ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.filter.delivery == 'delivery' ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
		if ( this.filter.delivery == 'shipping' ) {
			this.ProductFiltersCount = this.ProductFiltersCount+1;
		}
	}
	anyFilters() {
		if ( this.filter.type=='cannabis') {
			return true;
		}
		if ( this.filter.type=='product' ) {
			return true;
		}
		if ( this.showTHCCBD ) {
			return true;
		}
		if ( this.showInStock ) {
			return true;
		}
		if ( this.filter.pricing == 'scaled' ) {
			return true;
		}
		if ( this.filter.pricing == 'fixed' ) {
			return true;
		}
		if ( this.filter.delivery == 'delivery' ) {
			return true;
		}
		if ( this.filter.delivery == 'shipping' ) {
			return true;
		}
		return false;
	}

	clearFilters() {
		this.ifilter = false;
		this.filterSearch = 'false';
		this.filters = false;
		this.showFavorites = false;
		this.ProductFiltersCount = 0;
		this.ProductOrdersCount = 0;
		this.filter.type = null;
		this.showTHCCBD = false;
		this.showInStock = false;
		this.filter.brand = null;
		this.filter.pricing = null;
		this.filter.delivery = null;
		this.getProducts();
	}
	show_more() {
		if ( this.loadingitems ) return;
		if ( !this.showMore ) return;
		this.loadingitems = true;
		localStorage.setItem('loading', '1');
		this.load_layer=this.load_layer+1;
		this.offset = String(Number(this.offset) + Number(this.limit));
		this.getProducts( () => {
			this.ProductsUP(this.load_layer);
			this.resizeScreen();
			this.loadingitems = false;
			localStorage.setItem('loading', '0');
		}, this.load_layer);
	}
	change_page(backwards?) {
		if ( this.loadingitems  ) return;
		this.loadingitems = true;
		localStorage.setItem('loading', '1');
		if ( backwards ) {
			this.offset = String( Number(this.offset) - Number(this.limit) );
		} else {
			this.offset = String( Number(this.offset) + Number(this.limit) );
		}
		this.getProducts( () => {
			this.goToTop();
			this.loadingitems = false;
			localStorage.setItem('loading', '0');
		}, 0);
	}
	unpublish_button(product) {
		this.marketplace.unpublish(product).subscribe( (p) => {
			console.log(p);
			product = p[0];
		});
	}
	edit_button(product) {
		this.router.navigate(['/edit',product.id]);
	}
	get_favorites() {
		this.marketplace.getfavorite().subscribe( (p) => {
			if(p) {
				this.favorites = p;
				if(p[0]&&p[0].count) this.favCount = p[0].count;
				if ( this.onlyfavs ) {
					this.pageloading = false;
					this.favloading = false;
				}
				if ( this.fav ) this._fadeIn();
			}
		});
	}
	authorize() {
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) {
				this.loggedIn = true;
			}
		});
	}
	setCategory(c) {
		this.filterCategory  = (this.filterCategory==c.name)?false:c.name;
		this.filter.category = (this.filter.category==c.id)? false :c.id;
		this.offset = '0';
		this.getProducts();
	}
	GetWhatUserIsSelling(a) { 
	 	this.marketplace.sellingProducts(this.includeDrafts).subscribe( ( p ) => {
			if (p[0].count == 0 && this.offset != '0') {
				return;  // There were no products found...
			} else if (p[0].count == 0 && this.offset == '0') {
				this.noProducts = true;
				this.products = Array();
				this.featured = null;
				this.productsCount = 0;
			} else if (a==0) { // you're on the first page of products now..
				this.productsCount = p[0].count;
				this.featured = p;
				this.products = Array(p); 
				if (this.products[0]) this.noProducts = false;
				if (!this.products[0]) this.noProducts = true;
			} else if (a>0) { // Scroll-down and add another bunch of products now...
				this.noProducts = false;
				this.productsCount = this.productsCount + p[0].count;
				if (this.products) this.products.push(p);
			}
			// if ( this.productsCount > Number(this.limit) ) {
			// 	this.showAds = false;
			// }
			localStorage.setItem('loading', '0');
			this.pageloading = false;
			this.loading = false;
			if ( this.userFilter ) this._fadeIn();
	  	});
	}

	hashCode(s) {
		for(var i = 0, h = 0; i < s.length; i++)
			h = Math.imul(31, h) + s.charCodeAt(i) | 0;
		return h;
	}

	getProducts(cb?,a=0) {
		if ( this.owner ) {
			this.GetWhatUserIsSelling(a);
			return;
		} else { 
			this.loading = true;
			localStorage.setItem('loading', '1');
			this.filter.user 	 = this.userFilter;
			this.filter.limit 	 = this._limit();
			this.filter.offset 	 = this._offset(a);
			this.filter.search 	 = this.searchTerm;
			this.filter.onlysold = this.showSold;
			this.filter.instock  = this.showInStock;
			this.filter.randomize= this.randomize;
			let parsedCartItems = this.marketplace.parseCart();
			this.products_checksum = this.hashCode(JSON.stringify(this.filter));
			if ( localStorage.getItem('products_'+this.products_checksum) ) {
				this._fadeIn();
				this.products = JSON.parse(localStorage.getItem('products_'+this.products_checksum));
				this.productsCount = Number(localStorage.getItem('products_count_'+this.products_checksum));
				if(this.productsCount>0){
					this.featured = this.products[0];
					this.noProducts = false;	
				} else {
					this.featured = null;
					this.noProducts = true;	
				}
				this.pageloading = false;
				this.loading = false;
				if (cb) cb();
			} else {
				this.marketplace.products('any', 
					this.filter.user, 
					this.filter.order, 
					this.filter.location,
					this.filter.onlysold,
					this.filter.limit,
					this.filter.offset,
					this.filter.search,
					this.filter.type,
					this.filter.pricing,
					this.filter.delivery,
					this.filter.category,
					this.filter.sort,
					this.filter.lang,
					this.filter.instock,
					this.filter.country,
					this.filter.randomize,
					this.filter.brand,
					parsedCartItems,
					this.filter.time,
				).subscribe((p) => {
					if (p[0].count == 0 && this.offset != '0') {
						return;  // There were no products found...
					} else if (p[0].count && (p[0].count == 0 && this.offset == '0')) {
						this.noProducts = true;
						this.products = Array();
						this.featured = null;
						this.productsCount = 0;
					} else if ( a==0 ) { // you're on the first page of products now..
						this.productsCount = p[0].count;
						this.featured = p;
						this.products = Array(p); 
						if (this.products[0].count > 0) this.noProducts = false;
						if (this.products[0].count == 0) this.noProducts = true;
						localStorage.setItem('products_'+this.products_checksum, JSON.stringify(this.products));
						this.showAfterLoader[0] = true;
					} else if (a>0) { // Scroll-down and add another bunch of products now...
						this.noProducts = false;
						this.productsCount = this.productsCount + p[0].count;
						if (this.products) this.products.push(p);
						localStorage.setItem('products_'+this.products_checksum, JSON.stringify(this.products));
						this.showAfterLoader[this.products.length-1] = true;
					}
					this.resizeScreen();
					localStorage.setItem('loading', '0');
					localStorage.setItem('products_count_'+this.products_checksum, this.productsCount.toString());
					this.pageloading = false;
					this.loading = false;
					setTimeout( () => { this.showcrossout=true; }, 500);
					this.fullyLoaded.emit(1);
					this._fadeIn();
					if (cb) cb();
				});
			}
		}
	}
	_aa(b,c) {
		this._A[b]=c;
		if ( c==2 ) setTimeout(() => { this._A[b]=0; }, 260);
	}
	_fadeIn() {
		if (!this.fadeInT) {
			this.fadeInT=true;
			setTimeout( () => { this.fadeIn = true; }, 210);
		} 
	}
	_resetfade() {
		this.fadeInT=false;
		this.fadeIn=false;
	}
	get_categories() {
		this.marketplace.used_categories(this.filter).subscribe( (cat) => {
			if(cat[0].count>0){
				this.categories = cat;
				this.categoriesLength = cat[0].count;
			}
			this.loadedCategories = true;
		});
	}
	toggleSearch() {
		this.show = false; 
		this.filters = false; 
		this.filterSearch = (this.filterSearch!='false')?'false':(this.isMobile)?'true_mobile':'true1';
		if ( this.filterSearch == 'false' ) this.filter.term = '';
		this.trigger_search();
	}
	toggleFormat() {
		this.format = this.format ? false : true;
		this.setScreenFormat(this.format);
	}
	setScreenFormat(a) {
		if ( this.format ) {
			this.format = a;
			this.marketplace.setScreenFormat(a);
		}
	}
	modify(p) {
		this.router.navigate(['/edit',p.id])
	}
	favorite(p) {
		if( this.pA && this.pA[p.id]==true ) return;
		this.pA[p.id] = true;
		p.favorite = (p.favorite) ? false : true;
		setTimeout( () => { this.pA[p.id] = false; },1000);
		this.marketplace.favorite(p.id).subscribe( (bool) => {
			this.get_favorites();
			p.favorite = bool;
		});
	}
	feat(p) {
		p.featured = (p.featured) ? false : true;
		this.marketplace.feature(p.id).subscribe( (bool) => {
			p.featured = bool;
		});
	}
	homepagefeat(p) {
		if ( this.isAdmin ) { 
			p.homepagefeatured = (p.homepagefeatured) ? false : true;
			this.marketplace.homepagefeature(p.id).subscribe( (bool) => {
				p.homepagefeatured = bool;
			});
		}
	}
	isfavorite( p ) {
		return this.marketplace.isfavorite(p.id)
	}
	setFilterBrand(b) {
		this.filter.brand = b.vendor;
		this.getProducts();
		this.filters=false;
	}
	setFilterOrder(o) {
		this.filter.time = null;
		this.filter.order = o.by;
		this.filter.sort = o.sort;
		this.filter.title = o.title;
		if ( o.by == 'thc' || o.by == 'cbd') {
			this.showTHCCBD = true;
			this.showTHCCBDb4=true;
		} else {
			this.showTHCCBD = false;
		}
		this.getProducts();
		this.filters=false;
	}
	setFilterTime(t) {
		this.selectedTime = t;
		this.filter.time = t.by;
		this.filter.sort = t.sort;
		this.filter.title = t.title;
		this.getProducts();
		this.filters=false;
	}
	setFilterLocation(l) {
		this.selectedLocation = l;
		this.filter.location = l.code;
		this.getProducts();
		this.filters=false;
	}
	toggleCountry(l) { 
		this.toggleLocale(l);
	}
	toggleLocale(l, e?, f?) {
		console.log(l, e, f)
		if ( l.subdivisions ) {
			// this.filter.location = (this.filter.location) ? : ;
			// let a = String(l.id);
			// let o = this.filter.location.indexOf(a);
			// if (o > -1) {
			// 	if ( f )
			// 	this.filter.location.splice(o, 1);	
			// } else {
			// 	this.filter.location.push(a);
			// }
			// for (var i = l.subdivisions.length - 1; i >= 0; i--) {
			// 	let a = String(l.subdivisions[i].id);
			// 	let o = this.filter.location.indexOf(a);
			// 	if (o > -1) {
			// 		this.filter.location.splice(o, 1);	
			// 	} else {
			// 		this.filter.location.push(a);
			// 	}
			// }
		} else {
			// this.filter.location = (this.filter.location) ? : ;
			// let a = String(l.id);
			// let o = this.filter.location.indexOf(a);
			// if (o > -1) {
			// 	this.filter.location.splice(o, 1);
			// } else { 
			// 	this.filter.location.push(a);
			// }
		}
		console.log(this.filter.location)
	}
	locationTitle(s) {
		return (s) ? s.country : 'My Location';
	}
	sliderPosition(pos=0, opt) {
		return pos * 10 + '%';
	}
	sliderMove(event, opt, on) {
		if ( on != null ) this.movingSlider[opt] = on;
		if ( this.movingSlider[opt] ) {
			this.sliderPositions[opt] = event.srcElement.offsetLeft;
		}
	}
	addToCart(p) {
		if (!p) return;
		if ( p.scaled == 'scaled' ) {
			this.marketplace.addToCart( p.id, p.order );
			this.cartUpdate.emit(true);
		} else if ( p.scaled == 'fixed' )  { 
			for (var i = 0; i <= p.quantitiesCount; i++) {
				if ( p.quantities[i].in_stock == true ) {
					p.in_cart=true;
					this.marketplace.addToCart( p.id, 1, 'g', p.quantities[i].weight, false, p.quantities[i].name );
					this.get_cart_products();
					this.cartUpdate.emit(true);
					return;
				}
			}
		}
	}
	get_locations() {
		this.marketplace.countries().subscribe( (c) => {
			// this.selectedLocation = c[0];
			this.locations = c;
		});
	}
	get_brands() {
		this.marketplace.getBrands(this.filter.type).subscribe((b) => {
			this.brands = b;
		});
	}
	locate() {
		this.locating = true;
		let key = this.user.locKey();
		if ( key ) {
			this.get_locale();
		} else { 
			this.serv_locate();
		}
	}
	localeSelected(i) {
		i = String(i.id); let c = String(i.code);
		let k = (this.filter.location) ? this.filter.location.indexOf(i) : -1; 
		let l = (this.filter.location) ? this.filter.location.indexOf(c) : -1; 
		let results = (k > -1 || l > -1) ? true : false;
		return results;
	}
	shipEverywhere() {
		for (var i = 0; i >= 0; i + 1) {
			this.filter.location=this.countries[i].id;
		}
	}
	get_locale() {
		this.user.locale().subscribe( (a) => {
			if ( a[0] ) {
				this.locale = a;
				this.locationTag = a[0].msg;
				this.user.changeLocale( a[0].iso );
				this.located = true;
			}
		});
		setTimeout( () => {
			this.locating = false;
		}, 7420);
	}
	serv_locate() {
		// this.user.locate( (pos) => {
		// 	if ( pos != false ) {
		// 		this.user.pingLatLng(pos).subscribe( (l) => {
		// 			if ( l[0] ) this.locationTag = l[0].msg;
		// 			this.locale = l;
		// 			console.log(l[0].key)
		// 			this.user.setLocKey( l[0].key );
		// 			this.user.changeLocale( l[0].iso );
		// 			this.resetLimit();
		// 			this.getProducts();
		//    		});
		//    	}
	 //    });
	}
	inCart( product ) {
		if ( product && product.quantities && product.quantities.length > 0 ){
			let a = false;
			for (var i = 0; i <= product.quantities.length; i++) {
				if (product.quantities[i]) {
					let n = this.marketplace.getUnitQuantities(product.quantities[i]);
					if (product.id == 326) console.log(n);
					if (n > 0) {
						a = (!a) ? true : false;
					}
				}
			}
			return a;
		}
	}
	trigger_search(e?) {
		this.searchTerm = this.filter.term;
		this.resetLimit();
		this.getProducts();
	}
	resetLimit() {
		// this.setLimit();
		this.offset = '0';
		this.load = [];
		this.load_layers = [];
		this.ProductsUP();
	}
	goToTop(t=450, y = 60) {
		$("html, body").animate({ scrollTop: y+'px' }, t);
	}
	goTo(t) { this.router.navigate([t]); }
	setCat( name ) {
		this.filter.category = (this.filter.category == name) ? false : name;
		this.offset = '0'; this.getProducts();
	}
	loadProduct( p ) {
		localStorage.setItem('products_scroll_y_'+this.products_checksum, String(window.pageYOffset));
	}
	// autoGetLocales() {
	// 	this.autoLocales = setInterval( () => {
	// 		this.get_locales(); //true
	// 	}, 700);
	// }
	addFavsToCart() {
		if ( this.allincart ) this.router.navigate(['/cart'])
		for (var i = 0; i <= this.favCount; i++) {
			if (!this.inCart(this.favorites[i])) this.addToCart(this.favorites[i]);
		}
		this.allincart = true;
	}
	get_locales(c?) {
		let a = this.user.locKey();
		let b = this.user.getLocaleCountry();
		this.locationMsg = this.user.locMsg();
		if (b!=this.filter.country) {
			this.filter.country = b;
			if (c) {
				this._resetfade();
				this.resetLimit();
				this.getProducts();
			}
		}
		if (a!=this.filter.location) {
			this.filter.location = a;
			if (c) {
				this._resetfade();
				this.resetLimit();
				this.getProducts();
			}
		}
		
	}
	get_cart_products() {
		this.marketplace.cartContents().subscribe( (c) => {
			if (c[0]) {
				this.cartProducts = c;
				this.cartProductsLength = c[0].count;
			}
		});
	}
	filterButton( filterby ) {
		this.offset = '0'; 
		this.resetLimit(); 
		switch (filterby) {
			case "product":
				this.filter.type = 'product';
				break;
			default:
				this.filter.type = 'cannabis';
				break;
		}
		this.getProducts();
	}
	refreshCartLoop() {
		let pi = 0, it = 400;
		this.cartLoop = setInterval( () => {
			let pa = this.marketplace.parseCart();
			if ( this.parsedCart !== pa ) {
				this.get_cart_products();
				this.parsedCart = pa;
			} pi++;
		}, it);
	}
	ttr(a=-1) { 
		let b = (this.showTopFeatured && !this.onlyfavs && a==0)?1:0; 
		return b;
	}
	tts(a=-1) { 
		let n = Number(this.limit);
		if (a==0 && !this.isTablet) n = n + 2;
		return n;
	}
	_limit() {
		let b = Number(this.limit);
		if ( this.offset=='0' && !this.isTablet ) {
			b = b + 1;
		}
		return String(b);
	}
	_offset(a=0) {
		return String((a>0)?(Number(this.offset)+Number(this.limit)):this.offset);
	}

	_size_screen() {
		let p = this.location.path();
		if ( (p.indexOf('/my-account') > -1) ) {
			this.smallerLayout = true;
		}
	}
	// setLimit() {
		// if (  this.showTopFeatured && !this.showAds ) this.limit = '26';
		// if ( !this.showTopFeatured &&  this.showAds ) this.limit = '24';
		// if ( !this.showTopFeatured && !this.showAds ) this.limit = '28';
		// if (  this.showTopFeatured &&  this.showAds ) this.limit = '28';
		// this.limit = '28';
	// }
	scroll_left() {
		this.divToScroll.nativeElement.scrollLeft -= 189; 
	}
	scroll_right() {
		this.divToScroll.nativeElement.scrollLeft += 189; 
	}
	start_scrolling(d='left') {
		if(d=='left') {
			this.sci = setInterval( () => {
				this.divToScroll.nativeElement.scrollLeft -= 3;
			}, 33);
		} else {
			this.sci = setInterval( () => {
				this.divToScroll.nativeElement.scrollLeft += 3;
			}, 33);
		}
	}
	stop_scrolling() {
		clearInterval(this.sci);
	}
	togglePublished(product, e?) {
		this.unpublish_button(product);
	}
	randomBackground() {
		if (!this.numberofBackground) return false;
		this.showBackground = Math.floor(Math.random() * Math.floor(this.numberofBackground-1));
	}
	ngOnInit() {
		this.timer = setTimeout( () => { this.fadeInAd = true; }, 800);
		this.timer = setInterval( () => { this.dark = this.marketplace.getDark(); }, 1000);
		let top = Number(localStorage.getItem('products_scroll_y_'+this.products_checksum));
		if (top&&top>500) this.goToTop(420,top);
		localStorage.setItem('top','');
		localStorage.setItem('height','');
		localStorage.setItem('scrollHeight','');
		if ( !this.onlyfavs ) this.showTopFeatured = this.TopFeatured;
		this.filter.term = this.searchTerm;
		this.filter.user = this.userFilter;
		this.filter.type = this.filterType;
		this.filter.instock = this.showInStock;
		this.selectedTime = this.times[0];
		this.selectedTime = this.times[0];
		this.filter.time = this.selectedTime;
		this.resizeScreen();
		this.authorize();
		// this.locate();
	  	this.getProducts();
		this._size_screen();
		// this.setLimit();
		this.get_locales(true);
		// this.autoGetLocales();
		this.OrderUP();
		this.ProductsUP();
		this.get_categories();
		this.get_favorites();
		this.get_locations();
		this.refreshCartLoop();
		this.randomBackground();
		this.get_cart_products();
		this.updateFilterCount();
		this.setScreenFormat(true);
		this.get_brands();
		this.marketplace.pageTitle();
		this.user.adminStatus().subscribe( (i) => {
			this.isAdmin = i.json().admin;
		});
	}
	ngOnDestroy() {
		clearInterval(this.timer);
		clearInterval(this.autoLocales);
	}
}
export class SingleProduct {
	constructor(
		public quantity?: number,
		public unit?: string,
		public offer?: number
	) {}
}
export class Toggle {
	constructor(
		public published?: boolean
	) {}
}
export class Filter {
   constructor(
	public order?: string,
	public time?: string,
	public location?: string,
	public instock?: boolean,
	public brand?: any,
	public type?: string,
	public term?: string,
	public user?: string,
	public title?: string,
	public pricing?: string,
	public delivery?: string,
	public category?: string,
	public sort?: string,
	public lang?: string,
	public country?: string,
	public onlysold?: boolean,
	public limit?: any,
	public offset?: any,
	public search?: string,
	public randomize?: boolean,
	public thc?: string,
	public cbd?: string
   ) {}
}