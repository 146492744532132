import { Component, OnInit } from '@angular/core';
import { Account } from '../../models/account';
import { Review } from '../../models/review';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'user-reviews',
  templateUrl: './reviews.component.pug',
  styleUrls: ['./reviews.component.styl']
})
export class ReviewsComponent implements OnInit {

  constructor(
  	private user: UserService
  ) { this.getHome(); }

  reviews: Review;
  accounts: Account;
  username: string = '%username%';
  page:string = 'Orders';
  loaded:boolean;
  h = [];
  getHome() { for (var i = 0; i < 9;i++) {this.h.push(i);}}

  getReviews() { 
    this.user.getReviews().subscribe( (r) => {
      this.reviews = r;
    });
  }

  ngOnInit() {
    
  	// Authorize Account
		this.user.authorize().subscribe( (a) => {
			if (a && a[0]) this.username = a[0].username;
			this.accounts = a;
		});

  }

}
