import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClientModule, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Rx';
import { UserService } from './user.service';
import { Country, Address, City, Locale, Provence } from '../models/address';
import { ShippingOption } from '../models/shipping_option';
import { PaymentMethod } from '../models/paymentmethod';
import { Verification } from '../models/verification';
import { Transaction } from '../models/transaction';
import { BlockchainTransaction } from '../models/transaction';
import { Favorite } from '../models/favorite';
import { Currency } from '../models/currency';
import { Balances } from '../models/balances';
import { Category } from '../models/category';
import { ShopInfo } from '../models/shopinfo';
import { Account } from '../models/account';
import { Product } from '../models/product';
import { Postage } from '../models/postage';
import { Keyword } from '../models/keyword';
import { Earning } from '../models/earning';
import { Strain } from '../models/strain';
import { Coupon } from '../models/coupon';
import { Cheque } from '../models/cheque';
import { Image } from '../models/image';
import { Order } from '../models/order';
import { Brand } from '../models/brand';
import { Post } from '../models/post';
import { Cart } from '../models/cart';
import { Info } from '../models/info';
import { Coin } from '../models/coin';
import { Card } from '../models/card';
import { Item } from '../models/item';
import { Tip } from '../models/tip';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class MarketplaceService {
	private baseUrl: string = environment.base;
	private rememberedStrains: any;
	private majorfLoss='crash';
	cookie_opts = {
		path: '/',
		domain: environment.domain,
		storeUnencoded: (environment.production)?true:false
	};
	// futuredate = Date.now() + (64 * 86400);
	constructor( private http 		: Http,
				 private user 		: UserService,
				 private tits 		: Title,
				 private httpClient : HttpClientModule,
				 private cookie 	: CookieService ) { 
	}
	pageTitle(t='Tree St.') { this.tits.setTitle(t); }
	clearCart() { this.cookie.delete('tcart'); }
	rawCart() {
		var c = this.cookie.get('tcart');
		return (c) ? c : false;
	}
	parseCart() {
		var c = this.cart(), a = '';
		for (var i = c.length - 1; i >= 0; i--) {
			if ( !c[i] ) return;
			if ( c[i].name.length>0 ) {
				for (var k = 0; k <= c[i].name.length; k++) {
					if( c[i].name[k] ) {
						a = a + c[i].id + '-' + this.sharpen_name(c[i].name[k]) + ',';
					}
				}
			} else if ( c[i].weight.length > 0 ) {
				for (var k = 0; k <= c[i].weight.length; k++) {
					if( c[i].weight[k] ) {
						a = a + c[i].id + '-' + c[i].weight[k] + ',';
					}
				}
			}
		}
		return a;
	}
	cart() {
		var c = this.cookie.get('tcart'), a = [];
		if (!c) return false;
		var b = JSON.parse(c);
		return b;
	}
	numberInCart( id? ) {
		var c = this.cart();
		var t = 0;
		for (var i = c.length - 1; i >= 0; i--) {
			if ( c[i].id == id ) {
				if ( c[i].qty > 0 ) {
					t = t + c[i].qty;
				}  if (c[i].weight.length > 0) {
					t = t + c[i].weight.length;
				}  if (c[i].name.length > 0) {
					t = t + c[i].name.length;
				}
			}
		}
		return t;
	}
	sharpen_name( n ) {
		var k = '';
		if ( n != null ) {
			k = n.toLowerCase(); 
			k = this.replaceAll(k, '"', '');
			k = this.replaceAll(k, '”', '');
			k = this.replaceAll(k, " ", "_"); 
			k = this.replaceAll(k, "/", "_");
		}
		return k;
	}
	replaceAll(s, omit, place) {
		if ( s ) {
			while (s.indexOf(omit) !== -1) {
		    	s = s.replace(omit, place);
			}
		}
		return s;
	}
	addCoupon(c) { this.cookie.set('coupon',c); }
	clearCoupon() { this.cookie.delete('coupon'); }
	returnCoupon() { return this.cookie.get('coupon'); }
	getCoupon(code) {
		var Fries = `${this.baseUrl}coupon/check/`;
		var Cheese = new URLSearchParams();
			Cheese.append('coupon', code);
		var GRAVY = this.http.post( Fries, Cheese )
			.map(mapCoupon)
			.catch(handleError)
		return GRAVY;
	}
	addToCart( id, qty = 0, unit = 'g', weight = null, rm = false, name = false ) {
 		var oldCart = this.cookie.get('tcart'); var p={},fix=false;
 		if ( !oldCart && weight == null && name == false ) fix = true; // stupid 
 		if ( oldCart ) p = JSON.parse(oldCart);
		var a = [], l = Object(p).length, f = [], n = [];
 		for (var i = 0; i <= l; i++) {
 			if (p[i]) {
 				var b = p[i];
				// Push existing cart items  		// b['qty'] > 0 && 
	 			if ( p[i]['id'] != id ) {
	 				a.push(b);
	 			} else {
					if ( b.name.length > 0 ) {
						for (var j = 0; j <= b.name.length; j++) {
							var la = this.sharpen_name(b.name[j]);
							if ( la != '' ) n.push( la );
						}
					} else { // add existing
						f.push( qty ); 
					}
	 			}
	 		}
		}
		if ( name ) n.push( this.sharpen_name(name) );
		if ( !n ) qty = 0;
		a.push({
			'id': id,
			'qty': qty,
			'name': n,
			'weight': f,
			'unit': unit
		});
		var bb = JSON.stringify(a);
		this.cookie.delete('tcart');
		this.cookie.set('tcart', bb);
		return;
	}
	getUnitQuantities(o) {
		var c = this.cart(), a = 0;
		if ( c.length > 0 ) {
			for (var i = c.length - 1; i >= 0; i--) {
				if ( c[i] ) {
					if ( c[i].name.length > 0 ) {
						for (var j = c[i].name.length - 1; j >= 0; j--) {
							if ( c[i].id && (c[i].id == o.id) && (c[i].name[j] == this.sharpen_name(o.name)) ) {
								a = a?a+1:1;
							}
						}
					} else {
						for (var j = c[i].weight.length - 1; j >= 0; j--) {
							if ( c[i].id && (c[i].id == o.id) ) {
								a = c[i].weight[j];
							}
						}
					}
				}
			}
		}
		return a;
	}
	addUnit(p, o) {
		this.addToCart( p.id, 0, p.unit, null, false, o.name );
	}
	removeUnit(p, o, e=false) {
		var newCart = {};
		var oldCart = this.cookie.get('tcart'); 
 		if ( oldCart ) newCart = JSON.parse(oldCart);
 		var run = true, nw = [];
 		for (var i = 0; i < Object(newCart).length; i++) {
 			for (var j = 0; j < newCart[i].name.length; j++) {
 				if ( run && (newCart[i].name[j] == this.sharpen_name(o.name)) && (newCart[i].id == p.id) ) {
 					run = false;
 					newCart[i].name.splice(j, 1);
 				}
			}
		}
		for (var i = Object(newCart).length - 1; i >= 0; i--) {
			if ( newCart[i].qty > 0 || newCart[i].name.length != 0 ) {
				nw.push(newCart[i]);
			}
		}
		var n = JSON.stringify(nw);
		this.cookie.delete('tcart');
		this.cookie.set( 'tcart', n);
		return;
	}
	removeFromCart(item?, id?) {
		var oldCart = this.cookie.get('tcart'),
			c = (oldCart)?JSON.parse(oldCart):[],
			i = c.indexOf(item.id);
			c.splice(i,1);
		var n = JSON.stringify(c);
		this.cookie.delete('tcart');
		this.cookie.set( 'tcart', n);
		return;
	}
	removeUnitFromCart(p, o) {
		this.removeUnit(p, o, true);
	}
	unitInCart(weight, pid) {
		var c = this.cart();
		for (var i = c.length - 1; i >= 0; i--) {
			if ( c[i].id == pid && c[i].weight.indexOf(weight) > -1 ) {
				return true	
			}
		}
	}
	setDark( dark ) {
		this.cookie.set('dark', dark);
	}
	getDark() {
		return this.cookie.get('dark') == 'true' ? true : false;
	}
	toggleDark() {
		this.setDark( this.getDark()?false:true );
	}
	dusk_till_dawn() {
		var a = 'false';
		var today = new Date();
		var h = today.getHours();
		if(h<6) a = 'true';
		if(h>21) a = 'true';
		this.setDark( a );
	}
	getBrands(type?): Observable<Brand> {
		var Url = `${this.baseUrl}brands/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
		if (type) Params.append('type', type);
		var Opts = new RequestOptions({ headers: Header });
		var bra = this.http
			.post(Url, Params, Opts)
			.map(mapBrands)
			.catch(handleError);
		return bra;
	}
	getLocations(): Observable<Address> {
		var Url = `${this.baseUrl}addresses/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var loc = this.http
			.get(Url, Opts)
			.map(mapAddresses)
			.catch(handleError);
		return loc;
	}
	cartContents(): Observable<Product> { 
		var a = this.parseCart();
		if (a) {
			var Url = `${this.baseUrl}products/?products=`+a;
			var Header = new Headers();
			var Opts = new RequestOptions({ headers: Header });
			var pro = this.http
				.get(Url, Opts)
				.map(mapProduct)
				.catch(handleError);
			return pro;
		} else {
			return Observable.empty();
		}
	}
	cartWeight(): Observable<number> { 
		var d = this.parseCart();
		if (d) {
			var Url = `${this.baseUrl}products/?products=`+d;
			var Header = new Headers();
			var Opts = new RequestOptions({ headers: Header });
			var pro = this.http
				.get(Url, Opts)
				.map(countWeight)
				.catch(handleError);
			return pro;
		} else {
			return Observable.empty();
		}
	}
	cartTotal(): Observable<number> { 
		var k = this.parseCart();
		if (k) {
			var Url = `${this.baseUrl}products/?products=`+k;
			var Header = new Headers();
			var Opts = new RequestOptions({ headers: Header });
			var tot = this.http
				.get(Url, Opts)
				.map(countTotal)
				.catch(handleError);
			return tot;
		} else {
			return Observable.empty();
		}
	}
	cartInfo(coup='',delv=false,pick=false,ship=false,loc='',addr=0,f=0): Observable<Info> { 
		var j = this.parseCart(); 
		if (j||f>0) {
			if ( coup!='' ) j = j + '&coupon=' + coup;
			if ( loc!='' ) j = j + '&loc=' + loc;
			if ( delv!=false ) j = j + '&delivering=' + delv;
			if ( ship!=false ) j = j + '&shipping=' + ship;
			if ( addr>0 ) j = j + '&address=' + addr;
			var Url = `${this.baseUrl}cartinfo/?products=`+j;
			var Header = new Headers();
				Header.append('Authorization', this.user.wib());
			var Opts = new RequestOptions({ headers: Header });
			var tot = this.http
			.get(Url, Opts)
			.map(mapInfo)
			.catch(handleError);
			return tot;
		} else {
			return Observable.empty();
		}
	}
	sendCart() { 
		var cart = this.cookie.get( 'tcart' );
		var Params = new URLSearchParams();
			Params.append('cart', cart);
		var Header = new Headers();
		var Url = `${this.baseUrl}cart/`;
		var Opts = new RequestOptions({ headers: Header });
		var user = this.http
			.post(Url, Params)
			.map(mapCart)
			.catch(handleError);
		return user;
	}
	getCoins() {
		var Url = `${this.baseUrl}coins/available/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.get(Url, Opts)
			.map(mapCoins)
			.catch(handleError);
		return p;
	}
	cheques(): Observable<Cheque> {
		var Url = `${this.baseUrl}cheques/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.get(Url, Opts)
			.map(mapCheques)
			.catch(handleError);
		return p;
	}
	add_cheque(cq): Observable<Cheque> {
		var Url = `${this.baseUrl}cheques/add/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.get(Url, Opts)
			.map(mapCheques)
			.catch(handleError);
		return p;
	}
	featproduct(acc): Observable<boolean> {
		var Url = `${this.baseUrl}featproduct/?acc=`+acc;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.get(Url, Opts)
			.map(mapFeatProd)
			.catch(handleError);
		return p;
	}
	singlefeatproduct(): Observable<Product> {
		var Url = `${this.baseUrl}hyped/`; 
		var Params = new URLSearchParams();
			Params.append('limit', '1');
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return p; 
	}
	product(id) {
		var Url = `${this.baseUrl}product/`;
		var Params = new URLSearchParams();
		if ( id ) Params.append('productID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapProduce)
			.catch(handleError);
		return p;
	}
	item(id) {
		var Url = `${this.baseUrl}product/`;
		var Params = new URLSearchParams();
		if ( id ) Params.append('productID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapItem)
			.catch(handleError);
		return p;
	}

	suggestedProducts(): Observable<Product> { 
		var Url = `${this.baseUrl}suggested/products/`;
		var Params = new URLSearchParams();
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return p;
	}
	updateCart(itemObject) {
		var cart = this.cookie.get('tcart');
		this.cookie.delete( 'tcart' );
		this.cookie.set( 'tcart', cart);
	}
	changeQuantity(item, grams) {
		var cart = this.cookie.get('tcart');
		var entry = item; 
		this.cookie.delete( 'tcart' );
		this.cookie.set( 'tcart', entry);
	}
	locate(): Observable<Locale> { 
		var Url = `${this.baseUrl}locate/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.get(Url, Opts)
			.map(mapLocation)
			.catch(handleError);
		return c;
	}
	addStrains(type, num, off, random) {
		this.strains(type, num, off, random);
	}
	strains(type=null, num?, off?, name?, random?): Observable<Strain> {
		if ( !num ) num = 'all';
		var Url = `${this.baseUrl}strains/`;
		var Params = new URLSearchParams();
			Params.append('n', num);
			Params.append('o', off);
			Params.append('r', random);
			Params.append('t', type);
			Params.append('l', name);
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var strain = this.http
			.post(Url, Params, Opts)
			.map(mapStrain)
			.catch(handleError);
		return strain;
	}
	tax(s) {
		var Params = new URLSearchParams();
		if ( s ) Params.append('unit', s.unit);
		if ( s ) Params.append('amount', s.quantity);
		var Header = new Headers();
		var Url = `${this.baseUrl}taxes/`;
		var Opts = new RequestOptions({ headers: Header });
		var taxes = this.http
			.post(Url, Params, Opts)
			.map(mapTax)
			.catch(handleError);
		return taxes;
	}
	countStrains(): Observable<any> {
		var Url = `${this.baseUrl}strains/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var strain = this.http
			.get(Url, Opts)
			.map(countStrains)
			.catch(handleError);
		return strain;
	}
	getCurrencies(): Observable<Currency> {
		var Url = `${this.baseUrl}currencies/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.get(Url, Opts)
			.map(mapCurrency)
			.catch(handleError);
		return c;
	}
	saveCurrency( c ): Observable<Currency> {
		var Url = `${this.baseUrl}currency/set/1/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var Params = new URLSearchParams();
		if ( c ) Params.append('currency', c);
		var s = this.http
			.post(Url, Params, Opts)
			.map(saveCurrency)
			.catch(handleError);
		return s;
	}
	countCurrencies(): Observable<any> {
		var Url = `${this.baseUrl}currencies/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.get(Url, Opts)
			.map(countCurrency)
			.catch(handleError);
		return c;
	}
	getKeywords(): Observable<Keyword> {
		var Url = `${this.baseUrl}kw/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var key = this.http
			.get(Url, Opts)
			.map(mapKeyword)
			.catch(handleError);
		return key;
	}
	// subdivision(c): Observable<Address> {
	// 	var Url = `${this.baseUrl}subdivisions/`+c;
	// 	var Header = new Headers();
	// 	var Opts = new RequestOptions({ headers: Header });
	// 	return this.http.get(Url, Opts).map(res => res.json());
	// }
	countries(): Observable<Country> {
		var Url = `${this.baseUrl}countries/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var count = this.http
			.get(Url, Opts)
			.map(mapCountry)
			.catch(handleError);
		return count;
	}
	getCountries(): Observable<Country> {
		var Url = `${this.baseUrl}countries/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.get(Url, Opts)
			.map(mapCountry)
			.catch(handleError);
		return c;
	}
	getProvinces(c='CA', n=''): Observable<Provence> {
		var Url = `${this.baseUrl}provinces/`;
		var Header = new Headers();
		var Params = new URLSearchParams();
			if ( c ) Params.append('c', c);
			if ( n != '' ) Params.append('n', n);
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapProvence)
			.catch(handleError);
		return p;
	}
	getCities(pr='',cou='CA'): Observable<City> {
		var Url = `${this.baseUrl}cities/`;
		var Header = new Headers();
		var Params = new URLSearchParams();
			if ( pr ) Params.append('p', pr);
			if ( cou != '' ) Params.append('c', cou);
		var Opts = new RequestOptions({ headers: Header });
		var p = this.http
			.post(Url, Params, Opts)
			.map(mapCities)
			.catch(handleError);
		return p;
	}
	products(p='any', u?, o?, l?, s?, limt='12', ofset = '0', search = '', type = 'any', pricing = 'any', delivery = 'any', category?, r = 'DESC', lang = 'en', instock = true, country='', random=false, brand='', cart='', time=''): Observable<Product> {
		// var cache = btoa(this.cookie.get('products'));
		// if ( cache ) return cache;
		var Params = new URLSearchParams();
			if ( p ) Params.append('products', p);
			if ( u ) Params.append('user', u);
			if ( o ) Params.append('order', o);
			if ( r ) Params.append('sort', r);
			if ( l ) Params.append('location', l);
			if ( s ) Params.append('onlysold', s);
			if ( limt ) Params.append('limit', limt);
			if ( ofset ) Params.append('offset', ofset);
			if ( search ) Params.append('search', search);
			if ( type ) Params.append('type', type);
			if ( pricing ) Params.append('pricing', pricing);
			if ( delivery ) Params.append('delivery', delivery);
			if ( category ) Params.append('category', category);
			if ( lang ) Params.append('lang', lang);
			if ( random ) Params.append('randomize', (random)?'true':'false');
			if ( cart ) Params.append('cart', cart);
			if ( brand!='' ) Params.append('brand', brand);
			if ( time!='' ) Params.append('time', time);
			if ( instock ) Params.append('instock', (instock)?'true':'false');
			if ( country ) Params.append('country', country);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Url = `${this.baseUrl}products/`;
		var Opts = new RequestOptions({ headers: Head });
		var c = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return c;
	}
	countProducts(p='any', u?, o?, l?, s?, limt='12', ofset = '0'): Observable<any> {
		var Url = `${this.baseUrl}products/`;
		var Params = new URLSearchParams();
			if ( p ) Params.append('products', p);
			if ( u ) Params.append('user', u);
			if ( o ) Params.append('order', o);
			if ( l ) Params.append('location', l);
			if ( s ) Params.append('onlysold', s);
			if ( limt ) Params.append('limit', limt);
			if ( ofset ) Params.append('offset', ofset);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.post(Url, Params, Opts)
			.map(countProduct)
			.catch(handleError);
		return c;
	}
	randomproducts(p='any', u?, o?, l?, s?, limt='36', ofset = '0', search = '', type = 'any', pricing = 'any', delivery = 'any', r = 'DESC', lang = 'en', onlyfeatured = 'false', instock = 'true' ): Observable<Product> {
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if ( p ) Params.append('products', p);
			if ( u ) Params.append('user', u);
			if ( o ) Params.append('order', o);
			if ( r ) Params.append('sort', r);
			if ( l ) Params.append('location', l);
			if ( s ) Params.append('onlysold', s);
			if ( limt ) Params.append('limit', limt);
			if ( ofset ) Params.append('offset', ofset);
			if ( search ) Params.append('search', search);
			if ( type ) Params.append('type', type);
			if ( pricing ) Params.append('pricing', pricing);
			if ( delivery ) Params.append('delivery', delivery);
			if ( onlyfeatured != 'false' ) Params.append('onlyfeatured', onlyfeatured);
			if ( instock ) Params.append('instock', (instock)?'true':'false');
			if ( lang ) Params.append('lang', lang);
		var Url = `${this.baseUrl}products/random/`;
		var Opts = new RequestOptions({ headers: Head });
		var Rands = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return Rands;
	}
	featuredproducts(p='any', u?, o?, l?, s?, limt='36', ofset = '0', search = '', type = 'any', pricing = 'any', delivery = 'any', r = 'DESC', lang = 'en', onlyfeatured = 'true', instock = 'true' ): Observable<Product> {
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			if ( p ) Params.append('products', p);
			if ( u ) Params.append('user', u);
			if ( o ) Params.append('order', o);
			if ( r ) Params.append('sort', r);
			if ( l ) Params.append('location', l);
			if ( s ) Params.append('onlysold', s);
			if ( limt ) Params.append('limit', limt);
			if ( ofset ) Params.append('offset', ofset);
			if ( search ) Params.append('search', search);
			if ( type ) Params.append('type', type);
			if ( pricing ) Params.append('pricing', pricing);
			if ( delivery ) Params.append('delivery', delivery);
			if ( onlyfeatured ) Params.append('onlyfeatured', onlyfeatured);
			if ( instock ) Params.append('instock', (instock)?'true':'false');
			if ( lang ) Params.append('lang', lang);
		var Url = `${this.baseUrl}products/featured/`;
		var Opts = new RequestOptions({ headers: Head });
		var Feat = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return Feat;
	}
	search(term): Observable<Product> {
		var Url = `${this.baseUrl}search/?q=${term}`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var search = this.http
			.get(Url, Opts)
			.map(mapProducts)
			.catch(handleError);
		return search;
	}
	order(accountID, cart): Observable<Order> {
		var params = new URLSearchParams();
			params.set('owner', accountID);
			params.set('products', cart);
		var Url = `${this.baseUrl}order/create/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
			Opts.params = params;
		return this.http.get(Url, Opts).map(res => res.json());
	}
	get_order(orderID): Observable<Order> {
		var Params = new URLSearchParams();
			Params.append('order', orderID);
		var Header = new Headers();
		var Url = `${this.baseUrl}order/get/`;
		var Opts = new RequestOptions({ headers: Header });
		var user = this.http
			.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError);
		return user;
	}
	orders(id=0): Observable<Order> { 
		var Url = `${this.baseUrl}purchases/?id=`+id;
		var h = new Headers();
			h.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: h });
		return this.http.get(Url, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	orderCount(id=0): Observable<number> { 
		var Url = `${this.baseUrl}purchases/?id=`+id;
		var h = new Headers();
			h.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: h });
		return this.http.get(Url, Opts)
			.map(countOrders)
			.catch(handleError)
	}
	seen_order( id ): Observable<Order> {
		var Url = `${this.baseUrl}order/seen/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: H });
		var Params = new URLSearchParams();
			Params.append('id',id);
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	cancel_order( order, n ): Observable<Order> {
		var Url = `${this.baseUrl}order/cancel/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: H });
		var Params = new URLSearchParams();
			Params.append('id',order.id);
			Params.append('nonce',n);
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	cancel_order_payment( order_id? ) {
		var Url = `${this.baseUrl}order/cancel/payment/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',order_id);
		var Opts = new RequestOptions({ headers: H });
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)		
	}
	delivery_issue( order_id? ) {
		var Url = `${this.baseUrl}order/delivery/issue/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',order_id);
		var Opts = new RequestOptions({ headers: H });
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)		
	}
	vendor_order( order_id? ) {
		var Url = `${this.baseUrl}order/vendor/order/issue/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',order_id);
		var Opts = new RequestOptions({ headers: H });
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)		
	}
	report_issue( order_id ) {
		var Url = `${this.baseUrl}order/report/issue/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',order_id);
		var Opts = new RequestOptions({ headers: H });
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)		
	}
	remove_out_of_stock_items_from_order( order, n ): Observable<Order> {
		var Url = `${this.baseUrl}order/remove_out_of_stock/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Params = new URLSearchParams();
			Params.append('id',order.id);
			Params.append('nonce',n);
		var Opts = new RequestOptions({ headers: H });
		return this.http.post(Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	mark_order_as_shipped( order ): Observable<Order> {
		var Url = `${this.baseUrl}orders/shipped/?id=`+order.id;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: H });
		return this.http.get(Url, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	mark_order_as_received( order ): Observable<Order> {
		var Url = `${this.baseUrl}orders/received/?id=`+order.id;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: H });
		return this.http.get(Url, Opts)
			.map(mapOrders)
			.catch(handleError)
	}
	categories(): Observable<Category> {
		var Url = `${this.baseUrl}categories/`;
		var Header = new Headers();
		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.get(Url, Opts)
			.map(mapCategory)
			.catch(handleError);
		return c;
	}
	used_categories(filt?): Observable<Category> {
		var Url = `${this.baseUrl}categories/used/`;
		var Header = new Headers();
		var Params = new URLSearchParams();
			if ( filt.products )Params.append('products', 	filt.products);
			if ( filt.location )Params.append('location', 	filt.location);
			if ( filt.onlysold )Params.append('onlysold', 	filt.onlysold);
			if ( filt.limt ) 	Params.append('limit', 		filt.limt);
			if ( filt.offset ) 	Params.append('offset', 	filt.offset);
			if ( filt.search ) 	Params.append('search', 	filt.search);
			if ( filt.type ) 	Params.append('type', 		filt.type);
			if ( filt.pricing ) Params.append('pricing', 	filt.pricing);
			if ( filt.delivery )Params.append('delivery', 	filt.delivery);
			if ( filt.category )Params.append('category', 	filt.category);
			if ( filt.lang ) 	Params.append('lang', 		filt.lang);
			if ( filt.country ) Params.append('country', 	filt.country);

		var Opts = new RequestOptions({ headers: Header });
		var c = this.http
			.post(Url, Params, Opts)
			.map(mapCategory)
			.catch(handleError);
		return c;
	}
	newProduct(n='New Draft'): Observable<Product> {
		var Url = `${this.baseUrl}products/new/`;
		var Parm = new URLSearchParams();
			Parm.append('name', n);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var New = this.http.post( Url, Parm, Opts)
			.map(mapProducts)
			.catch(handleError)
		return New;
	}
	usersProducts(u): Observable<Product> {
		var Url = `${this.baseUrl}purchased/`;
		var Parm = new URLSearchParams();
			Parm.append('user', u);
		var Head = new Headers();
		var Opts = new RequestOptions({ headers: Head });
		var New = this.http.post( Url, Parm, Opts)
			.map(mapProduct)
			.catch(handleError)
		return New;
	}
	productsPurchsed(u?): Observable<Product> {
		var A = `${this.baseUrl}products/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var O = new RequestOptions({ headers: H });
		var por = this.http
			.post(A, H, O)
			.map(mapProduct)
			.catch(handleError);
		return por;
	}
	productsPurchsedCount(u?): Observable<number> {
		var A = `${this.baseUrl}products/`;
		var H = new Headers();
			H.append('Authorization', this.user.wib());
		var O = new RequestOptions({ headers: H });
		var cou = this.http
			.post(A, H, O)
			.map(countProduct)
			.catch(handleError);
		return cou;
	}
	sellingProducts(drafts?): Observable<Product> {
		var Url = `${this.baseUrl}products/selling/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Cheese = new URLSearchParams();
			if ( drafts ) Cheese.append('exclude', '');
		var Opts = new RequestOptions({ headers: Head });
		var New = this.http.get( Url, Opts )
			.map(mapProducts)
			.catch(handleError)
		return New;
	}
	countSellingProducts(): Observable<number> {
		var Url = `${this.baseUrl}products/selling/`;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var New = this.http.get( Url, Opts )
			.map(countProduct)
			.catch(handleError)
		return New;
	}
	addCategory( n,p ): Observable<Category> {
		var Fries = `${this.baseUrl}categories/new/`;
		var Cheese = new URLSearchParams();
			Cheese.append('name', n);
			Cheese.append('parent', p);
		var Spoon = new Headers();
			Spoon.append('Authorization', this.user.wib());
		var Gravy = new RequestOptions({ headers: Spoon });
		var Poutinerie = this.http.post( Fries, Cheese, Gravy )
			.map(mapCategory)
			.catch(handleError)
		return Poutinerie;
	}
	getPrevProduct(id): Observable<number> {
		var Url = `${this.baseUrl}products/prev/?id=`+id;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var NewProductID = this.http.get( Url, Opts )
			.map(getProductID)
			.catch(handleError)
		return NewProductID;
	}
	getNextProduct(id): Observable<number> {
		var Url = `${this.baseUrl}products/next/?id=`+id;
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var NewProductID = this.http.get( Url, Opts )
			.map(getProductID)
			.catch(handleError)
		return NewProductID;
	}
	store_locations(key?,city?,prov?,country?,range?,lat?,lng?): Observable<Address> {

		var Params = new URLSearchParams();
			if ( key ) Params.append('key', key);
			if ( city ) Params.append('city', city);
			if ( prov ) Params.append('prov', prov);
			if ( country ) Params.append('country', country);
			if ( range ) Params.append('range', range);
			if ( lat ) Params.append('lat', lat);
			if ( lng ) Params.append('lng', lng);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Url = `${this.baseUrl}locations/`;
		var Opts = new RequestOptions({ headers: Head });
		var c = this.http
			.post(Url, Params, Opts)
			.map(mapAddress)
			.catch(handleError);
		return c;
	}
	save( product ): Observable<Product> {
		if ( product.scaled=='scaled' ) product.quantities = [];
		var Params = new URLSearchParams();
			Params.append('id', product.id);
			Params.append('name', product.name);
			Params.append('type', product.type);
			Params.append('scaled', product.scaled);
			Params.append('category', product.category);
			Params.append('currency', product.currency);
			Params.append('price', product.price);
			Params.append('quantities', JSON.stringify(product.quantities));
			Params.append('quantity', product.quantity);
			Params.append('thc', product.thc);
			Params.append('cbd', product.cbd);
			Params.append('total_thc', product.total_thc);
			Params.append('total_cbd', product.total_cbd);
			Params.append('thc_unit', product.thc_unit);
			Params.append('unit', product.unit);
			Params.append('image', product.imageids);
			Params.append('origin', product.origin);
			Params.append('originNote', product.originNote);
			Params.append('strain', product.strain);
			Params.append('pickup', product.pickup);
			Params.append('ship', product.ship);
			Params.append('ship_to', product.ship_to);
			Params.append('html', product.html);
			Params.append('offers', product.offers);
			Params.append('locales', product.locales);
			Params.append('description', product.description);
			Params.append('preview', product.preview);
		var Url = `${this.baseUrl}products/save/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapProduct)
			.catch(handleError);
		return create;
	}
	publish( product ): Observable<Product> {
		if ( product.scaled=='scaled' ) product.quantities = [];
		var Params = new URLSearchParams();
			Params.append('id', product.id);
			Params.append('name', product.name);
			Params.append('type', product.type);
			Params.append('scaled', product.scaled);
			Params.append('category', product.category);
			Params.append('currency', product.currency);
			Params.append('price', product.price);
			Params.append('quantities', JSON.stringify(product.quantities));
			Params.append('quantity', product.quantity);
			Params.append('thc', product.thc);
			Params.append('cbd', product.cbd);
			Params.append('total_thc', product.total_thc);
			Params.append('total_cbd', product.total_cbd);
			Params.append('thc_unit', product.thc_unit);
			Params.append('unit', product.unit);
			Params.append('image', product.imageids);
			Params.append('origin', product.origin);
			Params.append('strain', product.strain);
			Params.append('pickup', product.pickup);
			Params.append('ship', product.ship);
			Params.append('ship_to', product.ship_to);
			Params.append('html', product.html);
			Params.append('offers', product.offers);
			Params.append('locales', product.locales);
			Params.append('description', product.description);
			Params.append('publish', 'true');
		var Url = `${this.baseUrl}products/publish/`
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapProductz)
			.catch(handleError);
		return create;
	}
	unpublish( product ): Observable<Product> {
		var Url = `${this.baseUrl}products/publish/`
		var Params = new URLSearchParams();
			Params.append('id', product.id);
			Params.append('unpublish', 'true');
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapProductz)
			.catch(handleError);
		return create;
	}
	getProductImages(id): Observable<Image> {
		var Params = new URLSearchParams();
			Params.append('productID', id);
		var Url = `${this.baseUrl}product/images/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapImage)
			.catch(handleError);
		return create;
	}
	getProductImageIds(id) {
		var Params = new URLSearchParams();
			Params.append('productID', id);
			Params.append('output', 'id');
		var Url = `${this.baseUrl}product/images/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapImageIDs)
			.catch(handleError);
		return create;
	}
	getPurchasedProducts( id=null ) {
		var Params = new URLSearchParams();
			Params.append('accountID', id);
		var Url = `${this.baseUrl}products/purchased/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var create = this.http
			.post(Url, Params, Opts)
			.map(mapProducts)
			.catch(handleError);
		return create;
	}
	uploadImage( fil: File, id ): Observable<Image> {
		var Url = `${this.baseUrl}upload/productimage/`;
		var Files = new FormData();
			Files.append('uploads', fil);
			Files.append('productID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		var a = this.http.post(Url, Files, Opts)
			.map(mapImage)
			.catch(handleError);
		return a;
	}
	uploadIdentityImage( fil: File, type = 'identity' ): Observable<Image> {
		var Url = `${this.baseUrl}upload/identityimage/`;
		var Files = new FormData();
			Files.append('type', type);
			Files.append('uploads', fil);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		return this.http
				.post(Url, Files, Opts)
				.map(mapImage)
				.catch(handleError);
	}
	uploadDriverImage( fil: File, id, type? ): Observable<Image> {
		var Url = `${this.baseUrl}upload/driverimage/`;
		var Files = new FormData();
			Files.append('uploads', fil);
			Files.append('driverId', id);
			if (type) Files.append('type', type);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		return this.http
				.post(Url, Files, Opts)
				.map(mapImage)
				.catch(handleError);
	}
	uploadBackground( fil: File, id ): Observable<Image> {
		var Url = `${this.baseUrl}upload/backgroundimage/`;
		var Files = new FormData();
			Files.append('uploads', fil);
			Files.append('accountID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		var a = this.http.post(Url, Files, Opts)
			.map(mapImage)
			.catch(handleError);
		return a;
	}
	uploadLogo( fil: File, id ): Observable<Image> {
		var Url = `${this.baseUrl}upload/logoimage/`;
		var Files = new FormData();
			Files.append('uploads', fil);
			Files.append('accountID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		var a = this.http.post(Url, Files, Opts)
			.map(mapImage)
			.catch(handleError);
		return a;
	}
	removeImage(id): Observable<boolean> {
		var Url = `${this.baseUrl}remove/image/`;
		var Files = new FormData();
			Files.append('imageId', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		return this.http.post(Url, Files, Opts)
			.map(removedImage)
			.catch(handleError);
	}
	uploadAvatar( fil: File, id ): Observable<Image> {
		var Url = `${this.baseUrl}profileimage/`;
		var Files = new FormData();
			Files.append('uploads', fil);
			Files.append('productID', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		var eh = this.http.post(Url, Files, Opts)
			.map(mapImage)
			.catch(handleError);
		return eh;
	}
	removeAvatar(): Observable<boolean> {
		var Url = `${this.baseUrl}user/removeimage/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = { headers: Header, reportProgress: true, observe: 'events' };
		return this.http.get(Url, Opts)
			.map(removedImage)
			.catch(handleError);
	}
	getScreenFormat() {
		var a = this.cookie.get('tsf');
		return (a)?a:'false';
	}
	setScreenFormat(b) {
		this.cookie.set('tsf', b);
	}
	setLoading(l) {
		return this.cookie.set('loader', l);
	}
	getLoading(f) {
		f(this.cookie.get('loader'));
	}
	updateImageTitle(id, title) {
		var Touch = `${this.baseUrl}update/image/`;

		var Doll = new FormData();
			Doll.append('imageId', id);
			Doll.append('title', title);
		var Man = new Headers();
			Man.append('Authorization', this.user.wib());
		var King = { headers: Man, reportProgress: true, observe: 'events' };
		var all = this.http.post(Touch, Doll, King).catch(handleError);
		return all;
	}
	rotateImage(imj, degri='90', product='0') {
		var g = `${this.baseUrl}rotate/image/`;
		var gin = new FormData();
			gin.append('id', imj.id);
			gin.append('degrees', degri);
			gin.append('productId', product);
		var gyn = new Headers();
			gyn.append('Authorization', this.user.wib());
		var gil = { headers: gyn, reportProgress: true, observe: 'events' };
		var come = this.http.post(g, gin, gil).catch(handleError);
		return come;
	}
	editImage(image, contrast?, saturation?, brightness?) {
		var Pipes = `${this.baseUrl}edit/image/`;
		var Foes = new FormData();
			if ( contrast ) Foes.append('contrast',  contrast);
			if ( saturation ) Foes.append('saturation',saturation);
			if ( brightness ) Foes.append('brightness',brightness);
		var Man = new Headers();
			Man.append('Authorization', this.user.wib());
		var King = { headers: Man, reportProgress: true, observe: 'events' };
		var done = this.http.post(Pipes, Foes, King).catch(handleError);
		return done;
	}
	cropImage(imageId, top, right, bottom, left, height, width, product='0') {
		var Pipes = `${this.baseUrl}product/crop/`;
		var Foto = new FormData();
			Foto.append('top', top);
			Foto.append('right', right);
			Foto.append('bottom', bottom);
			Foto.append('left', left);
			Foto.append('width', height);
			Foto.append('height', width);
			Foto.append('image', imageId);
			Foto.append('product', product);
		var Fing = new Headers();
			Fing.append('Authorization', this.user.wib());
		var King = { headers: Fing, reportProgress: true, observe: 'events' };
		var asss = this.http.post(Pipes, Foto, King).catch(handleError);
		return asss;
	}
	orderImages(productID, imgIDs): Observable<Image> {
		var Apple = `${this.baseUrl}product/order/images/`;
		var Keys = new FormData();
			Keys.append('productID', productID);
			Keys.append('images', imgIDs);
		var Chest = new Headers();
			Chest.append('Authorization', this.user.wib());
		var Gold = { headers: Chest, reportProgress: true, observe: 'events' };
		var Treasure = this.http.post(Apple, Keys, Gold).map(mapImage).catch(handleError);
		return Treasure;
	}
	/**
	*   Woo Hoo!  Let's Create a new Order!
	**/
	purchase(s,p,a,c,e,d,i,l,g,o,t): Observable<Order> {
		var Pupil = `${this.baseUrl}purchase/`;
		var Facet = new URLSearchParams();
			Facet.append('shipping_id',					s);
			Facet.append('products', 					p);
			Facet.append('total', 						a);
			Facet.append('coupon', 						c);
			Facet.append('payment', 					e);
			Facet.append('delivery', 					d);
			Facet.append('pickup', 						i);
			Facet.append('shipping', 					l);
			Facet.append('credits', 					g);
			Facet.append('currency', 					o);
			Facet.append('email', 						t);
		var Iris = new Headers();
			Iris.append('Authorization', this.user.wib() );
		var Lash = new RequestOptions({ headers: Iris });
		var Blink = this.http.post( Pupil, Facet, Lash )
						 .map(mapOrder)
						 .catch(handleError)
		return Blink;
	}
	/**
	*   A new PayPal Order on the double!
	**/
	PayPalPurchase(s,p,a,c,e,d,g,o,t,j,l,i,h,y): Observable<Order> {
		var Retina = `${this.baseUrl}paypal/purchase/`;
		var Lens = new URLSearchParams();
			Lens.append('shipping_id',					s);
			Lens.append('products', 					p);
			Lens.append('total', 						a);
			Lens.append('coupon', 						c);
			Lens.append('payment', 						e);
			Lens.append('delivery', 					d);
			Lens.append('credits', 						g);
			Lens.append('currency', 					o);
			Lens.append('token', 						t);
			Lens.append('pickup', 						j);
			Lens.append('shipping', 					l);
			Lens.append('orderID', 						i);
			Lens.append('payerID', 						h);
			Lens.append('paymentID', 					y);
		var Eyes = new Headers();
			Eyes.append('Authorization', this.user.wib() );
		var Optics = new RequestOptions({ headers: Eyes });
		var Focus = this.http.post( Retina, Lens, Optics )
						 .map(mapOrder)
						 .catch(handleError)
		return Focus;
	}
	/**
	* Create an Order
	**/
	newOrder(cart): Observable<Order> {

		var Al = `${this.baseUrl}neworder/`
		var Params = new URLSearchParams();
			Params.append('cart', cart);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var order = this.http.post( Al, Params, Opts)
			.map(mapOrders)
			.catch(handleError)

		return order;

	}
	/**
	* Tip an Order
	**/
	tip(tip): Observable<Tip> {
		var Al = `${this.baseUrl}tip/`
		var Params = new URLSearchParams();
			Params.append('id', tip.id);
			Params.append('amount', tip.amount);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var tipp = this.http.post( Al, Params, Opts)
			.map(mapTips)
			.catch(handleError)
		return tipp;

	}
    /**
    * Create an Order
    **/
    cancel_payment(tx): Observable<Transaction> {
        var Al = `${this.baseUrl}order/cancel/payment/`
        var Params = new URLSearchParams();
            Params.append('id', tx);
        var Head = new Headers();
            Head.append('Authorization', this.user.wib());
        var Opts = new RequestOptions({ headers: Head });
        var txx = this.http.post( Al, Params, Opts)
            .map(mapTransactions)
            .catch(handleError)
        return txx;
    }

    get_blockchain_transaction(coin,tx):  Observable<BlockchainTransaction> {
        var Al = `${this.baseUrl}blockchain/transaction/`
        var Params = new URLSearchParams();
            if (coin) Params.append('coin', coin);
            Params.append('tx', tx);
        var Head = new Headers();
            Head.append('Authorization', this.user.wib());
        var Opts = new RequestOptions({ headers: Head });
        var txx = this.http.post( Al, Params, Opts)
            .map(mapBlockchainTransaction)
            .catch(handleError)
        return txx;
    }

	/**
	* Save a credit card
	**/
	saveCard(card): Observable<Card> {
		var Al = `${this.baseUrl}card/add/`
		var Params = new URLSearchParams();
			Params.append('_n', card.nonce);
			Params.append('token', card.id);
			Params.append('id', card.card.id);
			Params.append('name', card.card.name);
			Params.append('country', card.card.country);
			Params.append('country_code', card.card.country_code);
			Params.append('number', this.cc(card.card.number));
			Params.append('cvv', this.cc(card.card.cvv));
			Params.append('cus', card.card.customer);
			Params.append('last4', card.card.last4);
			Params.append('exp_month', card.card.exp_month);
			Params.append('exp_year', card.card.exp_year);
			Params.append('address_zip', card.card.address_zip);
			Params.append('brand', card.card.brand);
			Params.append('stripeid', card.card.stripeid);
			Params.append('ip', card.client_ip);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var car = this.http.post( Al, Params, Opts)
			.map(mapCard)
			.catch(handleError)
		return car;
	}
	addCard(card): Observable<Card> {
		var Al = `${this.baseUrl}c/a/p/`;
		var Params = new URLSearchParams();
			Params.append('_n', card.nonce);
			Params.append('id', card.id);
			Params.append('n', this.cc(card.number));
			Params.append('c', this.cc(card.ccv));
			Params.append('l', this.cc(card.last4));
			Params.append('f', this.cc(card.fullname));
			Params.append('m', this.cc(card.exp_month));
			Params.append('y', this.cc(card.exp_year));
			if(card.token) Params.append('t', this.cc(card.token));
			if(card.stripeid) Params.append('j', this.cc(card.stripeid));
			Params.append('brand', card.brand);
			if (card.country && card.country!='undefined') {
				Params.append('o', this.cc(card.country));
			}
			if (card.country_code && card.country_code!='undefined') {
				Params.append('u', this.cc(card.country_code));
			}
			Params.append('v', this.cc(card.verification));
			Params.append('p', this.cc(card.postal));
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var car = this.http.post( Al, Params, Opts)
			.map(mapCard)
			.catch(handleError)
		return car;
	}
	/**
	* Payments 
	**/
	paymentAddress(coin='cann'): Observable<Coin> {
		var Url = `${this.baseUrl+coin}/new/`;
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var newProduct = this.http.post( Url, Params, Opts)
			.map(mapCoinAddress)
			.catch(handleError)
		return newProduct;
	}
	getSocalMediaPosts(): Observable<Post> {
		var Url = `${this.baseUrl}scoreboard/`;
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var dc = this.http.post( Url, Params, Opts)
			.map(mapScore)
			.catch(handleError)
		return dc;
	}
	getOrders(id=0,s?): Observable<Order> {
		var Url = `${this.baseUrl}orders/?id=`+id+`&s=`+s;
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var dc = this.http.post( Url, Params, Opts)
			.map(mapOrders)
			.catch(handleError)
		return dc;
	}
	countSocalMediaPosts(): Observable<any> {
		var Url = `${this.baseUrl}scoreboard/`;
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var dv = this.http.post( Url, Params, Opts)
			.map(countScore)
			.catch(handleError)
		return dv;
	}
	favorite(id = '0'): Observable<boolean> { 
	
		var Fab = `${this.baseUrl}favorites/add/`

		var Params = new URLSearchParams();
			Params.append('id', id);

		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });

		var fav = this.http.post( Fab, Params, Opts)
			.map(addFavorite)
			.catch(handleError)

		return fav;

	}
	save_page_design(a): Observable<boolean> { 
		var Fam = `${this.baseUrl}design/save/`
		var Mams = new URLSearchParams();
			Mams.append('top',a.top);
			Mams.append('full',a.full);
			Mams.append('pos',a.pos);
			Mams.append('rep',a.rep);
		var Papa = new Headers();
			Papa.append('Authorization', this.user.wib());
		var Paper = new RequestOptions({ headers: Papa });
		var fav = this.http.post( Fam, Mams, Paper)
			.map(addDesign)
			.catch(handleError)
		return fav;
	}
	feature(id = '0'): Observable<boolean> { 
	
		var Fam = `${this.baseUrl}feature/add/`
		var Mams = new URLSearchParams();
			Mams.append('id', id);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Paper = new RequestOptions({ headers: Head });
		var fav = this.http.post( Fam, Mams, Paper)
			.map(addFeature)
			.catch(handleError)

		return fav;

	}
	homepagefeature(id = '0'): Observable<boolean> { 
	
		var Fam = `${this.baseUrl}feature/add/homepage/`
		var Mams = new URLSearchParams();
			Mams.append('id', id);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Paper = new RequestOptions({ headers: Head });
		var fav = this.http.post( Fam, Mams, Paper)
			.map(addFeature)
			.catch(handleError)

		return fav;

	}
	earnings(): Observable<Earning> {
		var Eve = `${this.baseUrl}earnings/`
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Ad = this.http.post( Eve, Params, Opts)
			.map(mapEarning)
			.catch(handleError)
		return Ad;
	}
	/**
	* Shipping Options
	*/
	shippingOptions(origin): Observable<ShippingOption> {
		var La = `${this.baseUrl}shipping/providers/`
		var Li = new URLSearchParams();
			Li.append('origin',origin);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Lo = new RequestOptions({ headers: Head });
		var newPaymentMethods = this.http.post( La, Li, Lo )
			.map(mapShippingOption)
			.catch(handleError)
		return newPaymentMethods;
	}
	verification(verificationImage,card): Observable<Verification> {
		var La = `${this.baseUrl}verification/`
		var Li = new URLSearchParams();
			Li.append('_v',verificationImage);
			Li.append('_c',card);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Lo = new RequestOptions({ headers: Head });
		var verification = this.http.post( La, Li, Lo )
			.map(mapVerification)
			.catch(handleError)
		return verification;
	}
    /**
    * Add Interac Email
    **/
    depositInterac( stats ): Observable<Order> {
    
        var Path = `${this.baseUrl}interac/deposit/`;

        // Account deposit
        var Params = new URLSearchParams();
            Params.append('amount', stats.amount);
            Params.append('payment',  stats.id);
            Params.append('currency', stats.currency);
            Params.append('total', stats.amount);
    
        var Head = new Headers();
            Head.append('Authorization', this.user.wib());
        var Opts = new RequestOptions({ headers: Head });
    
        var newOrder = this.http
            .post(Path, Params, Opts)
            .map(mapOrder)
            .catch(handleError);
    
        return newOrder;
    }
    /**
    * Add PayPal Email
    **/
	depositPayPal( paypal ): Observable<Order> {
		var Fight = new URLSearchParams();
			Fight.append('amount', paypal.amount);
			Fight.append('payment',  'p'+paypal.id);
			Fight.append('currency', paypal.currency);
			Fight.append('total', paypal.amount);
			Fight.append('account_id', paypal.account_id);
			Fight.append('email', paypal.email);
			Fight.append('token', paypal.token);
			Fight.append('orderID', paypal.orderID);
			Fight.append('payerID', paypal.payerID);
			Fight.append('paymentID', paypal.paymentID);
		var Tustle = `${this.baseUrl}paypal/deposit/`;
		var Bro = new Headers();
			Bro.append('Authorization', this.user.wib());
		var Folkes = new RequestOptions({ headers: Bro });
		var Joked = this.http
			.post(Tustle, Fight, Folkes)
			.map(mapOrder)
			.catch(handleError);
		return Joked;
	}
	/**
	* Payment Options
	*/
	paymentOptions(): Observable<PaymentMethod> {
		var Al = `${this.baseUrl}payment_opts/`
		var Params = new URLSearchParams();
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var newPaymentMethods = this.http.post( Al, Params, Opts )
			.map(mapPaymentMethod)
			.catch(handleError)
		return newPaymentMethods;
	}
	paymentMethods() {
		var Url = `${this.baseUrl}methods/`;
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var products = this.http
			.get(Url, Opts)
			.map(mapPaymentMethod)
			.catch(handleError);
		return products;
	}
	removePayment(id): Observable<PaymentMethod> {
		var Url = `${this.baseUrl}remove_method/`;
		var Params = new URLSearchParams();
			Params.append('id', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var products = this.http
			.post(Url, Params, Opts)
			.map(mapPaymentMethod)
			.catch(handleError);
		return products;
	}
	removeAddress(id): Observable<PaymentMethod> {
		var Url = `${this.baseUrl}remove_address/`;
		var Params = new URLSearchParams();
			Params.append('id', id);
		var Header = new Headers();
			Header.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Header });
		var products = this.http
			.post(Url, Params, Opts)
			.map(mapPaymentMethod)
			.catch(handleError);
		return products;
	}
	getfavorite(): Observable<Product> {
		var c = this.parseCart();
		var Fab = `${this.baseUrl}favorites/?cart=`+c
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var Fav = this.http.get( Fab, Opts )
			.map(mapProducts)
			.catch(handleError)
		return Fav;
	}
	countFavorite(): Observable<number> {
		var Fab = `${this.baseUrl}favorites/count/`
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var FavC = this.http.get( Fab, Opts )
			.map(countProduct)
			.catch(handleError)
		return FavC;
	}
	locationAllowed(country?, prov?, city?): Observable<boolean> {
		var Fab = `${this.baseUrl}allow/`
		var Params = new URLSearchParams();
			Params.append('country', country);
			Params.append('prov', prov);
			Params.append('city', city);
		var Head = new Headers();
			Head.append('Authorization', this.user.wib());
		var Opts = new RequestOptions({ headers: Head });
		var A = this.http.post( Fab, Params, Opts )
			.map(allowTransactions)
			.catch(handleError)
		return A;
	}


	isfavorite(id=0) {
		return false;
	}
	getShopInfo(): Observable<ShopInfo> {
		var Url = `${this.baseUrl}storeinfo/`;
		var i = this.http.get( Url )
			.map(mapShopInfo)
			.catch(handleError)
		return i;
	}
	allowVending(user?, locale?) {
		return true
	}
	mapOrders(response:Response) {
		mapOrders(response);
	}

	cc(r) { 
		var t = Date.now(); t = Math.round(t/100000000);
		// r = r.split("").reverse().join("");
		var a = btoa(this.rc(t+176)+r+this.rc(238));
		return a; 
	}

	rc(l) {
		var re='',
		ch = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
		cl = ch.length;
		for ( var i = 0; i < l; i++ ) {
			re += ch.charAt(Math.floor(Math.random() * cl));
		} 
		return re;
	}

}
function mapProvence(response: Response):Provence {
	return response.json().provinces.map(toProvence);	
}
function mapCities(response: Response):City {
	return response.json().cities.map(toCity);	
}
function mapStrain(response:Response): Strain {
	return response.json().strains.map(toStrain);
}
function mapTax(response:Response) {
	return response.json().taxes;
}
function addStrain(response:Response): Strain {
	var strain = response.json().strains;
		strain.push(this.rememberedStrains);
	var resp = strain.map(toStrain);
	return resp;
}
function countStrains(response:Response): Strain {
	var res = response.json().count;
	return res;
}
function countTotal(response:Response): number {
	var res = response.json().total;
	return res;
}
function countWeight(response:Response): number {
	var res = response.json().weight;
	return res;
}
function mapScore(response:Response): Post {
	return response.json().posts.map(toPost);
}
function mapCard(response:Response): Card {
	return response.json().cards.map(toCard);
}
function countScore(response:Response): number {
	var res = response.json().count;
	return res;
}
function toProvence(r:any): Provence {
	var p = <Provence>({	
		id: r.id,
		city: r.city,
		code: r.code,
		country: r.country,
		provence: r.provence
	})
	return p;
}
function mapInfo(response:Response): Info {
	var res = response.json().info.map(toInfo);
	return res;
}
function toInfo(r:any): Info {
	var li = <Info>({
		total: r.total,
		subtotal: r.subtotal,
		weight: r.weight,
		taxes: r.taxes,
		bonus: r.bonus,
		delivery: r.delivery,
		delivery_available: r.delivery_available,
		dropship: r.dropship,
		shipping: r.shipping,
		discount: r.discount,
		currency: r.currency,
		owing: r.owing,
		length: r.length,
		count: r.count
	})
	return li;
}
function mapShopInfo(response:Response): ShopInfo {
	var res = response.json().fees.map(toShopInfo);
	return res;
}
function allowTransactions(response:Response): boolean {
	var res = response.json().allow;
	return res;
}
function toShopInfo(r:any): ShopInfo {
	var i = <ShopInfo>({
		SHOP_OPEN: r.SHOP_OPEN,
		SHOP_MAINTENANCE_MSG: r.SHOP_MAINTENANCE_MSG,
		BASE_FEE: r.BASE_FEE,
		TAX_PER_GRAM: r.TAX_PER_GRAM,
		REWARD_COIN: r.REWARD_COIN,
		REFERRAL_BONUS_COIN: r.REFERRAL_BONUS_COIN,
		GRAM_REWARD_COIN: r.GRAM_REWARD_COIN,
		DOLLAR_REWARD_COIN: r.DOLLAR_REWARD_COIN,
	})
	return i;
}
function toCard(r:any): Card {
	var li = <Card>({
		id: r.id,
		account_id: r.account_id,
		address_id: r.address_id,
		brand: r.brand,
		number: r.number,
		cardholder: r.cardholder,
		token: r.token,
		billing_address: r.billing_address,
		ccv: r.ccv,
		last_four: r.last_four,
		year: r.year,
		exp_year: r.year,
		month: r.month,
		exp_month: r.month,
		country: r.country,
		postal_code: r.postal_code,
		total_amount: r.total_amount,
		country_code: r.country_code,
		last_used: r.last_used,
		status: r.status,
		created_ip: r.created_ip,
		message: r.message,
		code: r.code,
		created: r.created,
		modified: r.modified
	})
	return li;
}
function mapCoins(response:Response): Coin {
	var res = response.json().coins.map(toCoin);
	return res;
}
function mapCoupon(response:Response): Coupon {
	var res = response.json().coupon.map(toCoupon);
	return res;
}
function toCoupon(r:any): Coupon {
	var li = <Coupon>({
		id: r.id,
		code: r.code,
		value: r.value,
		percentage: r.percentage,
		bind_to_account: r.bind_to_account,
		active: r.active,
		times_used: r.times_used,
		start_date: r.start_date,
		end_date: r.end_date,
		created: r.created,
		modified: r.modified,
	})
	return li;
}
function mapCheques(response:Response): Cheque {
	var res = response.json().cheques.map(toCheque);
	return res;
}
function toCheque(r:any): Cheque {
	var chq = <Cheque>({
		account_id: r.account_id,
		address_id: r.address_id,
		amount: r.amount,
		status: r.status,
		name: r.name,
		address: r.address,
		currency: r.currency,
		cheque_number: r.cheque_number,
		mailed: r.mailed,
		applied: r.applied,
		posted: r.posted,
		modified: r.modified,
		created: r.created
	})
	return chq;
}
function toCity(r:any): City {
	var p = <City>({	
		id: r.id,
		city: r.city,
		code: r.code,
		country: r.country,
		provence: r.provence
	})
	return p;
}
function countProvences(response:Response): number {
	var res = response.json().count;
	return res;
}
function toPost(r:any): Post {
	var bt = <Post>({
		url: r.url,
		author: r.author,
		image: r.image,
		description: r.description,
		service: r.service,
		date: r.date,
		published: r.published,
		rating: r.rating,
		likes: r.likes,
		shares: r.shares,
		created: r.created,
		modified: r.modified
	})
	return bt;
}
function toStrain(r:any): Strain {
	var dk = <Strain>({
		id: r.id,
		account_id: r.account_id,
		value: r.value,
		color: r.color,
		parent: r.parent,
		name: r.name,
		label: r.label,
		symbol: r.symbol,
		active: r.active,
		type: r.type,
		tags: r.tags,
		products: r.products,
		description: r.description,
		created: r.created,
		modified: r.modified
	});
	return dk;
}
function removedImage(response:Response) {
	var rm = response.json().removed;
	return rm;
}
function mapImages(response:Response): Image {
	var mp = response.json().images.map(toImage);
	return mp;
}
function mapImage(response:Response): Image {
	var im = response.json().image.map(toImage);
	return im;
}
function mapImageIDs(response:Response) {
	var is = response.json().imageIDs;
	return is;
}
function mapFeatProd(response:Response): boolean {
	var is = response.json().feat;
	return is;
}
function toImage(r:any): Image {
	var img = <Image>({
		alt:r.alt,
		base:r.base,
		created:r.created,
		description:r.description,
		error:r.error,
		height:r.height,
		id:r.id,
		params:r.params,
		product_id:r.product_id,
		size:r.size,
		tags:r.tags,
		title:r.title,
		updated_by:r.updated_by,
		url:r.url,
		width:r.width
	});
	return img;
}
function mapItem(response:Response): Item {
	var res = response.json().item.map(toItem);
	return res;
}
function mapProduce(response:Response): Product {
	var res = response.json().item.map(toProduct);
	return res;
}
function countProduct(response:Response) {
	var res = response.json().length;
	return res;
}
function mapProduct(response:Response): Product {
	var res = response.json().items.map(toProduct);
	return res;
}
function mapProductz(response:Response): Product {
	var res = response.json().product.map(toProduct);
	return res;
}
function mapProducts(response:Response): Product {
	var res = response.json().products.map(toProduct);
	return res;
}
function toItem(r:any): Item {
	var item = <Item>({
		id: r.id,
		seller_id: r.seller_id,
		seller: r.seller,
		title: r.title,
		slug: r.slug,
		icon: r.icon,
		description: r.description,
		html: r.html,
		richhtml: r.richhtml,
		scaled: r.scaled,
		unit: r.unit,
		weight: r.weight,
		limit: r.limit,
		currency: r.currency,
		images: r.images,
		imagecount: r.imagecount,
		imagesizes: r.imagesizes,
		price: r.price,
		locations: r.locations,
		discount: r.discount,
		category: r.category,
		shipping: r.shipping,
		group: r.group,
		group_info: r.group_info,
		types: r.types,
		stock: r.stock,
		delivery: r.delivery,
		fee_price: r.fee_price,
		in_stock: r.in_stock,
		published: r.published,
		sold: r.sold,
		created: r.created,
		modified: r.modified,
		shipping_provider: r.shipping_provider,
		type: r.type,
		quantity: r.quantity,
	});
	return item;
}
function toProduct(r:any): Product {
	var product = <Product>({
		id: r.id,
		ref: r.ref,
		username: r.username,
		account: r.account,
		accountid: r.accountid,
		category: r.category,
		categoryName: r.categoryName,
		count: r.count,
		currency: r.currency,
		enabled_currencies: r.enabled_currencies,
		description: r.description,
		est_delivery: r.est_delivery,
		name: r.name,
		html: r.html,
		points: r.points,
		price: r.price,
		subscribable: r.subscribable,
		favorite: r.favorite,
		type: r.type,
		items: r.items,
		offers: r.offers,
		discount: r.discount,
		includes: r.includes,
		reviews: r.reviews,
		reviewCount: r.reviewCount,
		icon: r.icon,
		published: r.published,
		pickup: r.pickup,
		weights: r.weights,
		unit: r.unit,
		limit: r.limit,
		lang: r.lang,
		thc: r.thc,
		cbd: r.cbd,
		total_thc: r.total_thc,
		total_cbd: r.total_cbd,
		thc_unit: r.thc_unit,
		scaled: r.scaled,
		ship_to: r.ship_to,
		ship_locally: r.ship_locally,
		shippable: r.shippable,
		quantity: r.quantity,
		quantities: r.quantities,
		quantitiesCount: r.quantitiesCount,
		sold: r.sold,
		origin: r.origin,
		origin_lat: r.origin_lat,
		origin_lng: r.origin_lng,
		origin_note: r.origin_note,
		origin_city: r.origin_city,
		origin_provence: r.origin_provence,
		origin_country: r.origin_country,
		country: r.country,
		countrycode: r.countrycode,
		originalprice: r.originalprice,
		image: r.image,
		image_thumbnail: r.image_thumbnail,
		image_product: r.image_product,
		imagecount: r.imagecount,
		images: r.images,
		imagesizes: r.imagesizes,
		slug: r.slug,
		editable: r.editable,
		in_stock: r.in_stock,
		in_cart: r.in_cart,
		shopify_id: r.shopify_id,
		shopify_vendor: r.shopify_vendor,
		subscription_products: r.subscription_products,
		subscription_enddate: r.subscription_enddate,
		subscriptions: r.subscriptions,
		shipping_provider: r.shipping_provider,
		tax_exempt: r.tax_exempt,
		tax_exempt_items: r.tax_exempt_items,
		shipping_exempt: r.shipping_exempt,
		shipping_exempt_items: r.shipping_exempt_items,
		strain: r.strain,
		shipping: r.shipping,
		delivery: r.delivery,
		static_shipping_price: r.static_shipping_price,
		multiple_shipping_price: r.multiple_shipping_price,
		taxes: r.taxes,
		order: r.order,
		stock: r.stock,
		fee: r.fee,
		featured: r.featured,
		homepagefeatured: r.homepagefeatured,
		est_shipping_time_in_days: r.est_shipping_time_in_days,
		est_handling_time_in_days: r.est_handling_time_in_days,
		vendor: r.vendor,
		units_in_cart: r.units_in_cart,
		on_sale: r.on_sale,
		url: r.url,
		cart_url: r.cart_url,
		rating: r.rating,
		created: r.created,
		updated: r.updated,
		modified: r.modified,
	});
	return product;
}
function mapAddress(response:Response): Address {
	var rest = response.json().map(toAddress);
	return rest;
}
function mapBrands(response:Response): Brand {
	var rest = response.json().brands.map(toBrand);
	return rest;
}
function toBrand(r:any): Brand{
	var brand = <Brand>({
		vendor: r.vendor
	});
	return brand;
}
function mapAddresses(response:Response): Address {
	var rest = response.json().addresses.map(toAddress);
	return rest;
}
function toAddress(r:any): Address{
	var address = <Address>({
		country: r.country,
		code: r.code,
		postal: r.postal,
		provence: r.provence
	});
	return address;
}
function mapLocation(response:Response): Locale {
	var look = response.json().locate.map(toLocation);
	return look;
}
function toLocation(r:any): Locale {
	var law = <Locale>({
		id: r.id,
		city: r.city,
		country: r.country,
		lng: r.lng,
		lat: r.lat,
		iso: r.iso,
		key: r.key,
		location: r.location,
		msg: r.msg,
		subdivision: r.subdivision,
		valid: r.valid
	});
	return law;
}
function mapKeyword(response: Response): Keyword {
	return response.json().keywords.map(toKeyword);
}
function toKeyword(r:any): Keyword {
	var key = <Keyword>({
		id: r.id,
		word: r.word,
	});
	return key;	
}
function mapCategory(response:Response): Category {
	return response.json().categories.map(toCategory);
}
function toCategory(r:any): Category {
	var cat = <Category>({
		id: r.id,
		name: r.name,
		icon: r.icon,
		label: r.label,
		value: r.value,
		parent: r.parent,
		children: r.children,
		parentname: r.parentname,
		description: r.description,
		color: r.color
	});
	return cat;
}
function mapCart(response:Response): Cart {
	var sleep = response.json().cart.map(toCart);
	return sleep;
}
function toCart(r:any): Cart {
	var lo = <Cart>({
		products: r.products,
		total: r.total
	});
	return lo;
}
function mapCountry(response:Response): Country {
	var eat = response.json().countries.map(toCountry);
	return eat;
}
function countCountry(response:Response): number {
	var eat = response.json().count;
	return eat;
}
function getProductID(response:Response): number {
	var eat = response.json().id;
	return eat;
}
function toCountry(r:any): Country {
	var c = <Country>({
		id: r.id,
		code: r.code,
		count: r.count,
		country: r.country,
		subdivisions: r.subdivisions
	});
	return c;
}
function mapCoinAddress(response:Response): Coin {
	var play = response.json().address.map(toCoin);
	return play;
}
function toCoin(r:any): Coin {
	var c = <Coin>({
		id:r.id,
		count:r.count,
		accountid:r.accountid,
		symbol:r.symbol,
		address:r.address,
		tx_ids:r.tx_ids,
		status:r.status,
		private_key: r.private_key,
		confirmations:r.confirmations,
		total_amount:r.total_amount,
		total_ins:r.total_ins,
		total_outs:r.total_outs,
		created_on:r.created_on,
		updated_on:r.updated_on
	});
	return c;
}
function mapCurrency(response:Response): Currency {
	var rest = response.json().currencies.map(toCurrency);
	return rest;
}
function countCurrency(response:Response): Currency {
	var eat = response.json().count;
	return eat;
}
function saveCurrency(response:Response): Currency {
	var earn = response.json().currency_set;
	return earn;
}
function toCurrency(r:any): Currency {
	var currency = <Currency>({
		id: r.id,
		name: r.name,
		value: r.value,
		symbol: r.symbol,
		enabled: r.enabled,
		count: r.count,
		default: r.default,
		updated: r.updated
	});
	return currency;
}
function mapMailOptions(response: Response): Postage {
	var rest = response.json().postage.map(toPostageMethod);
	return rest;
}
function toPostageMethod(r:any): Postage {
	var post = <Postage>({

	})
	return post;
}
function mapEarning(response: Response): Earning {
	var earn = response.json().earnings.map(toEarning);
	return earn;
}
function toEarning(r:any): Earning {
	var fur = <Earning>({
		id: r.id,
		x: r.x,
		y: r.y
	});
	return fur;
}
function mapPaymentMethod(response: Response): PaymentMethod {
	var rest = response.json().methods.map(toPaymentMethod);
	return rest;
}
function toPaymentMethod(r:any): PaymentMethod {
	var method = <PaymentMethod>({
		id: r.id,
		name: r.name,
		service: r.service,
		public_key: r.public_key,
		supported_currencies: r.supported_currencies,
		product_image: r.product_image,
		endpoint: r.endpoint,
		enabled: r.enabled
	});
	return method;
}
function mapShippingOption(response: Response): ShippingOption {
	var rest = response.json().methods.map(toShippingOption);
	return rest;
}
function toShippingOption(r:any): ShippingOption {
	var thatsanoption = <ShippingOption>({
		id: r.id,
		provider: r.provider,
		name: r.name,
		price: r.price,
		delivery_date: r.delivery_date,
		image: r.image,
		created: r.created,
		modified: r.modified,
		service: r.service,
		enabled: r.enabled,
		options: r.options,
		country: r.country,
		orders: r.orders,
		cost: r.cost,
		count: r.count,
		currency: r.currency
	});
	return thatsanoption;
}
function mapVerification(response: Response): Verification {
	var rest = response.json().verification.map(toVerification);
	return rest;
}
function toVerification(r:any): Verification {
	var v = <Verification>({
		code: r.code,
		message: r.message
	});
	return v;
}
function mapFavorites(response: Response): Favorite {
	var eat = response.json().favorites.map(toFavorites);
	return eat;
}
function addDesign(response: Response): boolean {
	var rest = response.json().addeddesign;
	return rest;
}
function addFavorite(response: Response): boolean {
	var eat = response.json().addedfavorite;
	return eat;
}
function addFeature(response: Response): boolean {
	var eat = response.json().addedfeature;
	return eat;
}
function toFavorites(r:any): Favorite {
	var fun = <Favorite>({
		id: r.id
	});
	return fun;
}
function mapTips(response:Response): Tip {
	var tip = response.json().tips.map(toTip);
	return tip;
}
function toTip(r:any): Tip {
	var tip = <Tip>({
		id: r.id,
		from_account_id: r.from_account_id,
		to_account_id: r.to_account_id,
		order_id: r.order_id,
		amount: r.amount,
		currency: r.currency,
		status: r.status,
		new: r.new,
		date_created: r.date_created,
		date_to_process: r.date_to_process,
		date_complete: r.date_complete,
	});
	return tip;
}
function mapOrder(response:Response): Order {
	var fun = response.json().order.map(toOrder);
	return fun;
}
function mapOrders(response:Response): Order {
	var fun = response.json().orders.map(toOrder);
	return fun;
}
function countOrders(response:Response): number {
	var fun = response.json().count;
	return fun;
}
function toOrder(r:any): Order {
	var order = <Order>({
		account: r.account,
		accountid: r.accountid,
		address: r.address,
		amount: r.amount,
		buyer_review: r.buyer_review,
		canceled: r.canceled,
		cardid: r.cardid,
		city: r.city,
		clientId: r.clientId,
		code: r.code,
		count: r.count,
		country: r.country,
		coupon: r.coupon,
		created: r.created,
		currencycode: r.currencycode,
		customization: r.customization,
		fulfillment_status: r.fulfillment_status,
		delivered: r.delivered,
		delivery: r.delivery,
		driver: r.driver,
		driverid: r.driverid,
		email: r.email,
		eta: r.eta,
		ez_code: r.ez_code,
		fee: r.fee,
		giftid: r.giftid,
		id: r.id,
		ip: r.ip,
		items: r.items,
		location: r.location,
		modified: r.modified,
		name: r.name,
		orderdate: r.orderdate,
		ordertype: r.ordertype,
		paid: r.paid,
		payment_expiry: r.payment_expiry,
		payment_id: r.payment_id,
		paypal: r.paypal,
		paypal_vendor: r.paypal_vendor,
		vendor_notified: r.vendor_notified,
		phone: r.phone,
		postal: r.postal,
		products: r.products,
		products_count: r.products_count,
		provence: r.provence,
		rating: r.rating,
		reviews: r.reviews,
		review_count: r.review_count,
		reports: r.reports,
		report_count: r.report_count,
		recurring: r.recurring,
		refunded: r.refunded,
		return_number: r.return_number,
		returned: r.returned,
		seller_review: r.seller_review,
		shipped: r.shipped,
		shipping_price: r.shipping_price,
		shippingid: r.shippingid,
		status: r.status,
		subtotal: r.subtotal,
		tax: r.tax,
		total: r.total,
		tracking_number: r.tracking_number,
		trip: r.trip,
		tripid: r.tripid,
		username: r.username,
		vendors: r.vendors,
		weight: r.weight,
		x: r.x
	});
	return order;
}

function mapTransactions(response:Response): Transaction {
    var res = response.json().transactions.map(toTransaction);
    return res;
}
function toTransaction(r:any): Transaction {
    var tx = <Transaction>({
        account_id: r.account_id,
        account_username: r.account_username,
        tx_id: r.tx_id,
        tx: r.tx,
        card_id: r.card_id,
        bank_id: r.bank_id,
        order_id: r.order_id,
        interac_id: r.interac_id,
        status: r.status,
        amount: r.amount,
        products: r.products,
        products_count: r.products_count,
        items: r.items,
        items_count: r.products_count,
        new: r.new,
        count: r.count,
        type: r.type,
        email: r.email,
        address: r.address,
        currency: r.currency,
        symbol: r.symbol,
        confirmations: r.confirmations,
        direction: r.direction,
        date_paid: r.date_paid,
        created: r.created,
        updated: r.updated
    });
    return tx;
}

function mapBlockchainTransaction(response:Response): BlockchainTransaction {
    var res = response.json().blockchain.map(toBlockchainTransaction);
    return res;
}
function toBlockchainTransaction(r:any): BlockchainTransaction {
    var blockchain = <BlockchainTransaction>({
		message:r.message,
		code:r.code,
		name:r.name,
		confirmations:r.confirmations,
		details:r.details,
		account:r.account,
		address:r.address,
		amount:r.amount,
		category:r.category,
		label:r.label,
		instantlock:r.instantlock,
		instantlock_internal:r.instantlock_internal,
		time:r.time,
		timereceived:r.timereceived,
		txid:r.txid,
		walletconflicts:r.walletconflicts
    });
    return blockchain;
}

function handleError(error: any) {
	var errorMsg = error.message || `Yikes!`
	console.error(error);
	return Observable.throw(errorMsg);
}